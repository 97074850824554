import React from "react";
import "./calendars.css";

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { Card, CardHeader, CardTitle, CardBody, Col, Row } from "reactstrap";

const localizer = momentLocalizer(moment)

class Calendars extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      myEventsList: [
        {
          start: new Date(),
          end: new Date(moment().add(7, "days")),
          title: "Tour to Malaysia"
        },
        {
          start: new Date(moment().subtract(3, "days")),
          end: new Date(moment().subtract(2, "days")),
          title: "Hotel Booked"
        },
        {
          start: new Date(moment().add(11, "days")),
          end: new Date(moment().add(11, "days")),
          title: "Design Analysis"
        }
      ]
    };

  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Calendar</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">App</a>
                <span className="breadcrumb-item active">Calendar</span>
              </nav>
            </div>
          </div>
        
          <Card className="borderless-shadow mb-0">
            <CardHeader>
              <CardTitle><h5 className="mb-0">Calendar App</h5></CardTitle>
            </CardHeader>
            <CardBody>
              
              <Row>
                <Col md={9}>
                  <Calendar
                    localizer={localizer}
                    events={this.state.myEventsList}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500, marginBottom: '.2rem' }}
                  />
                </Col>


                <Col md={3}>
                  <div className="aside">
                    <div className="aside-wrapper">

                      <div>
                        <p className="font-weight-semibold mb-2">Tags</p>
                        <p className="text-semibold">
                          <span className="status info mr-2"></span>
                          <a className="text-primary" href="#">Meeting</a>
                        </p>
                        <p className="text-semibold">
                          <span className="status success mr-2"></span>
                          <a className="text-primary" href="#">Holidays</a>
                        </p>
                        <p className="text-semibold">
                          <span className="status primary mr-2"></span>
                          <a className="text-primary" href="#">Private</a>
                        </p>
                        <p className="text-semibold">
                          <span className="status danger mr-2"></span>
                          <a className="text-primary" href="#">Important</a>
                        </p>
                      </div>
                      
                      <div className="mt-4">
                        <p className=" font-weight-semibold mb-2">Events</p>
                        <ul className="event-list">
                          <li className="event-item">Hotel Booked</li>
                          <li className="event-item">Tour to Malaysia</li>
                          <li className="event-item">Design Analysis</li>
                        </ul>
                        <p className="text-semibold">
                          <i className="mdi mdi-plus mr-2 text-info"></i>
                          <a className="text-primary" href="#" data-target="#event-modal" data-toggle="modal">
                            Add Events
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default Calendars;