import React from "react";
import "./tabs.css";

import { TabContent, CardBody, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from "classnames";

class Tabs extends React.Component {
  constructor(props) {
    super(props);

    this.tabvertical = this.tabvertical.bind(this);
    this.tabhorizontal = this.tabhorizontal.bind(this);
    this.tabround = this.tabround.bind(this);
    this.tabbordericon = this.tabbordericon.bind(this);

    this.state = {
      tabvertical: '1',
      tabhorizontal: '1',
      tabround: '1',
      tabbordericon: '1'
    };
  }

  tabvertical(tab) {
    if (this.state.tabvertical !== tab) {
      this.setState({
        tabvertical: tab
      });
    }
  };

  tabhorizontal(tab) {
    if (this.state.tabhorizontal !== tab) {
      this.setState({
        tabhorizontal: tab
      });
    }
  };

  tabround(tab) {
    if (this.state.tabround !== tab) {
      this.setState({
        tabround: tab
      });
    }
  };

  tabbordericon(tab) {
    if (this.state.tabbordericon !== tab) {
      this.setState({
        tabbordericon: tab
      });
    }
  };
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Tabs</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1"></i>Home</a>
                <a className="breadcrumb-item" href="#">Components</a>
                <span className="breadcrumb-item active">Tabs</span>
              </nav>
            </div>
          </div>

          <Row>
            <Col md={6} className="d-flex">
              <Card className="borderless-shadow">
                <div className="card-header">
                  <h4 className="card-title">Tab with Icon</h4>
                </div>
                {/* <CardTitle>Tab Border Icon</CardTitle> */}
                <CardBody>
                  <Nav tabs>
                    <NavItem >
                      <NavLink className={classnames({ active: this.state.tabbordericon === '1' })} onClick={() => { this.tabbordericon('1'); }}>
                        <i className="fa fa-home mr-2"></i>Home
                      </NavLink>
                    </NavItem>
                    <NavItem ><NavLink className={classnames({ active: this.state.tabbordericon === '2' })} onClick={() => { this.tabbordericon('2'); }}><i className="fa fa-user mr-2"></i> Profile</NavLink></NavItem>
                    <NavItem ><NavLink className={classnames({ active: this.state.tabbordericon === '3' })} onClick={() => { this.tabbordericon('3'); }}><i className="fa fa-image mr-2"></i>Portfolio</NavLink></NavItem>
                    <NavItem ><NavLink className={classnames({ active: this.state.tabbordericon === '4' })} onClick={() => { this.tabbordericon('4'); }}><i className="fa fa-phone-square mr-2"></i>Contact</NavLink></NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.tabbordericon} >
                    <TabPane tabId="1">
                      <p className="mb-0">The best way is to develop and follow a plan. Start with your goals in mind and then work backwards to develop the plan. What steps are required to get you to the goals? Make the plan as detailed as possible. Try to visualize and then plan for, every possible setback. Commit the plan to paper and then keep it with you at all times. Review it regularly and ensure that every step takes you closer to your Vision and Goals. If the plan doesn’t support the vision then change it!</p>
                    </TabPane>

                    <TabPane tabId="2">
                      <p className="mb-0">We all carry a lot of baggage, thanks to our upbringing. The majority of people carry with them, an entire series of self-limiting beliefs that will absolutely stop, and hold them back from, success. Things like “I’m not good enough”, “I’m not smart enough”, “I’m not lucky enough”, and the worst, “I’m not worthy” are but a few of the self-limiting beliefs I have encountered. We carry them with us like rocks in a knapsack, and then use them to sabotage our success. So, how twisted is that? </p>
                    </TabPane>

                    <TabPane tabId="3">
                      <p className="mb-0">Benjamin Franklin, inventor, statesman, writer, publisher and economist relates in his autobiography that early in his life he decided to focus on arriving at moral perfection. He made a list of 13 virtues, assigning a page to each. Under each virtue he wrote a summary that gave it fuller meaning. Then he practiced each one for a certain length of time. To make these virtues a habit, Franklin can up with a method to grade himself on his daily actions.</p>
                    </TabPane>

                    <TabPane tabId="4" >
                      <p className="mb-0">The other virtues practice in succession by Franklin were silence, order, resolution, frugality, industry, sincerity, Justice, moderation, cleanliness, tranquility, chastity and humility. For the summary order he followed a little scheme of employing his time each day. From five to seven each morning he spent in bodily personal attention, saying a short prayer, thinking over the day’s business and resolutions.</p>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} className="d-flex">
              <Card className="borderless-shadow" style={{ width: '100%' }}>
                <div className="card-header">
                  <h4 className="card-title">Tab with inner Card</h4>
                </div>
                {/* <CardTitle>Tab Round</CardTitle> */}
                <CardBody>
                  <Nav tabs>
                    <NavItem ><NavLink className={classnames({ active: this.state.tabround === '1' })} onClick={() => { this.tabround('1'); }}><i className="fa fa-home mr-2"></i>Home</NavLink></NavItem>
                    <NavItem ><NavLink className={classnames({ active: this.state.tabround === '2' })} onClick={() => { this.tabround('2'); }}><i className="fa fa-user mr-2"></i> Profile</NavLink></NavItem>
                    <NavItem ><NavLink className={classnames({ active: this.state.tabround === '3' })} onClick={() => { this.tabround('3'); }}><i className="fa fa-image mr-2"></i>Portfolio</NavLink></NavItem>
                    <NavItem ><NavLink className={classnames({ active: this.state.tabround === '4' })} onClick={() => { this.tabround('4'); }}><i className="fa fa-phone-square mr-2"></i>Contact</NavLink></NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.tabround} >
                    <TabPane tabId="1">
                      <Card body>
                        <CardTitle>Special Title Treatment</CardTitle>
                        <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                        <Button>Go somewhere</Button>
                      </Card>
                    </TabPane>

                    <TabPane tabId="2">
                      <Card body>
                        <CardTitle>Special Title Treatment</CardTitle>
                        <CardText>We all carry a lot of baggage, thanks to our upbringing.</CardText>
                        <Button>Go somewhere</Button>
                      </Card>
                    </TabPane>

                    <TabPane tabId="3">
                      <Card body>
                        <CardTitle>Special Title Treatment</CardTitle>
                        <CardText>Benjamin Franklin, inventor, statesman, writer, publisher and economist relates in his autobiography that early in his life he decided to focus on arriving at moral perfection.</CardText>
                        <Button>Go somewhere</Button>
                      </Card>
                    </TabPane>

                    <TabPane tabId="4" >
                      <Card body>
                        <CardTitle>Special Title Treatment</CardTitle>
                        <CardText>For the summary order he followed a little scheme of employing his time each day.</CardText>
                        <Button>Go somewhere</Button>
                      </Card>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={6} className="d-flex">
              <Card className="borderless-shadow tab-with-icon">
                <div className="card-header">
                  <h4 className="card-title">Tab without Icon</h4>
                </div>
                {/* <CardTitle>Tab Border</CardTitle> */}
                <CardBody>
                  <Nav tabs>
                    <NavItem><NavLink className={classnames({ active: this.state.tabhorizontal === '1' })} onClick={() => { this.tabhorizontal('1'); }}>Home</NavLink></NavItem>
                    <NavItem><NavLink className={classnames({ active: this.state.tabhorizontal === '2' })} onClick={() => { this.tabhorizontal('2'); }}>Profile</NavLink></NavItem>
                    <NavItem><NavLink className={classnames({ active: this.state.tabhorizontal === '3' })} onClick={() => { this.tabhorizontal('3'); }}>Portfolio</NavLink></NavItem>
                    <NavItem><NavLink className={classnames({ active: this.state.tabhorizontal === '4' })} onClick={() => { this.tabhorizontal('4'); }}>Contact</NavLink></NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.tabhorizontal} >
                    <TabPane tabId="1">
                      <p className="mb-0">The best way is to develop and follow a plan. Start with your goals in mind and then work backwards to develop the plan. What steps are required to get you to the goals? Make the plan as detailed as possible. Try to visualize and then plan for, every possible setback. Commit the plan to paper and then keep it with you at all times. Review it regularly and ensure that every step takes you closer to your Vision and Goals. If the plan doesn’t support the vision then change it!</p>
                    </TabPane>

                    <TabPane tabId="2">
                      <p className="mb-0">We all carry a lot of baggage, thanks to our upbringing. The majority of people carry with them, an entire series of self-limiting beliefs that will absolutely stop, and hold them back from, success. Things like “I’m not good enough”, “I’m not smart enough”, “I’m not lucky enough”, and the worst, “I’m not worthy” are but a few of the self-limiting beliefs I have encountered. We carry them with us like rocks in a knapsack, and then use them to sabotage our success. So, how twisted is that? </p>
                    </TabPane>

                    <TabPane tabId="3">
                      <p className="mb-0">Benjamin Franklin, inventor, statesman, writer, publisher and economist relates in his autobiography that early in his life he decided to focus on arriving at moral perfection. He made a list of 13 virtues, assigning a page to each. Under each virtue he wrote a summary that gave it fuller meaning. Then he practiced each one for a certain length of time. To make these virtues a habit, Franklin can up with a method to grade himself on his daily actions.</p>
                    </TabPane>

                    <TabPane tabId="4" >
                      <p className="mb-0">The other virtues practice in succession by Franklin were silence, order, resolution, frugality, industry, sincerity, Justice, moderation, cleanliness, tranquility, chastity and humility. For the summary order he followed a little scheme of employing his time each day. From five to seven each morning he spent in bodily personal attention, saying a short prayer, thinking over the day’s business and resolutions.</p>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} className="d-flex">
              <Card className="borderless-shadow tab-vertical">
                <div className="card-header">
                  <h4 className="card-title">Tab vertical</h4>
                </div>
                {/* <CardTitle>Tab vertical</CardTitle> */}
                <CardBody>
                  <Row className="clearfix">
                    <Col sm={3} className="custom-col">
                      <Nav pills tabs className="custom-nav-tabs">
                        <NavItem className="custom-ul"><NavLink className={classnames({ active: this.state.tabvertical === '1' })} onClick={() => { this.tabvertical('1'); }}>Tab 1</NavLink></NavItem>
                        <NavItem className="custom-ul"><NavLink className={classnames({ active: this.state.tabvertical === '2' })} onClick={() => { this.tabvertical('2'); }}>Tab 2</NavLink></NavItem>
                        <NavItem className="custom-ul"><NavLink className={classnames({ active: this.state.tabvertical === '3' })} onClick={() => { this.tabvertical('3'); }}>Tab 3</NavLink></NavItem>
                        <NavItem className="custom-ul"><NavLink className={classnames({ active: this.state.tabvertical === '4' })} onClick={() => { this.tabvertical('4'); }}>Tab 4</NavLink></NavItem>
                      </Nav>
                    </Col>
                    
                    <Col sm={9}>
                      <TabContent activeTab={this.state.tabvertical} >
                        <TabPane tabId="1">
                          <p className="mb-0">The best way is to develop and follow a plan. Start with your goals in mind and then work backwards to develop the plan. What steps are required to get you to the goals? Make the plan as detailed as possible. Try to visualize and then plan for, every possible setback. Commit the plan to paper and then keep it with you at all times. Review it regularly and ensure that every step takes you closer to your Vision and Goals!</p>
                        </TabPane>

                        <TabPane tabId="2">
                          <p className="mb-0">We all carry a lot of baggage, thanks to our upbringing. The majority of people carry with them, an entire series of self-limiting beliefs that will absolutely stop, and hold them back from, success. Things like “I’m not good enough”, “I’m not smart enough”, “I’m not lucky enough”, and the worst, “I’m not worthy” are but a few of the self-limiting beliefs I have encountered. We carry them with us like rocks in a knapsack.</p>
                        </TabPane>

                        <TabPane tabId="3">
                          <p className="mb-0">Benjamin Franklin, inventor, statesman, writer, publisher and economist relates in his autobiography that early in his life he decided to focus on arriving at moral perfection. He made a list of 13 virtues, assigning a page to each. Under each virtue he wrote a summary that gave it fuller meaning. Then he practiced each one for a certain length of time. To make these virtues a habit, Franklin can up with a method to grade himself on his daily actions.</p>
                        </TabPane>

                        <TabPane tabId="4">
                          <p className="mb-0">The other virtues practice in succession by Franklin were silence, order, resolution, frugality, industry, sincerity, Justice, moderation, cleanliness, tranquility, chastity and humility. For the summary order he followed a little scheme of employing his time each day. From five to seven each morning he spent in bodily personal attention, saying a short prayer, thinking over the day’s business and resolutions.</p>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </div>
    );
  }
}

export default Tabs;