import React from "react";
import "./form-layout.css";
import { Row, Col, Card, CardBody, FormGroup, Input, Label, CardTitle, CardHeader, Form } from "reactstrap";

class FormLayouts extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Form Layouts</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                {/* <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a> */}
                <a className="breadcrumb-item" href="#">Forms</a>
                <span className="breadcrumb-item active">Form Layouts</span>
              </nav>
            </div>
          </div>
          
          <Row>
            <Col md={6} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardHeader>
                  <CardTitle><h5 className="font-weight-semibold mb-0">Basic Form with label</h5></CardTitle>
                </CardHeader>
                <CardBody className="pt-4 pb-5">
                  <form>
                    <FormGroup>
                      <div>
                        <label htmlFor="email1">Email</label>
                        <input id="email1" type="email" className="form-control" />
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <div className="form-group">
                        <label htmlFor="password1">Password</label>
                        <input id="password1" type="password" className="form-control" />
                      </div>
                    </FormGroup>
                    
                    <FormGroup>
                      <div className="checkbox">
                        <input id="basicFormCheckbox2" type="checkbox" />
                        <label htmlFor="basicFormCheckbox2">Remember Me</label>
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <div>
                        <button className="btn btn-gradient-card-blue mr-2">Login</button>
                        <button className="btn btn-secondary">Reset</button>
                      </div>
                    </FormGroup>
                    
                  </form>
                </CardBody>
              </Card>
            </Col>

            <Col md={6} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardHeader>
                  <CardTitle><h5 className="font-weight-semibold mb-0">Basic Form With Placeholder</h5></CardTitle>
                </CardHeader>
                <CardBody>
                  <form>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <input type="text" className="form-control" placeholder="First Name" />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <input type="text" className="form-control" placeholder="Last Name" />
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormGroup>
                      <div>
                        <input type="email" className="form-control" placeholder="Email" />
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <div>
                        <input type="password" className="form-control" placeholder="Password" />
                      </div>
                    </FormGroup>

                    <FormGroup className="mt-4">
                      <div className="radio d-inline mr-2">
                        <input id="noLabelRadio1" name="noLabel" type="radio" defaultChecked />
                        <label htmlFor="noLabelRadio1">Male</label>
                      </div>
                      <div className="radio d-inline mr-2">
                        <input id="noLabelRadio2" name="noLabel" type="radio" />
                        <label htmlFor="noLabelRadio2">Female</label>
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <div className="switch d-inline mr-3 mt-3">
                        <Input type="checkbox" id="switch-1" />
                        <Label htmlFor="switch-1" />
                      </div>
                      <Label htmlFor="switch-1" style={{marginTop: "-30px"}}>I agree with Terms & Conditions</Label>
                    </FormGroup>

                    <FormGroup>
                      <div>
                        <button className="btn btn-gradient-card-blue mr-2">Submit</button>
                        <button className="btn btn-secondary">Reset</button>
                      </div>
                    </FormGroup>
                    
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className="borderless-shadow">
                <CardHeader>
                  <CardTitle>
                    <h5 className="mb-0">Form Inline</h5>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Form inline>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                      <input type="text" className="form-control mr-2" placeholder="Email Address" />
                    </FormGroup>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                      <input type="password" className="form-control mr-2" placeholder="Password" />
                    </FormGroup>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                      <div className="checkbox mr-2">
                        <input id="inlineCheckbox1" type="checkbox" />
                        <label htmlFor="inlineCheckbox1">Remember Me</label>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                      <button type="submit" className="btn btn-gradient-card-blue">Submit</button>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className="borderless-shadow">
                <CardHeader>
                  <CardTitle>
                    <h5 className="mb-0">Form Row</h5>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm={8} className="mr-auto ml-auto">
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="control-label">User Name</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group col-md-6">
                          <label className="control-label">Password</label>
                          <input type="password" className="form-control" />
                        </div>
                      </div>
                      
                      <FormGroup>
                        <label className="control-label">Address</label>
                        <input type="text" className="form-control" />
                      </FormGroup>

                      <FormGroup>
                        <label className="control-label">Address 2</label>
                        <input type="text" className="form-control" />
                      </FormGroup>

                      <FormGroup>
                        <div className="form-row">
                          <div className="form-group col-md-5">
                            <label className="control-label">City</label>
                            <input type="text" className="form-control" />
                          </div>
                          <div className="form-group col-md-5">
                            <label className="control-label">State</label>
                            <input type="text" className="form-control" />
                          </div>
                          <div className="form-group col-md-2">
                            <label className="control-label nobreak">Zip Code</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <div className="form-row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <div className="checkbox">
                                <input id="formRowCheckbox1" type="checkbox" />
                                <label htmlFor="formRowCheckbox1">I agree to the <a href="#">Term &amp; Conditions</a></label>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="text-sm-right">
                              <button className="btn btn-gradient-card-blue">Create Account</button>
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          
          <Row>
            <Col>
              <Card className="borderless-shadow mb-0">
                <CardHeader>
                  <CardTitle>
                    <h5 className="mb-0">Form with view only</h5>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label control-label text-dark">First name:</label>
                        <div className="col-sm-9">
                          <p className="form-control-plaintext">Ervin</p>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label control-label text-dark">Last name:</label>
                        <div className="col-sm-9">
                          <p className="form-control-plaintext">Walter</p>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label control-label text-dark">Gender:</label>
                        <div className="col-sm-9">
                          <p className="form-control-plaintext">Male</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label control-label text-dark">Card number:</label>
                        <div className="col-sm-9">
                          <p className="form-control-plaintext">xxxx-xxxx-xxxx-4515</p>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label control-label text-dark">Email:</label>
                        <div className="col-sm-9">
                          <p className="form-control-plaintext">ervin.walter@gamil.com</p>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label control-label text-dark">Phone</label>
                        <div className="col-sm-9">
                          <p className="form-control-plaintext">012345678</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>    
            </Col>
          </Row>
        
        </div>
      </div>
    );
  }
}

export default FormLayouts;