import React from 'react';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {  };
  }
  render() {
    return (
      <footer className="main-footer">
        <div className="footer-left">
          Copyright © 2020 | SKYT -  All rights Reserved
        </div>
        <div className="footer-right">
          1.0.0
        </div>
      </footer>
    );
  }
}

export default Footer;