import React from 'react';
import "./sidebar.css"
import { Link } from 'react-router-dom';

import { Scrollbars } from 'react-custom-scrollbars';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  render() {
    return (
      <div className="main-sidebar">
        <aside id="sidebar-wrapper" style={{height: "100%"}}>

          {/* BRAND */}
          <div className="sidebar-brand">
            <div style={{display: 'inline-block'}}>
              <a href="/" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img src={require(`../../assets/images/logo/logo.png`)} className="company-logo-img" />
                <span>SkyT</span>
              </a>
            </div>
          </div>
          
          <div className="sidebar-brand sidebar-brand-sm">
            <a href="#">
              {/* AL */}
              <img src={require(`../../assets/images/logo/logo.png`)} className="company-logo-img" />
            </a>
          </div>
          
          {/* SIDEBAR LINKS */}
          <Scrollbars
            autoHide
            autoHeight
            autoHeightMin={300}
            autoHeightMax={595}
            width="100%"
          >

            <ul className="sidebar-menu">
              <li className="menu-header">Navigations</li>
              <li className="nav-item dropdown "> {/* active */}
                <a href="#" className="nav-link has-dropdown">
                  <i className="fas fa-chalkboard"/><span>Dashboard</span>
                </a>
                <ul className="dropdown-menu">
                  <li className=""><Link className="nav-link" to="/dashboard1">Dashboard 1</Link></li>
                  <li><Link className="nav-link" to="/dashboard2">Dashboard 2</Link></li>
                  <li><Link className="nav-link" to="/dashboard3">Dashboard 3</Link></li>
                  <li><Link className="nav-link" to="/dashboard4">Dashboard 4</Link></li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a href="#" className="nav-link has-dropdown"><i className="fas fa-store"></i><span>E-Commerce</span></a>
                <ul className="dropdown-menu">
                  <li><Link className="nav-link" to="/products">Products</Link></li>
                  <li><Link className="nav-link" to="/product-detail">Product detail</Link></li>
                  <li><Link className="nav-link" to="/cart">Cart</Link></li>
                  <li><Link className="nav-link" to="/orders">Orders</Link></li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a href="#" className="nav-link has-dropdown" data-toggle="dropdown"><i className="fas fa-columns" />
                  <span>Apps</span></a>
                <ul className="dropdown-menu">
                  <li><Link className="nav-link" to="/email">Email</Link></li>
                  <li><Link className="nav-link" to="/calendars">Calendar App</Link></li>
                  <li><Link className="nav-link" to="/chat">Chat App</Link></li>
                  <li><Link className="nav-link" to="/contact">Contact</Link></li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a href="#" className="nav-link has-dropdown"><i className="fas fa-th" /> <span>UI Elements</span></a>
                <ul className="dropdown-menu">
                  {/* <li><a className="nav-link" href="#">Alert</a></li> */}
                  <li><Link className="nav-link" to="/alert">Alerts</Link></li>
                  <li><Link className="nav-link" to="/badge">Badges</Link></li>
                  <li><Link className="nav-link" to="/button">Buttons</Link></li>
                  <li><Link className="nav-link" to="/cards">Cards</Link></li>
                  <li><Link className="nav-link" to="/colors">Colors</Link></li>
                  <li><Link className="nav-link" to="/icons">Icons</Link></li>
                  <li><Link className="nav-link" to="/lists">Lists</Link></li>
                  <li><Link className="nav-link" to="/typography">Typogrphy</Link></li>
                  <li><Link className="nav-link" to="/navs">Navs</Link></li>
                  <li><Link className="nav-link" to="/navbar">Navbar</Link></li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a href="#" className="nav-link has-dropdown">
                  <i className="fab fa-trello"></i>
                  <span>Components</span>
                </a>
                <ul className="dropdown-menu">
                  {/* <li><a className="nav-link" href="#">Accordion</a></li> */}
                  <li><Link className="nav-link" to="/accordion">Accordion</Link></li>
                  <li><Link className="nav-link" to="/carousel">Carousel</Link></li>
                  <li><Link className="nav-link" to="/dropdown">Dropdown</Link></li>
                  <li><Link className="nav-link" to="/modals">Modals</Link></li>
                  <li><Link className="nav-link" to="/popover">Popovers</Link></li>
                  <li><Link className="nav-link" to="/progress">Progress Bars</Link></li>
                  <li><Link className="nav-link" to="/tabs">Tabs</Link></li>
                  <li><Link className="nav-link" to="/tooltips">Tooltips</Link></li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a href="#" className="nav-link has-dropdown">
                  <i className="fas fa-vector-square" /> <span>Widgets</span>
                </a>
                <ul className="dropdown-menu">
                  <li><Link className="nav-link" to="/widget-data">Data Widgets</Link></li>
                  <li><Link className="nav-link" to="/media-data">Media Widgets</Link></li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a href="#" className="nav-link has-dropdown">
                  <i className="fas fa-file-alt" /> <span>Forms</span>
                </a>
                <ul className="dropdown-menu">
                  <li><Link className="nav-link" to="/form-elements">Form Elements</Link></li>
                  <li><Link className="nav-link" to="/form-layouts">Form Layouts</Link></li>
                  <li><Link className="nav-link" to="/form-validation">Form Validations</Link></li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a href="#" className="nav-link has-dropdown"><i className="fas fa-table" /> <span>Tables</span></a>
                <ul className="dropdown-menu">
                  <li><Link className="nav-link" to="/basic-tables">Tables</Link></li>
                  <li><Link className="nav-link" to="/data-tables">Data Tables</Link></li>
                </ul>
              </li>

              <li>
                <Link className="nav-link" to="/charts">
                  <i className="fas fa-chart-bar" />
                  <span>Charts</span>
                </Link>
              </li>

              <li>
                <Link className="nav-link" to="/maps">
                  <i className="fas fa-map-marker-alt" />
                  <span>Vector Maps</span>
                </Link>
              </li>

              {/* <li className="menu-header">Others</li>

              <li className="nav-item dropdown">
                <a href="#" className="nav-link has-dropdown"><i className="fas fa-level-down-alt" /> <span>Multiple Levels</span></a>
                <ul className="dropdown-menu">
                  <li>
                    <a href="#" className="dropdown-item has-dropdown">Multiple Level 1</a>
                    <ul className="submenu dropdown-menu">
                      <li><a href="#" className="nav-link dropdown-item">Level 1a</a></li>
                      <li><a href="#" className="nav-link dropdown-item">Level 1b</a></li>
                      <li><a href="#" className="nav-link dropdown-item">Level 1c</a></li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown"><a className="nav-link" href="#">Level 2</a></li>
                  <li className="nav-item dropdown"><a className="nav-link" href="#">Level 3</a></li>
                </ul>
              </li> */}
            </ul>
          </Scrollbars>

        </aside>
      </div>
    );
  }
}

export default Sidebar;