import React from "react";
import "./lists.css";

class Lists extends React.Component {
  constructor(props) {
    super();
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Lists</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">UI Elements</a>
                <span className="breadcrumb-item active">Lists</span>
              </nav>
            </div>
          </div>
          
          <div className="card borderless-shadow">
            <div className="card-header pt-3 pb-3">
              <h4 className="mb-0">Basic List Style</h4>
            </div>
            <div className="card-body">
              <p>Applied <code>.list & .custom-list-padding</code> class to <code>ul</code> tag to display better list alignment.</p>
              <div className="row">
                <div className="col-lg-4 mb-3">
                  <h5 className=" mb-3">List Unordered</h5>
                  <ul className="list custom-list-padding">
                    <li>Lorem ipsum dolor sit amet</li>
                    <li>Consectetur adipiscing elit</li>
                    <li>Integer molestie lorem</li>
                    <li>Facilisis in pretium dui</li>
                    <li>Nulla volutpat aliquam velit</li>
                  </ul>
                </div>
                <div className="col-lg-4 mb-3">
                  <h5 className=" mb-3">List Ordered</h5>
                  <ol className="list custom-list-padding">
                    <li>Lorem ipsum dolor sit amet</li>
                    <li>Consectetur adipiscing elit</li>
                    <li>Integer molestie lorem</li>
                    <li>Facilisis in pretium dui</li>
                    <li>Nulla volutpat aliquam velit</li>
                  </ol>
                </div>
                <div className="col-lg-4 mb-3">
                  <h5 className=" mb-3">List Unstyled</h5>
                  <ul className="list list-unstyled">
                    <li>Lorem ipsum dolor sit amet</li>
                    <li>Consectetur adipiscing elit</li>
                    <li>Integer molestie lorem</li>
                    <li>Facilisis in pretium dui</li>
                    <li>Nulla volutpat aliquam velit</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="card borderless-shadow">
            <div className="card-header pt-3 pb-3">
              <h4 className="mb-0">List with Icons</h4>
            </div>
            <div className="card-body">
              <div className="row mb-4">
                <div className="col-xl-3 col-md-6 mb-3">
                  <h5 className="mb-3">Tick</h5>
                  <ul className="list tick">
                    <li>Lorem ipsum dolor sit amet</li>
                    <li>Consectetur adipiscing elit</li>
                    <li>Integer molestie lorem</li>
                    <li>Facilisis in pretium dui</li>
                    <li>Nulla volutpat aliquam velit</li>
                  </ul>
                </div>
                <div className="col-xl-3 col-md-6 mb-3">
                  <h5 className=" mb-3">Dash</h5>
                  <ul className="list dash">
                    <li>Lorem ipsum dolor sit amet</li>
                    <li>Consectetur adipiscing elit</li>
                    <li>Integer molestie lorem</li>
                    <li>Facilisis in pretium dui</li>
                    <li>Nulla volutpat aliquam velit</li>
                  </ul>
                </div>
                <div className="col-xl-3 col-md-6 mb-3">
                  <h5 className=" mb-3">Star</h5>
                  <ul className="list star">
                    <li>Lorem ipsum dolor sit amet</li>
                    <li>Consectetur adipiscing elit</li>
                    <li>Integer molestie lorem</li>
                    <li>Facilisis in pretium dui</li>
                    <li>Nulla volutpat aliquam velit</li>
                  </ul>
                </div>
                <div className="col-xl-3 col-md-6 mb-3">
                  <h5 className=" mb-3">Bullet</h5>
                  <ul className="list bullet">
                    <li>Lorem ipsum dolor sit amet</li>
                    <li>Consectetur adipiscing elit</li>
                    <li>Integer molestie lorem</li>
                    <li>Facilisis in pretium dui</li>
                    <li>Nulla volutpat aliquam velit</li>
                  </ul>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-xl-3 col-md-6 mb-3">
                  <h5 className=" mb-3">Arrow</h5>
                  <ul className="list arrow">
                    <li>Lorem ipsum dolor sit amet</li>
                    <li>Consectetur adipiscing elit</li>
                    <li>Integer molestie lorem</li>
                    <li>Facilisis in pretium dui</li>
                    <li>Nulla volutpat aliquam velit</li>
                  </ul>
                </div>
                <div className="col-xl-3 col-md-6 mb-3">
                  <h5 className=" mb-3">Decimal</h5>
                  <ul className="list decimal">
                    <li>Lorem ipsum dolor sit amet</li>
                    <li>Consectetur adipiscing elit</li>
                    <li>Integer molestie lorem</li>
                    <li>Facilisis in pretium dui</li>
                    <li>Nulla volutpat aliquam velit</li>
                  </ul>
                </div>
                <div className="col-xl-3 col-md-6 mb-3">
                  <h5 className=" mb-3">Upper Roman</h5>
                  <ul className="list upper-roman">
                    <li>Lorem ipsum dolor sit amet</li>
                    <li>Consectetur adipiscing elit</li>
                    <li>Integer molestie lorem</li>
                    <li>Facilisis in pretium dui</li>
                    <li>Nulla volutpat aliquam velit</li>
                  </ul>
                </div>
                <div className="col-xl-3 col-md-6 mb-3">
                  <h5 className=" mb-3">Lower Alpha</h5>
                  <ul className="list lower-alpha">
                    <li>Lorem ipsum dolor sit amet</li>
                    <li>Consectetur adipiscing elit</li>
                    <li>Integer molestie lorem</li>
                    <li>Facilisis in pretium dui</li>
                    <li>Nulla volutpat aliquam velit</li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <p>Use <code>.bullet-*</code> as prefix with contextual color to applied color to bullet, e.g <code>.bullet-primary</code></p>
                </div>
                <div className="col-md-6">
                  <h5 className=" mb-3">Bullet Primary</h5>
                  <ul className="list tick bullet-primary">
                    <li>Lorem ipsum dolor sit amet</li>
                    <li>Consectetur adipiscing elit</li>
                    <li>Integer molestie lorem</li>
                    <li>Facilisis in pretium dui</li>
                    <li>Nulla volutpat aliquam velit</li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <h5 className=" mb-3">Bullet Warning</h5>
                  <ul className="list star bullet-warning">
                    <li>Lorem ipsum dolor sit amet</li>
                    <li>Consectetur adipiscing elit</li>
                    <li>Integer molestie lorem</li>
                    <li>Facilisis in pretium dui</li>
                    <li>Nulla volutpat aliquam velit</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="card borderless-shadow">
            <div className="card-header pt-3 pb-3">
              <h4 className="mb-0">List Group</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-4 mb-4">
                  <h5 className="mb-3">Basic Example</h5>
                  <ul className="list-group">
                    <li className="list-group-item">Cras justo odio</li>
                    <li className="list-group-item">Dapibus ac facilisis in</li>
                    <li className="list-group-item">Morbi leo risus</li>
                    <li className="list-group-item">Porta ac consectetur ac</li>
                    <li className="list-group-item">Vestibulum at eros</li>
                  </ul>
                </div>
                <div className="col-lg-4 mb-4">
                  <h5 className="mb-3">With Links</h5>
                  <div className="list-group">
                    <a href="#" className="list-group-item list-group-item-action active">
                      <i className="mdi mdi-check m-r-10 text-success mr-2" />Cras justo odio
                      </a>
                    <a href="#" className="list-group-item list-group-item-action">
                      <i className="mdi mdi-check m-r-10 text-success mr-2" />Dapibus ac facilisis in
                      </a>
                    <a href="#" className="list-group-item list-group-item-action">
                      <i className="mdi mdi-check m-r-10 text-success mr-2" />Morbi leo risus
                      </a>
                    <a href="#" className="list-group-item list-group-item-action">
                      <i className="mdi mdi-check m-r-10 text-success mr-2" />Porta ac consectetur ac
                      </a>
                    <a href="#" className="list-group-item list-group-item-action disabled">
                      <i className="mdi mdi-check m-r-10 text-success mr-2" /> Vestibulum at eros
                      </a>
                  </div>
                </div>
                <div className="col-lg-4 mb-4">
                  <h5 className="mb-3">With badges</h5>
                  <ul className="list-group">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Cras justo odio
                        <span className="badge badge-primary badge-pill">14</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Dapibus ac facilisis in
                        <span className="badge badge-primary badge-pill">2</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Morbi leo risus
                        <span className="badge badge-warning badge-pill">1</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Porta ac consectetur ac
                        <span className="badge badge-success badge-pill">3</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Vestibulum at eros
                        <span className="badge badge-info badge-pill">6</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="card borderless-shadow mb-0">
            <div className="card-header pt-3 pb-3">
              <h4 className="mb-0">List Media</h4>
            </div>
            <div className="card-body">
              <div className="row mb-4">
                <div className="col-xl-4">
                  <h5 className="mb-3">Basic List Media</h5>
                  <div className="card">
                    <ul className="list-media">
                      <li className="list-item">
                        <div className="p-3">
                          <div className="media-img">
                            <img src={require(`../../../assets/images/profiles/profile-pic-17.jpg`)} />
                          </div>
                          <div className="info">
                            <span className="title">Susie Willis</span>
                            <span className="sub-title">commented on your post's</span>
                          </div>
                        </div>
                      </li>
                      <li className="list-item">
                        <div className="p-3">
                          <div className="media-img">
                            <img src={require(`../../../assets/images/profiles/profile-pic-21.jpg`)} />
                          </div>
                          <div className="info">
                            <span className="title">Debra Stewart</span>
                            <span className="sub-title">commented on your post's</span>
                          </div>
                        </div>
                      </li>
                      <li className="list-item">
                        <div className="p-3">
                          <div className="media-img">
                            <img src={require(`../../../assets/images/profiles/profile-pic-5.jpg`)} />
                          </div>
                          <div className="info">
                            <span className="title">Erin Gonzales</span>
                            <span className="sub-title">commented on your post's</span>
                          </div>
                        </div>
                      </li>
                      <li className="list-item">
                        <div className="p-3">
                          <div className="media-img">
                            <img src={require(`../../../assets/images/profiles/profile-pic-20.jpg`)} />
                          </div>
                          <div className="info">
                            <span className="title">Ava Alexander</span>
                            <span className="sub-title">commented on your post's</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-xl-4">
                  <h5 className="mb-3">List Media Link</h5>
                  <div className="card">
                    <ul className="list-media">
                      <li className="list-item">
                        <a href="#" className="media-hover">
                          <div className="media-img">
                            <div className="icon-avatar bg-primary">
                              <i className="ti-settings" />
                            </div>
                          </div>
                          <div className="info">
                            <span className="title">
                              System shutdown
                              </span>
                            <span className="sub-title">8 min ago</span>
                          </div>
                        </a>
                      </li>
                      <li className="list-item">
                        <a href="#" className="media-hover">
                          <div className="media-img">
                            <div className="icon-avatar bg-success">
                              <i className="ti-user" />
                            </div>
                          </div>
                          <div className="info">
                            <span className="title">
                              New User Registered
                              </span>
                            <span className="sub-title">12 min ago</span>
                          </div>
                        </a>
                      </li>
                      <li className="list-item">
                        <a href="#" className="media-hover">
                          <div className="media-img">
                            <div className="icon-avatar bg-warning">
                              <i className="ti-file" />
                            </div>
                          </div>
                          <div className="info">
                            <span className="title">
                              New Attacthemnet
                              </span>
                            <span className="sub-title">12 min ago</span>
                          </div>
                        </a>
                      </li>
                      <li className="list-item">
                        <a href="#" className="media-hover">
                          <div className="media-img">
                            <div className="icon-avatar bg-info">
                              <i className="ti-shopping-cart" />
                            </div>
                          </div>
                          <div className="info">
                            <span className="title">
                              New Order Received
                              </span>
                            <span className="sub-title">12 min ago</span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-xl-4">
                  <h5 className="mb-3">List Media With Action</h5>
                  <div className="card">
                    <ul className="list-media">
                      <li className="list-item">
                        <div className="p-3">
                          <div className="media-img">
                            <img src={require(`../../../assets/images/profiles/profile-pic-7.jpg`)} />
                          </div>
                          <div className="info">
                            <span className="title">Susie Willis</span>
                            <span className="sub-title">commented on your post's</span>
                            <div className="float-item">
                              <div className="checkbox ">
                                <input id="checkbox-1" name="checkbox-1" type="checkbox" defaultChecked />
                                <label htmlFor="checkbox-1" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="list-item">
                        <div className="p-3">
                          <div className="media-img">
                            <img src={require(`../../../assets/images/profiles/profile-pic-26.jpg`)} />
                          </div>
                          <div className="info">
                            <span className="title">Debra Stewart</span>
                            <span className="sub-title">commented on your post's</span>
                            <div className="float-item">
                              <div className="checkbox ">
                                <input id="checkbox-2" name="checkbox-2" type="checkbox" defaultChecked />
                                <label htmlFor="checkbox-2" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="list-item">
                        <div className="p-3">
                          <div className="media-img">
                            <img src={require(`../../../assets/images/profiles/profile-pic-13.jpg`)} />
                          </div>
                          <div className="info">
                            <span className="title">Erin Gonzales</span>
                            <span className="sub-title">commented on your post's</span>
                            <div className="float-item">
                              <div className="checkbox ">
                                <input id="checkbox-3" name="checkbox-3" type="checkbox" />
                                <label htmlFor="checkbox-3" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="list-item">
                        <div className="p-3">
                          <div className="media-img">
                            <img src={require(`../../../assets/images/profiles/profile-pic-22.jpg`)} />
                          </div>
                          <div className="info">
                            <span className="title">Ava Alexander</span>
                            <span className="sub-title">commented on your post's</span>
                            <div className="float-item">
                              <div className="checkbox ">
                                <input id="checkbox-4" name="checkbox-4" type="checkbox" />
                                <label htmlFor="checkbox-4" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <h5 className="mb-3">List Media With Border</h5>
                  <div className="card mb-0">
                    <ul className="list-media">
                      <li className="list-item border-bottom">
                        <a href="#" className="media-hover">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="media-img mr-3">
                              <img src={require(`../../../assets/images/profiles/profile-pic-25.jpg`)} />
                            </div>
                            <div className="info" style={{ paddingLeft: '0' }}>
                              <span className="title">Susie Willis</span>
                              <span className="sub-title">Let us wax poetic about the beauty of the cheeseburger.</span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li className="list-item border-bottom">
                        <a href="#" className="media-hover">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="media-img mr-3">
                              <img src={require(`../../../assets/images/profiles/profile-pic-17.jpg`)} />
                            </div>
                            <div className="info" style={{ paddingLeft: '0' }}>
                              <span className="title">Debra Stewart</span>
                              <span className="sub-title">I'm gonna build me an airport, put my name on it.</span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li className="list-item border-bottom">
                        <a href="#" className="media-hover">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="media-img mr-3">
                              <img src={require(`../../../assets/images/profiles/profile-pic-10.jpg`)} />
                            </div>
                            <div className="info" style={{ paddingLeft: '0' }}>
                              <span className="title">Erin Gonzales</span>
                              <span className="sub-title">Jelly-o sesame snaps halvah croissant oat cake cookie. </span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li className="list-item">
                        <a href="#" className="media-hover">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="media-img mr-3">
                              <img src={require(`../../../assets/images/profiles/profile-pic-16.jpg`)} />
                            </div>
                            <div className="info" style={{ paddingLeft: '0' }}>
                              <span className="title">Ava Alexander</span>
                              <span className="sub-title">Do you see any Teletubbies in here?</span>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-6">
                  <div>
                    <h5 className="mb-3">List Media Horizon</h5>
                    <div className="card">
                      <div className="card-header"
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                      >
                        <h6 className="mb-0">Devolopment - Android App</h6>
                        <div className="card-toolbar">
                          <ul>
                            <li>
                              <a className="text-gray m-0" href="#">
                                <i className="mdi mdi-dots-vertical font-size-20" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-body">
                        <p>Not a sunrise but a galaxyrise rogue, ship of the imagination paroxysm of global death intelligent beings dispassionate extraterrestrial observer. From which we spring. Rings of Uranus, vanquish the impossible science. She aked the designer.</p>
                        <div>
                          <ul className="list-media-horizon list-inline">
                            <li>
                              <a href="#">
                                <img src={require(`../../../assets/images/profiles/profile-pic-20.jpg`)} />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <img src={require(`../../../assets/images/profiles/profile-pic-23.jpg`)} />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <img src={require(`../../../assets/images/profiles/profile-pic-18.jpg`)} />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <img src={require(`../../../assets/images/profiles/profile-pic-3.jpg`)} />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <img src={require(`../../../assets/images/profiles/profile-pic-9.jpg`)} />
                              </a>
                            </li>
                            <li className="all-members">
                              <a href="#">
                                <span>+2</span>
                              </a>
                            </li>
                            <li className="add-member">
                              <a href="#">
                                <span>+</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    );
  }
}

export default Lists;