import React from "react";
import "./dashboard4.css";
import { Row, Col, Card, CardBody, Progress, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import ReactApexChart from 'react-apexcharts';
import Scrollbars from "react-custom-scrollbars"
import Calendar from 'react-calendar';
import Clock from 'react-clock';

class Dashboard4 extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);

    this.state = {
      activeTab: '1',
      date: new Date(),
      clockDate: new Date(),
      salesAreaSeries: [
        { name: 'Product 1', data: [121, 288, 206, 349, 415, 480, 550] },
        { name: 'Product 2', data: [354, 504, 479, 215, 298, 534, 520] },
        { name: 'Product 3', data: [248, 154, 168, 100, 238, 399, 315] },
      ],
      salesAreaOptions: {
        chart: { height: 350, type: 'area', toolbar: { show: false } },
        colors: ['#005bea', '#f18f1e', '#029666'],
        fill: { opacity: 1, colors: ['#005bea', '#f18f1e', '#029666'] },
        dataLabels: { enabled: false },
        stroke: { curve: 'smooth', linecap: 'round', width: '1', colors: ['#005bea', '#f18f1e', '#029666'] },
        grid: { show: false, borderColor: '#ff0000', padding: { top: 0, right: 0, bottom: 0 } },
        tooltip: { x: { show: false } },
        xaxis: {
          type: 'month',
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July"],
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          itemMargin: { horizontal: 25 },
          markers: { fillColors: ['#005bea', '#f18f1e', '#029666'] }
        },
      }
    }
  }

  onChange = date => this.setState({ date })

  toggle = tab => {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  }
  componentDidMount() {
    setInterval(
      () => this.setState({ clockDate: new Date() }),
      1000
    );
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pt-2 pl-0 pr-0">
          
          <Row className="mt-4">
            <Col sm={6} xl={3} className="d-flex">
              <Card className="borderless-shadow dash4-top-card dash4-top-card-blue" style={{width: '100%'}}>
                <CardBody>
                  <div className="dash4-top-card-inc-dec">
                    <i className="far fa-arrow-alt-circle-up mr-1"></i>
                    <span>12%</span>
                  </div>
                  <h6 className="font-size-16 font-weight-semibold mb-3 text-white">Sales Online</h6>
                  <div className="dash4-top-card-detail-container">
                    <div className="dash4-topcard-icon-container-blue mr-3">
                      <i className="fas fa-store font-size-25"></i>
                    </div>
                    <div>
                      <p className="font-size-17 font-weight-bold mb-0 text-white">3,156</p>
                      <p className="font-size-12 mb-0 text-white" style={{ opacity: "0.7" }}>
                        As per previous month
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={6} xl={3} className="d-flex">
              <Card className="borderless-shadow dash4-top-card dash4-top-card-orange" style={{ width: '100%' }}>
                <CardBody>
                  <div className="dash4-top-card-inc-dec">
                    <i className="far fa-arrow-alt-circle-down mr-1"></i>
                    <span>21%</span>
                  </div>
                  <h6 className="font-size-16 font-weight-semibold mb-3 text-white">Sales Offline</h6>
                  <div className="dash4-top-card-detail-container">
                    <div className="dash4-topcard-icon-container-orange mr-3">
                      <i className="fas fa-shopping-bag font-size-25"></i>
                    </div>
                    <div>
                      <p className="font-size-17 font-weight-bold mb-0 text-white">1,740</p>
                      <p className="font-size-12 mb-0 text-white" style={{ opacity: "0.7" }}>
                        As per previous month
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={6} xl={3} className="d-flex">
              <Card className="borderless-shadow dash4-top-card dash4-top-card-green" style={{ width: '100%' }}>
                <CardBody>
                  <div className="dash4-top-card-inc-dec">
                    <i className="far fa-arrow-alt-circle-up mr-1"></i>
                    <span>16%</span>
                  </div>
                  <h6 className="font-size-16 font-weight-semibold mb-3 text-white">Earnings</h6>
                  <div className="dash4-top-card-detail-container">
                    <div className="dash4-topcard-icon-container-green mr-3">
                      <i className="fas fa-dollar-sign font-size-25"></i>
                    </div>
                    <div>
                      <p className="font-size-17 font-weight-bold mb-0 text-white">$3,199</p>
                      <p className="font-size-12 mb-0 text-white" style={{ opacity: "0.7" }} >
                        As per previous month
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={6} xl={3} className="d-flex">
              <Card className="borderless-shadow dash4-top-card dash4-top-card-red" style={{ width: '100%' }}>
                <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className="dash4-top-card-inc-dec">
                    <i className="far fa-check-circle mr-1"></i>
                    <span>No Dues</span>
                  </div>
                  <h6 className="font-size-16 font-weight-semibold mb-3 text-white">Balance</h6>
                  <div className="dash4-top-card-detail-container" style={{ flex: 1 }}>
                    <div className="dash4-topcard-icon-container-red mr-3">
                      <i className="fas fa-wallet font-size-25"></i>
                    </div>
                    <div>
                      <p className="font-size-17 font-weight-bold mb-0 text-white">$78,190</p>
                      <p className="font-size-12 mb-0 text-white" style={{ opacity: "0.7" }}>
                        As of Current Date
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className="borderless-shadow">
                <CardBody className="pb-0 pl-0">
                  <h5 className="mb-3 font-size-20 ml-4">Top Product Sales</h5>
                  <Row>
                    <Col md={8}>
                      <div className="pl-1">
                        <ReactApexChart options={this.state.salesAreaOptions} series={this.state.salesAreaSeries} type="area" height={370}/>
                      </div>
                    </Col>

                    <Col md={4} className="mrb-breakpoint">
                      <h6 className="font-size-18 font-weight-semibold mb-4">Goal Achievements</h6>
                      <div className="mb-3" style={{width: '85%'}}>
                        <p className="font-size-13 mb-2">Total Sales Goal</p>
                        <Progress animated color="secondary" value="84" className="dash4-progress">
                          <span className="dash4-progress-text">84%</span>
                        </Progress>
                      </div>
                      <div className="mb-3" style={{ width: '85%' }}>
                        <p className="font-size-13 mb-2">Marketing Goal</p>
                        <Progress animated color="secondary" value="79" className="dash4-progress">
                          <span className="dash4-progress-text">79%</span>
                        </Progress>
                      </div>
                      <div className="mb-3" style={{ width: '85%' }}>
                        <p className="font-size-13 mb-2">Production Goal</p>
                        <Progress animated color="secondary" value="99" className="dash4-progress">
                          <span className="dash4-progress-text">99%</span>
                        </Progress>
                      </div>
                      <div className="mb-3" style={{ width: '85%' }}>
                        <p className="font-size-13 mb-2">1Million Sales</p>
                        <Progress animated color="secondary" value="41" className="dash4-progress">
                          <span className="dash4-progress-text">41%</span>
                        </Progress>
                      </div>
                      <div className="mb-3" style={{ width: '85%' }}>
                        <p className="font-size-13 mb-2">Customer Satifaction</p>
                        <Progress animated color="secondary" value="73" className="dash4-progress">
                          <span className="dash4-progress-text">73%</span>
                        </Progress>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={6} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardBody>
                  <Nav tabs className="dash4-custom-nav-tabs">
                    <NavItem className="dash4-custom-nav-item">
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => { this.toggle('1'); }}
                      >
                        <i className="mdi mdi-account-outline" style={{ display: 'block', fontSize: '16px' }}></i>
                        Contacts
                      </NavLink>
                    </NavItem>
                    <NavItem className="dash4-custom-nav-item">
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => { this.toggle('2'); }}
                      >
                        <i className="mdi mdi-bell-outline" style={{ display: 'block', fontSize: '16px' }}></i>
                        Notification
                    </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1" className="dash4-custom-tabpane-padding">
                      <ul className="dash4-contact-list mb-0">
                        <li className="dash4-contact-item">
                          <div className="dash4-contact-img-container">
                            <img src={require(`../../assets/images/profiles/profile-pic-2.png`)} width="100%" />
                          </div>

                          <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }} className="dash4-contact-flex-breakpoint">
                            <div className="ml-3" style={{ transform: 'translateY(4px)' }}>
                              <h6 className="font-size-15 font-weight-semibold mb-0">Benzette Pereira</h6>
                              <p className="font-size-11 text-muted mb-0">Designer</p>
                            </div>

                            <div className="dash4-contact-btn-container">
                              <button className="dash4-contact-btn dash4-contact-btn-msg">
                                <i className="mdi mdi-message-text-outline"></i>
                              </button>
                              <button className="dash4-contact-btn dash4-contact-btn-mail">
                                <i className="mdi mdi-email-outline"></i>
                              </button>
                              <button className="dash4-contact-btn dash4-contact-btn-call">
                                <i className="mdi mdi-phone"></i>
                              </button>
                            </div>
                          </div>
                        </li>
                        <li className="dash4-contact-item">
                          <div className="dash4-contact-img-container">
                            <img src={require(`../../assets/images/profiles/profile-pic-1.jpg`)} width="100%" />
                          </div>

                          <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }} className="dash4-contact-flex-breakpoint">
                            <div className="ml-3" style={{ transform: 'translateY(4px)' }}>
                              <h6 className="font-size-15 font-weight-semibold mb-0">Natasha Romanoff</h6>
                              <p className="font-size-11 text-muted mb-0">Designer</p>
                            </div>

                            <div className="dash4-contact-btn-container">
                              <button className="dash4-contact-btn dash4-contact-btn-msg">
                                <i className="mdi mdi-message-text-outline"></i>
                              </button>
                              <button className="dash4-contact-btn dash4-contact-btn-mail">
                                <i className="mdi mdi-email-outline"></i>
                              </button>
                              <button className="dash4-contact-btn dash4-contact-btn-call">
                                <i className="mdi mdi-phone"></i>
                              </button>
                            </div>
                          </div>
                        </li>
                        <li className="dash4-contact-item">
                          <div className="dash4-contact-img-container">
                            <img src={require(`../../assets/images/profiles/profile-pic-6.jpg`)} width="100%" />
                          </div>

                          <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }} className="dash4-contact-flex-breakpoint">
                            <div className="ml-3" style={{ transform: 'translateY(4px)' }}>
                              <h6 className="font-size-15 font-weight-semibold mb-0">Flamini Jabowski</h6>
                              <p className="font-size-11 text-muted mb-0">Administrator</p>
                            </div>

                            <div className="dash4-contact-btn-container">
                              <button className="dash4-contact-btn dash4-contact-btn-msg">
                                <i className="mdi mdi-message-text-outline"></i>
                              </button>
                              <button className="dash4-contact-btn dash4-contact-btn-mail">
                                <i className="mdi mdi-email-outline"></i>
                              </button>
                              <button className="dash4-contact-btn dash4-contact-btn-call">
                                <i className="mdi mdi-phone"></i>
                              </button>
                            </div>
                          </div>
                        </li>
                        <li className="dash4-contact-item">
                          <div className="dash4-contact-img-container">
                            <img src={require(`../../assets/images/profiles/profile-pic-24.jpg`)} width="100%" />
                          </div>

                          <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }} className="dash4-contact-flex-breakpoint">
                            <div className="ml-3" style={{ transform: 'translateY(4px)' }}>
                              <h6 className="font-size-15 font-weight-semibold mb-0">Hozeer Dawioli</h6>
                              <p className="font-size-11 text-muted mb-0">Team Lead</p>
                            </div>

                            <div className="dash4-contact-btn-container">
                              <button className="dash4-contact-btn dash4-contact-btn-msg">
                                <i className="mdi mdi-message-text-outline"></i>
                              </button>
                              <button className="dash4-contact-btn dash4-contact-btn-mail">
                                <i className="mdi mdi-email-outline"></i>
                              </button>
                              <button className="dash4-contact-btn dash4-contact-btn-call">
                                <i className="mdi mdi-phone"></i>
                              </button>
                            </div>
                          </div>
                        </li>
                        <li className="dash4-contact-item">
                          <div className="dash4-contact-img-container">
                            <img src={require(`../../assets/images/profiles/profile-pic-5.jpg`)} width="100%" />
                          </div>

                          <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }} className="dash4-contact-flex-breakpoint">
                            <div className="ml-3" style={{ transform: 'translateY(4px)' }}>
                              <h6 className="font-size-15 font-weight-semibold mb-0">Peter Salnuff</h6>
                              <p className="font-size-11 text-muted mb-0">Assistant</p>
                            </div>

                            <div className="dash4-contact-btn-container">
                              <button className="dash4-contact-btn dash4-contact-btn-msg">
                                <i className="mdi mdi-message-text-outline"></i>
                              </button>
                              <button className="dash4-contact-btn dash4-contact-btn-mail">
                                <i className="mdi mdi-email-outline"></i>
                              </button>
                              <button className="dash4-contact-btn dash4-contact-btn-call">
                                <i className="mdi mdi-phone"></i>
                              </button>
                            </div>
                          </div>
                        </li>
                        <li className="dash4-contact-item">
                          <div className="dash4-contact-img-container">
                            <img src={require(`../../assets/images/profiles/profile-pic-14.jpg`)} width="100%" />
                          </div>

                          <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }} className="dash4-contact-flex-breakpoint">
                            <div className="ml-3" style={{ transform: 'translateY(4px)' }}>
                              <h6 className="font-size-15 font-weight-semibold mb-0">Sarah James</h6>
                              <p className="font-size-11 text-muted mb-0">Marketting</p>
                            </div>

                            <div className="dash4-contact-btn-container">
                              <button className="dash4-contact-btn dash4-contact-btn-msg">
                                <i className="mdi mdi-message-text-outline"></i>
                              </button>
                              <button className="dash4-contact-btn dash4-contact-btn-mail">
                                <i className="mdi mdi-email-outline"></i>
                              </button>
                              <button className="dash4-contact-btn dash4-contact-btn-call">
                                <i className="mdi mdi-phone"></i>
                              </button>
                            </div>
                          </div>
                        </li>
                        <li className="dash4-contact-item">
                          <div className="dash4-contact-img-container">
                            <img src={require(`../../assets/images/profiles/profile-pic-3.jpg`)} width="100%" />
                          </div>

                          <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }} className="dash4-contact-flex-breakpoint">
                            <div className="ml-3" style={{ transform: 'translateY(4px)' }}>
                              <h6 className="font-size-15 font-weight-semibold mb-0">David Larson</h6>
                              <p className="font-size-11 text-muted mb-0">Programmer</p>
                            </div>

                            <div className="dash4-contact-btn-container">
                              <button className="dash4-contact-btn dash4-contact-btn-msg">
                                <i className="mdi mdi-message-text-outline"></i>
                              </button>
                              <button className="dash4-contact-btn dash4-contact-btn-mail">
                                <i className="mdi mdi-email-outline"></i>
                              </button>
                              <button className="dash4-contact-btn dash4-contact-btn-call">
                                <i className="mdi mdi-phone"></i>
                              </button>
                            </div>
                          </div>
                        </li>
                        <li className="dash4-contact-item">
                          <div className="dash4-contact-img-container">
                            <img src={require(`../../assets/images/profiles/profile-pic-15.jpg`)} width="100%" />
                          </div>

                          <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }} className="dash4-contact-flex-breakpoint">
                            <div className="ml-3" style={{ transform: 'translateY(4px)' }}>
                              <h6 className="font-size-15 font-weight-semibold mb-0">Norman Creed</h6>
                              <p className="font-size-11 text-muted mb-0">Programmer</p>
                            </div>

                            <div className="dash4-contact-btn-container">
                              <button className="dash4-contact-btn dash4-contact-btn-msg">
                                <i className="mdi mdi-message-text-outline"></i>
                              </button>
                              <button className="dash4-contact-btn dash4-contact-btn-mail">
                                <i className="mdi mdi-email-outline"></i>
                              </button>
                              <button className="dash4-contact-btn dash4-contact-btn-call">
                                <i className="mdi mdi-phone"></i>
                              </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </TabPane>
                    <TabPane tabId="2" className="dash4-custom-tabpane-padding">
                        <Scrollbars
                          autoHeight
                          autoHeightMin={300}
                          autoHeightMax={456}
                        >
                          <ul className="dash4-notification-list mb-0">
                            <li className="dash4-notification-item">
                              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '.4rem' }}>
                                <div className="dash4-notification-img-container">
                                  <img src={require(`../../assets/images/profiles/profile-pic-1.jpg`)} width="100%" />
                                </div>

                                <div className="ml-3" style={{ transform: 'translateY(4px)' }}>
                                  <h6 className="font-size-15 font-weight-semibold mb-0">Natasha Romanoff</h6>
                                  <p className="font-size-11 text-muted mb-0">Designer</p>
                                </div>
                              </div>

                              <p className="font-size-13 mb-2" style={{ lineHeight: '1.4' }}>
                                Completed Prototype design for world heritage flux design. Waiting for review, finalization, or any extra changes.
                              </p>

                              <div>
                                <button className="dash4-notification-btn dash4-notification-btn-msg">
                                  <i className="mdi mdi-message-text-outline"></i>
                                </button>
                                <button className="dash4-notification-btn dash4-notification-btn-mail">
                                  <i className="mdi mdi-email-outline"></i>
                                </button>
                                <button className="dash4-notification-btn dash4-notification-btn-call">
                                  <i className="mdi mdi-phone"></i>
                                </button>
                              </div>
                            </li>
                            <li className="dash4-notification-item">
                              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '.4rem' }}>
                                <div className="dash4-notification-img-container">
                                  <img src={require(`../../assets/images/profiles/profile-pic-14.jpg`)} width="100%" />
                                </div>

                                <div className="ml-3" style={{ transform: 'translateY(4px)' }}>
                                  <h6 className="font-size-15 font-weight-semibold mb-0">Sarah James</h6>
                                  <p className="font-size-11 text-muted mb-0">Marketting</p>
                                </div>
                              </div>

                              <p className="font-size-13 mb-2" style={{ lineHeight: '1.4' }}>
                                New Marketting schedule and goals set for this year. Following the flow next day.
                              </p>

                              <div>
                                <button className="dash4-notification-btn dash4-notification-btn-msg">
                                  <i className="mdi mdi-message-text-outline"></i>
                                </button>
                                <button className="dash4-notification-btn dash4-notification-btn-mail">
                                  <i className="mdi mdi-email-outline"></i>
                                </button>
                                <button className="dash4-notification-btn dash4-notification-btn-call">
                                  <i className="mdi mdi-phone"></i>
                                </button>
                              </div>
                            </li>
                            <li className="dash4-notification-item">
                              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '.4rem' }}>
                                <div className="dash4-notification-img-container">
                                  <img src={require(`../../assets/images/profiles/profile-pic-15.jpg`)} width="100%" />
                                </div>

                                <div className="ml-3" style={{ transform: 'translateY(4px)' }}>
                                  <h6 className="font-size-15 font-weight-semibold mb-0">Norman Creed</h6>
                                  <p className="font-size-11 text-muted mb-0">Programmer</p>
                                </div>
                              </div>

                              <p className="font-size-13 mb-2" style={{ lineHeight: '1.4' }}>
                                Algorithmic change on Live streaming section for Hoffman Bridges project.
                              </p>

                              <div>
                                <button className="dash4-notification-btn dash4-notification-btn-msg">
                                  <i className="mdi mdi-message-text-outline"></i>
                                </button>
                                <button className="dash4-notification-btn dash4-notification-btn-mail">
                                  <i className="mdi mdi-email-outline"></i>
                                </button>
                                <button className="dash4-notification-btn dash4-notification-btn-call">
                                  <i className="mdi mdi-phone"></i>
                                </button>
                              </div>
                            </li>
                            <li className="dash4-notification-item">
                              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '.4rem' }}>
                                <div className="dash4-notification-img-container">
                                  <img src={require(`../../assets/images/profiles/profile-pic-24.jpg`)} width="100%" />
                                </div>

                                <div className="ml-3" style={{ transform: 'translateY(4px)' }}>
                                  <h6 className="font-size-15 font-weight-semibold mb-0">Hozeer Dawioli</h6>
                                  <p className="font-size-11 text-muted mb-0">Team Lead</p>
                                </div>
                              </div>

                              <p className="font-size-13 mb-2" style={{ lineHeight: '1.4' }}>
                                Changes in Task plans and schedules for further new project.
                              </p>

                              <div>
                                <button className="dash4-notification-btn dash4-notification-btn-msg">
                                  <i className="mdi mdi-message-text-outline"></i>
                                </button>
                                <button className="dash4-notification-btn dash4-notification-btn-mail">
                                  <i className="mdi mdi-email-outline"></i>
                                </button>
                                <button className="dash4-notification-btn dash4-notification-btn-call">
                                  <i className="mdi mdi-phone"></i>
                                </button>
                              </div>
                            </li>
                          </ul>
                        </Scrollbars>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardBody>
                  <div style={{display: 'flex', alignItems: 'center', marginBottom: '.8rem'}}>
                    <span className="dash4-message-section-icon">
                      <i className="mdi mdi-message-text-outline"></i>
                    </span>
                    <h6 className="font-size-17 font-weight-semibold d-inline-block mb-0">Messages</h6>
                  </div>

                  <Scrollbars
                    autoHeight
                    autoHeightMin={300}
                    autoHeightMax={550}
                  >
                    <ul className="dash4-message-list mb-0">
                      <li className="dash4-message-item">
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '.75rem'}}>
                          <div className="dash4-message-img-container">
                            <img src={require(`../../assets/images/profiles/profile-pic-5.jpg`)} width="100%" />
                          </div>

                          <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }} className="dash4-contact-flex-breakpoint">
                            <div className="ml-3" style={{ transform: 'translateY(2px)' }}>
                              <h6 className="font-size-15 font-weight-semibold mb-0">Peter Salnuff</h6>
                              <p className="font-size-11 text-muted mb-0">Assistant</p>
                            </div>

                            <div className="dash4-contact-btn-container">
                              <button className="dash4-message-btn dash4-message-btn-msg">
                                <i className="mdi mdi-message-text-outline"></i>
                              </button>
                              <button className="dash4-message-btn dash4-message-btn-mail">
                                <i className="mdi mdi-email-outline"></i>
                              </button>
                              <button className="dash4-message-btn dash4-message-btn-call">
                                <i className="mdi mdi-phone"></i>
                              </button>
                            </div>
                          </div>
                        </div>

                        <p className="font-size-13 mb-2" style={{ lineHeight: '1.4' }}>
                          Can we discuss tomorrow about the deal to undertake with our recent vip customers?
                        </p>
                      </li>
                      <li className="dash4-message-item">
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '.75rem'}}>
                          <div className="dash4-message-img-container">
                            <img src={require(`../../assets/images/profiles/profile-pic-26.jpg`)} width="100%" />
                          </div>

                          <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }} className="dash4-contact-flex-breakpoint">
                            <div className="ml-3" style={{ transform: 'translateY(2px)' }}>
                              <h6 className="font-size-15 font-weight-semibold mb-0">Amora Claydeez</h6>
                              <p className="font-size-11 text-muted mb-0">Administration</p>
                            </div>

                            <div className="dash4-contact-btn-container">
                              <button className="dash4-message-btn dash4-message-btn-msg">
                                <i className="mdi mdi-message-text-outline"></i>
                              </button>
                              <button className="dash4-message-btn dash4-message-btn-mail">
                                <i className="mdi mdi-email-outline"></i>
                              </button>
                              <button className="dash4-message-btn dash4-message-btn-call">
                                <i className="mdi mdi-phone"></i>
                              </button>
                            </div>
                          </div>
                        </div>

                        <p className="font-size-13 mb-2" style={{ lineHeight: '1.4' }}>
                          Hi,
                          We have completed Prototype design for world heritage flux design. Review this and let me know.
                        </p>
                      </li>
                      <li className="dash4-message-item">
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '.75rem'}}>
                          <div className="dash4-message-img-container">
                            <img src={require(`../../assets/images/profiles/profile-pic-14.jpg`)} width="100%" />
                          </div>

                          <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }} className="dash4-contact-flex-breakpoint">
                            <div className="ml-3" style={{ transform: 'translateY(2px)' }}>
                              <h6 className="font-size-15 font-weight-semibold mb-0">Sarah James</h6>
                              <p className="font-size-11 text-muted mb-0">Marketting</p>
                            </div>

                            <div className="dash4-contact-btn-container">
                              <button className="dash4-message-btn dash4-message-btn-msg">
                                <i className="mdi mdi-message-text-outline"></i>
                              </button>
                              <button className="dash4-message-btn dash4-message-btn-mail">
                                <i className="mdi mdi-email-outline"></i>
                              </button>
                              <button className="dash4-message-btn dash4-message-btn-call">
                                <i className="mdi mdi-phone"></i>
                              </button>
                            </div>
                          </div>
                        </div>

                        <p className="font-size-13 mb-2" style={{ lineHeight: '1.4' }}>
                          Good morning, please schedule a meeting for laying out our marketing team schedules. 
                        </p>
                      </li>
                      <li className="dash4-message-item">
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '.75rem' }}>
                          <div className="dash4-message-img-container">
                            <img src={require(`../../assets/images/profiles/profile-pic-15.jpg`)} width="100%" />
                          </div>

                          <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }} className="dash4-contact-flex-breakpoint">
                            <div className="ml-3" style={{ transform: 'translateY(2px)' }}>
                              <h6 className="font-size-15 font-weight-semibold mb-0">Norman Creed</h6>
                              <p className="font-size-11 text-muted mb-0">Programmer</p>
                            </div>

                            <div className="dash4-contact-btn-container">
                              <button className="dash4-message-btn dash4-message-btn-msg">
                                <i className="mdi mdi-message-text-outline"></i>
                              </button>
                              <button className="dash4-message-btn dash4-message-btn-mail">
                                <i className="mdi mdi-email-outline"></i>
                              </button>
                              <button className="dash4-message-btn dash4-message-btn-call">
                                <i className="mdi mdi-phone"></i>
                              </button>
                            </div>
                          </div>
                        </div>

                        <p className="font-size-13 mb-2" style={{ lineHeight: '1.4' }}>
                          Hi, Sir the product is live. 
                        </p>
                      </li>
                      <li className="dash4-message-item">
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '.75rem' }}>
                          <div className="dash4-message-img-container">
                            <img src={require(`../../assets/images/profiles/profile-pic-2.png`)} width="100%" />
                          </div>

                          <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }} className="dash4-contact-flex-breakpoint">
                            <div className="ml-3" style={{ transform: 'translateY(2px)' }}>
                              <h6 className="font-size-15 font-weight-semibold mb-0">Benzette Pereira</h6>
                              <p className="font-size-11 text-muted mb-0">Designer</p>
                            </div>

                            <div className="dash4-contact-btn-container">
                              <button className="dash4-message-btn dash4-message-btn-msg">
                                <i className="mdi mdi-message-text-outline"></i>
                              </button>
                              <button className="dash4-message-btn dash4-message-btn-mail">
                                <i className="mdi mdi-email-outline"></i>
                              </button>
                              <button className="dash4-message-btn dash4-message-btn-call">
                                <i className="mdi mdi-phone"></i>
                              </button>
                            </div>
                          </div>
                        </div>

                        <p className="font-size-13 mb-2" style={{ lineHeight: '1.4' }}>
                          Duis interdum pulvinar mi in auctor. Aliquam massa neque, mollis at feugiat at, pulvinar ac arcu. Nunc fringilla rhoncus ante, vitae rhoncus sem ornare vitae. 
                        </p>
                      </li>
                    </ul>
                  </Scrollbars>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={7} className="d-flex">
              <Card className="borderless-shadow w-100 dash4-time-n-weather">
                <CardBody>
                  <Row>
                    <Col>
                      <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-around', marginBottom: '1.2rem'}} className="dash4-flex-breakpoint">
                        <div style={{marginRight: '1rem'}} className="dash4-calendar-container">
                          <Calendar onChange={this.onChange} value={this.state.date} />
                        </div>
                        <div className="dash4-clock-container">
                          <Clock value={this.state.clockDate} />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="dash4-weather-container">
                        <div className="dash4-weather-day-main" >
                          <h6 className="mb-3">
                            <span className="mb-2 font-size-20 font-weight-semibold">Sunday</span>
                            <br />
                            <span className="font-size-12 text-muted">21 June</span>
                          </h6>
                          <div>
                            <i className="fas fa-sun font-size-30"></i>
                            <p className="mb-0 mr-2 font-size-25">27&#8451;</p>
                          </div>
                        </div>

                        <div style={{ 
                          display: 'flex', flexWrap: 'wrap', paddingTop: '6px', paddingRight: '3px', paddingLeft: '6px', paddingBottom: '3px'
                        }}>
                          <div className="dash4-weather-day">
                            <h6>
                              <span className="mb-2 font-size-15 font-weight-semibold">Monday</span>
                              <br />
                              <span className="font-size-12 text-muted">22 June</span>
                            </h6>
                            <div className="dash4-weather-content">
                              <p className="mb-0 font-size-17 mr-2">27&#8451;</p>
                              <i className="fas fa-cloud-sun font-size-17"></i>
                            </div>
                          </div>
                          <div className="dash4-weather-day">
                            <h6>
                              <span className="mb-2 font-size-15 font-weight-semibold">Tuesday</span>
                              <br />
                              <span className="font-size-12 text-muted">23 June</span>
                            </h6>
                            <div className="dash4-weather-content">
                              <p className="mb-0 font-size-17 mr-2">27&#8451;</p>
                              <i className="fas fa-cloud font-size-17"></i>
                            </div>
                          </div>
                          <div className="dash4-weather-day">
                            <h6>
                              <span className="mb-2 font-size-15 font-weight-semibold">Wednesday</span>
                              <br />
                              <span className="font-size-12 text-muted">24 June</span>
                            </h6>
                            <div className="dash4-weather-content">
                              <p className="mb-0 font-size-17 mr-2">27&#8451;</p>
                              <i className="fas fa-cloud-showers-heavy font-size-17"></i>
                            </div>
                          </div>
                          <div className="dash4-weather-day">
                            <h6>
                              <span className="mb-2 font-size-15 font-weight-semibold">Thursday</span>
                              <br />
                              <span className="font-size-12 text-muted">25 June</span>
                            </h6>
                            <div className="dash4-weather-content">
                              <p className="mb-0 font-size-17 mr-2">27&#8451;</p>
                              <i className="fas fa-cloud-showers-heavy font-size-17"></i>
                            </div>
                          </div>
                          <div className="dash4-weather-day">
                            <h6>
                              <span className="mb-2 font-size-15 font-weight-semibold">Friday</span>
                              <br />
                              <span className="font-size-12 text-muted">26 June</span>
                            </h6>
                            <div className="dash4-weather-content">
                              <p className="mb-0 font-size-17 mr-2">27&#8451;</p>
                              <i className="fas fa-cloud-showers-heavy font-size-17"></i>
                            </div>
                          </div>
                          <div className="dash4-weather-day">
                            <h6>
                              <span className="mb-2 font-size-15 font-weight-semibold">Saturday</span>
                              <br />
                              <span className="font-size-12 text-muted">27 June</span>
                            </h6>
                            <div className="dash4-weather-content">
                              <p className="mb-0 font-size-17 mr-2">27&#8451;</p>
                              <i className="fas fa-cloud-rain font-size-17"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col lg={5} className="d-flex">
              <Card className="borderless-shadow w-100 dash4-recent-sales-activity">
                <CardBody className="sale-activity-body">
                  <h5 className="font-size-17 font-weight-semibold">Recent Sales activity</h5>
                  <p className="font-size-14 text-muted">Showing activities of past 7 days (week data)</p>

                  <div className="sale-activity-bar-wrapper">
                    <div className="sale-activity-container mb-3">
                      <div className="sale-activity-icon-container sa-icon-bg-blue">
                        <i className="fas fa-archive sale-activity-icon"></i>
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between", flexGrow: 1, alignItems: "center" }}>
                        <div className="sale-activity-detail ml-2">
                          <p className="mb-0 font-size-15 font-weight-semibold">Online Sale</p>
                          <p className="mb-0 font-size-12 text-muted">2k new sales</p>
                        </div>
                        <span className="sale-activity-day font-size-12 text-muted">1 day ago</span>
                      </div>
                    </div>

                    <div className="sale-activity-container mb-3">
                      <div className="sale-activity-icon-container sa-icon-bg-orange">
                        <i className="fas fa-shopping-bag sale-activity-icon"></i>
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between", flexGrow: 1, alignItems: "center" }}>
                        <div className="sale-activity-detail ml-2">
                          <p className="mb-0 font-size-15 font-weight-semibold">Offline Sale</p>
                          <p className="mb-0 font-size-12 text-muted">1.4k new sales</p>
                        </div>
                        <span className="sale-activity-day font-size-12 text-muted">3 day ago</span>
                      </div>
                    </div>

                    <div className="sale-activity-container mb-3">
                      <div className="sale-activity-icon-container sa-icon-bg-green">
                        <i className="fas fa-wallet sale-activity-icon"></i>
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between", flexGrow: 1, alignItems: "center" }}>
                        <div className="sale-activity-detail ml-2">
                          <p className="mb-0 font-size-15 font-weight-semibold">Balance</p>
                          <p className="mb-0 font-size-12 text-muted">$78,190k new sales</p>
                        </div>
                        <span className="sale-activity-day font-size-12 text-muted">7 day ago</span>
                      </div>
                    </div>

                    <div className="sale-activity-container mb-3">
                      <div className="sale-activity-icon-container sa-icon-bg-red">
                        <i className="fas fa-dollar-sign sale-activity-icon"></i>
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between", flexGrow: 1, alignItems: "center" }}>
                        <div className="sale-activity-detail ml-2">
                          <p className="mb-0 font-size-15 font-weight-semibold">New Production Cost</p>
                          <p className="mb-0 font-size-12 text-muted">$55,445</p>
                        </div>
                        <span className="sale-activity-day font-size-12 text-muted">7 day ago</span>
                      </div>
                    </div>

                    <div className="sale-activity-container mb-3">
                      <div className="sale-activity-icon-container sa-icon-bg-purple">
                        <i className="fas fa-warehouse sale-activity-icon"></i>
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between", flexGrow: 1, alignItems: "center" }}>
                        <div className="sale-activity-detail ml-2">
                          <p className="mb-0 font-size-15 font-weight-semibold">New Production</p>
                          <p className="mb-0 font-size-12 text-muted">5.5k new products</p>
                        </div>
                        <span className="sale-activity-day font-size-12 text-muted">7 day ago</span>
                      </div>
                    </div>

                    <div className="sale-activity-container mb-3">
                      <div className="sale-activity-icon-container sa-icon-bg-orange">
                        <i className="fas fa-shopping-bag sale-activity-icon"></i>
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between", flexGrow: 1, alignItems: "center" }}>
                        <div className="sale-activity-detail ml-2">
                          <p className="mb-0 font-size-15 font-weight-semibold">Offline Sale</p>
                          <p className="mb-0 font-size-12 text-muted">952 new sales</p>
                        </div>
                        <span className="sale-activity-day font-size-12 text-muted">10 day ago</span>
                      </div>
                    </div>

                    <div className="sale-activity-container mb-3">
                      <div className="sale-activity-icon-container sa-icon-bg-blue">
                        <i className="fas fa-archive sale-activity-icon"></i>
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between", flexGrow: 1, alignItems: "center" }}>
                        <div className="sale-activity-detail ml-2">
                          <p className="mb-0 font-size-15 font-weight-semibold">Online Sale</p>
                          <p className="mb-0 font-size-12 text-muted">1.4k new sales</p>
                        </div>
                        <span className="sale-activity-day font-size-12 text-muted">10 day ago</span>
                      </div>
                    </div>

                    <div className="sale-activity-container">
                      <div className="sale-activity-icon-container sa-icon-bg-purple">
                        <i className="fas fa-warehouse sale-activity-icon"></i>
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between", flexGrow: 1, alignItems: "center" }}>
                        <div className="sale-activity-detail ml-2">
                          <p className="mb-0 font-size-15 font-weight-semibold">New Production</p>
                          <p className="mb-0 font-size-12 text-muted">3k new products</p>
                        </div>
                        <span className="sale-activity-day font-size-12 text-muted">12 day ago</span>
                      </div>
                    </div>

                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </div>
    );
  }
}

export default Dashboard4;