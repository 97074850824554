import React from "react";
import "./charts.css";

import { CardBody, CardHeader, Card, CardTitle, Row, Col, Alert, UncontrolledAlert } from 'reactstrap';
import { Doughnut, Line, Bar, Radar, Pie, Polar, Scatter } from 'react-chartjs-2';
import ReactApexChart from 'react-apexcharts';

const rFactor = function () {
  return Math.round(Math.random() * 100);
};

const lineData = {
  labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  datasets: [
    {
      label: 'Hats',
      backgroundColor: 'rgba(77, 133, 218,0.2)',
      borderColor: 'rgb(77, 133, 218)',
      pointBorderColor: '#fff',
      data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
    },
    {
      label: 'Jackets',
      backgroundColor: 'rgba(80,202,149,0.2)',
      borderColor: 'rgba(80,202,149,1)',

      pointBorderColor: '#fff',
      data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
    }
  ]
};

const doughnutData = {
  labels: [
    'Jackets',
    'Hats',
    'Wallets',
    'Shoes',
    'Backpack'
  ],

  datasets: [{
    data: [400, 50, 100, 80, 150],
    backgroundColor: [
      '#57cc88',
      '#706ed1',
      '#FFCE56',
      '#e4819f',
      '#df8f60'
    ],
    hoverBackgroundColor: [
      '#57cc88',
      '#706ed1',
      '#FFCE56',
      '#e4819f',
      '#df8f60'
    ]
  }]
};

//Pie Chart
const pieData = {
  labels: [
    'Hats',
    'Jackets',
    'Wallets'
  ],
  datasets: [{
    data: [300, 50, 100],
    backgroundColor: [
      '#706ed1',
      '#57cc88',
      '#df8f60'
    ],
    hoverBackgroundColor: [
      '#706ed1',
      '#57cc88',
      '#df8f60'
    ]
  }]
};


const scatter = {
  labels: 'Your Sales',
  datasets: [
    {
      label: 'Hats Sales',
      fill: false,
      backgroundColor: 'rgba(63, 32, 204,0.4)',
      pointBorderColor: 'rgb(63, 32, 204)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 5,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgb(63, 32, 204)',
      pointHoverBorderColor: 'rgba(63, 32, 204,0.2)',
      pointHoverBorderWidth: 2,
      pointRadius: 5,
      pointHitRadius: 10,
      data: [
        { x: 65, y: 75 },
        { x: 59, y: 49 },
        { x: 90, y: 65 },
        { x: 81, y: 29 },
        { x: 56, y: 36 },
        { x: 55, y: 25 },
        { x: 40, y: 18 },
      ]
    },
    {
      label: 'Jacket Sales',
      fill: false,
      backgroundColor: 'rgba(32, 204, 104,0.4)',
      pointBorderColor: 'rgb(32, 204, 104)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 5,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgb(32, 204, 104)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 5,
      pointHitRadius: 10,
      data: [
        { x: 22, y: 23 },
        { x: 49, y: 49 },
        { x: 90, y: 70 },
        { x: 91, y: 39 },
        { x: 56, y: 46 },
        { x: 75, y: 55 },
        { x: 30, y: 38 },
      ]
    },
    {
      label: 'Wallet Sales',
      fill: false,
      backgroundColor: 'rgba(241, 162, 42,0.4)',
      pointBorderColor: 'rgb(241, 162, 42)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 5,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgb(241, 162, 42)',
      pointHoverBorderColor: 'rgb(241, 162, 42)',
      pointHoverBorderWidth: 2,
      pointRadius: 5,
      pointHitRadius: 10,
      data: [
        { x: 13, y: 26 },
        { x: 54, y: 42 },
        { x: 45, y: 66 },
        { x: 75, y: 47 },
        { x: 45, y: 54 },
        { x: 34, y: 12 },
        { x: 18, y: 42 },
      ]
    }
  ]
};

const polarData = {
  datasets: [{
    data: [
      8,
      28,
      12,
      17
    ],
    backgroundColor: [
      '#df8f60',
      '#706ed1',
      '#FFCE56',
      '#57cc88'
    ],
    label: 'My dataset'
  }],
  labels: [
    'Label 1',
    'Label 2',
    'Label 3',
    'Label 4'
  ]
};

// Rader Chart
const radarData = {
  labels: ['Eating', 'Drinking', 'Sleeping', 'Designing', 'Coding', 'Cycling', 'Running'],
  datasets: [{
    label: 'Nokia',
    backgroundColor: 'rgba(63, 32, 204,0.2)',
    borderColor: 'rgb(63, 32, 204)',
    data: [65, 44, 90, 81, 18, 78, 40]
  }, {
    label: 'Sumsung',
      backgroundColor: 'rgba(32, 204, 104,0.2)',
      borderColor: 'rgb(32, 204, 104)',
    data: [28, 48, 11, 38, 96, 50, 100]
  },
  {
    label: 'Wallet',
    backgroundColor: 'rgba(241, 162, 42,0.2)',
    borderColor: 'rgb(241, 162, 42))',
    data: [100, 67, 43, 100, 75, 23, 90]
  }]
};

class Charts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      barSeries: [
        { name: 'Net Profit', data: [44, 55, 57, 56, 61, 58, 63, 60, 66] },
        { name: 'Revenue', data: [76, 85, 101, 98, 87, 105, 91, 114, 94] },
        { name: 'Free Cash Flow', data: [35, 41, 36, 26, 45, 48, 52, 53, 41] }
      ],
      barOptions: {
        grid: { borderColor: '#eee' },
        dataLabels: { enabled: false },
        stroke: { show: true, width: 4, colors: ['transparent'] },
        fill: { opacity: 1, colors: ['#28b366', '#005bea', '#f18f1e'] },
        chart: { type: 'bar', height: 350, toolbar: { show: false } },
        xaxis: { categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'], },
        // yaxis: { title: { text: '$ (thousands)' } },
        tooltip: { y: { formatter: function (val) { return "$ " + val + " thousands" } } },
        plotOptions: { bar: { horizontal: false, columnWidth: '70%', endingShape: 'rounded' } },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'right',
          itemMargin: { horizontal: 10, vertical: 0 },
          markers: { fillColors: ['#28b366', '#005bea', '#f18f1e'] }
        }
      },

      radialSeries: [44, 55, 67, 83],
      radialOptions: {
        chart: { height: 350, type: 'radialBar', },
        labels: ['Apples', 'Oranges', 'Bananas', 'Berries'],
        fill: { opacity: 1, colors: ['#28b366', '#005bea', '#f18f1e', '#f93a5a'] },
        grid: { padding: { top: 0, bottom: 0, left: 0, right: 0 } },
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: { fontSize: '22px' },
              value: { fontSize: '16px' },
              style: { colors: ['#28b366', '#005bea', '#f18f1e', '#f93a5a'] },
              total: {
                show: true, label: 'Total', formatter: function (w) {
                  // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                  return 3156
                }
              }
            }
          }
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'center',
          itemMargin: { horizontal: 10 },
          markers: { fillColors: ['#28b366', '#005bea', '#f18f1e', '#f93a5a'] }
        }
      },

      salesAreaSeries: [
        { name: 'Product 1', data: [121, 288, 206, 349, 415, 480, 550] },
        { name: 'Product 2', data: [354, 504, 479, 215, 298, 534, 520] },
        { name: 'Product 3', data: [248, 154, 168, 100, 238, 399, 315] },
      ],
      salesAreaOptions: {
        chart: { height: 350, type: 'area', toolbar: { show: false } },
        colors: ['#005bea', '#f18f1e', '#029666'],
        fill: { opacity: 1, colors: ['#005bea', '#f18f1e', '#029666'] },
        dataLabels: { enabled: false },
        stroke: { curve: 'smooth', linecap: 'round', width: '1', colors: ['#005bea', '#f18f1e', '#029666'] },
        grid: { show: false, borderColor: '#ff0000', padding: { top: 0, right: 0, bottom: 0 } },
        tooltip: { x: { show: false } },
        xaxis: {
          type: 'month',
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July"],
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          itemMargin: { horizontal: 25 },
          markers: { fillColors: ['#005bea', '#f18f1e', '#029666'] }
        },
      },

      radialGradientSeries: [75],
      radialGradientOptions: {
        chart: { type: 'radialBar', toolbar: { show: false } },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            offsetX: 0,
            offsetY: 0,
            hollow: {
              margin: 0,
              size: '80%',
              background: '#fff',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24
              }
            },
            track: {
              background: '#fff',
              strokeWidth: '97%',
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35
              }
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: '#888',
                fontSize: '17px'
              },
              value: {
                formatter: function (val) {
                  return parseInt(val);
                },
                color: '#111',
                fontSize: '36px',
                show: true,
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#005bea'],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: { lineCap: 'round'},
        labels: ['Percent'],
      },
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Charts</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">Charts</a>
                <span className="breadcrumb-item active">Charts</span>
              </nav>
            </div>
          </div>
          
          <Row>
            <Col lg={7} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardHeader>
                  <CardTitle><h5 className="mb-0">Bar Chart</h5></CardTitle>
                </CardHeader>
                <CardBody className="pt-3 pl-0 pr-0 pb-0">
                  <ReactApexChart
                    options={this.state.barOptions}
                    series={this.state.barSeries}
                    type="bar"
                    height={350}
                  />
                </CardBody>
              </Card>
            </Col>

            <Col lg={5} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardHeader>
                  <CardTitle><h5 className="mb-0">Radial Chart</h5></CardTitle>
                </CardHeader>
                <CardBody className="pt-4 pl-0 pr-0 pb-0">
                  <ReactApexChart
                    options={this.state.radialOptions}
                    series={this.state.radialSeries}
                    type="radialBar" height={350}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={8} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardHeader>
                  <CardTitle><h5 className="mb-0">Area Chart</h5></CardTitle>
                </CardHeader>
                <CardBody className="pb-0 pl-0 pr-3">
                  <div className="pl-1">
                    <ReactApexChart options={this.state.salesAreaOptions} series={this.state.salesAreaSeries} type="area" height={370} />
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={4} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardHeader>
                  <CardTitle><h5 className="mb-0">Radial Gradient Chart</h5></CardTitle>
                </CardHeader>
                <CardBody>
                  <p className="font-size-12 text-muted mb-0">Duis in euismod orci. Proin ac viverra eros. Nullam scelerisque vitae lorem in bibendum.</p>
                  
                  <div className="radial-gradient-chart-container mt-4">
                    <ReactApexChart options={this.state.radialGradientOptions} series={this.state.radialGradientSeries} type="radialBar" width="100%" />
                  </div>

                  {/* <div className="radial-sale">
                    <div>
                      <p className="font-size-18 font-weight-semibold mb-2">Online Sale</p>
                      <span className="font-weight-semibold">3156</span>
                    </div>
                    <div>
                      <p className="font-size-18 font-weight-semibold mb-2">Offline Sale</p>
                      <span className="font-weight-semibold">1740</span>
                    </div>
                  </div> */}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={6} lg={6} xl={4}>
              <Card className="borderless-shadow">
                <CardHeader>
                  <CardTitle><h5 className="mb-0">Donut Chart</h5></CardTitle>
                </CardHeader>
                <CardBody>
                  <Doughnut data={doughnutData} width={150} />
                  {/* <p className="text-center mt-3">A donut chart showing data as chunks of pie. It uses donut and donutSolid to draw a donut chart.</p> */}
                </CardBody>
              </Card>
            </Col>

            <Col md={6} lg={6} xl={4}>
              <Card className="borderless-shadow">
                <CardHeader>
                  <CardTitle><h5 className="mb-0">Line Chart</h5></CardTitle>
                </CardHeader>
                <CardBody>
                  <Line data={lineData} width={150} className="ct-chart" />
                  {/* <p className="text-center mt-3">A simple line chart with 2 subject data. You can also add any number of subject that will show an individual line chart.</p> */}
                </CardBody>
              </Card>
            </Col>

            <Col md={6} lg={6} xl={4}>
              <Card className="borderless-shadow">
                <CardHeader>
                  <CardTitle><h5 className="mb-0">Radar Chart</h5></CardTitle>
                </CardHeader>
                <CardBody>
                  <Radar data={radarData} width={150} />
                  {/* <p className="text-center mt-3">The Radar chart shows the data objects spreading whitin the range to form closing radar. Resize your browser to see the effect of the responsive configuration.</p> */}
                </CardBody>
              </Card>
            </Col>
            
            <Col md={6} lg={6} xl={4}>
              <Card className="borderless-shadow pie-chart">
                <CardHeader>
                  <CardTitle><h5 className="mb-0">Pie Chart</h5></CardTitle>
                </CardHeader>
                <CardBody>
                  <Pie data={pieData} width={150} />
                </CardBody>
              </Card>
            </Col>
            
            <Col md={6} lg={6} xl={4}>
              <Card className="borderless-shadow bi-polar-chart">
                <CardHeader>
                  <CardTitle><h5 className="mb-0">Bi-polar chart</h5></CardTitle>
                </CardHeader>
                <CardBody>
                  <Polar data={polarData} width={150} />
                </CardBody>
              </Card>
            </Col>

            <Col md={6} lg={6} xl={4}>
              <Card className="borderless-shadow scatter-chart">
                <CardHeader>
                  <CardTitle><h5 className="mb-0">Scatter Chart</h5></CardTitle>
                </CardHeader>
                <CardBody>
                  <Scatter data={scatter} className="ct-chart" width={150} />
                </CardBody>
              </Card>
            </Col>
         
          </Row>

          <Row>
          </Row>
        
        </div>
      </div>
    );
  }
}

export default Charts;