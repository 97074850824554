import React from "react";
import "./orders.css";

import { Card, CardBody, Input, InputGroup, InputGroupAddon } from "reactstrap";

class Orders extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Orders</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">E-Commerce</a>
                <span className="breadcrumb-item active">Orders</span>
              </nav>
            </div>
          </div>

          <Card className="borderless-shadow mb-0">
            <CardBody>
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}
              >
                <div className="orders-table-search-container mb-3">
                  <input type="search" className="orders-table-search" placeholder="Search.." />
                  <span className="orders-table-search-icon-container" >
                    <i className="mdi mdi-magnify orders-table-search-icon"></i>
                  </span>
                </div>
                <div className="mb-3">
                  <button className="orders-table-add-new-order-btn">
                    <i className="mdi mdi-plus mr-2"></i>
                    add new order
                  </button>
                </div>
              </div>
              
              <div className="table-overflow-x">
                <table className="orders-table">
                  <thead>
                    <tr>
                      <th style={{ width: '20px' }}>
                        <div className="custom-control custom-checkbox">
                          <input id="customCheck1" type="checkbox" className="custom-control-input form-check-input" />
                          <label htmlFor="customCheck1" className="custom-control-label">&nbsp;</label>
                        </div>
                      </th>
                      <th>Order Id</th>
                      <th>Billing Name</th>
                      <th>Date</th>
                      <th>Total</th>
                      <th>Payment Status</th>
                      <th>Payment Method</th>
                      <th>View Details</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="custom-control custom-checkbox">
                          <input id="customCheck2" type="checkbox" className="custom-control-input" />
                          <label htmlFor="customCheck2" className="custom-control-label">&nbsp;</label>
                        </div>
                      </td>
                      <td>
                        <a className="text-body font-weight-semibold" href="#">#JD12001</a>
                      </td>
                      <td>James Hattman</td>
                      <td>07 July, 2020</td>
                      <td>$1200</td>
                      <td><span className="order-status order-status-paid">Paid</span></td>
                      <td><i className="fab fa-cc-mastercard mr-1"></i> Mastercard</td>
                      <td>
                        <button type="button" className="orders-view-detail-btn">View Details</button>
                      </td>
                      <td>
                        <a className="mr-3 text-primary" href="#">
                          <i className="mdi mdi-pencil font-size-18 mr-3" id="edittooltip"></i>
                        </a>
                        <a className="text-danger" href="#">
                          <i className="mdi mdi-close font-size-18 mr-3" id="deletetooltip"></i>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="custom-control custom-checkbox">
                          <input id="customCheck2" type="checkbox" className="custom-control-input" />
                          <label htmlFor="customCheck2" className="custom-control-label">&nbsp;</label>
                        </div>
                      </td>
                      <td>
                        <a className="text-body font-weight-semibold" href="#">#JD12002</a>
                      </td>
                      <td>Jeremy Mathias</td>
                      <td>05 July, 2020</td>
                      <td>$260</td>
                      <td><span className="order-status order-status-cancelled">Cancelled</span></td>
                      <td><i className="fab fa-cc-visa mr-1"></i> Visa</td>
                      <td>
                        <button type="button" className="orders-view-detail-btn">View Details</button>
                      </td>
                      <td>
                        <a className="mr-3 text-primary" href="#">
                          <i className="mdi mdi-pencil font-size-18 mr-3" id="edittooltip"></i>
                        </a>
                        <a className="text-danger" href="#">
                          <i className="mdi mdi-close font-size-18 mr-3" id="deletetooltip"></i>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="custom-control custom-checkbox">
                          <input id="customCheck2" type="checkbox" className="custom-control-input" />
                          <label htmlFor="customCheck2" className="custom-control-label">&nbsp;</label>
                        </div>
                      </td>
                      <td>
                        <a className="text-body font-weight-semibold" href="#">#JD12003</a>
                      </td>
                      <td>Amy Jordan</td>
                      <td>4 July, 2020</td>
                      <td>$499.00</td>
                      <td><span className="order-status order-status-paid">Paid</span></td>
                      <td><i className="fab fa-cc-paypal mr-1"></i> Paypal</td>
                      <td>
                        <button type="button" className="orders-view-detail-btn">View Details</button>
                      </td>
                      <td>
                        <a className="mr-3 text-primary" href="#">
                          <i className="mdi mdi-pencil font-size-18 mr-3" id="edittooltip"></i>
                        </a>
                        <a className="text-danger" href="#">
                          <i className="mdi mdi-close font-size-18 mr-3" id="deletetooltip"></i>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="custom-control custom-checkbox">
                          <input id="customCheck2" type="checkbox" className="custom-control-input" />
                          <label htmlFor="customCheck2" className="custom-control-label">&nbsp;</label>
                        </div>
                      </td>
                      <td>
                        <a className="text-body font-weight-semibold" href="#">#JD12004</a>
                      </td>
                      <td>John Kyle</td>
                      <td>3 July, 2020</td>
                      <td>$639.99</td>
                      <td><span className="order-status order-status-paid">Paid</span></td>
                      <td><i className="fab fas fa-money-bill-alt mr-1"></i> COD</td>
                      <td>
                        <button type="button" className="orders-view-detail-btn">View Details</button>
                      </td>
                      <td>
                        <a className="mr-3 text-primary" href="#">
                          <i className="mdi mdi-pencil font-size-18 mr-3" id="edittooltip"></i>
                        </a>
                        <a className="text-danger" href="#">
                          <i className="mdi mdi-close font-size-18 mr-3" id="deletetooltip"></i>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="custom-control custom-checkbox">
                          <input id="customCheck2" type="checkbox" className="custom-control-input" />
                          <label htmlFor="customCheck2" className="custom-control-label">&nbsp;</label>
                        </div>
                      </td>
                      <td>
                        <a className="text-body font-weight-semibold" href="#">#JD12005</a>
                      </td>
                      <td>Ollie Walker</td>
                      <td>2 July, 2020</td>
                      <td>$950.50</td>
                      <td><span className="order-status order-status-chargeback">Chargeback</span></td>
                      <td><i className="fab fa-cc-mastercard mr-1"></i> Mastercard</td>
                      <td>
                        <button type="button" className="orders-view-detail-btn">View Details</button>
                      </td>
                      <td>
                        <a className="mr-3 text-primary" href="#">
                          <i className="mdi mdi-pencil font-size-18 mr-3" id="edittooltip"></i>
                        </a>
                        <a className="text-danger" href="#">
                          <i className="mdi mdi-close font-size-18 mr-3" id="deletetooltip"></i>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="custom-control custom-checkbox">
                          <input id="customCheck2" type="checkbox" className="custom-control-input" />
                          <label htmlFor="customCheck2" className="custom-control-label">&nbsp;</label>
                        </div>
                      </td>
                      <td>
                        <a className="text-body font-weight-semibold" href="#">#JD12006</a>
                      </td>
                      <td>Kyle Watson</td>
                      <td>1 july, 2020</td>
                      <td>$499.99</td>
                      <td><span className="order-status order-status-paid">Paid</span></td>
                      <td><i className="fab fa-cc-paypal mr-1"></i> Paypal</td>
                      <td>
                        <button type="button" className="orders-view-detail-btn">View Details</button>
                      </td>
                      <td>
                        <a className="mr-3 text-primary" href="#">
                          <i className="mdi mdi-pencil font-size-18 mr-3" id="edittooltip"></i>
                        </a>
                        <a className="text-danger" href="#">
                          <i className="mdi mdi-close font-size-18 mr-3" id="deletetooltip"></i>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="custom-control custom-checkbox">
                          <input id="customCheck2" type="checkbox" className="custom-control-input" />
                          <label htmlFor="customCheck2" className="custom-control-label">&nbsp;</label>
                        </div>
                      </td>
                      <td>
                        <a className="text-body font-weight-semibold" href="#">#JD12007</a>
                      </td>
                      <td>Alice Nuttcracker</td>
                      <td>30 June, 2020</td>
                      <td>$799.49</td>
                      <td><span className="order-status order-status-refund">Refund</span></td>
                      <td><i className="fab fa-cc-mastercard mr-1"></i> Mastercard</td>
                      <td>
                        <button type="button" className="orders-view-detail-btn">View Details</button>
                      </td>
                      <td>
                        <a className="mr-3 text-primary" href="#">
                          <i className="mdi mdi-pencil font-size-18 mr-3" id="edittooltip"></i>
                        </a>
                        <a className="text-danger" href="#">
                          <i className="mdi mdi-close font-size-18 mr-3" id="deletetooltip"></i>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="custom-control custom-checkbox">
                          <input id="customCheck2" type="checkbox" className="custom-control-input" />
                          <label htmlFor="customCheck2" className="custom-control-label">&nbsp;</label>
                        </div>
                      </td>
                      <td>
                        <a className="text-body font-weight-semibold" href="#">#JD12008</a>
                      </td>
                      <td>Golian Petty</td>
                      <td>28 July, 2020</td>
                      <td>$1100</td>
                      <td><span className="order-status order-status-refund">Refund</span></td>
                      <td><i className="fab fa-cc-paypal mr-1"></i> Paypal</td>
                      <td>
                        <button type="button" className="orders-view-detail-btn">View Details</button>
                      </td>
                      <td>
                        <a className="mr-3 text-primary" href="#">
                          <i className="mdi mdi-pencil font-size-18 mr-3" id="edittooltip"></i>
                        </a>
                        <a className="text-danger" href="#">
                          <i className="mdi mdi-close font-size-18 mr-3" id="deletetooltip"></i>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="custom-control custom-checkbox">
                          <input id="customCheck2" type="checkbox" className="custom-control-input" />
                          <label htmlFor="customCheck2" className="custom-control-label">&nbsp;</label>
                        </div>
                      </td>
                      <td>
                        <a className="text-body font-weight-semibold" href="#">#JD12009</a>
                      </td>
                      <td>Claire Redfield</td>
                      <td>27 June, 2020</td>
                      <td>$265.50</td>
                      <td><span className="order-status order-status-paid">COD</span></td>
                      <td><i className="fab fas fa-money-bill-alt mr-1"></i> Mastercard</td>
                      <td>
                        <button type="button" className="orders-view-detail-btn">View Details</button>
                      </td>
                      <td>
                        <a className="mr-3 text-primary" href="#">
                          <i className="mdi mdi-pencil font-size-18 mr-3" id="edittooltip"></i>
                        </a>
                        <a className="text-danger" href="#">
                          <i className="mdi mdi-close font-size-18 mr-3" id="deletetooltip"></i>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="custom-control custom-checkbox">
                          <input id="customCheck2" type="checkbox" className="custom-control-input" />
                          <label htmlFor="customCheck2" className="custom-control-label">&nbsp;</label>
                        </div>
                      </td>
                      <td>
                        <a className="text-body font-weight-semibold" href="#">#JD12010</a>
                      </td>
                      <td>Niel Dawson</td>
                      <td>23 June, 2020</td>
                      <td>$400</td>
                      <td><span className="order-status order-status-chargeback">Chargeback</span></td>
                      <td><i className="fab fa-cc-paypal mr-1"></i> Paypal</td>
                      <td>
                        <button type="button" className="orders-view-detail-btn">View Details</button>
                      </td>
                      <td>
                        <a className="mr-3 text-primary" href="#">
                          <i className="mdi mdi-pencil font-size-18 mr-3" id="edittooltip"></i>
                        </a>
                        <a className="text-danger" href="/ecommerce-orders">
                          <i className="mdi mdi-close font-size-18 mr-3" id="deletetooltip"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
                  
              <div className="orders-table-pagination mt-2">
                <button className="orders-table-pagination-btn">
                  <i className="mdi mdi-arrow-left-bold-circle-outline"></i>
                </button>
                <button className="orders-table-pagination-btn">
                  1
                </button>
                <button className="orders-table-pagination-btn orders-table-pagination-btn-active">
                  2
                </button>
                <button className="orders-table-pagination-btn">
                  3
                </button>
                <button className="orders-table-pagination-btn">
                  4
                </button>
                <button className="orders-table-pagination-btn">
                  5
                </button>
                <button className="orders-table-pagination-btn orders-table-pagination-btn-disabled">
                  <i className="mdi mdi-arrow-right-bold-circle-outline"></i>
                </button>
              </div>
            </CardBody>
          </Card>

        </div>
      </div>
    );
  }
}

export default Orders;