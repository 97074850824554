import React, { Component, Fragment } from 'react';
import { HashRouter, Switch, Route, withRouter } from 'react-router-dom';

import $ from 'jquery';

import './App.css';
import './Vendor.js';

import  {getProducts} from './actions';
import { connect } from 'react-redux';

import Header from './layout/header/header';
import Sidebar from './layout/sidebar/sidebar';
import Footer from './layout/footer';

import Dashboard1 from './pages/dashboard1/dashboard1';
import Dashboard2 from './pages/dashboard2/dashboard2';
import Dashboard3 from './pages/dashboard3/dashboard3';
import Dashboard4 from './pages/dashboard4/dashboard4';

import Accordions from './components/accordion/accordion';
import Carousels from './components/carousel/carousel';
import Dropdowns from './components/dropdown/dropdown';
import Modals from './components/modals/modals';
import Popovers from './components/popover/popover';
import ProgressBar from './components/progress/progressbar';
import Tabs from './components/tabs/tabs';
import Tooltips from './components/tooltip/tooltip';

import Alerts from "./components/ui-elements/alerts/alerts";
import Badges from './components/ui-elements/badge/badges';
import Buttons from './components/ui-elements/buttons/buttons';
import Cards from './components/ui-elements/cards/cards';
import Colors from './components/ui-elements/colors/colors';
import Icons from './components/ui-elements/icons/icons';
import Lists from './components/ui-elements/lists/lists';
import Navbars from './components/ui-elements/navbar/navbar';
import Navs from './components/ui-elements/navs/navs';
import Typography from './components/ui-elements/typography/typography';
import DataWidget from './components/widgets/data/data-widget';
import MediaWidget from './components/widgets/media/media-widget';
import FormElements from './components/form/form-elements/form-elements';
import FormLayouts from './components/form/form-layout/form-layout';
import FormValidation from './components/form/form-validation/form-validation';
import BasicTables from './components/tables/basic-tables/basic-tables';
import DataTables from './components/tables/data-table/data-table';
import Charts from './components/charts/charts';
import Maps from './components/map/maps';
import Calendars from './components/application/calendar/calendars';
import EmailsApp from './components/application/email/email';
import ChatApp from './components/application/chat/chat';
import Contacts from './components/application/contact/contact';
import ProductsPage from './e-commerce/products/products';
import ProductDetail from './e-commerce/product-detail/product-detail';
import Cart from './e-commerce/cart/cart';
import Orders from './e-commerce/orders/orders';

class App extends React.Component {
  constructor(props) {
    super(props)
  }
  UNSAFE_componentWillMount()
  {
    this.props.getProducts();
  }
  render() {
    return (
      <Fragment>
        <div id="app">
          <div className="main-wrapper">
            <Header />
            <Sidebar />
            <Switch>
              <Route exact path="/" component={Dashboard1} />
              <Route exact path="/dashboard1" component={Dashboard1} />
              <Route exact path="/dashboard2" component={Dashboard2} />
              <Route exact path="/dashboard3" component={Dashboard3} />
              <Route exact path="/dashboard4" component={Dashboard4} />

              <Route exact path="/accordion" component={Accordions} />
              <Route exact path="/carousel" component={Carousels}/>
              <Route exact path="/dropdown" component={Dropdowns} />
              <Route exact path="/modals" component={Modals} />
              <Route exact path="/popover" component={Popovers}/>
              <Route exact path="/progress" component={ProgressBar}/>
              <Route exact path="/tabs" component={Tabs}/>
              <Route exact path="/tooltips" component={Tooltips}/>

              <Route exact path="/alert" component={Alerts} />
              <Route exact path="/badge" component={Badges} />
              <Route exact path="/button" component={Buttons} />
              <Route exact path="/cards" component={Cards} />
              <Route exact path="/colors" component={Colors} />
              <Route exact path="/icons" component={Icons} />
              <Route exact path="/lists" component={Lists} />
              <Route exact path="/navbar" component={Navbars} />
              <Route exact path="/navs" component={Navs} />
              <Route exact path="/typography" component={Typography} />
              <Route exact path="/widget-data" component={DataWidget} />
              <Route exact path="/media-data" component={MediaWidget} />
              <Route exact path="/media-data" component={MediaWidget} />

              <Route exact path="/form-elements" component={FormElements} />
              <Route exact path="/form-layouts" component={FormLayouts} />
              <Route exact path="/form-validation" component={FormValidation} />

              <Route exact path="/basic-tables" component={BasicTables} />
              <Route exact path="/data-tables" component={DataTables} />

              <Route exact path="/charts" component={Charts} />

              <Route exact path="/maps" component={Maps} />

              <Route exact path="/calendars" component={Calendars} />
              <Route exact path="/email" component={EmailsApp} />
              <Route exact path="/chat" component={ChatApp} />
              <Route exact path="/contact" component={Contacts} />

              <Route exact path="/products" component={ProductsPage} />
              <Route exact path="/product-detail" component={ProductDetail} />
              <Route exact path="/cart" component={Cart} />
              <Route exact path="/orders" component={Orders} />
            </Switch>
            <Footer />
          </div>
        </div>
      </Fragment>
    );
  }
}

const AppMapStateToProps = state => {
  return {
    products: state.data.products
  };
};

const AppMapDispatchToProps = dispatch => {
  return {
    getProducts: () => {
      dispatch(getProducts());
    }
  };
};


export default connect(AppMapStateToProps,AppMapDispatchToProps)(withRouter(App))