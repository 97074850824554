import React from "react";
import "./map.css";

import { scaleLinear } from "d3-scale";
import request from "axios";
import { CardBody, CardHeader, Card, CardTitle, Row, Col, Alert, UncontrolledAlert } from 'reactstrap';
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
  Markers,
  Marker,
} from "react-simple-maps";

const wrapperStyles = {
  width: "100%",
  maxWidth: 980,
  margin: "0 auto",
}

const cityScale = scaleLinear().domain([0, 37843000]).range([1, 25]);

class Maps extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      center: [0, 20],
      zoom: 1,
      cities: [
        { name: "India", coordinates: [78.9629, 20.5937] },
        { name: "Bangkok", coordinates: [100.5018, 13.7563] },
        { name: "United States of America", coordinates: [95.7129, 37.0902] },
        { name: "Canada", coordinates: [106.3468, 56.1304] },
        { name: "Brazil", coordinates: [51.9253, 14.2350] },
        { name: "Zimbabwe", coordinates: [29.1549, 19.0154] },
        { name: "Nigeria", coordinates: [8.6753, 9.0820] },
      ],
      citiesdata: [
        { "name": "Tokyo", "coordinates": [139.6917,35.6895], "population": 37843000 },
        { "name": "Jakarta", "coordinates": [106.8650,-6.1751], "population": 30539000 },
        { "name": "Delhi", "coordinates": [77.1025,28.7041], "population": 24998000 },
        { "name": "Manila", "coordinates": [120.9842,14.5995], "population": 24123000 },
        { "name": "Seoul", "coordinates": [126.9780,37.5665], "population": 23480000 },
        { "name": "Shanghai", "coordinates": [121.4737,31.2304], "population": 23416000 },
        { "name": "Karachi", "coordinates": [67.0099,24.8615], "population": 22123000 },
        { "name": "Beijing", "coordinates": [116.4074,39.9042], "population": 21009000 },
        { "name": "New York", "coordinates": [-74.0059,40.7128], "population": 20630000 },
        { "name": "Guangzhou", "coordinates": [113.2644,23.1291], "population": 20597000 },
        { "name": "Sao Paulo", "coordinates": [-46.6333,-23.5505], "population": 20365000 },
        { "name": "Mexico City", "coordinates": [-99.1332,19.4326], "population": 20063000 },
        { "name": "Mumbai", "coordinates": [72.8777,19.0760], "population": 17712000 },
        { "name": "Osaka", "coordinates": [135.5022,34.6937], "population": 17444000 },
        { "name": "Moscow", "coordinates": [37.6173,55.7558], "population": 16170000 },
        { "name": "Dhaka", "coordinates": [90.4125,23.8103], "population": 15669000 },
        { "name": "Greater Cairo", "coordinates": [31.2357,30.0444], "population": 15600000 },
        { "name": "Los Angeles", "coordinates": [-118.2437,34.0522], "population": 15058000 },
        { "name": "Bangkok", "coordinates": [100.5018,13.7563], "population": 14998000 },
        { "name": "Kolkata", "coordinates": [88.3639,22.5726], "population": 14667000 },
        { "name": "Buenos Aires", "coordinates": [-58.3816,-34.6037], "population": 14122000 },
        { "name": "Tehran", "coordinates": [51.3890,35.6892], "population": 13532000 },
        { "name": "Istanbul", "coordinates": [28.9784,41.0082], "population": 13287000 },
        { "name": "Lagos", "coordinates": [3.3792,6.5244], "population": 13123000 },
        { "name": "Shenzhen", "coordinates": [114.0579,22.5431], "population": 12084000 },
        { "name": "Rio de Janeiro", "coordinates": [-43.1729,-22.9068], "population": 11727000 },
        { "name": "Kinshasa", "coordinates": [15.2663,-4.4419], "population": 11587000 },
        { "name": "Tianjin", "coordinates": [117.3616,39.3434], "population": 10920000 },
        { "name": "Paris", "coordinates": [2.3522,48.8566], "population": 10858000 },
        { "name": "Lima", "coordinates": [-77.0428,-12.0464], "population": 10750000 }
      ]
    };

    this.handleCitySelection = this.handleCitySelection.bind(this);
    this.handleReset = this.handleReset.bind(this);
   // this.fetchCities = this.fetchCities.bind(this);
  }

  componentDidMount() {
    //this.fetchCities()
    window.scrollTo(0, 0)
  } 
  handleCitySelection(evt) {
    const cityId = evt.target.getAttribute("data-city")
    const city = this.state.cities[cityId]
    this.setState({
      center: city.coordinates,
      zoom: 2,
    })
  }
  handleReset() {
    this.setState({
      center: [0, 20],
      zoom: 1,
    })
  }
/*  fetchCities() {
    request
      .get(require(`./cities/world-most-populous-cities.json`))
      .then(res => {
        this.setState({
          citiesdata: res.data,
        })
      })
  } */
  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Vector Map</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">Map</a>
                <span className="breadcrumb-item active">Vector Map</span>
              </nav>
            </div>
          </div>
          
          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle><h5 className="mb-0">World Map Zoom</h5></CardTitle>
            </CardHeader>
            <CardBody>
              <div style={wrapperStyles}>
                {
                  this.state.cities.map((city, i) => (
                    <button
                      key={i}
                      className="btn btn-primary mb-3 mr-2"
                      data-city={i}
                      onClick={this.handleCitySelection}
                    >
                      {city.name}
                    </button>
                  ))
                }
                <button onClick={this.handleReset} className="btn btn-secondary mb-3">
                  {"Reset"}
                </button>
              </div>
              <div style={wrapperStyles}>
                <ComposableMap
                  projectionConfig={{
                    scale: 205,
                  }}
                  width={980}
                  height={551}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                >
                  <ZoomableGroup center={this.state.center} zoom={this.state.zoom}>
                    <Geographies geography={require(`./cities/world-50m.json`)}>
                      {(geographies, projection) => geographies.map((geography, i) => geography.id !== "ATA" && (
                        <Geography
                          key={i}
                          geography={geography}
                          projection={projection}
                          style={{
                            default: {
                              fill: "#ececec",
                              stroke: "#aaa",
                              strokeWidth: 0.5,
                              outline: "none",
                            },
                            hover: {
                              fill: "#c9c9df",
                              stroke: "#5a5a81",
                              strokeWidth: 0.5,
                              outline: "none",
                            },
                            pressed: {
                              fill: "#ababe2",
                              stroke: "#5a5a81",
                              strokeWidth: 0.5,
                              outline: "none",
                            },
                          }}
                        />
                      ))}
                    </Geographies>
                    <Markers>
                      {
                        this.state.cities.map((city, i) => (
                          <Marker key={i} marker={city}>
                            <circle
                              cx={0}
                              cy={0}
                              r={6}
                              fill="#ff8800"
                              stroke="#none"
                            />
                          </Marker>
                        ))
                      }
                    </Markers>
                  </ZoomableGroup>
                </ComposableMap>
              </div>
            </CardBody>
          </Card>

          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle><h5 className="mb-0">Simple World Map</h5></CardTitle>
            </CardHeader>
            <CardBody>

              <ComposableMap
                projectionConfig={{
                  scale: 205,
                  rotation: [-11, 0, 0],
                }}
                width={980}
                height={551}
                style={{
                  width: "100%",
                  height: "auto",
                }}
              >
                <ZoomableGroup center={[0, 20]} disablePanning>
                  <Geographies geography={require(`./cities/world-50m.json`)}>
                    {(geographies, projection) => geographies.map((geography, i) => geography.id !== "ATA" && (
                      <Geography
                        key={i}
                        geography={geography}
                        projection={projection}
                        style={{
                          default: {
                            fill: "#ececec",
                            stroke: "#aaa",
                            strokeWidth: 0.5,
                            outline: "none",
                          },
                          hover: {
                            fill: "#c9c9df",
                            stroke: "#5a5a81",
                            strokeWidth: 0.5,
                            outline: "none",
                          },
                          pressed: {
                            fill: "#ababe2",
                            stroke: "#5a5a81",
                            strokeWidth: 0.5,
                            outline: "none",
                          },
                        }}
                      />
                    ))}
                  </Geographies>
                </ZoomableGroup>
              </ComposableMap>

            </CardBody>
          </Card>
          
          <Card className="borderless-shadow mb-0">
            <CardHeader>
              <CardTitle><h5 className="mb-0">World Map Bubbles</h5></CardTitle>
            </CardHeader>
            <CardBody>

              <ComposableMap
                projectionConfig={{ scale: 205 }}
                width={980}
                height={551}
                style={{
                  width: "100%",
                  height: "auto",
                }}
              >
                <ZoomableGroup center={[0, 20]} disablePanning>
                  <Geographies geography={require(`./cities/world-50m.json`)}>
                    {(geographies, projection) =>
                      geographies.map((geography, i) =>
                        geography.id !== "ATA" && (
                          <Geography
                            key={i}
                            geography={geography}
                            projection={projection}
                            style={{
                              default: {
                                fill: "#ececec",
                                stroke: "#aaa",
                                strokeWidth: 0.5,
                                outline: "none",
                              },
                              hover: {
                                fill: "#c9c9df",
                                stroke: "#5a5a81",
                                strokeWidth: 0.5,
                                outline: "none",
                              },
                              pressed: {
                                fill: "#ababe2",
                                stroke: "#5a5a81",
                                strokeWidth: 0.5,
                                outline: "none",
                              },
                            }}
                          />
                        ))}
                  </Geographies>
                  <Markers>
                    {
                      this.state.citiesdata.map((city, i) => (
                        <Marker key={i} marker={city}>
                          <circle
                            cx={0}
                            cy={0}
                            r={cityScale(city.population)}
                            fill="rgba(255, 136, 0, 0.8)"
                            stroke="#e6451d"
                            strokeWidth="1"
                          />
                        </Marker>
                      ))
                    }
                  </Markers>
                </ZoomableGroup>
              </ComposableMap>

            </CardBody>
          </Card>

        </div>
      </div>
    );
  }
}

export default Maps;