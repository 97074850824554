import React from "react";
import "./dashboard3.css";
import { Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, CardTitle, CardText, Button, Form, FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import classnames from 'classnames';
import ReactApexChart from "react-apexcharts";

class Dashboard3 extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);

    this.state = {
      activeTab: '1',
      BTCSeries: [{
        name: 'BTC',
        data: [12, 14, 2, 51, 42, 75, 65, 40, 19, 81, 95, 100]
      }],
      BTCOptions: {
        chart: { type: 'area', toolbar: { show: false }, zoom: { enabled: false } },
        colors: ["#f18f1e"],
        dataLabels: { enabled: false },
        grid: { show: false, borderColor: '#ff0000', padding: { top: -20, right: 0, bottom: 0, left: 0 } },
        labels: ['Apples', 'Oranges', 'Berries', 'Grapes'],
        stroke: { curve: 'smooth', width: 1 },
        title: { text: "", floating: true, margin: 0 },
        tooltip: { x: { show: false } },
        fill: {
          opacity: 0.5,
          gradient: {
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.7,
            opacityTo: 0,
            stops: [0, 90, 100],
            colorStops: []
          }
        },
        xaxis: {
          type: 'number',
          categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
          floating: true,
          labels: { show: false },
          tooltip: { enabled: false },
          crosshairs: { show: false },
          axisBorder: { show: false }
        },
        yaxis: {
          labels: { show: false },
          floating: true,
        },
      },
      ETHSeries: [{
        name: 'BTC',
        data: [32, 50, 16, 15, 42, 75, 100, 76, 56, 71, 60, 65]
      }],
      ETHOptions: {
        chart: { type: 'area', toolbar: { show: false }, zoom: { enabled: false } },
        colors: ["#005bea"],
        dataLabels: { enabled: false },
        grid: { show: false, borderColor: '#ff0000', padding: { top: -20, right: 0, bottom: 0, left: 0 } },
        labels: ['Apples', 'Oranges', 'Berries', 'Grapes'],
        stroke: { curve: 'smooth', width: 1 },
        title: { text: "", floating: true, margin: 0 },
        tooltip: { x: { show: false } },
        fill: {
          opacity: 0.5,
          gradient: {
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.7,
            opacityTo: 0,
            stops: [0, 80, 100],
            colorStops: []
          }
        },
        xaxis: {
          type: 'number',
          categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
          floating: true,
          labels: { show: false },
          tooltip: { enabled: false },
          crosshairs: { show: false },
          axisBorder: { show: false }
        },
        yaxis: {
          labels: { show: false },
          floating: true,
        },
      },
      LTCSeries: [{
        name: 'BTC',
        data: [29, 46, 41, 30, 18, 15, 21, 38, 30, 34, 39, 42]
      }],
      LTCOptions: {
        chart: { type: 'area', toolbar: { show: false }, zoom: { enabled: false } },
        colors: ["#029666"],
        dataLabels: { enabled: false },
        grid: { show: false, borderColor: '#ff0000', padding: { top: -20, right: 0, bottom: 0, left: 0 } },
        labels: ['Apples', 'Oranges', 'Berries', 'Grapes'],
        stroke: { curve: 'smooth', width: 1 },
        title: { text: "", floating: true, margin: 0 },
        tooltip: { x: { show: false } },
        fill: {
          opacity: 0.5,
          gradient: {
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.7,
            opacityTo: 0,
            stops: [0, 80, 100],
            colorStops: []
          }
        },
        xaxis: {
          type: 'number',
          categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
          floating: true,
          labels: { show: false },
          tooltip: { enabled: false },
          crosshairs: { show: false },
          axisBorder: { show: false }
        },
        yaxis: {
          labels: { show: false },
          floating: true,
        },
      },
      MakerSeries: [{
        name: 'BTC',
        data: [23, 46, 41, 30, 42, 55, 21, 38, 45, 58, 39, 42]
      }],
      MakerOptions: {
        chart: { type: 'area', toolbar: { show: false }, zoom: { enabled: false } },
        colors: ["#2bc2c2"],
        dataLabels: { enabled: false },
        grid: { show: false, borderColor: '#ff0000', padding: { top: -20, right: 0, bottom: 0, left: 0 } },
        labels: ['Apples', 'Oranges', 'Berries', 'Grapes'],
        stroke: { curve: 'smooth', width: 1 },
        title: { text: "", floating: true, margin: 0 },
        tooltip: { x: { show: false } },
        fill: {
          opacity: 0.5,
          gradient: {
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.7,
            opacityTo: 0,
            stops: [0, 80, 100],
            colorStops: []
          }
        },
        xaxis: {
          type: 'number',
          categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
          floating: true,
          labels: { show: false },
          tooltip: { enabled: false },
          crosshairs: { show: false },
          axisBorder: { show: false }
        },
        yaxis: {
          labels: { show: false },
          floating: true,
        },
      },
      DashSeries: [{
        name: 'BTC',
        data: [29, 46, 41, 30, 42, 37, 42, 38, 30, 34, 39, 42]
      }],
      DashOptions: {
        chart: { type: 'area', toolbar: { show: false }, zoom: { enabled: false } },
        colors: ["#f93a5a"],
        dataLabels: { enabled: false },
        grid: { show: false, borderColor: '#ff0000', padding: { top: -20, right: 0, bottom: 0, left: 0 } },
        labels: ['Apples', 'Oranges', 'Berries', 'Grapes'],
        stroke: { curve: 'smooth', width: 1 },
        title: { text: "", floating: true, margin: 0 },
        tooltip: { x: { show: false } },
        fill: {
          opacity: 0.5,
          gradient: {
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.7,
            opacityTo: 0,
            stops: [0, 80, 100],
            colorStops: []
          }
        },
        xaxis: {
          type: 'number',
          categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
          floating: true,
          labels: { show: false },
          tooltip: { enabled: false },
          crosshairs: { show: false },
          axisBorder: { show: false }
        },
        yaxis: {
          labels: { show: false },
          floating: true,
        },
      },
      MoneroSeries: [{
        name: 'BTC',
        data: [29, 46, 41, 30, 45, 50, 60, 38, 44, 53, 70, 72]
      }],
      MoneroOptions: {
        chart: { type: 'area', toolbar: { show: false }, zoom: { enabled: false } },
        colors: ["#f9cc3a"],
        dataLabels: { enabled: false },
        grid: { show: false, borderColor: '#ff0000', padding: { top: -20, right: 0, bottom: 0, left: 0 } },
        labels: ['Apples', 'Oranges', 'Berries', 'Grapes'],
        stroke: { curve: 'smooth', width: 1 },
        title: { text: "", floating: true, margin: 0 },
        tooltip: { x: { show: false } },
        fill: {
          opacity: 0.5,
          gradient: {
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.7,
            opacityTo: 0,
            stops: [0, 80, 100],
            colorStops: []
          }
        },
        xaxis: {
          type: 'number',
          categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
          floating: true,
          labels: { show: false },
          tooltip: { enabled: false },
          crosshairs: { show: false },
          axisBorder: { show: false }
        },
        yaxis: {
          labels: { show: false },
          floating: true,
        },
      },
      radialwalletBalanceSeries: [89, 72, 56, 43],
      radialwalletBalanceOptions: {
        chart: { type: 'radialBar', toolbar: { show: false } },
        colors: ['#f18f1e', '#2bc2c2', '#005bea', '#28b366'],
        labels: ['Bitcoin', 'Maker', 'Ethereum', 'Litecoin'],
        fill: { opacity: 1, colors: ['#f18f1e', '#2bc2c2','#005bea', '#28b366'] },
        grid: { show: false, padding: { top: 0, right: 0, bottom: 0, left: 0 } },
        stroke: { width: 10, lineCap: 'round' },
        plotOptions: {
          radialBar: {
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: '60%',
            },
            track: { show: true, margin: 5 },
            dataLabels: {
              name: { fontSize: '22px', offsetY: -6 },
              value: { fontSize: '16px', fontWeight: 600, offsetY: 5},
              total: {
                show: true, label: 'Total',
                color: '#333',
                fontSize: '18px',
                formatter: function (w) {
                  // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                  return '112%'
                }
              }
            }
          }
        },
      }
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  toggle = tab => {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  }
 
  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pt-2 pl-0 pr-0">

          <Row className="mt-4">
            <Col md={4} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardBody>
                  <div style={{ display: 'flex', alignItems: 'center', flexShrink: 0, flexWrap: 'wrap' }}>
                    <div className="image-ring-90-4 mb-3 mr-3">
                      <div style={{
                        height: '96%', width: '96%', borderRadius: '50px', overflow: "hidden",
                        position: "absolute", top: '50%', left: '50%', transform: 'translate(-50%, -50%)'
                      }}>
                        <img src={require(`../../assets/images/profiles/profile-pic-3.jpeg`)} width="100%" />
                      </div>

                    </div>
                    <div className="mb-3">
                      <h6 className="font-weight-semibold mb-2"> <span>Hi, </span>David Houston</h6>
                      <p className="font-size-14 text-muted mb-0">David.Houston@abc.com</p>
                      <span className="font-size-14 text-muted">ID: </span>
                      <span className="font-size-14 text-muted">#DH0234</span>
                    </div>
                  </div>

                  <div className="divide-bar mb-4" style={{ backgroundColor: '#eee' }} />
                  
                  <Row>
                    <Col className="dash3-1117breakpoint"> {/* md={6} but at 1117px, not at 768px */}
                      <div className="mb-2">
                        <p className="font-weight-semibold mb-2 text-muted">Balance:</p>
                        <p className="font-size-20 font-weight-semibold mb-0 nobreak">$7823.99</p>
                      </div>
                    </Col>
                    <Col className="dash3-1117breakpoint"> {/* md={6} but at 1117px, not at 768px */}
                      <div className="mb-4">
                        <p className="font-weight-semibold mb-2 text-muted">Coins:</p>
                        <div className="font-size-15">
                          <div
                            className="d-inline-block mr-2"
                            style={{
                              backgroundColor: 'rgba(241, 142, 30, 0.2)',
                              color: '#f18f1e',
                              width: '2em', height: '2em', borderRadius: '50em',
                              textAlign: 'center', lineHeight: '2em'
                            }}
                          >
                            <i className="fab fa-btc"></i>
                          </div>

                          <div
                            className="d-inline-block"
                            style={{
                              backgroundColor: 'rgb(0, 91, 234, 0.2)',
                              color: '#005bea',
                              width: '2em', height: '2em', borderRadius: '50em',
                              textAlign: 'center', lineHeight: '2em'
                            }}
                          >
                            <i className="fab fa-ethereum"></i>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                
                  <div className="divide-bar mb-3" style={{ backgroundColor: '#eee' }} />    
                  
                  <div style={{ display: 'flex', justifyContent: "space-evenly", alignItems: 'center', flexWrap: 'nowrap' }}>
                    <button className="btn-crypto btn-crypto-buy-sell mr-3 nobreak">Buy / Sell</button>
                    <button className="btn-crypto btn-crypto-deposit">Deposit</button>
                  </div>

                </CardBody>
              </Card>
            </Col>
            <Col md={8}>
              <Row style={{height: '100%'}}>
                <Col md={6} lg={4} xl={4} className="d-flex">
                  <Card className="borderless-shadow w-100">
                    <CardBody className="pb-2">
                      <h6 className="font-size-18 mb-2">
                        <div
                          className="d-inline-block mr-2 mb-2"
                          style={{
                            backgroundColor: 'rgba(241, 142, 30, 0.2)',
                            color: '#f18f1e',
                            width: '2em', height: '2em', borderRadius: '50em',
                            textAlign: 'center', lineHeight: '2em'
                          }}
                        >
                          <i className="fab fa-btc"></i>
                        </div>
                        Bitcoin
                      </h6>

                      <div className="dash3-coin-areachart-content-container">
                        <div className="mr-2">
                          <p className="font-size-15 font-weight-semibold nobreak mb-2">$ 9,210.93</p>
                          <p className="font-size-12 text-muted mb-0 font-weight-semibold">
                            <i className="far fa-arrow-alt-circle-up mr-1"></i>
                            1.35%
                          </p>
                        </div>
                        <div style={{ minHeight: '10px', maxHeight: '70px', width: '100%' }}>
                          <ReactApexChart options={this.state.BTCOptions} series={this.state.BTCSeries} type="area" width="100%" height="100%" />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} lg={4} xl={4} className="d-flex">
                  <Card className="borderless-shadow w-100">
                    <CardBody className="pb-2">
                      <h6 className="font-size-18 mb-2">
                        <div
                          className="d-inline-block mr-2 mb-2"
                          style={{
                            backgroundColor: 'rgb(0, 91, 234, 0.2)',
                            color: '#005bea',
                            width: '2em', height: '2em', borderRadius: '50em',
                            textAlign: 'center', lineHeight: '2em'
                          }}
                        >
                          <i className="fab fa-ethereum"></i>
                        </div>
                        Ethereum
                      </h6>

                      <div className="dash3-coin-areachart-content-container">
                        <div className="mr-2">
                          <p className="font-size-15 font-weight-semibold nobreak mb-2">$ 233.474</p>
                          <p className="font-size-12 text-muted mb-0 font-weight-semibold">
                            <i className="far fa-arrow-alt-circle-up mr-1"></i>
                            2.80%
                          </p>
                        </div>
                        <div style={{ minHeight: '10px', maxHeight: '70px', width: '100%' }}>
                          <ReactApexChart options={this.state.ETHOptions} series={this.state.ETHSeries} type="area" width="100%" height="100%" />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} lg={4} xl={4} className="d-flex">
                  <Card className="borderless-shadow w-100">
                    <CardBody className="pb-2">
                      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        <div
                          className="d-inline-block mr-2 mb-2"
                          style={{
                            backgroundColor: 'rgba(2, 150, 103, 0.2)',
                            width: '2.25em', height: '2.25em', borderRadius: '50em',
                            textAlign: 'center', lineHeight: '2em', flexShrink: 0
                          }}
                        >
                          <img src={require(`../../assets/images/icons/litecoin.png`)} width="45%" />
                        </div>
                        <h6 className="font-size-18 mb-2">Litecoin</h6>
                      </div>

                      <div className="dash3-coin-areachart-content-container">
                        <div className="mr-2">
                          <p className="font-size-15 font-weight-semibold nobreak mb-2">$ 42.3632</p>
                          <p className="font-size-12 text-muted mb-0 font-weight-semibold">
                            <i className="far fa-arrow-alt-circle-up mr-1"></i>
                            2.02%
                          </p>
                        </div>
                        <div style={{ minHeight: '10px', maxHeight: '70px', width: '100%' }}>
                          <ReactApexChart options={this.state.LTCOptions} series={this.state.LTCSeries} type="area" width="100%" height="100%" />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} lg={4} xl={4} className="d-flex">
                  <Card className="borderless-shadow w-100">
                    <CardBody className="pb-2">
                      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        <div
                          className="d-inline-block mr-2 mb-2"
                          style={{
                            backgroundColor: 'rgb(249, 58, 90, 0.2)',
                            width: '2.25em', height: '2.25em', borderRadius: '50em',
                            textAlign: 'center', lineHeight: '2em', flexShrink: 0
                          }}
                        >
                          <img src={require(`../../assets/images/icons/dash.png`)} width="45%" />
                        </div>
                        <h6 className="font-size-18 mb-2">Dash</h6>
                      </div>

                      <div className="dash3-coin-areachart-content-container">
                        <div className="mr-2">
                          <p className="font-size-15 font-weight-semibold nobreak mb-2">$ 68.6862</p>
                          <p className="font-size-12 text-muted mb-0 font-weight-semibold">
                            <i className="far fa-arrow-alt-circle-up mr-1"></i>
                            2.21%
                          </p>
                        </div>
                        <div style={{ minHeight: '10px', maxHeight: '70px', width: '100%' }}>
                          <ReactApexChart options={this.state.DashOptions} series={this.state.DashSeries} type="area" width="100%" height="100%" />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} lg={4} xl={4} className="d-flex">
                  <Card className="borderless-shadow w-100">
                    <CardBody className="pb-2">
                      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        <div
                          className="d-inline-block mr-2 mb-2"
                          style={{
                            backgroundColor: 'rgb(41, 161, 182, 0.2)',
                            width: '2.25em', height: '2.25em', borderRadius: '50em',
                            textAlign: 'center', lineHeight: '2em', flexShrink: 0
                          }}
                        >
                          <img src={require(`../../assets/images/icons/maker.png`)} width="45%" />
                        </div>
                        <h6 className="font-size-18 mb-2">Maker</h6>
                      </div>

                      <div className="dash3-coin-areachart-content-container">
                        <div className="mr-2">
                          <p className="font-size-15 font-weight-semibold nobreak mb-2">$ 470.568</p>
                          <p className="font-size-12 text-muted mb-0 font-weight-semibold">
                            <i className="far fa-arrow-alt-circle-up mr-1"></i>
                            0.54%
                          </p>
                        </div>
                        <div style={{ minHeight: '10px', maxHeight: '70px', width: '100%' }}>
                          <ReactApexChart options={this.state.MakerOptions} series={this.state.MakerSeries} type="area" width="100%" height="100%" />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} lg={4} xl={4} className="d-flex">
                  <Card className="borderless-shadow w-100">
                    <CardBody className="pb-2">
                      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        <div
                          className="d-inline-block mr-2 mb-2"
                          style={{
                            backgroundColor: 'rgb(249, 204, 58, 0.2)',
                            width: '2.25em', height: '2.25em', borderRadius: '50em',
                            textAlign: 'center', lineHeight: '2em', flexShrink: 0
                          }}
                        >
                          <img src={require(`../../assets/images/icons/monero.png`)} width="45%" />
                        </div>
                        <h6 className="font-size-18 mb-2">Monero</h6>

                      </div>

                      <div className="dash3-coin-areachart-content-container">
                        <div className="mr-2">
                          <p className="font-size-15 font-weight-semibold nobreak mb-2">$ 63.6683</p>
                          <p className="font-size-12 text-muted mb-0 font-weight-semibold">
                            <i className="far fa-arrow-alt-circle-up mr-1"></i>
                            0.02%
                          </p>
                        </div>
                        <div style={{ minHeight: '10px', maxHeight: '70px', width: '100%' }}>
                          <ReactApexChart options={this.state.MoneroOptions} series={this.state.MoneroSeries} type="area" width="100%" height="100%" />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xl={8}>
              <Card className="borderless-shadow">
                <CardBody>
                  <h5 className="font-size-18 font-weight-semibold mb-3">Wallet Balance</h5>
                  <Row>
                    <Col md={4} className="pr-0">
                      <h6 className="font-size-15 font-weight-normal mb-2 mt-4">Available Balance</h6>
                      <p className="font-size-20 font-weight-bold mb-1">$ 7823.99</p>
                      <p className="font-size-12 text-muted mb-4">
                        +0.004981 <span>(0.48 %) <i className="fas fa-long-arrow-alt-up text-success"></i></span>
                      </p>
                      <Row>
                        <Col xs={6} className="mt-3">
                          <h6 className="font-size-15 font-weight-normal mb-2">Income</h6>
                          <p className="font-size-16 font-weight-semibold mb-1">$ 1,384.23</p>
                        </Col>
                        <Col xs={6} className="mt-3">
                          <h6 className="font-size-15 font-weight-normal mb-2">Expense</h6>
                          <p className="font-size-16 font-weight-semibold mb-1">$ 626.87</p>
                        </Col>
                      </Row>
                      <a href="#" className="dash3-view-more-btn mt-4">
                        View More <i className="fas fa-long-arrow-alt-right ml-1" style={{transform: 'translateY(1px)'}}></i>
                      </a>
                    </Col>
                    <Col md={4} className="pl-0 pr-0">
                      <div className="dash3-radialchart-container">
                        <div>
                          <ReactApexChart
                            options={this.state.radialwalletBalanceOptions}
                            series={this.state.radialwalletBalanceSeries}
                            type="radialBar" width="130%"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md={4} className="pl-4">
                      <div className="dash4-radialchart-label-container">
                        <div className="mb-2">
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <span className="dot-Marker dot-marker-orange mr-2"></span>
                            <h6 className="text-muted">Bitcoin</h6>
                          </div>
                          <p className="font-size-15 font-weight-semibold">
                            <span>1.000 BTC</span> = <span className="font-size-15" style={{ color: "#f18e1e"}}>$ 9256.00</span>
                          </p>
                        </div>
                        <div className="mb-2">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span className="dot-Marker dot-marker-teal mr-2"></span>
                            <h6 className="text-muted">Maker</h6>
                          </div>
                          <p className="font-size-15 font-weight-semibold">
                            <span>1.000 Maker</span> = <span className="font-size-15" style={{ color: "#2bc2c2" }}>$ 445.34</span>
                          </p>
                        </div>
                        <div className="mb-2">
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <span className="dot-Marker dot-marker-blue mr-2"></span>
                            <h6 className="text-muted">Ethereum</h6>
                          </div>
                          <p className="font-size-15 font-weight-semibold">
                            <span>1.000 ETH</span> = <span className="font-size-15" style={{ color: "#005bea"}}>$ 236.52</span>
                          </p>
                        </div>
                        <div className="mb-2">
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <span className="dot-Marker dot-marker-green mr-2"></span>
                            <h6 className="text-muted">Litecoin</h6>
                          </div>
                          <p className="font-size-15 font-weight-semibold">
                            <span>1.000 LTC</span> = <span className="font-size-15" style={{ color: "#28b366"}}>$ 43.06</span>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col xl={4} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardBody>
                  <div
                    className="mb-2"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                  >
                    <h6 className="font-size-15 mb-0">Buy / Sell</h6>
                    <div className="font-size-15">
                      <i className="fas fa-wallet mr-2" style={{ color: "#28b366" }}></i>
                      <span className="font-weight-semibold">$ 7,823.99</span>
                    </div>
                  </div>
                  <div>
                    <Nav tabs className="custom-nav-tabs">
                      <NavItem className="custom-nav-item">
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '1' })}
                          onClick={() => { this.toggle('1'); }}
                        >
                          Buy
                        </NavLink>
                      </NavItem>
                      <NavItem className="custom-nav-item">
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '2' })}
                          onClick={() => { this.toggle('2'); }}
                        >
                          Sell
                      </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1" className="custom-tabpane-padding">
                        <Row>
                          <Col sm="12">
                            <h6 className="font-size-15 font-weight-semibold mb-2 mt-1">Buy Coin</h6>
                            <Form>

                              <FormGroup className="mb-2">
                                <Label for="payment-method" className="mb-1 font-size-14">Payment Method</Label>
                                <Input type="select" name="paymentMethod" id="payment-method" size="sm">
                                  <option>Credit / Debit Card</option>
                                  <option>Paypal</option>
                                </Input>
                              </FormGroup>

                              <FormGroup>
                                <Label for="buy-add-amount" className="mb-1 font-size-14">Add Amount</Label>
                                <InputGroup size="sm">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Amount</InputGroupText>
                                  </InputGroupAddon>
                                  <InputGroupAddon addonType="append">
                                    <Input
                                      type="select" name="addAmount" id="buy-add-amount-select" size="sm"
                                      style={{borderRadius: 0, borderRight: 'none'}}
                                    >
                                      <option selected>BTC</option>
                                      <option>ETH</option>
                                      <option>LTC</option>
                                    </Input>
                                  </InputGroupAddon>
                                  <Input id="buy-add-amount" placeholder="Amount" />
                                </InputGroup>
                              </FormGroup>

                              <FormGroup>
                                <InputGroup size="sm">
                                  <InputGroupAddon addonType="prepend" >Total</InputGroupAddon>
                                  <Input type="number" id="total" name="total" />
                                </InputGroup>
                              </FormGroup>

                              <button className="buy-sell-coin-btn buy-coin-btn">Buy Coin</button>
                            </Form>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2" className="custom-tabpane-padding">
                        <Row>
                          <Col sm="12">
                            <h6 className="font-size-15 font-weight-semibold mb-2 mt-1">Sell Coin</h6>
                            <Form>

                              <FormGroup className="mb-2">
                                <Label for="sell-email" className="mb-1 font-size-14">Email</Label>
                                <Input type="text" name="paymentMethod" id="sell-email" size="sm" />
                              </FormGroup>

                              <FormGroup>
                                <Label for="sell-add-amount" className="mb-1 font-size-14">Add Amount</Label>
                                <InputGroup size="sm">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Amount</InputGroupText>
                                  </InputGroupAddon>
                                  <InputGroupAddon addonType="append">
                                    <Input
                                      type="select" name="addAmount" id="sell-add-amount-select" size="sm"
                                      style={{ borderRadius: 0, borderRight: 'none' }}
                                    >
                                      <option selected>BTC</option>
                                      <option>ETH</option>
                                      <option>LTC</option>
                                    </Input>
                                  </InputGroupAddon>
                                  <Input id="sell-add-amount" placeholder="Amount" />
                                </InputGroup>
                              </FormGroup>

                              <FormGroup>
                                <InputGroup size="sm">
                                  <InputGroupAddon addonType="prepend" >Total</InputGroupAddon>
                                  <Input type="number" id="total" name="total" />
                                </InputGroup>
                              </FormGroup>

                              <button className="buy-sell-coin-btn sell-coin-btn">Sell Coin</button>
                            </Form>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
          
          <Row>
            <Col lg={6} className="d-flex">
              <Card className="borderless-shadow w-100 dash3-recent-activity-1">
                <CardBody>
                  <h6 className="font-size-18 font-weight-semibold mb-3">Recent Activities</h6>
                  <div className="table-overflow-x">
                    <table className="dash3-recent-table mb-2">
                      <thead>
                        <tr>
                          <th style={{ width: '50%' }}>CRYPTO ORDERS</th>
                          <th style={{ width: '25%' }}>STATUS</th>
                          <th style={{ width: '25%' }}>AMOUNT</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              <div
                                className="d-inline-block mr-3"
                                style={{
                                  backgroundColor: 'rgba(40, 179, 103, 0.2)',
                                  color: '#28b366',
                                  width: '2em', height: '2em', borderRadius: '50em',
                                  fontSize: '18px', textAlign: 'center', lineHeight: '2em'
                                }}
                              >
                                <i className="far fa-handshake"></i>
                              </div>
                              <div>
                                <p className="font-size-14 font-weight-semibold mb-0">Buy Record</p>
                                <span className="font-size-10 text-muted mr-3">4-7</span>
                                <span className="font-size-10 text-muted">11:15:35</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="dash3-table-status-badge dash3-table-status-badge-completed">Completed</span>
                          </td>
                          <td style={{ color: "#28b366", fontWeight: 500 }}><span>+</span><span>$ 590.50</span></td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              <div
                                className="d-inline-block mr-3"
                                style={{
                                  backgroundColor: 'rgba(134, 41, 49, 0.2)',
                                  color: '#d1424e',
                                  width: '2em', height: '2em', borderRadius: '50em',
                                  fontSize: '18px', textAlign: 'center', lineHeight: '2em'
                                }}
                              >
                                <i className="fas fa-stopwatch"></i>
                              </div>
                              <div>
                                <p className="font-size-14 font-weight-semibold mb-0">Sell Record</p>
                                <span className="font-size-10 text-muted mr-3">25-6</span>
                                <span className="font-size-10 text-muted">18:03:45</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="dash3-table-status-badge dash3-table-status-badge-pending">Pending</span>
                          </td>
                          <td style={{ color: "#d1424e", fontWeight: 500 }}><span>-</span><span>$ 380.00</span></td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              <div
                                className="d-inline-block mr-3"
                                style={{
                                  backgroundColor: 'rgb(0, 91, 234, 0.2)',
                                  color: '#005bea',
                                  width: '2em', height: '2em', borderRadius: '50em',
                                  fontSize: '18px', textAlign: 'center', lineHeight: '2em'
                                }}
                              >
                                <i className="fas fa-retweet"></i>
                              </div>
                              <div>
                                <p className="font-size-14 font-weight-semibold mb-0">Exchange Record</p>
                                <span className="font-size-10 text-muted mr-3">24-6</span>
                                <span className="font-size-10 text-muted">15:45:11</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="dash3-table-status-badge dash3-table-status-badge-exchanged">Exchanged</span>
                          </td>
                          <td style={{ color: "#005bea", fontWeight: 500 }}><span>+</span><span>$ 1248.50</span></td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              <div
                                className="d-inline-block mr-3"
                                style={{
                                  backgroundColor: 'rgba(40, 179, 103, 0.2)',
                                  color: '#28b366',
                                  width: '2em', height: '2em', borderRadius: '50em',
                                  fontSize: '18px', textAlign: 'center', lineHeight: '2em'
                                }}
                              >
                                <i className="far fa-handshake"></i>
                              </div>
                              <div>
                                <p className="font-size-14 font-weight-semibold mb-0">Buy Record</p>
                                <span className="font-size-10 text-muted mr-3">24-6</span>
                                <span className="font-size-10 text-muted">15:45:11</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="dash3-table-status-badge dash3-table-status-badge-cancelled">Cancelled</span>
                          </td>
                          <td style={{ color: "#28b366", fontWeight: 500 }}><span>+</span><span>$ 562.55</span></td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                className="d-inline-block mr-3"
                                style={{
                                  backgroundColor: 'rgb(0, 91, 234, 0.2)',
                                  color: '#005bea',
                                  width: '2em', height: '2em', borderRadius: '50em',
                                  fontSize: '18px', textAlign: 'center', lineHeight: '2em'
                                }}
                              >
                                <i className="fas fa-retweet"></i>
                              </div>
                              <div>
                                <p className="font-size-14 font-weight-semibold mb-0">Exchange Record</p>
                                <span className="font-size-10 text-muted mr-3">22-6</span>
                                <span className="font-size-10 text-muted">11:52:20</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="dash3-table-status-badge dash3-table-status-badge-exchanged">Exchanged</span>
                          </td>
                          <td style={{ color: "#005bea", fontWeight: 500 }}><span>+</span><span>$ 450.00</span></td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                className="d-inline-block mr-3"
                                style={{
                                  backgroundColor: 'rgb(0, 91, 234, 0.2)',
                                  color: '#005bea',
                                  width: '2em', height: '2em', borderRadius: '50em',
                                  fontSize: '18px', textAlign: 'center', lineHeight: '2em'
                                }}
                              >
                                <i className="fas fa-retweet"></i>
                              </div>
                              <div>
                                <p className="font-size-14 font-weight-semibold mb-0">Exchange Record</p>
                                <span className="font-size-10 text-muted mr-3">22-6</span>
                                <span className="font-size-10 text-muted">17:20:18</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="dash3-table-status-badge dash3-table-status-badge-exchanged">Exchanged</span>
                          </td>
                          <td style={{ color: "#005bea", fontWeight: 500 }}><span>+</span><span>$ 300.50</span></td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                className="d-inline-block mr-3"
                                style={{
                                  backgroundColor: 'rgba(40, 179, 103, 0.2)',
                                  color: '#28b366',
                                  width: '2em', height: '2em', borderRadius: '50em',
                                  fontSize: '18px', textAlign: 'center', lineHeight: '2em'
                                }}
                              >
                                <i className="far fa-handshake"></i>
                              </div>
                              <div>
                                <p className="font-size-14 font-weight-semibold mb-0">Buy Record</p>
                                <span className="font-size-10 text-muted mr-3">20-6</span>
                                <span className="font-size-10 text-muted">9:58:25</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="dash3-table-status-badge dash3-table-status-badge-completed">Completed</span>
                          </td>
                          <td style={{ color: "#28b366", fontWeight: 500 }}><span>+</span><span>$ 1100.50</span></td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                className="d-inline-block mr-3"
                                style={{
                                  backgroundColor: 'rgba(134, 41, 49, 0.2)',
                                  color: '#d1424e',
                                  width: '2em', height: '2em', borderRadius: '50em',
                                  fontSize: '18px', textAlign: 'center', lineHeight: '2em'
                                }}
                              >
                                <i className="fas fa-stopwatch"></i>
                              </div>
                              <div>
                                <p className="font-size-14 font-weight-semibold mb-0">Sell Record</p>
                                <span className="font-size-10 text-muted mr-3">20-6</span>
                                <span className="font-size-10 text-muted">20:03:45</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="dash3-table-status-badge dash3-table-status-badge-cancelled">Cancelled</span>
                          </td>
                          <td style={{ color: "#d1424e", fontWeight: 500 }}><span>-</span><span>$ 815.00</span></td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                className="d-inline-block mr-3"
                                style={{
                                  backgroundColor: 'rgba(40, 179, 103, 0.2)',
                                  color: '#28b366',
                                  width: '2em', height: '2em', borderRadius: '50em',
                                  fontSize: '18px', textAlign: 'center', lineHeight: '2em'
                                }}
                              >
                                <i className="far fa-handshake"></i>
                              </div>
                              <div>
                                <p className="font-size-14 font-weight-semibold mb-0">Buy Record</p>
                                <span className="font-size-10 text-muted mr-3">24-6</span>
                                <span className="font-size-10 text-muted">10:29:46</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="dash3-table-status-badge dash3-table-status-badge-completed">completed</span>
                          </td>
                          <td style={{ color: "#28b366", fontWeight: 500 }}><span>+</span><span>$ 1200.99</span></td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                className="d-inline-block mr-3"
                                style={{
                                  backgroundColor: 'rgba(40, 179, 103, 0.2)',
                                  color: '#28b366',
                                  width: '2em', height: '2em', borderRadius: '50em',
                                  fontSize: '18px', textAlign: 'center', lineHeight: '2em'
                                }}
                              >
                                <i className="far fa-handshake"></i>
                              </div>
                              <div>
                                <p className="font-size-14 font-weight-semibold mb-0">Buy Record</p>
                                <span className="font-size-10 text-muted mr-3">18-6</span>
                                <span className="font-size-10 text-muted">16:53:52</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="dash3-table-status-badge dash3-table-status-badge-pending">Pending</span>
                          </td>
                          <td style={{ color: "#f18e1e", fontWeight: 500 }}><span>-</span><span>$ 1100.50</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6} className="d-flex">
              <Card className="borderless-shadow w-100 dash3-recent-activity-2">
                <CardBody>
                  <h6 className="font-size-18 font-weight-semibold mb-3">Recent Activities</h6>
                  <div className="table-overflow-x">
                    <table className="dash3-recent-table mb-2">
                      <thead>
                        <th style={{ width: '45%' }} className="nobreak">CRYPTO TRADE</th>
                        <th style={{ width: '15%' }}>TIME</th>
                        <th style={{ width: '20%' }}>STATUS</th>
                        <th style={{ width: '20%' }}>AMOUNT</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ paddingTop: '12px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                className="d-inline-block mr-3"
                                style={{
                                  backgroundColor: 'rgba(241, 142, 30, 0.2)',
                                  color: '#f18e1e',
                                  width: '2em', height: '2em', borderRadius: '50em',
                                  fontSize: '18px', textAlign: 'center', lineHeight: '2em'
                                }}
                              >
                                <i className="fab fa-btc"></i>
                              </div>
                              <div>
                                <p className="font-size-15 font-weight-semibold mb-0">Bitcoin</p>
                              </div>
                            </div>
                          </td>
                          <td style={{ fontSize: '13px', color: '#666' }}>11:45:25</td>
                          <td>
                            <span className="dash3-table-status-badge dash3-table-status-badge-completed">Completed</span>
                          </td>
                          <td style={{ color: "#28b366", fontWeight: 500 }}><span>+</span><span>$ 849.50</span></td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                className="d-inline-block mr-3"
                                style={{
                                  backgroundColor: 'rgb(0, 91, 234, 0.2)',
                                  color: '#005bea',
                                  width: '2em', height: '2em', borderRadius: '50em',
                                  fontSize: '18px', textAlign: 'center', lineHeight: '2em'
                                }}
                              >
                                <i className="fab fa-ethereum"></i>
                              </div>
                              <div>
                                <p className="font-size-14 font-weight-semibold mb-0">Ethereum</p>
                              </div>
                            </div>
                          </td>
                          <td style={{ fontSize: '13px', color: '#666' }}>18:03:45</td>
                          <td>
                            <span className="dash3-table-status-badge dash3-table-status-badge-pending">Pending</span>
                          </td>
                          <td style={{ color: "#f18e1e", fontWeight: 500 }}><span>-</span><span>$ 380.00</span></td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                className="d-inline-block mr-3"
                                style={{
                                  backgroundColor: 'rgb(249, 58, 90, 0.2)',
                                  width: '2.6em', height: '2.6em', borderRadius: '50em',
                                  textAlign: 'center', lineHeight: '2.25em'
                                }}
                              >
                                <img src={require(`../../assets/images/icons/dash.png`)} width="45%" />
                              </div>
                              <div>
                                <p className="font-size-14 font-weight-semibold mb-0">Dash</p>
                              </div>
                            </div>
                          </td>
                          <td style={{ fontSize: '13px', color: '#666' }}>14:20:18</td>
                          <td>
                            <span className="dash3-table-status-badge dash3-table-status-badge-exchanged">Exchanged</span>
                          </td>
                          <td style={{ color: "#005bea", fontWeight: 500 }}><span>+</span><span>$ 750.00</span></td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                className="d-inline-block mr-3"
                                style={{
                                  backgroundColor: 'rgb(41, 161, 182, 0.2)',
                                  width: '2.6em', height: '2.6em', borderRadius: '50em',
                                  textAlign: 'center', lineHeight: '2.25em'
                                }}
                              >
                                <img src={require(`../../assets/images/icons/maker.png`)} width="45%" />
                              </div>
                              <div>
                                <p className="font-size-14 font-weight-semibold mb-0">Maker</p>
                              </div>
                            </div>
                          </td>
                          <td style={{ fontSize: '13px', color: '#666' }}>15:45:11</td>
                          <td>
                            <span className="dash3-table-status-badge dash3-table-status-badge-cancelled">Cancelled</span>
                          </td>
                          <td style={{ color: "#28b366", fontWeight: 500 }}><span>+</span><span>$ 562.55</span></td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                className="d-inline-block mr-3"
                                style={{
                                  backgroundColor: 'rgb(41, 161, 182, 0.2)',
                                  width: '2.6em', height: '2.6em', borderRadius: '50em',
                                  textAlign: 'center', lineHeight: '2.25em'
                                }}
                              >
                                <img src={require(`../../assets/images/icons/maker.png`)} width="45%" />
                              </div>
                              <div>
                                <p className="font-size-14 font-weight-semibold mb-0">Maker</p>
                              </div>
                            </div>
                          </td>
                          <td style={{ fontSize: '13px', color: '#666' }}>11:52:20</td>
                          <td>
                            <span className="dash3-table-status-badge dash3-table-status-badge-exchanged">Exchanged</span>
                          </td>
                          <td style={{ color: "#005bea", fontWeight: 500 }}><span>+</span><span>$ 450.00</span></td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                className="d-inline-block mr-3"
                                style={{
                                  backgroundColor: 'rgba(241, 142, 30, 0.2)',
                                  color: '#f18e1e',
                                  width: '2em', height: '2em', borderRadius: '50em',
                                  fontSize: '18px', textAlign: 'center', lineHeight: '2em'
                                }}
                              >
                                <i className="fab fa-btc"></i>
                              </div>
                              <div>
                                <p className="font-size-14 font-weight-semibold mb-0">Bitcoin</p>
                              </div>
                            </div>
                          </td>
                          <td style={{ fontSize: '13px', color: '#666' }}>17:20:18</td>
                          <td>
                            <span className="dash3-table-status-badge dash3-table-status-badge-exchanged">Exchanged</span>
                          </td>
                          <td style={{ color: "#005bea", fontWeight: 500 }}><span>+</span><span>$ 300.50</span></td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                className="d-inline-block mr-3"
                                style={{
                                  backgroundColor: 'rgb(249, 58, 90, 0.2)',
                                  width: '2.6em', height: '2.6em', borderRadius: '50em',
                                  textAlign: 'center', lineHeight: '2.25em'
                                }}
                              >
                                <img src={require(`../../assets/images/icons/dash.png`)} width="45%" />
                              </div>
                              <div>
                                <p className="font-size-14 font-weight-semibold mb-0">Dash</p>
                              </div>
                            </div>
                          </td>
                          <td style={{ fontSize: '13px', color: '#666' }}>9:58:25</td>
                          <td>
                            <span className="dash3-table-status-badge dash3-table-status-badge-completed">Completed</span>
                          </td>
                          <td style={{ color: "#28b366", fontWeight: 500 }}><span>+</span><span>$ 950.50</span></td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                className="d-inline-block mr-3"
                                style={{
                                  backgroundColor: 'rgb(0, 91, 234, 0.2)',
                                  color: '#005bea',
                                  width: '2em', height: '2em', borderRadius: '50em',
                                  fontSize: '18px', textAlign: 'center', lineHeight: '2em'
                                }}
                              >
                                <i className="fab fa-ethereum"></i>
                              </div>
                              <div>
                                <p className="font-size-14 font-weight-semibold mb-0">Ethereum</p>
                              </div>
                            </div>
                          </td>
                          <td style={{ fontSize: '13px', color: '#666' }}>19:11:02</td>
                          <td>
                            <span className="dash3-table-status-badge dash3-table-status-badge-completed">Completed</span>
                          </td>
                          <td style={{ color: "#28b366", fontWeight: 500 }}><span>+</span><span>$ 1400.80</span></td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                className="d-inline-block mr-3"
                                style={{
                                  backgroundColor: 'rgb(0, 91, 234, 0.2)',
                                  color: '#005bea',
                                  width: '2em', height: '2em', borderRadius: '50em',
                                  fontSize: '18px', textAlign: 'center', lineHeight: '2em'
                                }}
                              >
                                <i className="fab fa-ethereum"></i>
                              </div>
                              <div>
                                <p className="font-size-14 font-weight-semibold mb-0">Ethereum</p>
                              </div>
                            </div>
                          </td>
                          <td style={{fontSize: '13px', color: '#666'}}>20:03:45</td>
                          <td>
                            <span className="dash3-table-status-badge dash3-table-status-badge-cancelled">Cancelled</span>
                          </td>
                          <td style={{ color: "#d1424e", fontWeight: 500 }}><span>-</span><span>$ 620.00</span></td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                className="d-inline-block mr-3"
                                style={{
                                  backgroundColor: 'rgb(249, 58, 90, 0.2)',
                                  width: '2.6em', height: '2.6em', borderRadius: '50em',
                                  textAlign: 'center', lineHeight: '2.25em'
                                }}
                              >
                                <img src={require(`../../assets/images/icons/dash.png`)} width="45%" />
                              </div>
                              <div>
                                <p className="font-size-14 font-weight-semibold mb-0">Dash</p>
                              </div>
                            </div>
                          </td>
                          <td style={{fontSize: '13px', color: '#666'}}>15:03:06</td>
                          <td>
                            <span className="dash3-table-status-badge dash3-table-status-badge-pending">Pending</span>
                          </td>
                          <td style={{ color: "#f18e1e", fontWeight: 500 }}><span>-</span><span>$ 950.50</span></td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                className="d-inline-block mr-3"
                                style={{
                                  backgroundColor: 'rgb(41, 161, 182, 0.2)',
                                  width: '2.6em', height: '2.6em', borderRadius: '50em',
                                  textAlign: 'center', lineHeight: '2.25em'
                                }}
                              >
                                <img src={require(`../../assets/images/icons/maker.png`)} width="45%" />
                              </div>
                              <div>
                                <p className="font-size-14 font-weight-semibold mb-0">Maker</p>
                              </div>
                            </div>
                          </td>
                          <td style={{ fontSize: '13px', color: '#666' }}>12:41:01</td>
                          <td>
                            <span className="dash3-table-status-badge dash3-table-status-badge-completed">Completed</span>
                          </td>
                          <td style={{ color: "#28b366", fontWeight: 500 }}><span>+</span><span>$ 619.00</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </div>
    );
  }
}

export default Dashboard3;