import React from "react";
import "./popover.css";
import { Button, Popover, PopoverBody, PopoverHeader, Row, Col, CardHeader, CardTitle, Card, CardBody } from 'reactstrap';

class Popovers extends React.Component {
  constructor(props) {
    super(props);

    this.popovertop = this.popovertop.bind(this);
    this.popoverright = this.popoverright.bind(this);
    this.popoverleft = this.popoverleft.bind(this);
    this.popoverbottom = this.popoverbottom.bind(this);
    this.popovertopend = this.popovertopend.bind(this);
    this.popoverrightstart = this.popoverrightstart.bind(this);
    this.popoverbottomend = this.popoverbottomend.bind(this);
    this.popverleftstart = this.popverleftstart.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false,
      popovertop: false,
      popoverright: false,
      popoverleft: false,
      popoverbottom: false,
      popovertopend: false,
      popoverrightstart: false,
      popoverbottomend: false,
      popverleftstart: false
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  };

  popovertop() {
    this.setState({
      popovertop: !this.state.popovertop
    });
  };

  popoverright() {
    this.setState({
      popoverright: !this.state.popoverright
    });
  };

  popoverleft() {
    this.setState({
      popoverleft: !this.state.popoverleft
    });
  };

  popoverbottom() {
    this.setState({
      popoverbottom: !this.state.popoverbottom
    });
  };

  popovertopend() {
    this.setState({
      popovertopend: !this.state.popovertopend
    });
  };

  popoverrightstart() {
    this.setState({
      popoverrightstart: !this.state.popoverrightstart
    });
  };

  popoverbottomend() {
    this.setState({
      popoverbottomend: !this.state.popoverbottomend
    });
  };

  popverleftstart() {
    this.setState({
      popverleftstart: !this.state.popverleftstart
    });
  };

  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Popover</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">Components</a>
                <span className="breadcrumb-item active">Popover</span>
              </nav>
            </div>
          </div>
          
          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle className="font-weight-semibold font-size-18">Basic</CardTitle>
            </CardHeader>
            <CardBody>
              <p>Basic popover example.</p>
              <div>
                <Button id="Popover1" onClick={this.toggle} className="custom-btn">
                  Click to toggle popover
                </Button>
                <Popover placement="bottom" isOpen={this.state.popoverOpen} target="Popover1" toggle={this.toggle}>
                  <PopoverHeader>Popover Title</PopoverHeader>
                  <PopoverBody>And here's some amazing content. It's very engaging. Right?</PopoverBody>
                </Popover>
              </div>
            </CardBody>
          </Card>

          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle className="font-weight-semibold font-size-18">Directions</CardTitle>
            </CardHeader>
            <CardBody>
              <p>Four options are available: top, right, bottom, and left aligned.</p>
              <div>
                <Button id="Popover4" onClick={this.popoverleft} className="custom-btn btn-gradient-card-orange">
                  Popover on Left
                    </Button>
                <Popover placement="left" isOpen={this.state.popoverleft} target="Popover4" toggle={this.popoverleft}>
                  <PopoverBody>Vivamus sagittis lacus vel augue laoreet rutrum faucibus.</PopoverBody>
                </Popover>

                <Button id="Popover11" onClick={this.popovertop} className="custom-btn btn-gradient-card-blue">
                  Popover on top
                    </Button>
                <Popover placement="top" isOpen={this.state.popovertop} target="Popover11" toggle={this.popovertop}>
                  <PopoverBody>Vivamus sagittis lacus vel augue laoreet rutrum faucibus.</PopoverBody>
                </Popover>

                <Button id="Popover3" onClick={this.popoverbottom} className="custom-btn btn-gradient-card-teal">
                  Popover on Buttom
                    </Button>
                <Popover placement="bottom" isOpen={this.state.popoverbottom} target="Popover3" toggle={this.popoverbottom}>
                  <PopoverBody>Vivamus sagittis lacus vel augue laoreet rutrum faucibus.</PopoverBody>
                </Popover>

                <Button id="Popover2" onClick={this.popoverright} className="custom-btn btn-gradient-card-green">
                  Popover on right
                    </Button>
                <Popover placement="right" isOpen={this.state.popoverright} target="Popover2" toggle={this.popoverright}>
                  <PopoverBody>Vivamus sagittis lacus vel augue laoreet rutrum faucibus.</PopoverBody>
                </Popover>

              </div>
            </CardBody>
          </Card>

          <Card className="borderless-shadow mb-0">
            <CardHeader>
              <CardTitle className="font-weight-semibold font-size-18">Directions</CardTitle>
            </CardHeader>
            <CardBody>
              <p>Four options are available: top-end, right-start, bottom-end, and left=start aligned.</p>
              <div>
                <Button id="popverleftstart" data-trigger="focus" onClick={this.popverleftstart} className="custom-btn btn-gradient-card-orange">
                  Left Start popover
                    </Button>
                <Popover placement="left-start" isOpen={this.state.popverleftstart} target="popverleftstart" toggle={this.popverleftstart}>
                  <PopoverHeader>Left Start popover</PopoverHeader>
                  <PopoverBody>And here's some amazing content. It's very engaging. Right?.</PopoverBody>
                </Popover>

                <Button id="popovertopend" onClick={this.popovertopend} className="custom-btn btn-gradient-card-blue">
                  Top End popover
                    </Button>
                <Popover placement="top-end" isOpen={this.state.popovertopend} target="popovertopend" toggle={this.popovertopend}>
                  <PopoverHeader> Top End popover</PopoverHeader>
                  <PopoverBody>And here's some amazing content. It's very engaging. Right?.</PopoverBody>
                </Popover>

                <Button id="Popoverbottomend" data-trigger="focus" onClick={this.popoverbottomend} className="custom-btn btn-gradient-card-teal">
                  Bottom End popover
                    </Button>
                <Popover placement="bottom-end" isOpen={this.state.popoverbottomend} target="Popoverbottomend" toggle={this.popoverbottomend}>
                  <PopoverHeader>Bottom End popover</PopoverHeader>
                  <PopoverBody>And here's some amazing content. It's very engaging. Right?.</PopoverBody>
                </Popover>

                <Button id="Popoverrightstart" data-trigger="focus" onClick={this.popoverrightstart} className="custom-btn btn-gradient-card-green">
                  Right start popover
                    </Button>
                <Popover placement="right-start" isOpen={this.state.popoverrightstart} target="Popoverrightstart" toggle={this.popoverrightstart}>
                  <PopoverHeader> Right start popover</PopoverHeader>
                  <PopoverBody>And here's some amazing content. It's very engaging. Right?.</PopoverBody>
                </Popover>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default Popovers;