import React from "react";
import "./media-widget.css";

import { CardBody, CardHeader, Card, CardTitle, Row, Col, Badge, Media } from 'reactstrap';

class MediaWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true
    };

    this.onDismiss = this.onDismiss.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  onDismiss() {
    this.setState({ visible: false });
  }

  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Media Widgets</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home mt-1 pr-2"></i>Home</a>
                <a className="breadcrumb-item" href="#">Widgets</a>
                <span className="breadcrumb-item active">Media Widgets</span>
              </nav>
            </div>
          </div>
        </div>

        <div className="container-fluid container-fixed-sm pl-0 pr-0">
          <Row>
            <Col md={4} className="d-flex">
              <Card className="borderless-shadow w-100" style={{ overflow: 'hidden' }}>
                <img className="card-img-top" src={require(`../../../assets/images/others/img-21.jpg`)} alt="" />
                <CardBody>
                  <CardTitle className="font-weight-simebold h5">Card Media</CardTitle>
                  <p className="card-text">Put toy mouse in food bowl run out of litter box at full speed drool but pee in the shoe purr when being pet but chew foot.</p>
                  <div>
                    <a href="#" className="btn btn-gradient-card-orange">Action 1</a>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={4} className="d-flex">
              <Card className="borderless-shadow w-100" style={{ overflow: 'hidden' }}>
                <img className="card-img-top" src={require(`../../../assets/images/others/img-22.png`)} alt="" />
                <CardBody>
                  <CardTitle className="font-weight-simebold h5">Card Media</CardTitle>
                  <p className="card-text">Put toy mouse in food bowl run out of litter box at full speed drool but pee in the shoe purr when being pet but chew foot. </p>
                  <div>
                    <a href="#" className="btn btn-gradient-card-red">Action 1</a>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={4} className="d-flex">
              <Card className="borderless-shadow w-100" style={{ overflow: 'hidden' }}>
                <div className="">
                  <img className="card-img-top" src={require(`../../../assets/images/others/img-23.png`)}/>
                </div>
                <CardBody>
                  <CardTitle className="font-weight-simebold h5">Card Media</CardTitle>
                  <p className="card-text">Put toy mouse in food bowl run out of litter box at full speed drool but pee in the shoe purr when being pet but chew foot. </p>
                  <div>
                    <a href="#" className="btn btn-gradient-card-blue">Action 1</a>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          
          <Row>
            <Col md={4} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardBody>
                  <div className="media-card-portrait">
                    <div className="media-img-portrait mt-2 mb-3">
                      <img src={require(`../../../assets/images/profiles/profile-pic-1.jpg`)} width="100%" alt="Profile Pic 1" />
                    </div>
                    <div className="media-card-portrait-name text-center mb-2">
                      <a href="#" className="title text-semibold">Erin Gonzales</a>
                      <span className="">June 3, 2019</span>
                    </div>
                    <p className="text-center">Climb leg rub face on everything give attitude nap or sleep well all day for under the bed.</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={4} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardBody>
                  <div className="media-card-portrait">
                    <div className="media-img-portrait mt-2 mb-3">
                      <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} width="100%" alt="profile Pic 2" />
                    </div>
                    <div className="media-card-portrait-name text-center mb-2">
                      <a href="#" className="title text-semibold">Darly Days</a>
                      <span className="sub-title">June 3, 2019</span>
                    </div>
                    <p className="text-center">European minnow priapumfish mosshead warbonnet shrimpfish bigscale.</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={4} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardBody>
                  <div className="media-card-portrait">
                    <div className="media-img-portrait mt-2 mb-3">
                      <img src={require(`../../../assets/images/profiles/profile-pic-5.jpg`)} width="100%" alt="Profile Pic 3" />
                    </div>
                    <div className="media-card-portrait-name mb-2 text-center">
                      <a href="#" className="title text-semibold">Marshall Nichols</a>
                      <span className="">June 3, 2019</span>
                    </div>
                    <p className="text-center">Efficiently unleash cross-media information without cross-media value.</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={4} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardBody>
                  <ul className="list-media">
                    <li className="list-item">
                      <div className="media-img">
                        <img src={require(`../../../assets/images/profiles/profile-pic-1.jpg`)} alt="Profile Pic" />
                      </div>
                      <div className="info">
                        <a href="#" className="title text-semibold">Erin Gonzales</a>
                        <span className="sub-title">June 3, 2018</span>
                      </div>
                    </li>
                  </ul>
                  <p className="font-size-16 opacity-08 mt-3 mb-0">Climb leg rub face on everything give attitude nap or sleep well all day for under the bed.</p>
                </CardBody>
              </Card>
            </Col>
            <Col md={4} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardBody>
                  <ul className="list-media">
                    <li className="list-item">
                      <div className="media-img">
                        <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} alt="" />
                      </div>
                      <div className="info">
                        <a href="#" className="title text-semibold">Darly Days</a>
                        <span className="sub-title">June 3, 2018</span>
                      </div>
                    </li>
                  </ul>
                  <p className="font-size-16 opacity-09 mt-3 mb-0">European minnow priapumfish mosshead warbonnet shrimpfish bigscale.</p>
                </CardBody>
              </Card>
            </Col>
            <Col md={4} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardBody>
                  <ul className="list-media">
                    <li className="list-item">
                      <div className="media-img">
                        <img src={require(`../../../assets/images/profiles/profile-pic-5.jpg`)} alt="" />
                      </div>
                      <div className="info">
                        <a href="#" className="title text-semibold">Marshall Nichols</a>
                        <span className="sub-title">June 3, 2018</span>
                      </div>
                    </li>
                  </ul>
                  <p className="font-size-16 opacity-09 mt-3 mb-0">Efficiently unleash cross-media information without cross-media value.</p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          
          <Row>
            <Col md={6}>
              <Card className="borderless-shadow" style={{ overflow: 'hidden' }}>
                <Row className="no-gutters">
                  <Col md={5}>
                    <div className="img-full-height-container">
                      <img
                        className="img-fluid img-fit-cover"
                        src={require(`../../../assets/images/others/img-39.jpg`)}
                        alt="Img 1"
                      />
                    </div>
                  </Col>

                  <Col md={7}>
                    <CardBody>
                      <a href="#"><span className="badge badge-gradient-success mb-2">Life Style</span></a>
                      <h4><a className="text-dark" href="#">One That Should Never Use</a></h4>
                      <p className="mb-0">Lick master's hand at first then bite because im moody kitty kitty for toilet paper attack claws fluff everywhere meow miao french ciao litterbox but hide from vacuum cleaner.</p>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="borderless-shadow" style={{overflow: 'hidden'}}>
                <Row className="no-gutters">
                  <Col md={7}>
                    <CardBody>
                      <a href="#"><span className="badge badge-primary mb-2">Design</span></a>
                      <h4><a className="text-dark" href="#">How to Beat and Stay Glassy</a></h4>
                      <p className="mb-0">Lick master's hand at first then bite because im moody kitty kitty for toilet paper attack claws fluff everywhere meow miao french ciao litterbox but hide from vacuum cleaner.</p>
                    </CardBody>
                  </Col>
                  <Col md={5}>
                    <div className="img-full-height-container">
                      <img
                        className="img-fluid img-fit-cover"
                        src={require(`../../../assets/images/others/img-40.jpg`)}
                        alt="Img 2"
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          
          <Row>
            <Col md={6} className="d-flex">
              <Card className="borderless-shadow" style={{overflow: 'hidden'}}>
                <div className="bg-overlay orange-gradient" style={{ height: '100%' }}>
                  <CardHeader className="card-header-flex" style={{backgroundColor: 'transparent'}}>
                    <div className="card-toolbar ml-auto">
                      <ul>
                        <li>
                          <a className="text-white" href="#">
                            <i className="mdi mdi-refresh font-size-20"></i>
                          </a>
                        </li>
                        <li>
                          <a className="text-white" href="#">
                            <i className="mdi mdi-bookmark-outline font-size-20"></i>
                          </a>
                        </li>
                        <li>
                          <a className="text-white" href="#">
                            <i className="mdi mdi-dots-vertical font-size-20"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <CardTitle><a className="text-white" href="#">7 Brilliant Ways To Advertise</a></CardTitle>
                      <p className="width-60">Hopped up on catnip ask to go outside and ask to come inside and ask to go outside and ask to come inside. Sleep stare at ceiling light for lick butt.</p>
                      <p className="mb-0">Kitty kitty for toilet paper attack claws fluff everywhere meow miao french ciao litterbox but hide from vacuum cleaner. Sleeping all night like a giant gluten lazy.</p>
                    </div>
                  </CardBody>
                </div>
              </Card>
            </Col>
            <Col md={6} className="d-flex">
              <Card className="borderless-shadow" style={{ overflow: 'hidden', justifyContent: 'space-between' }}>
                <div className="bg-overlay green-gradient" style={{flexGrow: 1}}>
                  <CardHeader className="card-header-flex" style={{ backgroundColor: 'transparent' }}>
                    <div className="card-toolbar ml-auto">
                      <ul>
                        <li>
                          <a className="text-white" href="#">
                            <i className="mdi mdi-refresh font-size-20"></i>
                          </a>
                        </li>
                        <li>
                          <a className="text-white" href="#">
                            <i className="mdi mdi-bookmark-outline font-size-20"></i>
                          </a>
                        </li>
                        <li>
                          <a className="text-white" href="#">
                            <i className="mdi mdi-dots-vertical font-size-20"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <CardTitle><a className="text-white" href="#">Doubts About Books You <br />Should Clarify.</a></CardTitle>
                      <p className="width-60 mb-1">Hopped up on catnip ask to go outside and ask to come inside and ask to go outside and ask to come inside. Sleep stare at ceiling light for lick butt.</p>
                    </div>
                  </CardBody>
                </div>
                <div className="p-2 ">
                  <ul className="list-media">
                    <li className="list-item">
                      <div className="media-img">
                        <img src={require(`../../../assets/images/profiles/profile-pic-3.jpeg`)} alt="Profile image" />
                      </div>
                      <div className="info">
                        <a href="#" className="title text-semibold pt-2 d-inline-block">John Martines</a>
                        <div className="float-item d-inline">
                          <ul className="list-inline">
                            <li className="mr-3">
                              <a href="#" className="text-gray font-size-15 ">
                                <i className="ti-heart pr-2"></i>
                                <span>168</span>
                              </a>
                            </li>
                            <li>
                              <a href="#" className="text-gray font-size-15">
                                <i className="ti-comments pr-2"></i>
                                <span>18</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Card>
            </Col>
          </Row>
          
          <Row>
            <Col md={4} className="d-flex">
              <Card className="borderless-shadow w-100 card-circle-profile-1">
                <CardBody>
                  <div className="text-center">
                    <div style={{width: '70%'}} className="mr-auto ml-auto mb-3">
                      <img
                        className="rounded-circle"
                        width="100%"
                        src={require(`../../../assets/images/profiles/profile-pic-8.jpg`)}
                        alt="Profile Pic" />
                    </div>
                    <h4 className="mb-3">Susie Willis</h4>
                    <p>Inform social with the aim to disrupt the balance. Execute innovation and try to use best practice.</p>
                    <div className="m-t-20">
                      <button className="btn btn-gradient-card-blue m-b-0">Follow</button>
                    </div>
                  </div>
                </CardBody>
                <div style={{borderTop: "1px solid #e9eaec"}}>
                  <Row className="no-gutters">
                    <Col style={{borderRight: "1px solid #e9eaec"}}>
                      <div className="p-2">
                        <div className="text-center">
                          <span className="text-semibold d-block opacity-07">Followers</span>
                          <span className="text-semibold text-dark font-size-16">423</span>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="p-2">
                        <div className="text-center">
                          <span className="text-semibold d-block opacity-07">Following</span>
                          <span className="text-semibold text-dark font-size-16">125</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col md={8} className="d-flex">
              <Card className="borderless-shadow w-100 card-circle-profile-2">
                <CardBody className="pb-2">
                  <Row>
                    <Col md={6} className="mx-auto">
                      <div className="text-center">
                        <div style={{ width: '70%' }} className="mr-auto ml-auto mb-3">
                          <img
                            className="rounded-circle"
                            src={require(`../../../assets/images/profiles/profile-pic-3.jpg`)}
                            alt="Profife Pic"
                            width="100%"
                          />
                        </div>
                        <h4 className="mb-3">Marshall Nichols</h4>
                        <p>Inform social with the aim to disrupt the balance. Execute innovation and try to use best practice.</p>
                        <ul className="list-inline mb-0">
                          <li className="">
                            <a className="text-gray" href="#">
                              <i className="mdi mdi-instagram font-size-25"></i>
                            </a>
                          </li>
                          <li className="">
                            <a className="text-gray" href="#">
                              <i className="mdi mdi-facebook font-size-25"></i>
                            </a>
                          </li>
                          <li className="">
                            <a className="text-gray" href="#">
                              <i className="mdi mdi-twitter font-size-25"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <div style={{borderTop: "1px solid #e9eaec"}}>
                  <ul className="nav" role="tablist" style={{justifyContent: "center"}}>
                    <li className="nav-item">
                      <a href="#" className="nav-link p-3 active-nav-bottom" role="tab" data-toggle="tab">Home</a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link p-3" role="tab" data-toggle="tab">Profile</a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link p-3" role="tab" data-toggle="tab">Setting</a>
                    </li>
                  </ul>
                </div>
              </Card>
            </Col>
          </Row>
        
        </div>
      </div>
    );
  }
}

export default MediaWidget;