import React from "react";
import "./data-widget.css";

import {
  CardBody, CardHeader, Media, Table, Card, CardTitle, Row, Col, Alert, UncontrolledAlert, Progress
} from 'reactstrap';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

class DataWidget extends React.Component {
  constructor(props) {
    super(props);
    this.onDismiss = this.onDismiss.bind(this);

    this.state = {
      visible: true
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  onDismiss() { this.setState({ visible: false }); }

  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Data Widgets</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1"></i>Home</a>
                <a className="breadcrumb-item" href="#">Widgets</a>
                <span className="breadcrumb-item active">Data Widgets</span>
              </nav>
            </div>
          </div>
        </div>

        <div className="container-fluid container-fixed-sm pl-0 pr-0">
          <Row>
            <Col xl={3} md={6} className="d-flex">
              <Card className="borderless-shadow top-card-main-container top-card-main-container-blue" style={{width: '100%'}}>
                <CardBody>
                  <div className="top-card-inc-dec">
                    <i className="far fa-arrow-alt-circle-up mr-1"></i>
                    <span>12%</span>
                  </div>
                  <h6 className="font-size-16 font-weight-semibold mb-3 text-white">Sales Online</h6>
                  <div className="top-card-detail-container">
                    <div>
                      <p className="font-size-17 font-weight-bold mb-0 text-white">3,156</p>
                      <p className="font-size-12 mb-0 text-white" style={{ opacity: "0.7" }}>
                        As per previous month
                      </p>
                    </div>
                    <div className="dashboard1-topcard-icon-container-blue">
                      <i className="fas fa-store font-size-25"></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6} className="d-flex">
              <Card className="borderless-shadow top-card-main-container top-card-main-container-orange" style={{ width: '100%' }}>
                <CardBody>
                  <div className="top-card-inc-dec">
                    <i className="far fa-arrow-alt-circle-down mr-1"></i>
                    <span>21%</span>
                  </div>
                  <h6 className="font-size-16 font-weight-semibold mb-3 text-white">Sales Offline</h6>
                  <div className="top-card-detail-container">
                    <div>
                      <p className="font-size-17 font-weight-bold mb-0 text-white">1,740</p>
                      <p className="font-size-12 mb-0 text-white" style={{ opacity: "0.7" }}>
                        As per previous month
                      </p>
                    </div>
                    <div className="dashboard1-topcard-icon-container-orange">
                      <i className="fas fa-shopping-bag font-size-25"></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6} className="d-flex">
              <Card className="borderless-shadow top-card-main-container top-card-main-container-green" style={{ width: '100%' }}>
                <CardBody>
                  <div className="top-card-inc-dec">
                    <i className="far fa-arrow-alt-circle-up mr-1"></i>
                    <span>16%</span>
                  </div>
                  <h6 className="font-size-16 font-weight-semibold mb-3 text-white">Earnings</h6>
                  <div className="top-card-detail-container">
                    <div>
                      <p className="font-size-17 font-weight-bold mb-0 text-white">$3,199</p>
                      <p className="font-size-12 mb-0 text-white" style={{ opacity: "0.7" }} >
                        As per previous month
                      </p>
                    </div>
                    <div className="dashboard1-topcard-icon-container-green">
                      <i className="fas fa-dollar-sign font-size-25"></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6} className="d-flex">
              <Card className="borderless-shadow top-card-main-container top-card-main-container-red" style={{width: '100%'}}>
                <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className="top-card-inc-dec">
                    <i className="far fa-check-circle mr-1"></i>
                    <span>No Dues</span>
                  </div>
                  <h6 className="font-size-16 font-weight-semibold mb-3 text-white">Balance</h6>
                  <div className="top-card-detail-container" style={{ flex: 1 }}>
                    <div>
                      <p className="font-size-17 font-weight-bold mb-0 text-white">$78,190</p>
                      <p className="font-size-12 mb-0 text-white" style={{ opacity: "0.7" }}>
                        As of Current Date
                      </p>
                    </div>
                    <div className="dashboard1-topcard-icon-container-red">
                      <i className="fas fa-wallet font-size-25"></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={3} md={6} className="d-flex">
              <Card className="borderless-shadow dash4-top-card dash4-top-card-blue" style={{width: '100%'}}>
                <CardBody>
                  <div className="dash4-top-card-inc-dec">
                    <i className="far fa-arrow-alt-circle-up mr-1"></i>
                    <span>12%</span>
                  </div>
                  <h6 className="font-size-16 font-weight-semibold mb-3 text-white">Sales Online</h6>
                  <div className="dash4-top-card-detail-container">
                    <div className="dash4-topcard-icon-container-blue mr-3">
                      <i className="fas fa-store font-size-25"></i>
                    </div>
                    <div>
                      <p className="font-size-17 font-weight-bold mb-0 text-white">3,156</p>
                      <p className="font-size-12 mb-0 text-white" style={{ opacity: "0.7" }}>
                        As per previous month
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3} md={6} className="d-flex">
              <Card className="borderless-shadow dash4-top-card dash4-top-card-orange" style={{ width: '100%' }}>
                <CardBody>
                  <div className="dash4-top-card-inc-dec">
                    <i className="far fa-arrow-alt-circle-down mr-1"></i>
                    <span>21%</span>
                  </div>
                  <h6 className="font-size-16 font-weight-semibold mb-3 text-white">Sales Offline</h6>
                  <div className="dash4-top-card-detail-container">
                    <div className="dash4-topcard-icon-container-orange mr-3">
                      <i className="fas fa-shopping-bag font-size-25"></i>
                    </div>
                    <div>
                      <p className="font-size-17 font-weight-bold mb-0 text-white">1,740</p>
                      <p className="font-size-12 mb-0 text-white" style={{ opacity: "0.7" }}>
                        As per previous month
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3} md={6} className="d-flex">
              <Card className="borderless-shadow dash4-top-card dash4-top-card-green" style={{ width: '100%' }}>
                <CardBody>
                  <div className="dash4-top-card-inc-dec">
                    <i className="far fa-arrow-alt-circle-up mr-1"></i>
                    <span>16%</span>
                  </div>
                  <h6 className="font-size-16 font-weight-semibold mb-3 text-white">Earnings</h6>
                  <div className="dash4-top-card-detail-container">
                    <div className="dash4-topcard-icon-container-green mr-3">
                      <i className="fas fa-dollar-sign font-size-25"></i>
                    </div>
                    <div>
                      <p className="font-size-17 font-weight-bold mb-0 text-white">$3,199</p>
                      <p className="font-size-12 mb-0 text-white" style={{ opacity: "0.7" }} >
                        As per previous month
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3} md={6} className="d-flex">
              <Card className="borderless-shadow dash4-top-card dash4-top-card-red" style={{ width: '100%' }}>
                <CardBody style={{display: 'flex', flexDirection: 'column'}}>
                  <div className="dash4-top-card-inc-dec">
                    <i className="far fa-check-circle mr-1"></i>
                    <span>No Dues</span>
                  </div>
                  <h6 className="font-size-16 font-weight-semibold mb-3 text-white">Balance</h6>
                  <div className="dash4-top-card-detail-container" style={{flex: 1}}>
                    <div className="dash4-topcard-icon-container-red mr-3">
                      <i className="fas fa-wallet font-size-25"></i>
                    </div>
                    <div>
                      <p className="font-size-17 font-weight-bold mb-0 text-white">$78,190</p>
                      <p className="font-size-12 mb-0 text-white" style={{ opacity: "0.7" }}>
                        AS of Current Date
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={3} sm={6} className="d-flex">
              <Card className="borderless-shadow dash2-top-card-main-container" style={{width: '100%'}}>
                <CardBody>
                  <div className="dash2-top-card-inc-dec ml-2">
                    <i className="far fa-arrow-alt-circle-up mr-1"></i>
                    <span>12%</span>
                  </div>

                  <div className="dash2-top-card-detail-container">
                    <div className="dash2-topcard-icon-container-blue">
                      <i className="fas fa-store font-size-25"></i>
                    </div>
                    <div className="mr-2">
                      <p className="font-size-23 font-weight-semibold mb-0">3,156</p>
                      <p className="font-size-15 font-weight-semibold text-muted mb-0">Sales Online</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} sm={6} className="d-flex">
              <Card className="borderless-shadow dash2-top-card-main-container" style={{ width: '100%' }}>
                <CardBody>
                  <div className="dash2-top-card-inc-dec ml-2">
                    <i className="far fa-arrow-alt-circle-down mr-1"></i>
                    <span>21%</span>
                  </div>

                  <div className="dash2-top-card-detail-container">
                    <div className="dash2-topcard-icon-container-orange">
                      <i className="fas fa-shopping-bag font-size-25"></i>
                    </div>
                    <div className="mr-2">
                      <p className="font-size-23 font-weight-semibold mb-0">1,740</p>
                      <p className="font-size-15 font-weight-semibold text-muted mb-0">Sales Offline</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} sm={6} className="d-flex">
              <Card className="borderless-shadow top-card-main-container" style={{ width: '100%' }}>
                <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className="dash2-top-card-inc-dec ml-2">
                    <i className="far fa-arrow-alt-circle-up mr-1"></i>
                    <span>16%</span>
                  </div>

                  <div className="dash2-top-card-detail-container" style={{ flex: 1 }}>
                    <div className="dash2-topcard-icon-container-green">
                      <i className="fas fa-dollar-sign font-size-25"></i>
                    </div>
                    <div className="mr-2">
                      <p className="font-size-23 font-weight-semibold mb-0">$3,199</p>
                      <p className="font-size-15 font-weight-semibold text-muted mb-0">Earnings</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} sm={6} className="d-flex">
              <Card className="borderless-shadow top-card-main-container" style={{ width: '100%' }}>
                <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className="dash2-top-card-inc-dec ml-2">
                    <i className="far fa-check-circle mr-1"></i>
                    <span>No Dues</span>
                  </div>

                  <div className="dash2-top-card-detail-container" style={{ flex: 1 }}>
                    <div className="dash2-topcard-icon-container-red">
                      <i className="fas fa-wallet font-size-25"></i>
                    </div>
                    <div className="mr-2">
                      <p className="font-size-23 font-weight-semibold mb-0">$78,190</p>
                      <p className="font-size-15 font-weight-semibold text-muted mb-0">Balance</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          
          <Row>
            <Col xl={3} sm={6}>
              <Card className="borderless-shadow">
                <CardBody>
                  <h3 className="h3 font-weight-light mb-3 text-muted">Income</h3>
                  <Media>
                    <i className="fa fa-wallet font-size-40 icon-gradient-primary"></i>
                    <div className="ml-3">
                      <h2 className="font-weight-light mb-0">$53,495</h2>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} sm={6}>
              <Card className="borderless-shadow">
                <CardBody>
                  <h3 className="h3 font-weight-light mb-3 text-muted">Tax</h3>
                  <Media>
                    <i className="fa fa-receipt font-size-40 icon-gradient-warning"></i>
                    <div className="ml-3">
                      <h2 className="font-weight-light mb-0">$2,738</h2>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} sm={6}>
              <Card className="borderless-shadow">
                <CardBody>
                  <h3 className="h3 font-weight-light mb-3 text-muted">Growth</h3>
                  <Media>
                    <i className="fa fa-signal font-size-40 icon-gradient-success"></i>
                    <div className="ml-3">
                      <h2 className="font-weight-light mb-0">
                        <span>18%</span>
                        <i className="ti-arrow-up font-size-14"></i>
                      </h2>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} sm={6}>
              <Card className="borderless-shadow">
                <CardBody>
                  <h3 className="h3 font-weight-light mb-3 text-muted">Transaction</h3>
                  <Media>
                    <i className="fa fa-sync font-size-35 icon-gradient-danger text-danger"></i>
                    <div className="ml-3">
                      <h2 className="font-weight-light mb-0">
                        <span>$21,338</span>
                      </h2>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={3} sm={6}>
              <Card className="borderless-shadow">
                <CardBody>
                  <p className="color-grey">Total Revenue</p>
                  <Media className="justify-content-between">
                    <div className="align-self-end">
                      <i className="ti-credit-card font-size-60 text-success opacity-01"></i>
                    </div>
                    <div>
                      <h2 className="font-size-28 font-weight-light">$54,533</h2>
                      <span className="text-semibold text-success font-size-15">
                        <i className="ti-arrow-up font-size-11"></i>
                        <span>22%</span>
                      </span>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} sm={6}>
              <Card className="borderless-shadow">
                <CardBody>
                  <p className="color-grey">Daily Products Sale</p>
                  <Media className="justify-content-between">
                    <div>
                      <i className="ti-pie-chart font-size-60 text-info opacity-01"></i>
                    </div>
                    <div>
                      <h2 className="font-size-28 font-weight-light">1,458</h2>
                      <span className="text-semibold text-success font-size-15">
                        <i className="ti-arrow-up font-size-11"></i>
                        <span>11%</span>
                      </span>
                    </div>

                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} sm={6}>
              <Card className="borderless-shadow">
                <CardBody>
                  <p className="color-grey">Growth Rate</p>
                  <Media className="justify-content-between">
                    <div className="align-self-end">
                      <i className="ti-bar-chart font-size-60 text-danger opacity-01"></i>
                    </div>
                    <div className="mr-3">
                      <h2 className="font-size-28 font-weight-light">8%</h2>
                      <span className="text-semibold text-success font-size-15">
                        <i className="ti-arrow-up font-size-11"></i>
                        <span>8%</span>
                      </span>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} sm={6}>
              <Card className="borderless-shadow">
                <CardBody>
                  <p className="color-grey">New Customers</p>
                  <Media className="justify-content-between">
                    <div className="align-self-end">
                      <i className="ti-user font-size-60 text-primary opacity-01"></i>
                    </div>
                    <div>
                      <h2 className="font-size-28 font-weight-light">1,416</h2>
                      <span className="text-semibold text-success font-size-15">
                        <i className="ti-arrow-up font-size-11"></i>
                        <span>8%</span>
                      </span>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </Row>
        
          <Row>
            <Col md={4} className="d-flex">
              <Card className="borderless-shadow" style={{ width: '100%' }}>
                <CardBody>
                  <div className="goals-wrapper">
                    <div className="goal-icon-image">
                      <img src={require(`../../../assets/images/icons/shop.png`)} width="100%" />
                    </div>

                    <div className="goal-content-box ml-3">
                      <h5 className="mb-3">Online Sales Goal</h5>
                      <Progress animated color="success" value="55" >55%</Progress>
                      <div className="goal-sales mt-1">
                        <span className="font-weight-bold">1100</span>
                        <span className="font-size-12">sales out of</span>
                        <span className="font-weight-bold">1,640</span>
                      </div>
                    </div>

                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={4} className="d-flex">
              <Card className="borderless-shadow" style={{ width: '100%' }}>
                <CardBody>
                  <div className="goals-wrapper">
                    <div className="goal-icon-image">
                      {/* <i className="fas fa-tram font-size-60"></i> */}
                      <img src={require(`../../../assets/images/icons/company.png`)} width="100%" />
                    </div>

                    <div className="goal-content-box ml-3">
                      <h5 className="mb-3">Productions Goal</h5>
                      <Progress animated color="success" value="88" >88%</Progress>
                      <div className="goal-sales mt-1">
                        <span className="font-weight-bold">2640</span>
                        <span className="font-size-12">sales out of</span>
                        <span className="font-weight-bold">3,000</span>
                      </div>
                    </div>

                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={4} className="d-flex">
              <Card className="borderless-shadow" style={{ width: '100%' }}>
                <CardBody>
                  <div className="goals-wrapper">
                    <div className="goal-icon-image">
                      {/* <i className="fas fa-balance-scale font-size-60"></i> */}
                      <img src={require(`../../../assets/images/icons/analysis.png`)} width="100%" />
                    </div>

                    <div className="goal-content-box ml-3">
                      <h5 className="mb-3">Marketing Goal</h5>
                      <Progress animated color="success" value="70" >70%</Progress>
                      <div className="goal-sales mt-1">
                        <span className="font-weight-bold">15,400</span>
                        <span className="font-size-12">hits out of</span>
                        <span className="font-weight-bold">22,000</span>
                      </div>
                    </div>

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* CIRCULAR PROGRESS BARS - ACHIEVING GOALS */}
          <Row>
            <Col md={4} className="d-flex">
              <Card className="borderless-shadow" style={{width: '100%'}}>
                <CardBody>
                  <div className="goals-wrapper-circular">

                    <h5 className="mb-4 text-center font-size-25">Online Sales Goal</h5>

                    <div className="goal-content-box">
                      <div className="circular-progress-container">
                        <CircularProgressbar
                          value={0.55}
                          maxValue={1}
                          text="55%"
                          strokeWidth="8"
                          background={true}
                          backgroundPadding="5"
                          styles={buildStyles({
                            // Colors
                            pathColor: `#383DC2`,
                            textColor: '#383DC2',
                            trailColor: '#d6d6d6',
                            backgroundColor: '#f1f1f1',
                          })}
                        />
                      </div>
                      <div className="goal-sales-ciucular text-center font-size-25 mt-4">
                        <span className="font-weight-bold font-size-20">1100</span>
                        <span className=""> / </span>
                        <span className="font-weight-bold">1,640</span>
                      </div>
                    </div>

                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={4} className="d-flex">
              <Card className="borderless-shadow" style={{ width: '100%' }}>
                <CardBody>
                  <div className="goals-wrapper-circular">

                    <h5 className="mb-4 text-center font-size-25">Productions Goal</h5>

                    <div className="goal-content-box">
                      <div className="circular-progress-container">
                        <CircularProgressbar
                          value={0.88}
                          maxValue={1}
                          text="88%"
                          strokeWidth="8"
                          background={true}
                          backgroundPadding="5"
                          styles={buildStyles({
                            // Colors
                            pathColor: `#FF8736`,
                            textColor: '#FF8736',
                            trailColor: '#d6d6d6',
                            backgroundColor: '#f1f1f1',
                          })}
                        />
                      </div>
                      <div className="goal-sales-ciucular text-center font-size-25 mt-4">
                        <span className="font-weight-bold font-size-20">2640</span>
                        <span className=""> / </span>
                        <span className="font-weight-bold">3000</span>
                      </div>
                    </div>

                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={4} className="d-flex">
              <Card className="borderless-shadow" style={{ width: '100%' }}>
                <CardBody>
                  <div className="goals-wrapper-circular">

                    <h5 className="mb-4 text-center font-size-25">Marketing Goal</h5>

                    <div className="goal-content-box">
                      <div className="circular-progress-container">
                        <CircularProgressbar
                          value={0.70}
                          maxValue={1}
                          text="70%"
                          strokeWidth="8"
                          background={true}
                          backgroundPadding="5"
                          styles={buildStyles({
                            // Colors
                            pathColor: `#28a745`,
                            textColor: '#28a745',
                            trailColor: '#d6d6d6',
                            backgroundColor: '#f1f1f1',
                          })}
                        />
                      </div>
                      <div className="goal-sales-ciucular text-center font-size-25 mt-4">
                        <span className="font-weight-bold font-size-20">15,400</span>
                        <span className=""> / </span>
                        <span className="font-weight-bold">22,000</span>
                      </div>
                    </div>

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          
          {/* Weather */}
          <Row>
            <Col>
              <div className="weather-container">
                <div className="weather-day-main">
                  <h6 className="mb-3">
                    <span className="mb-2 font-size-20 font-weight-semibold">Sunday</span>
                    <br />
                    <span className="font-size-12 text-muted">21 June</span>
                  </h6>
                  <div>
                    <i className="fas fa-sun font-size-30"></i>
                    <p className="mb-0 mr-2 font-size-25">27&#8451;</p>
                  </div>
                </div>

                <div style={{
                  display: 'flex', flexWrap: 'wrap', flexGrow: 1, paddingTop: '6px', paddingRight: '3px', paddingLeft: '6px', paddingBottom: '3px'
                }}>
                  <div className="weather-day">
                    <h6>
                      <span className="mb-2 font-size-15 font-weight-semibold">Monday</span>
                      <br />
                      <span className="font-size-12 text-muted">22 June</span>
                    </h6>
                    <div className="weather-content">
                      <p className="mb-0 font-size-17 mr-2">27&#8451;</p>
                      <i className="fas fa-cloud-sun font-size-17"></i>
                    </div>
                  </div>
                  <div className="weather-day">
                    <h6>
                      <span className="mb-2 font-size-15 font-weight-semibold">Tuesday</span>
                      <br />
                      <span className="font-size-12 text-muted">23 June</span>
                    </h6>
                    <div className="weather-content">
                      <p className="mb-0 font-size-17 mr-2">27&#8451;</p>
                      <i className="fas fa-cloud font-size-17"></i>
                    </div>
                  </div>
                  <div className="weather-day">
                    <h6>
                      <span className="mb-2 font-size-15 font-weight-semibold">Wednesday</span>
                      <br />
                      <span className="font-size-12 text-muted">24 June</span>
                    </h6>
                    <div className="weather-content">
                      <p className="mb-0 font-size-17 mr-2">27&#8451;</p>
                      <i className="fas fa-cloud-showers-heavy font-size-17"></i>
                    </div>
                  </div>
                  <div className="weather-day">
                    <h6>
                      <span className="mb-2 font-size-15 font-weight-semibold">Thursday</span>
                      <br />
                      <span className="font-size-12 text-muted">25 June</span>
                    </h6>
                    <div className="weather-content">
                      <p className="mb-0 font-size-17 mr-2">27&#8451;</p>
                      <i className="fas fa-cloud-showers-heavy font-size-17"></i>
                    </div>
                  </div>
                  <div className="weather-day">
                    <h6>
                      <span className="mb-2 font-size-15 font-weight-semibold">Friday</span>
                      <br />
                      <span className="font-size-12 text-muted">26 June</span>
                    </h6>
                    <div className="weather-content">
                      <p className="mb-0 font-size-17 mr-2">27&#8451;</p>
                      <i className="fas fa-cloud-showers-heavy font-size-17"></i>
                    </div>
                  </div>
                  <div className="weather-day">
                    <h6>
                      <span className="mb-2 font-size-15 font-weight-semibold">Saturday</span>
                      <br />
                      <span className="font-size-12 text-muted">27 June</span>
                    </h6>
                    <div className="weather-content">
                      <p className="mb-0 font-size-17 mr-2">27&#8451;</p>
                      <i className="fas fa-cloud-rain font-size-17"></i>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          
          <Row>
            <Col xl={7} className="d-flex">
              <div className="credit-card borderless-shadow" style={{ width: '100%' }}>
                <h4 className="font-weight-normal mb-4">Credit Card Detail</h4>
                <Row>
                  <Col md={7}>
                    <Card className="bg-credit-card text-light">
                      <CardBody>
                        <h6 className="font-weight-light text-right mb-0">CREDIT</h6>
                        <img src={require(`../../../assets/images/icons/card-chip.png`)} width="15%" />
                        
                        <h3 className="font-weight-light mt-2">
                          <span className="mr-2 font-size-23">5224</span>
                          <span className="mr-2 font-size-23">7576</span>
                          <span className="mr-2 font-size-23">2197</span>
                          <span className="mr-2 font-size-23">3458</span>
                        </h3>
                        
                        <div className="credit-card-footer">
                          <span>8 / 23</span>
                          <img src={require(`../../../assets/images/icons/mastercard.png`)} width="15%" />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={5}>
                    <Row className="mb-2">
                      <Col sm={7}>
                        <span className="font-weight-semibold font-size-15 text-dark">Name on Card</span>
                      </Col>
                      <Col sm={5} className="text-right">
                        <span>John Doe</span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm={7}>
                        <span className="font-weight-semibold font-size-15 text-dark">Credit Avaiable</span>
                      </Col>
                      <Col sm={5} className="text-right">
                        <span>$10,350</span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm={7}>
                        <span className="font-weight-semibold font-size-15 text-dark">Outstanding</span>
                      </Col>
                      <Col sm={5} className="text-right">
                        <span>$654</span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm={7}>
                        <span className="font-weight-semibold font-size-15 text-dark">Due Date</span>
                      </Col>
                      <Col sm={5} className="text-right">
                        <span>30/6/2020</span>
                      </Col>
                    </Row>
                    <button className="btn-credit-payment mt-3">
                      Make Payment
                    </button>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col xl={5} className="d-flex">
              <Card className="borderless-shadow" style={{ width: '100%' }}>
                <CardHeader>
                  <CardTitle><h4 className="font-weight-normal mb-0">Recent Activities</h4></CardTitle>
                </CardHeader>
                <div className="table-overflow-x">
                  <Table className="table-xl mb-0">
                    <tbody>
                      <tr>
                        <td className="text-center">
                          <span>May</span>
                          <h5 className="mb-0">21</h5>
                        </td>
                        <td>
                          <h5 className="font-size-15 font-weight-semibold mb-0">Online Payment</h5>
                          <span className="font-size-13">Credit</span>
                        </td>
                        <td className="text-right">
                          <h5 className="mb-0 text-success">+ 220.40</h5>
                          <span className="font-size-13">Currency Code</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <span>May</span>
                          <h5 className="mb-0">20</h5>
                        </td>
                        <td>
                          <h5 className="font-size-15 font-weight-semibold mb-0">PayPal USA LLC</h5>
                          <span className="font-size-13">Credit</span>
                        </td>
                        <td className="text-right">
                          <h5 className="mb-0 text-success">+ 1788.20</h5>
                          <span className="font-size-13">Currency Code</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <span>May</span>
                          <h5 className="mb-0">16</h5>
                        </td>
                        <td>
                          <h5 className="font-size-15 font-weight-semibold mb-0">Bank of America</h5>
                          <span className="font-size-13">Withdraw</span>
                        </td>
                        <td className="text-right">
                          <h5 className="mb-0">- 332.80</h5>
                          <span className="font-size-13">Currency Code</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <span>May</span>
                          <h5 className="mb-0">5</h5>
                        </td>
                        <td>
                          <h5 className="font-size-15 font-weight-semibold mb-0">Bank of America</h5>
                          <span className="font-size-13">Withdraw</span>
                        </td>
                        <td className="text-right">
                          <h5 className="mb-0">- 1255.50</h5>
                          <span className="font-size-13">Currency Code</span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Card>
            </Col>
          </Row>
          
          <Row>
            <Col md={12}>
              <Card className="borderless-shadow mb-0">
                <CardBody>
                  <div className="table-overflow-x">
                    <Table id="dt-opt" className="table-hover table-xl">
                      <thead className="pt-3 pb-3">
                        <tr>
                          <th>
                            <div className="checkbox p-0">
                              <input id="selectable1" type="checkbox" className="checkAll" name="checkAll" />
                              <label htmlFor="selectable1 mb-0"></label>
                            </div>
                          </th>
                          <th className="font-weight-semibold font-size-18">Name</th>
                          <th className="font-weight-semibold font-size-18">Status</th>
                          <th className="font-weight-semibold font-size-18 nobreak">Bill Code</th>
                          <th className="font-weight-semibold font-size-18">Date</th>
                          <th className="font-weight-semibold font-size-18">Amount</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="checkbox">
                              <input id="selectable2" type="checkbox" />
                              <label htmlFor="selectable2"></label>
                            </div>
                          </td>
                          <td>
                            <div className="list-media">
                              <div className="list-item">
                                <div className="media-img">
                                  <img src={require(`../../../assets/images/profiles/profile-pic-1.jpg`)} />
                                </div>
                                <div className="info">
                                  <span className="title">Marshall Nichols</span>
                                  <span className="sub-title">ID 870</span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td><span className="badge badge-pill badge-gradient-success">Actived</span></td>
                          <td>#33667</td>
                          <td>08 May 2018</td>
                          <td> $168.00</td>
                          <td className="text-center font-size-18">
                            <a href="#" className="text-gray mr-2"><i className="ti-pencil"></i></a>
                            <a href="#" className="text-gray"><i className="ti-trash"></i></a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox">
                              <input id="selectable3" type="checkbox" />
                              <label htmlFor="selectable3"></label>
                            </div>
                          </td>
                          <td>
                            <div className="list-media">
                              <div className="list-item">
                                <div className="media-img">
                                  <img src={require(`../../../assets/images/profiles/profile-pic-7.jpg`)} />
                                </div>
                                <div className="info">
                                  <span className="title">Susie Willis</span>
                                  <span className="sub-title">ID 861</span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td><span className="badge badge-pill badge-gradient-success">Actived</span></td>
                          <td>#33683</td>
                          <td>05 May 2018</td>
                          <td>$433.00</td>
                          <td className="text-center font-size-18">
                            <a href="#" className="text-gray mr-2"><i className="ti-pencil"></i></a>
                            <a href="#" className="text-gray"><i className="ti-trash"></i></a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox">
                              <input id="selectable4" type="checkbox" />
                              <label htmlFor="selectable4"></label>
                            </div>
                          </td>
                          <td>
                            <div className="list-media">
                              <div className="list-item">
                                <div className="media-img">
                                  <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                                </div>
                                <div className="info">
                                  <span className="title">Debra Stewart</span>
                                  <span className="sub-title">ID 863</span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td><span className="badge badge-pill badge-warning">Pending</span></td>
                          <td>#33668</td>
                          <td>09 May 2018</td>
                          <td>$2488.00</td>
                          <td className="text-center font-size-18">
                            <a href="#" className="text-gray mr-2"><i className="ti-pencil"></i></a>
                            <a href="#" className="text-gray"><i className="ti-trash"></i></a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox">
                              <input id="selectable7" type="checkbox" />
                              <label htmlFor="selectable7"></label>
                            </div>
                          </td>
                          <td>
                            <div className="list-media">
                              <div className="list-item">
                                <div className="media-img">
                                  <img src={require(`../../../assets/images/profiles/profile-pic-2.png`)} />
                                </div>
                                <div className="info">
                                  <span className="title">Josh Alexander</span>
                                  <span className="sub-title">ID 878</span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td><span className="badge badge-pill badge-gradient-danger">Rejected</span></td>
                          <td>#33686</td>
                          <td>08 May 2018</td>
                          <td>$567.00</td>
                          <td className="text-center font-size-18">
                            <a href="#" className="text-gray mr-2"><i className="ti-pencil"></i></a>
                            <a href="#" className="text-gray"><i className="ti-trash"></i></a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox">
                              <input id="selectable9" type="checkbox" />
                              <label htmlFor="selectable9"></label>
                            </div>
                          </td>
                          <td>
                            <div className="list-media">
                              <div className="list-item">
                                <div className="media-img">
                                  <img src={require(`../../../assets/images/profiles/profile-pic-5.jpg`)} />
                                </div>
                                <div className="info">
                                  <span className="title">Erin Gonzales</span>
                                  <span className="sub-title">ID 873</span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td><span className="badge badge-pill badge-gradient-success">Actived</span></td>
                          <td>#33684</td>
                          <td>16 May 2018</td>
                          <td>$762.00</td>
                          <td className="text-center font-size-18">
                            <a href="#" className="text-gray mr-2"><i className="ti-pencil"></i></a>
                            <a href="#" className="text-gray"><i className="ti-trash"></i></a>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          
        </div>
      
      </div>
    );
  }
}

export default DataWidget;