import React from "react";
import "./cart.css";
import { Card, CardBody, CardTitle, CardHeader } from "reactstrap";
import { Link } from "react-router-dom";

class Cart extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  GetCartItems() {
    return JSON.parse(localStorage.getItem("CartProduct"));
  }
  RemoveItem = (Index) => {
    var CartValue = JSON.parse(localStorage.getItem("CartProduct"));
    CartValue = CartValue.slice(0, Index).concat(CartValue.slice(Index + 1, CartValue.length));
    localStorage.removeItem("CartProduct");
    localStorage.setItem("CartProduct", JSON.stringify(CartValue));
  }

  AddQty = (Index) => {
    var CartValue = JSON.parse(localStorage.getItem("CartProduct"));
    CartValue[Index].Qty = parseInt(CartValue[Index].Qty + 1);
    localStorage.removeItem("CartProduct");
    localStorage.setItem("CartProduct", JSON.stringify(CartValue));
  }

  RemoveQty = (Index) => {
    var CartValue = JSON.parse(localStorage.getItem("CartProduct"));

    if (CartValue[Index].Qty != 1) {

      CartValue[Index].Qty = parseInt(CartValue[Index].Qty - 1);
      localStorage.removeItem("CartProduct");
      localStorage.setItem("CartProduct", JSON.stringify(CartValue));
    } else {
      this.RemoveItem(Index);
    }
  }
  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Cart</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">E-Commerce</a>
                <span className="breadcrumb-item active">Cart</span>
              </nav>
            </div>
          </div>

          <Card className="borderless-shadow mb-0">
            <CardHeader>
              <CardTitle><h5 className="mb-0">Your Cart</h5></CardTitle>
            </CardHeader>
            <CardBody>
              {(this.GetCartItems() != null && this.GetCartItems().length > 0) ?
                <div className="table-overflow-x">

                  <table className="cart-table mb-3">
                    <thead>
                      <tr>
                        <th className="table-product-head nobreak">Product</th>
                        <th className="table-product-price nobreak">Price</th>
                        <th className="table-product-quantity nobreak">Quantity</th>
                        <th className="table-product-total nobreak">Price</th>
                        <th className="table-product-remove nobreak">Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.GetCartItems().map((CartItem, index) => (
                        <tr>
                          <td>
                            <div className="table-product-container">
                              <div className="table-product-image mr-2">
                                <img src={require(`../../assets/images/${CartItem.ProductImage}`)} width="100%" />
                              </div>
                              <div className="table-product-detail">
                                <p className="mb-1">{CartItem.ProductName}</p>
                                <span>Size: </span> <span>8</span>
                              </div>
                            </div>
                          </td>
                          <td className="font-size-15 font-weight-semibold">
                            ${CartItem.Rate.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <Link className="btn-product btn-product-up" onClick={() => this.RemoveQty(index)}> <i className="ti-minus" />
                              </Link>
                              <input className="form-product" type="text" name="form-product" value={CartItem.Qty} />
                              <Link className="btn-product btn-product-down" onClick={() => this.AddQty(index)}> <i className="ti-plus" />
                              </Link>
                            </div>
                          </td>
                          <td className="font-size-15 font-weight-semibold">
                            ${(CartItem.Rate * CartItem.Qty).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
                          </td>
                          <td><Link onClick={() => this.RemoveItem(index)} ><i className="fas fa-times" style={{ cursor: "pointer" }}></i></Link></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                :
                <div className="row">
                  <div className="col-md-12 text-center pb-11">
                    <h3 className="mb-4">Your cart is Currently Empty.</h3>
                    <Link className="btn btn-primary mr-3" to="/">Homes</Link>
                    <Link className="btn btn-primary" to="/products">Continue Shoppings</Link>
                  </div>
                </div>
              }
              <div className="coupon-n-total mt-4">
                <div className="coupon-container">
                  <input type="text" placeholder="Coupon Code" className="mb-2" />
                  <button className="cart-buttons apply-coupon-btn mb-2">Apply Coupon</button>
                </div>

                <div className="total">
                  <span className="font-size-17 font-weight-semibold mr-2">Total</span>
                  {(this.GetCartItems() != null && this.GetCartItems().length > 0) ?
                  <span className="font-size-20 font-weight-bold">${this.GetCartItems().reduce((fr, CartItem) => fr + (CartItem.Qty * CartItem.Rate), 0).toLocaleString(navigator.language, { minimumFractionDigits: 0 })} </span>
                  :
                  <span className="font-size-20 font-weight-bold">$0</span>
                  }
                  </div>
              </div>

              <div className="divide-bar mt-2 mb-3" style={{ backgroundColor: "#ececec" }} />

              <div className="checkout-container">
                <Link className="proceed-to-checkout" href="#">Proceed To Checkout</Link>
              </div>
            </CardBody>
          </Card>

        </div>
      </div>
    );
  }
}

export default Cart;