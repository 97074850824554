import React from "react";
import "./basic-tables.css";

import { CardBody, CardHeader, Card, CardTitle, Row, Col, Alert, UncontrolledAlert, Table, Badge } from 'reactstrap';

class BasicTables extends React.Component {
  constructor(props) {
    super(props);

  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Basic Table</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">Tables</a>
                <span className="breadcrumb-item active">Basic Table</span>
              </nav>
            </div>
          </div>
          
          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle><h5 className="font-weight-semibold mb-0">Basic Table</h5></CardTitle>
            </CardHeader>
            <CardBody className="pb-0">
              <div className="table-overflow-x">
                <Table>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">First</th>
                      <th scope="col">Last</th>
                      <th scope="col">Handle</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>@mdo</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Jacob</td>
                      <td>Thornton</td>
                      <td>@fat</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>Larry</td>
                      <td>the Bird</td>
                      <td>@twitter</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          
          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle><h5 className="font-weight-semibold mb-0">Table head highlighted</h5></CardTitle>
            </CardHeader>
            <CardBody className="pb-0">
              <div className="table-overflow-x">
                <Table>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">First</th>
                      <th scope="col">Last</th>
                      <th scope="col">Handle</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>@mdo</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Jacob</td>
                      <td>Thornton</td>
                      <td>@fat</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>Larry</td>
                      <td>the Bird</td>
                      <td>@twitter</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          
          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle><h5 className="font-weight-semibold mb-0">Striped</h5></CardTitle>
            </CardHeader>
            <CardBody>
              <div className="table-overflow-x">
                <Table striped>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">First</th>
                      <th scope="col">Last</th>
                      <th scope="col">Handle</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>@mdo</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Jacob</td>
                      <td>Thornton</td>
                      <td>@fat</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>Larry</td>
                      <td>the Bird</td>
                      <td>@twitter</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          
          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle><h5 className="font-weight-semibold mb-0">Bordered</h5></CardTitle>
            </CardHeader>
            <CardBody className="pb-1">
              <div className="table-overflow-x">
                <Table bordered>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">First</th>
                      <th scope="col">Last</th>
                      <th scope="col">Handle</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>@mdo</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Jacob</td>
                      <td>Thornton</td>
                      <td>@fat</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colSpan={2}>Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          
          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle><h5 className="font-weight-semibold mb-0">Hovered Row</h5></CardTitle>
            </CardHeader>
            <CardBody className="pb-0">
              <div className="table-overflow-x">
                <Table hover>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">First</th>
                      <th scope="col">Last</th>
                      <th scope="col">Handle</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>@mdo</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Jacob</td>
                      <td>Thornton</td>
                      <td>@fat</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colSpan={2}>Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          
          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle><h5 className="font-weight-semibold mb-0">Small Table</h5></CardTitle>
            </CardHeader>
            <CardBody className="pb-0">
              <div className="table-overflow-x">  
                <Table className="table-sm">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">First</th>
                      <th scope="col">Last</th>
                      <th scope="col">Handle</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>@mdo</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Jacob</td>
                      <td>Thornton</td>
                      <td>@fat</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colSpan={2}>Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          
          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle><h5 className="font-weight-semibold mb-0">Large Table</h5></CardTitle>
            </CardHeader>
            <CardBody className="pb-0">
              <div className="table-overflow-x">
                <Table className=" table-lg">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">First</th>
                      <th scope="col">Last</th>
                      <th scope="col">Handle</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>@mdo</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Jacob</td>
                      <td>Thornton</td>
                      <td>@fat</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colSpan={2}>Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          
          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle><h5 className="font-weight-semibold mb-0">Extra Large Table</h5></CardTitle>
            </CardHeader>
            <CardBody className="pb-0">
              <div className="table-overflow-x">
                <Table className=" table-xl">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">First</th>
                      <th scope="col">Last</th>
                      <th scope="col">Handle</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>@mdo</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Jacob</td>
                      <td>Thornton</td>
                      <td>@fat</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colSpan={2}>Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          
          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle><h5 className="font-weight-semibold mb-0">Media</h5></CardTitle>
            </CardHeader>
            <CardBody className="pb-1">
              <div className="table-overflow-x">
                <Table className="table-xl border">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Applications</th>
                      <th scope="col">Downloads</th>
                      <th scope="col">Publish</th>
                      <th scope="col">Earning</th>
                      <th scope="col">Status</th>
                      <th className="text-center" scope="col">Report</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="list-media">
                          <div className="list-item">
                            <div className="media-img">
                              <img className="rounded" src={require(`../../../assets/images/backgrounds/background2-512x512.jpg`)} />
                            </div>
                            <div className="info">
                              <span className="title">Teleroid</span>
                              <span className="sub-title">Music</span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>30,321</td>
                      <td>23-7-2017</td>
                      <td>$ 13,503</td>
                      <td><span className="badge badge-pill badge-warning">Expired</span></td>
                      <td className="text-center"><a href="#" className="text-gray font-size-22"><i className="mdi mdi-file-outline" /></a></td>
                    </tr>
                    <tr>
                      <td>
                        <div className="list-media">
                          <div className="list-item">
                            <div className="media-img">
                              <img className="rounded" src={require(`../../../assets/images/backgrounds/background-512x512.jpg`)} />
                            </div>
                            <div className="info">
                              <span className="title">Notepen</span>
                              <span className="sub-title">Office</span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>26,765</td>
                      <td>15-2-2018</td>
                      <td>$ 10,299</td>
                      <td><Badge className="badge-pill badge-gradient-success">Actived</Badge></td>
                      <td className="text-center"><a href="#" className="text-gray font-size-22"><i className="mdi mdi-file-outline" /></a></td>
                    </tr>
                    <tr>
                      <td>
                        <div className="list-media">
                          <div className="list-item">
                            <div className="media-img">
                              <img className="rounded" src={require(`../../../assets/images/backgrounds/background3-512x512.jpg`)} />
                            </div>
                            <div className="info">
                              <span className="title">GoFlight</span>
                              <span className="sub-title">Travel</span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>17,683</td>
                      <td>4-3-2018</td>
                      <td>10,228</td>
                      <td><span className="badge badge-pill badge-gradient-success">Actived</span></td>
                      <td className="text-center"><a href="#" className="text-gray font-size-22"><i className="mdi mdi-file-outline" /></a></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          
          <Card className="borderless-shadow mb-0">
            <CardHeader>
              <CardTitle><h5 className="font-weight-semibold mb-0">Selectable</h5></CardTitle>
            </CardHeader>
            <CardBody className="pb-0">
              <div className="table-overflow-x">
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <div className="checkbox">
                          <input id="selectable1" type="checkbox" className="checkAll" name="checkAll" />
                          <label htmlFor="selectable1" />
                        </div>
                      </th>
                      <th className='nobreak'>First Name</th>
                      <th className='nobreak'>Last Name</th>
                      <th className='nobreak'>Username</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="checkbox">
                          <input id="selectable2" type="checkbox" />
                          <label htmlFor="selectable2" />
                        </div>
                      </td>
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>@mdo</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="checkbox">
                          <input id="selectable3" type="checkbox" />
                          <label htmlFor="selectable3" />
                        </div>
                      </td>
                      <td>Jacob</td>
                      <td>Thornton</td>
                      <td>@fat</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="checkbox">
                          <input id="selectable4" type="checkbox" />
                          <label htmlFor="selectable4" />
                        </div>
                      </td>
                      <td colSpan={2}>Larry</td>
                      <td>@twitter</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        
        </div>
      </div>
    );
  }
}

export default BasicTables;