import React from "react";
import "./icon.css";

class Icons extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Icons</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">UI Elements</a>
                <span className="breadcrumb-item active">Icons</span>
              </nav>
            </div>
          </div>
          
          <div className="row">
            <div className="col-12">
              <div className="card borderless-shadow mb-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <img className="img-fluid m-h-auto d-block" src={require(`../../../assets/images/others/font-awesome-bg.jpg`)} />
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex align-items-center h-100">
                        <div className="d-block">
                          <h4>Font Awesome 4</h4>
                          <p>Font Awesome gives you scalable vector icons that can instantly be customized — size, color, drop shadow, and anything that can be done with the power of CSS.</p>
                          <div className="m-t-25">
                            <a href="https://fontawesome.com/v4.7.0/icons/" target="_blank" className="btn btn-info">View Icons</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-6">
                      <img className="img-fluid m-h-auto d-block" src={require(`../../../assets/images/others/themify-bg.jpg`)} />
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex align-items-center h-100">
                        <div className="d-block">
                          <h4>Themify Icons</h4>
                          <p>Themify Icons is a complete set of icons for use in web design and apps, consisting of 320+ pixel-perfect, hand-crafted icons that draw inspiration from Apple iOS 7 - available to the public, 100% FREE! You may use or distribute it for any purpose, whether personal or commercial. This icon set is a must have tool for web designers and developers.</p>
                          <div className="m-t-25">
                            <a href="https://themify.me/themify-icons" target="_blank" className="btn btn-info">View Icons</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-6">
                      <img className="img-fluid m-h-auto d-block" src={require(`../../../assets/images/others/material-icons-bg.jpg`)} />
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex align-items-center h-100">
                        <div className="d-block">
                          <h4>Material Design Icons</h4>
                          <p>Material Design Icons' growing icon collection allows designers and developers targeting various platforms to download icons in the format, color and size they need for any project.</p>
                          <div className="m-t-25">
                            <a href="https://cdn.materialdesignicons.com/1.7.22/" target="_blank" className="btn btn-info">View Icons</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Icons;