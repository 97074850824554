import React from 'react';
import "./accordion.css";
import {
  CardBody, CardHeader, Card, CardTitle, Row, Col, Alert, UncontrolledAlert, Breadcrumb, BreadcrumbItem
} from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemTitle, AccordionItemBody } from "react-accessible-accordion";


class Accordions extends React.Component {
  constructor(props) {
    super(props);
    this.OpenAccordion = this.OpenAccordion.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  OpenAccordion(sectionName, Wrapdiv) {
    var CurrentCls = document.getElementById(sectionName).getAttribute("class");
    if (CurrentCls == "acd-des") {
      document.getElementById(sectionName).setAttribute("class", "acd-des show");
      document.getElementById(Wrapdiv).setAttribute("class", "acd-group acd-active");
    }
    else {
      document.getElementById(sectionName).setAttribute("class", "acd-des");
      document.getElementById(Wrapdiv).setAttribute("class", "acd-group");
    }
  }

  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Accordion</h2>
            <div className="header-sub-title">
              <Breadcrumb>
                <BreadcrumbItem><a href="#"><i className="ti-home pr-2 mt-1" />Home</a></BreadcrumbItem>
                <BreadcrumbItem><a href="#"><i className="ti-home pr-2 mt-1" />Components</a></BreadcrumbItem>
                <BreadcrumbItem active>Accordion</BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>

          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle className="font-weight-semibold font-size-18">Default Accordion</CardTitle>
            </CardHeader>
            <CardBody>
              <p className="mb-2">Use <code>"Accordion"</code> element to to wrap the whole accordion content.</p>
              <p className="mb-3">Inside of "Accordion" element, <code>"AccordionItem"</code> element serves the actual accordion functionality</p>
              <Accordion>
                  <AccordionItem>
                    <AccordionItemTitle>
                      <h6 className="u-position-relative"><strong>Collapsible Group Item #1</strong>
                        <div className="accordion__arrow" role="presentation"></div>
                      </h6>
                    </AccordionItemTitle>
                    <AccordionItemBody>
                      <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</p>
                    </AccordionItemBody>
                  </AccordionItem>
                {/* <Card>
                </Card> */}

                  <AccordionItem>
                    <AccordionItemTitle >
                      <h6 className="u-position-relative"><strong>Collapsible Group Item #2</strong>
                        <div className="accordion__arrow" role="presentation"></div></h6>
                    </AccordionItemTitle>
                    <AccordionItemBody>
                      <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</p>
                    </AccordionItemBody>
                  </AccordionItem>
                {/* <Card>
                </Card> */}
                
                  <AccordionItem>
                    <AccordionItemTitle >
                      <h6 className="u-position-relative"><strong>Collapsible Group Item #3</strong>
                        <div className="accordion__arrow" role="presentation"></div></h6>
                    </AccordionItemTitle>
                    <AccordionItemBody>
                      <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</p>
                    </AccordionItemBody>
                  </AccordionItem>
                {/* <Card>
                </Card> */}
              </Accordion>
            </CardBody>
          </Card>

          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle className="font-weight-semibold font-size-18">Nested</CardTitle>
            </CardHeader>
            <CardBody>
              <p className="mb-3">
                Nest <code>"Accordion"</code> element, <code>inside</code> of "Accordion" element to make accrodion nested.
                {/* Apllied <code>.nested</code> class to <code>accordion</code> to make accordion nested. */}
              </p>
                <Accordion>
                  <AccordionItem>
                    <AccordionItemTitle>
                      <h6 className="u-position-relative">
                        Multiple Component
                        <div className="accordion__arrow" role="presentation" />
                      </h6>
                    </AccordionItemTitle>
                    <AccordionItemBody>
                      <Accordion accordion={false}>
                        <AccordionItem>
                          <AccordionItemTitle >
                            <h6 className="u-position-relative"><strong>Collapsible Group Item #1</strong>
                              <div className="accordion__arrow" role="presentation"></div>
                            </h6>
                          </AccordionItemTitle>
                          <AccordionItemBody>
                            <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</p>
                          </AccordionItemBody>
                        </AccordionItem>

                        <AccordionItem>
                          <AccordionItemTitle >
                            <h6 className="u-position-relative"><strong>Collapsible Group Item #2</strong>
                              <div className="accordion__arrow" role="presentation"></div>
                            </h6>
                          </AccordionItemTitle>
                          <AccordionItemBody>
                            <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</p>
                          </AccordionItemBody>
                        </AccordionItem>

                        <AccordionItem>
                          <AccordionItemTitle >
                            <h6 className="u-position-relative"><strong>Collapsible Group Item #3</strong>
                              <div className="accordion__arrow" role="presentation"></div>
                            </h6>
                          </AccordionItemTitle>
                          <AccordionItemBody>
                            <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</p>
                          </AccordionItemBody>
                        </AccordionItem>

                      </Accordion>
                    </AccordionItemBody>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemTitle>
                      <h6 className=" u-position-relative u-margin-bottom-s">
                        Single Accordion
                        <div className="accordion__arrow" role="presentation" />
                      </h6>
                    </AccordionItemTitle>
                    <AccordionItemBody>
                      <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</p>
                    </AccordionItemBody>
                  </AccordionItem>
                </Accordion>
              {/* <Card>
              </Card> */}

            </CardBody>
          </Card>

          <Card className="borderless-shadow mb-0">
            <CardHeader>
              <CardTitle className="font-weight-semibold font-size-18">Borderless</CardTitle>
            </CardHeader>
            <CardBody>
              <p className="m-b-25">
                Apllied <code>.borderless</code> class to <code>accordion</code> to make accordion borderless.
              </p>

              <Accordion className="borderless" role="tablist">
                  <AccordionItem>
                    <AccordionItemTitle >
                      <h6 className="u-position-relative"><strong>Collapsible Group Item #1</strong>
                        <div className="accordion__arrow" role="presentation"></div>
                      </h6>
                    </AccordionItemTitle>
                    <AccordionItemBody>
                      <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</p>
                    </AccordionItemBody>
                  </AccordionItem>
                {/* <Card>
                </Card> */}

                  <AccordionItem>
                    <AccordionItemTitle >
                      <h6 className="u-position-relative"><strong>Collapsible Group Item #2</strong>
                        <div className="accordion__arrow" role="presentation"></div>
                      </h6>
                    </AccordionItemTitle>
                    <AccordionItemBody>
                      <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</p>
                    </AccordionItemBody>
                  </AccordionItem>
                {/* <Card>
                </Card> */}

                  <AccordionItem>
                    <AccordionItemTitle >
                      <h6 className="u-position-relative"><strong>Collapsible Group Item #3</strong>
                        <div className="accordion__arrow" role="presentation"></div>
                      </h6>
                    </AccordionItemTitle>
                    <AccordionItemBody>
                      <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</p>
                    </AccordionItemBody>
                  </AccordionItem>
                {/* <Card>
                </Card> */}
              </Accordion>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default Accordions;