import React from "react";
import "./badges.css"

import { CardBody, CardHeader, Badge, Card, CardTitle, Row, Col } from 'reactstrap';

class Badges extends React.Component {
  constructor(props) {
    super(props);

  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Badges</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">UI Elements</a>
                <span className="breadcrumb-item active">Badges</span>
              </nav>
            </div>
          </div>
          
          <Card className="borderless-shadow mb-0">
            <CardBody>
              <Row>
                <Col md={6} className="mb-3 mt-2">
                  <h5>Basic</h5>
                  <p>Add any of the below mentioned modifier classes - <code>color="default/primary/..etc"</code> to change the appearance of a badge.</p>
                  <Row className="mt-2 custom-row">
                    <Badge color="secondary" className="mr-1 mb-1">Default</Badge>
                    <Badge color="primary" className="mr-1">Primary</Badge>
                    <Badge color="success" className="mr-1">Success</Badge>
                    <Badge color="info" className="mr-1">Info</Badge>
                    <Badge color="warning" className="mr-1">Warning</Badge>
                    <Badge color="danger" className="mr-1">Danger</Badge>
                  </Row>
                </Col>
                <Col md={6} className="mb-2 mt-2">
                  <h5>With Gradient</h5>
                  <p>Use <code>.badge-gradient-*</code> as prefix along with contextual colors to applied gradient style.</p>
                  <Row className="mt-2 custom-row">
                    <Badge color="gradient-primary" className="mr-1 mb-1">Primary</Badge>
                    <Badge color="gradient-success" className="mr-1">Success</Badge>
                    <Badge color="gradient-info" className="mr-1">Info</Badge>
                    <Badge color="gradient-warning" className="mr-1">Warning</Badge>
                    <Badge color="gradient-danger" className="mr-1">Danger</Badge>
                  </Row>
                </Col>
              </Row>
              <hr />

              <Row>
                <Col md={6} className="mb-3 mt-2">
                  <h5>Pill</h5>
                  <p>Use the <code>pill</code> value prop to make badges more rounded (with a larger <code>border-radius</code> and additional horizontal <code>padding</code>). Useful if you miss the badges from v3.</p>
                  <Row className="mt-2 custom-row">
                    <Badge color="secondary" pill className="mr-1 mb-1">Default</Badge>
                    <Badge color="primary" pill className="mr-1">Primary</Badge>
                    <Badge color="success" pill className="mr-1">Success</Badge>
                    <Badge color="info" pill className="mr-1">Info</Badge>
                    <Badge color="warning" pill className="mr-1">Warning</Badge>
                    <Badge color="danger" pill className="mr-1">Danger</Badge>
                  </Row>
                </Col>
                <Col md={6} className="mb-2 mt-2">
                  <h5>Pill With Gradient</h5>
                  <p>Use <code>.badge-gradient-*</code> as prefix along with contextual colors to applied gradient style.</p>
                  <Row className="mt-2 custom-row">
                    <Badge color="gradient-primary" pill className="mr-1 mb-1">Primary</Badge>
                    <Badge color="gradient-success" pill className="mr-1">Success</Badge>
                    <Badge color="gradient-info" pill className="mr-1">Info</Badge>
                    <Badge color="gradient-warning" pill className="mr-1">Warning</Badge>
                    <Badge color="gradient-danger" pill className="mr-1">Danger</Badge>
                  </Row>
                </Col>
              </Row>
              <hr />

              <Row>
                <Col md={6} className="mb-3 mt-2">
                  <h5>Links</h5>
                  <p>Using the contextual <code>href="#"</code> prop value on <code>Badge</code> component to have <em>actionable</em> badges with hover and focus states.</p>
                  <Row className="mt-2 custom-row">
                    <Badge href="#" color="secondary" pill className="mr-1 mb-1">Default</Badge>
                    <Badge href="#" color="primary" pill className="mr-1">Primary</Badge>
                    <Badge href="#" color="success" pill className="mr-1">Success</Badge>
                    <Badge href="#" color="info" pill className="mr-1">Info</Badge>
                    <Badge href="#" color="warning" pill className="mr-1">Warning</Badge>
                    <Badge href="#" color="danger" pill className="mr-1">Danger</Badge>
                  </Row>
                </Col>
                <Col md={6} className="mb-2 mt-2">
                  <h5>Sizing</h5>
                  <p>Customize badge size using <code>.badge-sm</code>, <code>.badge-lg</code> &amp; <code>.badge-xl</code></p>
                  <Row className="mt-2 custom-row">
                    <Badge color="gradient-primary" className="badge-xl mr-1 mb-1" pill>Badge-xl</Badge>
                    <Badge color="gradient-success" className="badge-lg mr-1" pill>Badge-lg</Badge>
                    <Badge color="gradient-info" className="badge-md mr-1" pill>Badge-md</Badge>
                    <Badge color="gradient-danger" className="badge-sm mr-1" pill>Badge-sm</Badge>
                  </Row>
                </Col>
              </Row>
              <hr />
              
              <Row>
                <Col md={6} className="mb-3">
                  <h5>Status Badge</h5>
                  <p>Use <code>.status</code> along with contextual class to create status badge</p>
                  <Row className="mt-2 custom-row">
                    <span className="status mr-1" />
                    <span className="status primary mr-1" />
                    <span className="status success mr-1" />
                    <span className="status info mr-1" />
                    <span className="status warning mr-1" />
                    <span className="status danger mr-1" />
                  </Row>
                </Col>
                <Col md={6}>
                  {/* <h5>List Media Status</h5>
                  <p>Status badge can also applied to list-media as below</p>
                  <ul className="list-media inline-block">
                    <li className="list-item  inline-block">
                      <div className="p-2">
                        <div className="media-img">
                          <img src="assets/images/avatars/thumb-2.jpg" alt />
                          <span className="status success" />
                        </div>
                      </div>
                    </li>
                    <li className="list-item  inline-block">
                      <div className="p-2">
                        <div className="media-img">
                          <img src="assets/images/avatars/thumb-8.jpg" alt />
                          <span className="status warning" />
                        </div>
                      </div>
                    </li>
                    <li className="list-item  inline-block">
                      <div className="p-2">
                        <div className="media-img">
                          <img src="assets/images/avatars/thumb-13.jpg" alt />
                          <span className="status danger" />
                        </div>
                      </div>
                    </li>
                    <li className="list-item  inline-block">
                      <div className="p-2">
                        <div className="media-img">
                          <img src="assets/images/avatars/thumb-11.jpg" alt />
                          <span className="status danger" />
                        </div>
                      </div>
                    </li>
                  </ul> */}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default Badges;