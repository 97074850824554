import React from "react";
import "./progressbar.css";
import { CardBody, CardHeader, Card, CardTitle, Row, Col, Alert, UncontrolledAlert, Progress } from 'reactstrap';

class ProgressBar extends React.Component {
  constructor(props) {
    super(props);

  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Progress Bars</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">Components</a>
                <span className="breadcrumb-item active">Progress Bars</span>
              </nav>
            </div>
          </div>

          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle className="font-weight-semibold font-size-18">Basic</CardTitle>
            </CardHeader>
            <CardBody>
              <p className="mb-3">Progress components are built with two HTML elements, some CSS to set the width, and a few attributes, ensuring you can stack progress bars, animate them, and place text labels over them. <code>Progess</code> component is all you needfor any type of progress bars.</p>
              <div>
                <Row>
                  <Col md={8}>
                    <Progress className="progress-mb" />
                    <Progress className="progress-mb" color="success" value="25" />
                    <Progress className="progress-mb" color="success" value="50" />
                    <Progress className="progress-mb" color="success" value="100" />
                  </Col>
                </Row>
                <p className="mt-2">Bootstrap provides a handful of <a target="_blank" href="https://getbootstrap.com/docs/4.0/utilities/sizing/">utilities for setting width</a>. Depending on your needs, these may help with quickly configuring progress.</p>
                <div className="m-t-25">
                  <Row>
                    <Col md={8}>
                      <Progress className="progress-mb" color="success" value="75" />
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
          </Card>

          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle className="font-weight-semibold font-size-18">Backgrounds</CardTitle>
            </CardHeader>
            <CardBody>
              <p className="mb-3">Use background utility classes - <code>color="success/info..etc."</code> to change the appearance of individual progress bars.</p>
              <div>
                <Row>
                  <Col md={8}>
                    <Progress value={2 * 5} className="progress-mb" color="primary" />
                    <Progress color="success" value="25" className="progress-mb" />
                    <Progress color="info" value={50} className="progress-mb" />
                    <Progress color="warning" value={75} className="progress-mb" />
                    <Progress color="danger" value="100" className="progress-mb" />
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>

          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle className="font-weight-semibold font-size-18">Label Progress Bar</CardTitle>
            </CardHeader>
            <CardBody>
              <p className="mb-3">Use <code>value=""</code> prop value to have labeled progress bar.</p>
              <div>
                <Row>
                  <Col md={8}>
                    <Progress value="25" className="progress-mb" color="primary">25%</Progress>
                    <Progress value={50} className="progress-mb" color="warning">1/2</Progress>
                    <Progress value={75} className="progress-mb" color="teal">You're almost there!</Progress>
                    <Progress color="success" value="100" className="progress-mb">You did it!</Progress>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>

          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle className="font-weight-semibold font-size-18">Sizing</CardTitle>
            </CardHeader>
            <CardBody>
              <p className="mb-3">Use <code>.progress-sm</code>, <code>.progress-lg</code> or <code>.progress-xl</code> to customize progress size</p>
              <div>
                <Row>
                  <Col md={8}>
                    <Progress className="progress-sm progress-mb" color="primary" value="20" />
                    <Progress className="progress-mb" color="success" value="40" />
                    <Progress className="progress-xl progress-mb" color="warning" value="60" />
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
          
          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle className="font-weight-semibold font-size-18">Animated</CardTitle>
            </CardHeader>
            <CardBody>
              <p className="mb-3">Use <code>animated</code> along with <code>color="success/info..etc."</code> prop value, to customize the progress bar with animated background</p>
              <div className="m-t-25">
                <Row>
                  <Col md={8}>
                    <Progress animated value={2 * 5} className="progress-mb" color="primary" />
                    <Progress animated color="success" value="25" className="progress-mb" />
                    <Progress animated color="info" value={50} className="progress-mb" />
                    <Progress animated color="warning" value={75} className="progress-mb" />
                    <Progress animated color="danger" value="100" className="progress-mb" />
                    <Progress multi className="progress-mb">
                      <Progress animated bar color="primary" value="10" />
                      <Progress animated bar color="success" value="30" />
                      <Progress animated bar color="warning" value="20" />
                      <Progress animated bar color="danger" value="20" />
                    </Progress>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>

          <Card className="borderless-shadow mb-0">
            <CardHeader>
              <CardTitle className="font-weight-semibold font-size-18">Multiple bars</CardTitle>
            </CardHeader>
            <CardBody>
              <p className="mb-3">Include multiple progress bars in a progress component by adding <code>multi</code> prop value to the parent Progress component, and then nest individual Progress components inside.</p>
              <div>
                <Row>
                  <Col md={8}>
                    <Progress multi className="progress-mb">
                      <Progress bar color="primary" value="15" />
                      <Progress bar color="success" value="30" />
                      <Progress bar color="info" value="25" />
                      <Progress bar color="warning" value="20" />
                      <Progress bar color="danger" value="5" />
                    </Progress>

                    <Progress multi className="progress-mb">
                      <Progress animated bar color="primary" value="10" />
                      <Progress animated bar color="success" value="30" />
                      <Progress animated bar color="warning" value="20" />
                      <Progress animated bar color="danger" value="20" />
                    </Progress>
                    <Progress multi className="progress-mb">
                      <Progress bar color="primary" value="15">Meh</Progress>
                      <Progress bar color="success" value="30">Wow!</Progress>
                      <Progress bar color="info" value="25">Cool</Progress>
                      <Progress bar color="warning" value="20">20%</Progress>
                      <Progress bar color="danger" value="5">!!</Progress>
                    </Progress>
                    <Progress multi className="progress-mb">
                      <Progress bar striped color="primary" value="15">Stripes</Progress>
                      <Progress bar animated color="success" value="30">Animated Stripes</Progress>
                      <Progress bar color="info" value="25">Plain</Progress>
                    </Progress>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>

        </div>
      </div>
    );
  }
}

export default ProgressBar;