import React from "react";
import "./cards.css";

import { Card, CardHeader, CardColumns, CardImg, CardText, CardBody, CardTitle, CardSubtitle, Button, CardDeck, Col, Row } from 'reactstrap';

class Cards extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Cards</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">UI Elements</a>
                <span className="breadcrumb-item active">Cards</span>
              </nav>
            </div>
          </div>
          
          <div className="row">
            <div className="col-md-4 d-flex">
              <Card className="borderless-shadow" style={{ overflow: 'hidden' }}>
                <CardImg top width="100%" src={require(`../../../assets/images/backgrounds/img101-bg.jpeg`)} />
                <CardBody>
                  <CardTitle>Card title</CardTitle>
                  <CardText>Scratch the postman wake up lick paw wake up owner meow meow lick plastic bags so cat not kitten around meow all night having their mate disturbing sleeping humans.</CardText>
                  <Button>Button</Button>
                </CardBody>
              </Card>
            </div>
            <div className="col-md-4 d-flex">
              <Card className="borderless-shadow">
                <CardHeader>
                  <CardTitle className="card-title-bold">Basic Card</CardTitle>
                </CardHeader>
                <div className="card-body">
                  <p>Put toy mouse in food bowl run out of litter box at full speed drool but pee in the shoe purr when being pet but chew foot.</p>
                  <p>Scratch the postman wake up lick paw wake up owner meow meow lick plastic bags so cat not kitten around meow all night having their mate disturbing sleeping humans.</p>
                  <p className="mb-0">Try to jump onto window and fall while scratching at wall ignore the squirrels, you'll never catch them anyway cat snacks.</p>
                </div>
              </Card>
            </div>
            <div className="col-md-4 d-flex">
              <div className="card borderless-shadow">
                <div className="card-body">
                  <h4 className="card-title mb-3">Card Inner Title</h4>
                  <p>Put toy mouse in food bowl run out of litter box at full speed drool but pee in the shoe purr when being pet but chew foot.</p>
                  <p>Scratch the postman wake up lick paw wake up owner meow meow lick plastic bags so cat not kitten around meow all night having their mate disturbing sleeping humans.</p>
                  <p className="mb-0">Try to jump onto window and fall while scratching at wall ignore the squirrels, you'll never catch them anyway cat snacks. Cough furball cat not kitten.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 d-flex">
              <div className="card borderless-shadow">
                <div className="card-body">
                  <h4 className="card-title mb-3">Card Footer</h4>
                  <p>Put toy mouse in food bowl run out of litter box at full speed drool but pee in the shoe purr when being pet but chew foot.</p>
                  <p>Scratch the postman wake up lick paw wake up owner meow meow lick plastic bags so cat not kitten around meow all night having their mate disturbing sleeping humans.</p>
                  <p className="mb-0">Try to jump onto window and fall while scratching at wall ignore the squirrels, you'll never catch them anyway.</p>
                </div>
                <div className="card-footer">
                  <div className="text-right">
                    <button className="btn btn-gradient-success mr-2">Accept</button>
                    <button className="btn btn-default btn-outline-secondary">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="card borderless-shadow">
                <div className="card-header card-header-flex" style={{alignItems: 'center'}}>
                  <h4 className="card-title">Card with Toolbar</h4>
                  <div className="card-toolbar" style={{transform: 'translateY(3px)'}}>
                    <ul>
                      <li className="mr-2">
                        <a className="text-gray mr-0" href="#">
                          <i className="mdi mdi-refresh font-size-18" />
                        </a>
                      </li>
                      <li className="mr-2">
                        <a className="text-gray mr-0" href="#">
                          <i className="mdi mdi-bookmark-outline font-size-18" />
                        </a>
                      </li>
                      <li>
                        <a className="text-gray mr-0" href="#">
                          <i className="mdi mdi-dots-vertical font-size-18" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-body">
                  <p>Put toy mouse in food bowl run out of litter box at full speed drool but pee in the shoe purr when being pet but chew foot.</p>
                  <p>Scratch the postman wake up lick paw wake up owner meow meow lick plastic bags so cat not kitten around meow all night having their mate disturbing sleeping humans.</p>
                  <p className="mb-0">Try to jump onto window and fall while scratching at wall ignore the squirrels, you'll never catch them anyway cat snacks. Cough furball cat.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="card borderless-shadow" style={{width: '100%'}}>
                <div className="card-header" style={{ borderBottom: '1px solid rgba(0,0,0,.125)' }}>
                  <h4 className="card-title" >Card With List</h4>
                </div>
                <div className="card-body p-0">
                  <ul className="list-media mb-1 pt-2 pb-2">
                    <li className="list-item">
                      <a href="#" className="media-hover p-h-20">
                        <div className="media-img">
                          <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} alt="Image1" />
                        </div>
                        <div className="info">
                          <span className="title">Susie Maccharthy</span>
                          <span className="sub-title">commented on your post's</span>
                        </div>
                      </a>
                    </li>
                    <li className="list-item">
                      <a href="#" className="media-hover p-h-20">
                        <div className="media-img">
                          <img src={require(`../../../assets/images/profiles/profile-pic-1.jpg`)} alt="Image2" />
                        </div>
                        <div className="info">
                          <span className="title">Debra Stewart</span>
                          <span className="sub-title">commented on your post's</span>
                        </div>
                      </a>
                    </li>
                    <li className="list-item">
                      <a href="#" className="media-hover p-h-20">
                        <div className="media-img">
                          <img src={require(`../../../assets/images/profiles/profile-pic-3.jpg`)} alt="Image2" />
                        </div>
                        <div className="info">
                          <span className="title">Paul Dawson</span>
                          <span className="sub-title">commented on your post's</span>
                        </div>
                      </a>
                    </li>
                    <li className="list-item">
                      <a href="#" className="media-hover p-h-20">
                        <div className="media-img">
                          <img src={require(`../../../assets/images/profiles/profile-pic-19.jpg`)} alt="Image2" />
                        </div>
                        <div className="info">
                          <span className="title">Cacy Xander</span>
                          <span className="sub-title">commented on your post's</span>
                        </div>
                      </a>
                    </li>
                    <li className="list-item">
                      <a href="#" className="media-hover p-h-20">
                        <div className="media-img">
                          <img src={require(`../../../assets/images/profiles/profile-pic-24.jpg`)} alt="Image2" />
                        </div>
                        <div className="info">
                          <span className="title">Steven Davis</span>
                          <span className="sub-title">commented on your post's</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                  <div className="card-footer" style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
                    <div className="text-center m-t-5">
                      <a href="#" className="text-gray">View More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col-lg-3 col-md-6 d-flex">
              <div className="card borderless-shadow" style={{ overflow: 'hidden' }}>
                <img className="card-img-top" src={require(`../../../assets/images/backgrounds/background-512x512.jpg`)} />
                <div className="card-body">
                  <h4 className="card-title m-t-10">Card Media</h4>
                  <p className="card-text">Put toy mouse in food bowl run out of litter box at full speed drool but pee in the shoe purr when being pet but chew foot. </p>
                  <div className="m-t-20">
                    <a href="#" className="btn btn-gradient-card-green">Action 1</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 d-flex">
              <div className="card borderless-shadow" style={{ overflow: 'hidden' }}>
                <div className="bg-overlay">
                  <div className="card-toolbar mt-2">
                    <ul>
                      <li>
                        <a className="text-white" href="#">
                          <i className="mdi mdi-refresh font-size-20" />
                        </a>
                      </li>
                      <li>
                        <a className="text-white" href="#">
                          <i className="mdi mdi-bookmark-outline font-size-20" />
                        </a>
                      </li>
                      <li>
                        <a className="text-white" href="#">
                          <i className="mdi mdi-dots-vertical font-size-20" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <img className="card-img-top" src={require(`../../../assets/images/backgrounds/background2-512x512.jpg`)} />
                </div>
                <div className="card-body">
                  <h4 className="card-title m-t-10">Card Media With Toolbar</h4>
                  <p className="card-text">Put toy mouse in food bowl run out of litter box at full speed drool but pee in the shoet. </p>
                  <div className="m-t-20">
                    <a href="#" className="btn btn-gradient-card-blue">Action 1</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-6 d-flex">
              <Row>
                <Col lg={12} md={6} className="d-flex">
                  <div className="card borderless-shadow" style={{ overflow: 'hidden' }}>
                    <div className="bg-overlay bg"
                      style={{
                        height: '100%',
                        backgroundImage: 'url("/src/assets/images/backgrounds/background-512x512.jpg")'
                      }}
                    >
                      <div className="card-header p-3" style={{ backgroundColor: 'transparent' }}>
                        <div className="card-toolbar" style={{ justifyContent: 'flex-end' }}>
                          <ul>
                            <li>
                              <a className="text-white" href="#">
                                <i className="mdi mdi-refresh font-size-20" />
                              </a>
                            </li>
                            <li>
                              <a className="text-white" href="#">
                                <i className="mdi mdi-bookmark-outline font-size-20" />
                              </a>
                            </li>
                            <li>
                              <a className="text-white" href="#">
                                <i className="mdi mdi-dots-vertical font-size-20" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-body">
                        <div>
                          <h4 className="font-size-20px font-weight-semibold text-white">Card Image Overlay</h4>
                          <p className="width-60">Hopped up on catnip ask to go outside and ask to come inside and ask to go outside and ask to come inside. Sleep stare at ceiling light for lick butt.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={12} md={6} className="d-flex">
                  <div className="card borderless-shadow" style={{ overflow: 'hidden' }}>
                    <div className="bg-overlay bg"
                      style={{
                        height: '100%',
                        backgroundImage: "url('/src/assets/images/backgrounds/background2-512x512.jpg')"
                      }}
                    >
                      <div className="card-header p-3" style={{ backgroundColor: 'transparent' }}>
                        <div className="card-toolbar" style={{ justifyContent: 'flex-end' }}>
                          <ul>
                            <li>
                              <a className="text-white" href="#">
                                <i className="mdi mdi-refresh font-size-20" />
                              </a>
                            </li>
                            <li>
                              <a className="text-white" href="#">
                                <i className="mdi mdi-bookmark-outline font-size-20" />
                              </a>
                            </li>
                            <li>
                              <a className="text-white" href="#">
                                <i className="mdi mdi-dots-vertical font-size-20" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-body">
                        <div>
                          <h4 className="font-size-20px font-weight-semibold text-white">Card Image Overlay</h4>
                          <p className="width-60">Hopped up on catnip ask to go outside and ask to come inside and ask to go outside and ask to come inside. Sleep stare at ceiling light for lick butt.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          
          <div className="row">
            <Col md={12}>
              <Card className="borderless-shadow">
                <CardHeader>
                  <CardTitle className="card-title-bold">Card Deck</CardTitle>
                </CardHeader>
                <CardBody>
                  <CardDeck>
                    <Card className="borderless-shadow">
                      <CardImg top width="100%" src={require(`../../../assets/images/backgrounds/img101-bg.jpeg`)} alt="Card image cap" />
                      <CardBody>
                        <CardTitle>Card title</CardTitle>
                        <CardSubtitle>Card subtitle</CardSubtitle>
                        <CardText>This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</CardText>
                        <Button>Button</Button>
                      </CardBody>
                    </Card>
                    <Card className="borderless-shadow">
                      <CardImg top width="100%" src={require(`../../../assets/images/backgrounds/img101-bg.jpeg`)} alt="Card image cap" />
                      <CardBody>
                        <CardTitle>Card title</CardTitle>
                        <CardSubtitle>Card subtitle</CardSubtitle>
                        <CardText>This card has supporting text below as a natural lead-in to additional content.</CardText>
                        <Button>Button</Button>
                      </CardBody>
                    </Card>
                    <Card className="borderless-shadow">
                      <CardImg top width="100%" src={require(`../../../assets/images/backgrounds/img101-bg.jpeg`)} alt="Card image cap" />
                      <CardBody>
                        <CardTitle>Card title</CardTitle>
                        <CardSubtitle>Card subtitle</CardSubtitle>
                        <CardText>This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</CardText>
                        <Button>Button</Button>
                      </CardBody>
                    </Card>
                  </CardDeck>
                </CardBody>
              </Card>
            </Col>
          </div>
          
          <div className="row">
            <div className="col-md-4 d-flex">
              <div className="card bg-primary-gradient-to-bottom-gradient text-white borderless-shadow" style={{ overflow: 'hidden' }}>
                <div className="card-header" style={{ borderBottom: '1px solid rgba(0,0,0,.125)', backgroundColor: 'transparent' }}>
                  <h4 className="card-title">Card With Context Background</h4>
                </div>
                <div className="card-body">
                  <p>Put toy mouse in food bowl run out of litter box at full speed drool but pee in the shoe purr when being pet but chew foot.</p>
                  <p>Scratch the postman wake up lick paw wake up owner meow meow lick plastic bags so cat not kitten around meow all night having their mate disturbing sleeping humans.</p>
                  <p>Try to jump onto window and fall while scratching at wall ignore the squirrels, you'll never catch them anyway cat snacks.</p>
                  <p>I'll sacrifice anything for my children. Good luck.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="card bg-gradient-primary text-white borderless-shadow">
                <div className="card-header" style={{ borderBottom: '1px solid rgba(0,0,0,.125)', backgroundColor: 'transparent' }}>
                  <h4 className="card-title">Card With Gradient Background</h4>
                </div>
                <div className="card-body">
                  <p>Put toy mouse in food bowl run out of litter box at full speed drool but pee in the shoe purr when being pet but chew foot.</p>
                  <p>Scratch the postman wake up lick paw wake up owner meow meow lick plastic bags so cat not kitten around meow all night having their mate disturbing sleeping humans.</p>
                  <p>Try to jump onto window and fall while scratching at wall ignore the squirrels, you'll never catch them anyway cat snacks.</p>
                </div>
                <div className="card-footer">
                  <div className="text-right">
                    <button className="btn btn-gradient-success">Accept</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="card borderless-shadow text-white" style={{overflow: 'hidden'}}>
                <div className="bg-secondary-gradient-to-bottom-gradient" style={{ height: '100%' }}>
                  <div className="card-header custom-padding" style={{ backgroundColor: 'transparent' }}>
                    <div className="card-toolbar" style={{ justifyContent: 'flex-end' }}>
                      <ul>
                        <li>
                          <a className="text-white" href="#">
                            <i className="mdi mdi-refresh font-size-20" />
                          </a>
                        </li>
                        <li>
                          <a className="text-white" href="#">
                            <i className="mdi mdi-bookmark-outline font-size-20" />
                          </a>
                        </li>
                        <li>
                          <a className="text-white" href="#">
                            <i className="mdi mdi-dots-vertical font-size-20" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="m-t-95 m-b-20">
                      <h4 className="mb-3">Card Gradient Overlay</h4>
                      <p className="width-60">Hopped up on catnip ask to go outside and ask to come inside and ask to go outside and ask to come inside. Sleep stare at ceiling light for lick butt.</p>
                      <p>Scratch the postman wake up lick paw wake up owner meow meow lick plastic bags so cat not kitten around meow all night having their mate disturbing sleeping humans.</p>
                       <p>Try to jump onto window and fall while scratching at wall ignore the squirrels, you'll never catch them anyway cat snacks.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="row">
            <Col>
              <Card className="borderless-shadow mb-0">
                <CardHeader>
                  <CardTitle className="card-title-bold">Card Columns</CardTitle>
                </CardHeader>
                <CardBody>
                  <CardColumns>
                    <Card className="borderless-shadow" style={{overflow: 'hidden'}}>
                      <CardImg top width="100%" src={require(`../../../assets/images/backgrounds/img101-bg.jpeg`)} />
                      <CardBody>
                        <CardTitle>Card title</CardTitle>
                        <CardSubtitle>Card subtitle</CardSubtitle>
                        <CardText>This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</CardText>
                        <Button>Button</Button>
                      </CardBody>
                    </Card>
                    <Card className="borderless-shadow" style={{ overflow: 'hidden' }}>
                      <CardImg top width="100%" src={require(`../../../assets/images/backgrounds/img101-bg.jpeg`)} alt="Card image cap" />
                    </Card>
                    <Card className="borderless-shadow">
                      <CardBody>
                        <CardTitle>Card title</CardTitle>
                        <CardSubtitle>Card subtitle</CardSubtitle>
                        <CardText>This card has supporting text below as a natural lead-in to additional content.</CardText>
                        <Button>Button</Button>
                      </CardBody>
                    </Card>
                    <Card className="borderless-shadow" body inverse style={{ backgroundColor: '#333', borderColor: '#333' }}>
                      <CardTitle>Special Title Treatment</CardTitle>
                      <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                      <Button color="success">Button</Button>
                    </Card>
                    <Card className="borderless-shadow" style={{ overflow: 'hidden' }}>
                      <CardImg top width="100%" src={require(`../../../assets/images/backgrounds/img101-bg.jpeg`)} alt="Card image cap" />
                      <CardBody>
                        <CardTitle>Card title</CardTitle>
                        <CardSubtitle>Card subtitle</CardSubtitle>
                        <CardText>This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</CardText>
                        <Button>Button</Button>
                      </CardBody>
                    </Card>
                    <Card className="borderless-shadow" body inverse color="primary">
                      <CardTitle>Special Title Treatment</CardTitle>
                      <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                      <Button color="warning">Button</Button>
                    </Card>
                  </CardColumns>
                </CardBody>
              </Card>
            </Col>
          </div>
        
        </div>
      </div>
    );
  }
}

export default Cards;