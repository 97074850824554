import React from "react";
import "./buttons.css";

import { CardBody, Nav, Card, Row, Col, Button, ButtonGroup, ButtonToolbar } from 'reactstrap';

class Buttons extends React.Component {
  constructor(props) {
    super(props);

  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Buttons</h2>
            <div className="header-sub-title">
              <Nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">UI Elements</a>
                <span className="breadcrumb-item active">Buttons</span>
              </Nav>
            </div>
          </div>
          <Row>
            <Col md={12}>
              <Card className="borderless-shadow mb-0">
                <CardBody>
                  <Row>
                    <Col md={6} className="mb-3 mt-2">
                      <h4>Context Buttons</h4>
                      <p>Bootrap context Buttons</p>
                      <div className="mt-2">
                        <Button color="primary" className="mr-2 mb-2">primary</Button>
                        <Button color="secondary" className="mr-2 mb-2">secondary</Button>
                        <Button color="success" className="mr-2 mb-2">success</Button>
                        <Button color="info" className="mr-2 mb-2">info</Button>
                        <Button color="warning" className="mr-2 mb-2">warning</Button>
                        <Button color="danger" className="mr-2 mb-2">danger</Button>
                        <Button color="link" className="mr-2 mb-2">link</Button>
                      </div>
                    </Col>
                    <Col md={6} className="mb-2 mt-2">
                      <h4>Group Button</h4>
                      <p>Bootrap with Group Button.</p>
                      <ButtonGroup>
                        <Button>Left</Button>
                        <Button>Middle</Button>
                        <Button>Right</Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                  <hr />

                  <Row>
                    <Col md={6} className="mb-3 mt-2">
                      <h4>Round Buttons</h4>
                      <p>applies <code>.btn-rounded</code> to make round style</p>
                      <div className="mt-2">
                        <Button color="primary" className="btn-rounded mr-2 mb-2">primary</Button>
                        <Button color="secondary" className="btn-rounded mr-2 mb-2">secondary</Button>
                        <Button color="success" className="btn-rounde mr-2 mb-2">success</Button>
                        <Button color="info" className="btn-rounded mr-2 mb-2">info</Button>
                        <Button color="warning" className="btn-rounded mr-2 mb-2">warning</Button>
                        <Button color="danger" className="btn-rounded mr-2 mb-2">danger</Button>
                        <Button color="link" className="btn-rounded mr-2 mb-2">link</Button>
                      </div>
                    </Col>
                    <Col md={6} className="mb-2 mt-2">
                      <h4>Button Toolbar</h4>
                      <p>Button Toolbar</p>
                      <ButtonToolbar>
                        <ButtonGroup className="mr-2 mb-2">
                          <Button>1</Button>
                          <Button>2</Button>
                          <Button>3</Button>
                          <Button>4</Button>
                        </ButtonGroup>
                        <ButtonGroup className="mr-2 mb-2">
                          <Button>5</Button>
                          <Button>6</Button>
                          <Button>7</Button>
                        </ButtonGroup>
                        <ButtonGroup className="mr-2 mb-2">
                          <Button>8</Button>
                        </ButtonGroup>
                      </ButtonToolbar>
                    </Col>
                  </Row>
                  <hr />

                  <Row>
                    <Col md={6} className="mb-3 mt-2">
                      <h4>Outline Buttons</h4>
                      <p>applies <code>outline</code> prop value to make button ouline styled.</p>
                      <div className="mt-2">
                        <Button outline color="primary" className="mr-2 mb-2">primary</Button>
                        <Button outline color="secondary" className="mr-2 mb-2">secondary</Button>
                        <Button outline color="success" className="mr-2 mb-2">success</Button>
                        <Button outline color="info" className="mr-2 mb-2">info</Button>
                        <Button outline color="warning" className="mr-2 mb-2">warning</Button>
                        <Button outline color="danger" className="mr-2 mb-2">danger</Button>
                      </div>
                    </Col>
                    <Col md={6} className="mb-2 mt-2">
                      <h4>Rounded Outline Buttons</h4>
                      <p>applies <code>outline</code> prop value &amp; <code>.btn-rounded</code> class to make round ouline styled button.</p>
                      <div className="mt-2">
                        <Button outline className="btn-rounded mr-2 mb-2" color="primary">primary</Button>
                        <Button outline className="btn-rounded mr-2 mb-2" color="secondary">secondary</Button>
                        <Button outline className="btn-rounded mr-2 mb-2" color="success">success</Button>
                        <Button outline className="btn-rounded mr-2 mb-2" color="info">info</Button>
                        <Button outline className="btn-rounded mr-2 mb-2" color="warning">warning</Button>
                        <Button outline className="btn-rounded mr-2 mb-2" color="danger">danger</Button>
                      </div>
                    </Col>
                  </Row>
                  <hr />

                  <Row>
                    <Col md={6} className="mb-3 mt-2">
                      <h4>Buttons Sizes</h4>
                      <p>applies <code>size="lg"</code>, <code>size="md"</code> and <code>size="sm"</code> prop value to Button Component to customize the size of Button.</p>
                      <div className="mt-2">
                          <Button color="primary" size="lg" className="mr-2 mb-2">btn-lg</Button>
                          <Button color="info" size="md" className="mr-2 mb-2">btn-md</Button>
                          <Button color="warning" size="sm" className="mr-2 mb-2">btn-sm</Button>
                      </div>
                    </Col>
                    <Col md={6} className="mb-2 mt-2">
                      <div className="m-b-30">
                        <h4>Button Group Sizing</h4>
                        <p>applies <code>size="lg"</code>, <code>size="md"</code> and <code>size="sm"</code> prop value to ButtonGroup Component to customize the size Button Group.</p>
                        <ButtonGroup size="lg" className="mr-2 mb-2">
                          <Button color="primary">Left</Button>
                          <Button color="primary">Middle</Button>
                          <Button color="primary">Right</Button>
                        </ButtonGroup ><br />
                        <ButtonGroup size="md" className="mr-2 mb-2">
                          <Button color="info">Left</Button>
                          <Button color="info">Middle</Button>
                          <Button color="info">Right</Button>
                        </ButtonGroup><br />
                        <ButtonGroup size="sm" className="mr-2 mb-2">
                          <Button color="warning">Left</Button>
                          <Button color="warning">Middle</Button>
                          <Button color="warning">Right</Button>
                        </ButtonGroup>
                      </div>
                    </Col>
                  </Row>
                  <hr />

                  <Row>
                    <Col md={4} className="mb-3 mt-2">
                      <h4>Icon Buttons</h4>
                      <p>Use <code>btn-icon</code> to create a icon Button.</p>
                      <div className="mt-2 mb-4">
                        <Button className="btn btn-icon btn-info mr-2 mb-2">
                          <i className="mdi mdi-check" />
                        </Button>
                        <Button className="btn btn-icon btn-primary mr-2 mb-2">
                          <i className="mdi mdi-message-outline" />
                        </Button>
                        <Button className="btn btn-icon btn-success mr-2 mb-2">
                          <i className="mdi mdi-link" />
                        </Button>
                        <Button className="btn btn-icon btn-info mr-2 mb-2">
                          <i className="mdi mdi-lock-open-outline" />
                        </Button>
                        <Button className="btn btn-icon btn-warning mr-2 mb-2">
                          <i className="mdi mdi-refresh" />
                        </Button>
                        <Button className="btn btn-icon btn-danger mr-2 mb-2">
                          <i className="mdi mdi-heart-outline" />
                        </Button>
                      </div>
                      <div className="mt-2 mb-4">
                        <p>Use <code>btn-outline</code> to create a outline style.</p>
                        <Button className="btn btn-icon btn-outline btn-primary mr-2 mb-2">
                          <i className="mdi mdi-message-outline" />
                        </Button>
                        <Button className="btn btn-icon btn-outline btn-success mr-2 mb-2">
                          <i className="mdi mdi-link" />
                        </Button>
                        <Button className="btn btn-icon btn-outline btn-info mr-2 mb-2">
                          <i className="mdi mdi-lock-open-outline" />
                        </Button>
                        <Button className="btn btn-icon btn-outline btn-warning mr-2 mb-2">
                          <i className="mdi mdi-refresh" />
                        </Button>
                        <Button className="btn btn-icon btn-outline btn-danger mr-2 mb-2">
                          <i className="mdi mdi-heart-outline" />
                        </Button>
                      </div>
                      <div className="mt-2 mb-4">
                        <p>applies <code>.btn-rounded</code> to make round style</p>
                        <div className="m-t-25">
                          <Button className="btn btn-icon btn-rounded btn-info mr-2 mb-2">
                            <i className="mdi mdi-check" />
                          </Button>
                          <Button className="btn btn-icon btn-rounded btn-primary mr-2 mb-2">
                            <i className="mdi mdi-message-outline" />
                          </Button>
                          <Button className="btn btn-icon btn-rounded btn-success mr-2 mb-2">
                            <i className="mdi mdi-link" />
                          </Button>
                          <Button className="btn btn-icon btn-rounded btn-info mr-2 mb-2">
                            <i className="mdi mdi-lock-open-outline" />
                          </Button>
                          <Button className="btn btn-icon btn-rounded btn-warning mr-2 mb-2">
                            <i className="mdi mdi-refresh" />
                          </Button>
                          <Button className="btn btn-icon btn-rounded btn-danger mr-2 mb-2">
                            <i className="mdi mdi-heart-outline" />
                          </Button>
                        </div>
                      </div>
                      <div className="mt-2 mb-4">
                        <p>applies <code>.btn-float</code> to make float style</p>
                        <div className="m-t-25">
                          <Button className="btn btn-icon btn-float btn-default mr-2 mb-2">
                            <i className="mdi mdi-check" />
                          </Button>
                          <Button className="btn btn-icon btn-float btn-primary mr-2 mb-2">
                            <i className="mdi mdi-message-outline" />
                          </Button>
                          <Button className="btn btn-icon btn-float btn-success mr-2 mb-2">
                            <i className="mdi mdi-link" />
                          </Button>
                          <Button className="btn btn-icon btn-float btn-info mr-2 mb-2">
                            <i className="mdi mdi-lock-open-outline" />
                          </Button>
                          <Button className="btn btn-icon btn-float btn-warning mr-2 mb-2">
                            <i className="mdi mdi-refresh" />
                          </Button>
                          <Button className="btn btn-icon btn-float btn-danger mr-2 mb-2">
                            <i className="mdi mdi-heart-outline" />
                          </Button>
                        </div>
                      </div>
                      <div className="mt-2 mb-4">
                        <p>applies <code>.btn-outline</code> &amp; <code>.btn-rounded</code> to make round ouline style</p>
                        <div className="m-t-25">
                          <Button className="btn btn-icon btn-rounded btn-outline btn-default mr-2 mb-2">
                            <i className="mdi mdi-check" />
                          </Button>
                          <Button className="btn btn-icon btn-rounded btn-outline btn-primary mr-2 mb-2">
                            <i className="mdi mdi-message-outline" />
                          </Button>
                          <Button className="btn btn-icon btn-rounded btn-outline btn-success mr-2 mb-2">
                            <i className="mdi mdi-link" />
                          </Button>
                          <Button className="btn btn-icon btn-rounded btn-outline btn-info mr-2 mb-2">
                            <i className="mdi mdi-lock-open-outline" />
                          </Button>
                          <Button className="btn btn-icon btn-rounded btn-outline btn-warning mr-2 mb-2">
                            <i className="mdi mdi-refresh" />
                          </Button>
                          <Button className="btn btn-icon btn-rounded btn-outline btn-danger mr-2 mb-2">
                            <i className="mdi mdi-heart-outline" />
                          </Button>
                        </div>
                      </div>
                    </Col>
                    <Col md={8} className="mb-2 mt-2">
                      <h4>Social Buttons</h4>
                      <div className="mt-2 mb-4">
                        <p>Use <code>btn-icon</code> to create a icon Button.</p>
                        <Button className="btn btn-icon btn-facebook mr-2 mb-2">
                          <i className="mdi mdi-facebook" />
                        </Button>
                        <Button className="btn btn-icon btn-twitter mr-2 mb-2">
                          <i className="mdi mdi-twitter" />
                        </Button>
                        <Button className="btn btn-icon btn-google-plus mr-2 mb-2">
                          <i className="mdi mdi-google-plus" />
                        </Button>
                        <Button className="btn btn-icon btn-instagram mr-2 mb-2">
                          <i className="mdi mdi-instagram" />
                        </Button>
                        <Button className="btn btn-icon btn-dropbox mr-2 mb-2">
                          <i className="mdi mdi-dropbox" />
                        </Button>
                        <Button className="btn btn-icon btn-dribbble mr-2 mb-2">
                          <i className="mdi mdi-dribbble" />
                        </Button>
                        <Button className="btn btn-icon btn-skype mr-2 mb-2">
                          <i className="mdi mdi-skype" />
                        </Button>
                        <Button className="btn btn-icon btn-youtube mr-2 mb-2">
                          <i className="mdi mdi-youtube-play" />
                        </Button>
                        <Button className="btn btn-icon btn-pinterest mr-2 mb-2">
                          <i className="mdi mdi-pinterest" />
                        </Button>
                        <Button className="btn btn-icon btn-behance mr-2 mb-2">
                          <i className="mdi mdi-behance" />
                        </Button>
                        <Button className="btn btn-icon btn-html5 mr-2 mb-2">
                          <i className="mdi mdi-language-html5" />
                        </Button>
                        <Button className="btn btn-icon btn-linkedin mr-2 mb-2">
                          <i className="mdi mdi-linkedin" />
                        </Button>
                        <Button className="btn btn-icon btn-wordpress mr-2 mb-2">
                          <i className="mdi mdi-wordpress" />
                        </Button>
                        <Button className="btn btn-icon btn-tumblr mr-2 mb-2">
                          <i className="mdi mdi-tumblr" />
                        </Button>
                      </div>
                      <div className="mt-2 mb-4">
                        <p>Use <code>btn-outline</code> to create a outline style.</p>
                        <Button className="btn btn-icon btn-facebook btn-outline mr-2 mb-2">
                          <i className="mdi mdi-facebook" />
                        </Button>
                        <Button className="btn btn-icon btn-twitter btn-outline mr-2 mb-2">
                          <i className="mdi mdi-twitter" />
                        </Button>
                        <Button className="btn btn-icon btn-google-plus btn-outline mr-2 mb-2">
                          <i className="mdi mdi-google-plus" />
                        </Button>
                        <Button className="btn btn-icon btn-instagram btn-outline mr-2 mb-2">
                          <i className="mdi mdi-instagram" />
                        </Button>
                        <Button className="btn btn-icon btn-dropbox btn-outline mr-2 mb-2">
                          <i className="mdi mdi-dropbox" />
                        </Button>
                        <Button className="btn btn-icon btn-dribbble btn-outline mr-2 mb-2">
                          <i className="mdi mdi-dribbble" />
                        </Button>
                        <Button className="btn btn-icon btn-skype btn-outline mr-2 mb-2">
                          <i className="mdi mdi-skype" />
                        </Button>
                        <Button className="btn btn-icon btn-youtube btn-outline mr-2 mb-2">
                          <i className="mdi mdi-youtube-play" />
                        </Button>
                        <Button className="btn btn-icon btn-pinterest btn-outline mr-2 mb-2">
                          <i className="mdi mdi-pinterest" />
                        </Button>
                        <Button className="btn btn-icon btn-behance btn-outline mr-2 mb-2">
                          <i className="mdi mdi-behance" />
                        </Button>
                        <Button className="btn btn-icon btn-html5 btn-outline mr-2 mb-2">
                          <i className="mdi mdi-language-html5" />
                        </Button>
                        <Button className="btn btn-icon btn-linkedin btn-outline mr-2 mb-2">
                          <i className="mdi mdi-linkedin" />
                        </Button>
                        <Button className="btn btn-icon btn-wordpress btn-outline mr-2 mb-2">
                          <i className="mdi mdi-wordpress" />
                        </Button>
                        <Button className="btn btn-icon btn-tumblr btn-outline mr-2 mb-2">
                          <i className="mdi mdi-tumblr" />
                        </Button>
                      </div>
                      <div className="mt-2 mb-4">
                        <p>applies <code>.btn-rounded</code> to make round style</p>
                        <Button className="btn btn-icon btn-facebook btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-facebook" />
                        </Button>
                        <Button className="btn btn-icon btn-twitter btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-twitter" />
                        </Button>
                        <Button className="btn btn-icon btn-google-plus btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-google-plus" />
                        </Button>
                        <Button className="btn btn-icon btn-instagram btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-instagram" />
                        </Button>
                        <Button className="btn btn-icon btn-dropbox btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-dropbox" />
                        </Button>
                        <Button className="btn btn-icon btn-dribbble btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-dribbble" />
                        </Button>
                        <Button className="btn btn-icon btn-skype btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-skype" />
                        </Button>
                        <Button className="btn btn-icon btn-youtube btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-youtube-play" />
                        </Button>
                        <Button className="btn btn-icon btn-pinterest btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-pinterest" />
                        </Button>
                        <Button className="btn btn-icon btn-behance btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-behance" />
                        </Button>
                        <Button className="btn btn-icon btn-html5 btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-language-html5" />
                        </Button>
                        <Button className="btn btn-icon btn-linkedin btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-linkedin" />
                        </Button>
                        <Button className="btn btn-icon btn-wordpress btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-wordpress" />
                        </Button>
                        <Button className="btn btn-icon btn-tumblr btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-tumblr" />
                        </Button>
                      </div>
                      <div className="mt-2 mb-4">
                        <p>applies <code>.btn-float</code> to make float style</p>
                        <Button className="btn btn-icon btn-facebook btn-float mr-2 mb-2">
                          <i className="mdi mdi-facebook" />
                        </Button>
                        <Button className="btn btn-icon btn-twitter btn-float mr-2 mb-2">
                          <i className="mdi mdi-twitter" />
                        </Button>
                        <Button className="btn btn-icon btn-google-plus btn-float mr-2 mb-2">
                          <i className="mdi mdi-google-plus" />
                        </Button>
                        <Button className="btn btn-icon btn-instagram btn-float mr-2 mb-2">
                          <i className="mdi mdi-instagram" />
                        </Button>
                        <Button className="btn btn-icon btn-dropbox btn-float mr-2 mb-2">
                          <i className="mdi mdi-dropbox" />
                        </Button>
                        <Button className="btn btn-icon btn-dribbble btn-float mr-2 mb-2">
                          <i className="mdi mdi-dribbble" />
                        </Button>
                        <Button className="btn btn-icon btn-skype btn-float mr-2 mb-2">
                          <i className="mdi mdi-skype" />
                        </Button>
                        <Button className="btn btn-icon btn-youtube btn-float mr-2 mb-2">
                          <i className="mdi mdi-youtube-play" />
                        </Button>
                        <Button className="btn btn-icon btn-pinterest btn-float mr-2 mb-2">
                          <i className="mdi mdi-pinterest" />
                        </Button>
                        <Button className="btn btn-icon btn-behance btn-float mr-2 mb-2">
                          <i className="mdi mdi-behance" />
                        </Button>
                        <Button className="btn btn-icon btn-html5 btn-float mr-2 mb-2">
                          <i className="mdi mdi-language-html5" />
                        </Button>
                        <Button className="btn btn-icon btn-linkedin btn-float mr-2 mb-2">
                          <i className="mdi mdi-linkedin" />
                        </Button>
                        <Button className="btn btn-icon btn-wordpress btn-float mr-2 mb-2">
                          <i className="mdi mdi-wordpress" />
                        </Button>
                        <Button className="btn btn-icon btn-tumblr btn-float mr-2 mb-2">
                          <i className="mdi mdi-tumblr" />
                        </Button>
                      </div>
                      <div className="mt-2 mb-4">
                        <p>applies <code>.btn-outline</code> &amp; <code>.btn-rounded</code> to make round ouline style</p>
                        <Button className="btn btn-icon btn-facebook btn-outline btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-facebook" />
                        </Button>
                        <Button className="btn btn-icon btn-twitter btn-outline btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-twitter" />
                        </Button>
                        <Button className="btn btn-icon btn-google-plus btn-outline btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-google-plus" />
                        </Button>
                        <Button className="btn btn-icon btn-instagram btn-outline btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-instagram" />
                        </Button>
                        <Button className="btn btn-icon btn-dropbox btn-outline btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-dropbox" />
                        </Button>
                        <Button className="btn btn-icon btn-dribbble btn-outline btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-dribbble" />
                        </Button>
                        <Button className="btn btn-icon btn-skype btn-outline btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-skype" />
                        </Button>
                        <Button className="btn btn-icon btn-youtube btn-outline btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-youtube-play" />
                        </Button>
                        <Button className="btn btn-icon btn-pinterest btn-outline btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-pinterest" />
                        </Button>
                        <Button className="btn btn-icon btn-behance btn-outline btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-behance" />
                        </Button>
                        <Button className="btn btn-icon btn-html5 btn-outline btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-language-html5" />
                        </Button>
                        <Button className="btn btn-icon btn-linkedin btn-outline btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-linkedin" />
                        </Button>
                        <Button className="btn btn-icon btn-wordpress btn-outline btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-wordpress" />
                        </Button>
                        <Button className="btn btn-icon btn-tumblr btn-outline btn-rounded mr-2 mb-2">
                          <i className="mdi mdi-tumblr" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Buttons;