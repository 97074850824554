import React from 'react';
import "./chat.css";
import { Scrollbars } from "react-custom-scrollbars";

import { Link } from 'react-router-dom';
import { Row, Col, NavItem, Nav, Dropdown, Breadcrumb, BreadcrumbItem, DropdownItem, DropdownMenu, DropdownToggle, TabContent, TabPane } from 'reactstrap';

class ChatApp extends React.Component {
  constructor(props) {
    super(props); this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      name: 'Darci Stewart',
      chatdropdown: false,
      dropdownbarOpen: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  toggle(tab, name) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        name: name
      });
    }
  }

  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Chat</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">App</a>
                <span className="breadcrumb-item active">Chat</span>
              </nav>
            </div>
          </div>
        </div>

        <Row className="chat chat-app borderless-shadow">
          <Col lg={4} style={{padding: 0}} className="col-to-add-margin-bottom">
            <div className="chat-list w-100" style={{height: '100%'}}>
              <div className="chat-user-tool">
                <i className="search-icon mdi mdi-magnify pr-2 font-size-18" />
                <input placeholder="Search" />
              </div>

              <Scrollbars
                autoHeight
                autoHeightMin={200}
                autoHeightMax={530}
                className="chat-user-list"
              >
                <div className="mb-3 mt-3">
                  <h6 className="text-semibold ml-3">Online</h6>
                  <ul className="list-media">
                    <li className="list-item unread-msg">
                      <a onClick={() => { this.toggle('1', 'Darci Stewart'); }} className="conversation-toggler media-hover p-h-20">
                        <div className="media-img">
                          <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                          <span className="status success" />
                        </div>
                        <div className="info">
                          <span className="title">Darci Stewart</span>
                          <span className="sub-title">Here's the prototye...</span>
                          <div className="float-item">
                            <span className="chat-counter">2</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li className="list-item unread-msg active">
                      <a onClick={() => { this.toggle('2', 'Clevin Hope'); }} className="conversation-toggler media-hover p-h-20">
                        <div className="media-img">
                          <img src={require(`../../../assets/images/profiles/profile-pic-2.png`)} />
                          <span className="status success" />
                        </div>
                        <div className="info">
                          <span className="title">Clevin Hope</span>
                          <span className="sub-title">Do you see any Teletubbies...</span>
                          <div className="float-item">
                            <span className="chat-counter">1</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li className="list-item">
                      <a onClick={() => { this.toggle('3', 'Lumi Holmes'); }} className="conversation-toggler media-hover p-h-20">
                        <div className="media-img">
                          <img src={require(`../../../assets/images/profiles/profile-pic-4.jpg`)} />
                          <span className="status success" />
                        </div>
                        <div className="info">
                          <span className="title">Lumi Holmes</span>
                          <span className="sub-title">Oh my God. I didn't even...</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="mb-3">
                  <h6 className="text-semibold ml-3">Away</h6>
                  <ul className="list-media">
                    <li className="list-item">
                      <a onClick={() => { this.toggle('4', 'Pedro Gonzales'); }} className="conversation-toggler media-hover p-h-20">
                        <div className="media-img">
                          <img src={require(`../../../assets/images/profiles/profile-pic-1.jpg`)} />
                          <span className="status away" />
                        </div>
                        <div className="info">
                          <span className="title p-t-10">Pedro Gonzales</span>
                        </div>
                      </a>
                    </li>
                    <li className="list-item">
                      <a onClick={() => { this.toggle('5', 'Kacy Brewster'); }} className="conversation-toggler media-hover p-h-20">
                        <div className="media-img">
                          <img src={require(`../../../assets/images/profiles/profile-pic-8.jpg`)} />
                          <span className="status away" />
                        </div>
                        <div className="info">
                          <span className="title p-t-10">Kacy Brewster</span>
                        </div>
                      </a>
                    </li>
                    <li className="list-item">
                      <a onClick={() => { this.toggle('6', 'Ollie Beans'); }} className="conversation-toggler media-hover">
                        <div className="media-img">
                          <img src={require(`../../../assets/images/profiles/profile-pic-2.png`)} />
                          <span className="status away" />
                        </div>
                        <div className="info">
                          <span className="title p-t-10">Ollie Beans</span>
                        </div>
                      </a>
                    </li>
                    <li className="list-item">
                      <a onClick={() => { this.toggle('7', 'John Douglas'); }} className="conversation-toggler media-hover p-h-20">
                        <div className="media-img">
                          <img src={require(`../../../assets/images/profiles/profile-pic-3.jpeg`)} />
                          <span className="status away" />
                        </div>
                        <div className="info">
                          <span className="title p-t-10">John Douglas</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="mb-3">
                  <h6 className="text-semibold ml-3">Busy</h6>
                  <ul className="list-media">
                    <li className="list-item">
                      <a onClick={() => { this.toggle('8', 'Anita Phogat'); }} className="conversation-toggler media-hover p-h-20">
                        <div className="media-img">
                          <img src={require(`../../../assets/images/profiles/profile-pic-9.jpg`)} />
                          <span className="status busy" />
                        </div>
                        <div className="info">
                          <span className="title">Anita Phogat</span>
                        </div>
                      </a>
                    </li>
                    <li className="list-item">
                      <a onClick={() => { this.toggle('9', 'Chung Lee'); }} className="conversation-toggler media-hover p-h-20">
                        <div className="media-img">
                          <img src={require(`../../../assets/images/profiles/profile-pic-5.jpg`)} />
                          <span className="status busy" />
                        </div>
                        <div className="info">
                          <span className="title">Chung Lee</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </Scrollbars>
            </div>
          </Col>

          <Col lg={8} style={{ padding: 0 }}>
            <div className="chat-content w-100">
              <div className="conversation">
                <div className="conversation-wrapper">

                  <div className="conversation-header">
                    <span className="recipient">Darci Stewart</span>
                    <ul className="tools">
                      <li>
                        <a className="text-gray" href="#">
                          <i className="mdi mdi-dots-vertical" />
                        </a>
                      </li>
                      <li className="d-md-none">
                        <a className="text-gray conversation-toggler" href="#">
                          <i className="mdi mdi-chevron-right" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  
                  <div className="conversation-body pt-0 pr-0">
                    <Scrollbars
                      autoHeight
                      autoHeightMin={200}
                      autoHeightMax={450}
                      className="pt-0"
                    >
                      <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                          <div className="msg datetime">
                            <span>10:32AM</span>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-2.png`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Hi Good morning, how are u?</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Hello a very good morning, i'm doing awesome</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-2.png`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Any tight schedules?</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Not really, u?</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-2.png`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Yeah a bit busy after lunch</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg datetime">
                            <span>7:15PM</span>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-2.png`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Works done! Bye</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Great!! CYA</span>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <div className="msg datetime">
                            <span>10:57PM</span>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-4.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Hey Manoj, what are you doing?</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Texting the most beautiful girl in the world.</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-4.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Oh? How Cute</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Yup but she's not replying so texting you</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-4.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Okay</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg datetime">
                            <span>8:05PM</span>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-4.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Bye</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>I'm just kidding..!!</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Hello</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>It's me..can you hear me..!!</span>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="3">
                          <div className="msg datetime">
                            <span>7:57PM</span>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                            <img src={require(`../../../assets/images/profiles/profile-pic-5.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Hey, what are you doing?</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Texting the most beautiful girl in the world.</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-5.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Oh? How Cute</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Yup but she's not replying so texting you</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-5.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Okay</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg datetime">
                            <span>8:05PM</span>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-5.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Bye</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>I'm just kidding..!!</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Hello</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>It's me..can you hear me..!!</span>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="4">
                          <div className="msg datetime">
                            <span>7:57PM</span>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                            <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Hey, what are you doing?</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Texting the most beautiful girl in the world.</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Oh? How Cute</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Yup but she's not replying so texting you</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Okay</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg datetime">
                            <span>8:05PM</span>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Bye</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>I'm just kidding..!!</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Hello</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>It's me..can you hear me..!!</span>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="5">
                          <div className="msg datetime">
                            <span>7:57PM</span>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Hey, what are you doing?</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Texting the most beautiful girl in the world.</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Oh? How Cute</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Yup but she's not replying so texting you</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Okay</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg datetime">
                            <span>8:05PM</span>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Bye</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>I'm just kidding..!!</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Hello</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>It's me..can you hear me..!!</span>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="6">
                          <div className="msg datetime">
                            <span>7:57PM</span>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Hey, what are you doing?</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Texting the most beautiful girl in the world.</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Oh? How Cute</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Yup but she's not replying so texting you</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Okay</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg datetime">
                            <span>8:05PM</span>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Bye</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>I'm just kidding..!!</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Hello</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>It's me..can you hear me..!!</span>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="7">
                          <div className="msg datetime">
                            <span>7:57PM</span>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Hey, what are you doing?</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Texting the most beautiful girl in the world.</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Oh? How Cute</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Yup but she's not replying so texting you</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Okay</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg datetime">
                            <span>8:05PM</span>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Bye</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>I'm just kidding..!!</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Hello</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>It's me..can you hear me..!!</span>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="8">
                          <div className="msg datetime">
                            <span>7:57PM</span>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Hey, what are you doing?</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Texting the most beautiful girl in the world.</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Oh? How Cute</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Yup but she's not replying so texting you</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Okay</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg datetime">
                            <span>8:05PM</span>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Bye</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>I'm just kidding..!!</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Hello</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>It's me..can you hear me..!!</span>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="9">
                          <div className="msg datetime">
                            <span>7:57PM</span>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Hey, what are you doing?</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Texting the most beautiful girl in the world.</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Oh? How Cute</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Yup but she's not replying so texting you</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Okay</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg datetime">
                            <span>8:05PM</span>
                          </div>
                          <div className="msg msg-recipient">
                            <div className="user-img">
                              <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                            </div>
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Bye</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>I'm just kidding..!!</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>Hello</span>
                              </div>
                            </div>
                          </div>
                          <div className="msg msg-sent">
                            <div className="bubble">
                              <div className="bubble-wrapper">
                                <span>It's me..can you hear me..!!</span>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                      </TabContent>

                    </Scrollbars>
                  </div>
                  
                  <div className="conversation-footer">
                    <button className="upload-btn">
                      <i className="ti-face-smile" />
                    </button>
                    <input className="chat-input" type="text" placeholder="Type a message..." />
                    <button className="sent-btn">
                      <i className="far fa-paper-plane" />
                    </button>
                  </div>
                
                </div>
              </div>
            </div>
          </Col>

        </Row>

      </div>
    );
  }
}

export default ChatApp;