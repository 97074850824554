import React from "react";
import "./form-validation.css";

import { Input, InputGroup, InputGroupAddon, InputGroupText, Button, ButtonDropdown, FormGroup, Label, DropdownMenu, DropdownToggle, DropdownItem, CardBody, CardHeader, Card, CardTitle, Row, Col, Alert, UncontrolledAlert, Breadcrumb, BreadcrumbItem, Form, FormFeedback, FormText } from 'reactstrap';

class FormValidation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nameField: '',
      emailField: '',
      messageFiled: ''
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  handleSumbit(event) {
    event.preventDefault();
  }

  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Form Elements</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">Forms</a>
                <span className="breadcrumb-item active">Form Elements</span>
              </nav>
            </div>
          </div>

          <Row>
            <Col md={6} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardHeader>
                  <CardTitle>
                    <h5 className="mb-0">Default Validation</h5>
                  </CardTitle>
                </CardHeader>
                <CardBody className="pt-4 pb-5">
                  <Form>
                    <FormGroup>
                      <Label htmlFor="name1" className="mb-2">Name</Label>
                      <Input type="text" name="name" id="name1" required />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="email1" className="mb-2">Email</Label>
                      <Input id="email1" type="email" name="email1" required />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="address1" className="mb-2">Address</Label>
                      <Input id="address1" type="text" name="address1" />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="message" className="mb-2">Message</Label>
                      <Input type="textarea" id="message" rows={3} defaultValue={""} required />
                    </FormGroup>

                    <FormGroup>
                      <div>
                        <button className="btn btn-gradient-card-blue mr-2">Submit</button>
                      </div>
                    </FormGroup>

                  </Form>

                </CardBody>
              </Card>
            </Col>

            <Col md={6} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardHeader>
                  <CardTitle>
                    <h5 className="mb-0">Server-side Validation</h5>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormGroup>
                      <Label for="name2" className="mb-2">Name</Label>
                      <Input id="name2" defaultValue="John Smith" valid />
                      <FormFeedback valid>Sweet! that name is available</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label for="exampleEmail" className="mb-2">Email</Label>
                      <Input invalid required/>
                      <FormFeedback>Error! Email is invalid</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="address2" className="mb-2">Address</Label>
                      <Input id="address2" type="text" name="address2" />
                    </FormGroup>

                    <FormGroup>
                      <Label className="mb-2">Message</Label>
                      <Input type="textarea" invalid required/>
                      <FormFeedback>Please provide some message</FormFeedback>
                    </FormGroup>

                    <FormGroup className="mb-0">
                      <div>
                        <button className="btn btn-gradient-card-blue mr-2">Submit</button>
                      </div>
                    </FormGroup>

                  </Form>

                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={6} className="d-flex">
              <Card className="borderless-shadow w-100 mb-0">
                <CardHeader>
                  <CardTitle>
                    <h5 className="mb-0">Javascript Validation</h5>
                  </CardTitle>
                </CardHeader>

                <CardBody>
                  <Form onSubmit={this.handleSumbit.bind(this)}>
                    <FormGroup>
                      <Label for="namevalidate" className="mb-2">Name</Label>
                      <Input
                        id="namevalidate"
                        name="nameValidate"
                        type="text"
                        onChange={(event) => this.setState({ nameField: event.target.value })}
                        valid={this.state.nameField ? true : false}
                        invalid={this.state.nameField ? false : true}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="emailValidate" className="mb-2">Email</Label>
                      <Input
                        id="emailValidate"
                        name="emailValidate"
                        type="email"
                        onChange={(event) => this.setState({ emailField: event.target.value })}
                        valid={
                          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.emailField) ? true : false
                        }
                        invalid={
                          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.emailField) ? false : true
                        }
                      />
                      <FormFeedback>Error! Email is invalid</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label className="mb-2">Address</Label>
                      <Input type="text" />
                    </FormGroup>

                    <FormGroup>
                      <Label for="messageValidate" className="mb-2">Message</Label>
                      <Input
                        id="messageValidate"
                        type="textarea"
                        onChange={(event) => this.setState({ messageFiled: event.target.value })}
                        valid={this.state.messageFiled ? true : false}
                        invalid={this.state.messageFiled ? false : true}
                      />
                      <FormFeedback>Please provide some message</FormFeedback>
                    </FormGroup>

                    <FormGroup className="mb-0">
                      <div>
                        <button className="btn btn-gradient-card-blue mr-2">Submit</button>
                      </div>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          
        </div>
      </div>
    );
  }
}

export default FormValidation;