import React from "react";
import "./dropdown.css";

import { CardBody, CardHeader, Card, CardTitle, Row, Col, Alert, UncontrolledAlert, Breadcrumb, BreadcrumbItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class Dropdowns extends React.Component {
  constructor(props) {
    super(props);
    this.nocaretdemo = this.nocaretdemo.bind(this);
    this.dropdownright = this.dropdownright.bind(this);
    this.dropdownup = this.dropdownup.bind(this);
    this.dropdownlg = this.dropdownlg.bind(this);
    this.dropdownsm = this.dropdownsm.bind(this);
    this.dropdownmd = this.dropdownmd.bind(this);

    this.state = {
      nocaretdemo: false,
      dropdownlg: false,
      dropdownsm: false,
      dropdownmd: false,
      dropdownright: false,
      dropdownup: false
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  dropdownlg() {
    this.setState(prevState => ({
      dropdownlg: !prevState.dropdownlg
    }));
  }
  dropdownsm() {
    this.setState(prevState => ({
      dropdownsm: !prevState.dropdownsm
    }));
  }
  dropdownmd() {
    this.setState(prevState => ({
      dropdownmd: !prevState.dropdownmd
    }));
  }
  nocaretdemo() {
    this.setState(prevState => ({
      nocaretdemo: !prevState.nocaretdemo
    }));
  }
  dropdownright() {
    this.setState(prevState => ({
      dropdownright: !prevState.dropdownright
    }));
  }

  dropdownup() {
    this.setState(prevState => ({
      dropdownup: !prevState.dropdownup
    }));
  }

  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Dropdown</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">Components</a>
                <span className="breadcrumb-item active">Dropdown</span>
              </nav>
            </div>
          </div>
          
          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle><h5 className="mb-0">Dropdown</h5></CardTitle>
            </CardHeader>
            <CardBody>
              <Row className="mb-5">
                <Col md={4} className="mb-4">
                  <h5 className="mb-3">Basic Dropdown</h5>
                  <p>Use <code>"DropdownToggle"</code> component along with <code>"DropdownMenu"</code> component inside of 'Dropdown' component. Dropdown component uses <code>'isOpen' and 'toggle'</code> props. The DropdownToggle uses the Button component internally.</p>
                  <div className="mt-2">
                    <Dropdown isOpen={this.state.nocaretdemo} toggle={this.nocaretdemo}>
                      <DropdownToggle caret className="btn btn-gradient-card-blue">
                        Dropdown
                      </DropdownToggle>
                      <DropdownMenu className="custom-dropdown">
                        <DropdownItem>Action</DropdownItem>
                        <DropdownItem>Another Action</DropdownItem>
                        <DropdownItem >Something else here</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>Separated link</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </Col>

                <Col md={4} className="mb-4">
                  <h5 className="mb-3">Right Menu alignment</h5>
                  <p>By default, a dropdown menu is automatically positioned 100% from the top and along the left side of its parent. Add <code>direction="right"</code> prop value to <code>Dropdown</code> component to right align the dropdown menu.</p>
                  <div className="mt-2">
                    <Dropdown isOpen={this.state.dropdownright} direction="right" toggle={this.dropdownright}>
                      <DropdownToggle caret className="btn btn-gradient-card-blue">
                        Right Dropdown
                      </DropdownToggle>
                      <DropdownMenu className="custom-dropdown">

                        <DropdownItem>Action</DropdownItem>
                        <DropdownItem>Another Action</DropdownItem>
                        <DropdownItem >Something else here</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>Separated link</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </Col>

                <Col md={4} className="mb-4">
                  <h5 className="mb-3">Dropup variation</h5>
                  <p>Trigger dropdown menus above elements by adding <code>direction="up"</code> prop value to the <code>Dropdown</code> component.</p>
                  <div className="mt-2">
                    <Dropdown isOpen={this.state.dropdownup} direction="up" toggle={this.dropdownup}>
                      <DropdownToggle caret className="btn btn-gradient-card-blue">
                        Dropup button
                      </DropdownToggle>
                      <DropdownMenu className="custom-dropdown">

                        <DropdownItem>Action</DropdownItem>
                        <DropdownItem>Another Action</DropdownItem>
                        <DropdownItem >Something else here</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>Separated link</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col md={4} className="mb-4">
                  <h5 className="mb-3">Large Dropdown</h5>
                  <p>Add <code>size="lg"</code> prop value to <code>Dropdown</code> component to make dropdown's size bigger.</p>
                  <div className="mt-2">
                    <Dropdown group isOpen={this.state.dropdownlg} size="lg" toggle={this.dropdownlg}>
                      <DropdownToggle caret className="btn btn-gradient-card-blue">
                        Large Dropdown
                      </DropdownToggle>
                      <DropdownMenu className="custom-dropdown">
                        <DropdownItem>Action</DropdownItem>
                        <DropdownItem>Another Action</DropdownItem>
                        <DropdownItem >Something else here</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>Separated link</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </Col>

                <Col md={4} className="mb-4">
                  <h5 className="mb-3">Medium Dropdown</h5>
                  <p>By default, a dropdown menu is automatically sized to medium. Therefore no need to mention size, even though you can add <code>size="md"</code> prop value to <code>Dropdown</code> component to make it sized medium.</p>
                  <div className="mt-2">
                    <Dropdown isOpen={this.state.dropdownmd} size="md" toggle={this.dropdownmd}>
                      <DropdownToggle caret className="btn btn-gradient-card-blue">
                        Medium Dropdown
                            </DropdownToggle>
                      <DropdownMenu className="custom-dropdown">

                        <DropdownItem>Action</DropdownItem>
                        <DropdownItem>Another Action</DropdownItem>
                        <DropdownItem >Something else here</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>Separated link</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </Col>

                <Col md={4} className="">
                  <h5 className="mb-3">Small Dropdown</h5>
                  <p>Add <code>size="sm"</code> prop value to <code>Dropdown</code> component to make it sized medium.</p>
                  <div className="mt-2">
                    <Dropdown isOpen={this.state.dropdownsm} size="sm" toggle={this.dropdownsm}>
                      <DropdownToggle caret className="btn btn-gradient-card-blue">
                        Small Dropdown
                      </DropdownToggle>
                      <DropdownMenu className="custom-dropdown" >
                        <DropdownItem>Action</DropdownItem>
                        <DropdownItem>Another Action</DropdownItem>
                        <DropdownItem >Something else here</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>Separated link</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card className="borderless-shadow mb-0">
            <CardHeader>
              <CardTitle><h5 className="mb-0">Dropdown Menu</h5></CardTitle>
            </CardHeader>
            <CardBody>
              <div className="row mb-5">
                <Col md={4} className="mb-4">
                  <h5 className="mb-3">Menu Header</h5>
                  <p>Add a header to label sections of actions in any dropdown menu.</p>
                  <div className="mt-20">
                    <DropdownMenu className="d-block custom-dropdown">
                      {/* Remove .d-block & .relative when copy */}
                      <h6 className="dropdown-header">Dropdown header</h6>
                      <DropdownItem>Action</DropdownItem>
                      <DropdownItem>Another action</DropdownItem>
                    </DropdownMenu>
                  </div>
                </Col>
                <Col md={4} className="mb-4">
                  <h5 className="mb-3">Menu dividers</h5>
                  <p>Add <code>"divider"</code> prop to DropdownItem component classname to Add a divider between any of the DropdownItem of the DropdownMenu.</p>
                  <div className="mt-20">
                    <DropdownMenu className="d-block custom-dropdown">
                      {/* Remove .d-block & .relative when copy */}
                      <DropdownItem>Action</DropdownItem>
                      <DropdownItem>Another action</DropdownItem>
                      <div className="dropdown-divider" />
                      <DropdownItem>Separated link</DropdownItem>
                    </DropdownMenu>
                  </div>
                </Col>
                <Col md={4} className="mb-4">
                  <h5 className="mb-3">Disabled Menu Items</h5>
                  <p>Add <code>'disabled'</code> prop value to DropdownItem that you want to disable</p>
                  <div className="mt-20">
                    <DropdownMenu className="d-block custom-dropdown">
                      {/* Remove .d-block & .relative when copy */}
                      <DropdownItem>Action</DropdownItem>
                      <DropdownItem disabled>Disabled action</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem>Something else here</DropdownItem>
                    </DropdownMenu>
                  </div>
                </Col>
              </div>
              <div className="row mb-4">
                <Col md={4} className="mb-4">
                  <h5 className="mb-3">Actived Menu Items</h5>
                  <p>Add <code>.active</code> to a <code>DropdownItem</code> component in the DropdownMenu component to active the link.</p>
                  <div className="mt-20">
                    <DropdownMenu className="d-block custom-dropdown">
                      {/* Remove .d-block & .relative when copy */}
                      <DropdownItem>Action</DropdownItem>
                      <DropdownItem className="active">Another action</DropdownItem>
                      <DropdownItem>Something else here</DropdownItem>
                    </DropdownMenu>
                  </div>
                </Col>
                <Col md={4} className="mb-4">
                  <h5 className="mb-3">Icons Menu Items</h5>
                  <p>Use bootstrap icon including format <code>&lt;i className="fa fa-cog pr-2" &gt;</code> to include icon to your dropdown item.</p>
                  <div className="mt-20">
                    <DropdownMenu className="d-block custom-dropdown">
                      {/* Remove .d-block & .relative when copy */}
                      <DropdownItem>
                        <i className="fa fa-cog pr-2" />
                        <span>Setting</span>
                      </DropdownItem>
                      <DropdownItem>
                        <i className="fa fa-user pr-2" />
                        <span>Profile</span>
                      </DropdownItem>
                      <DropdownItem>
                        <i className="fa fa-envelope pr-2" />
                        <span>Inbox</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </div>
                </Col>
                <Col md={4} className="">
                  <h5 className="mb-3">Menu Items Badges</h5>
                  <p>Use <code>badge badge-pill badge-gradient-info pull-right</code> classnames to include badge to your dropdown item.</p>
                  <div className="mt-20">
                    <DropdownMenu className="d-block custom-dropdown">
                      {/* Remove .d-block & .relative when copy */}
                      <DropdownItem>
                        <span>Action</span>
                        <span className="badge badge-pill badge-gradient-success pull-right">3</span>
                      </DropdownItem>
                      <DropdownItem>
                        <span>Another action</span>
                        <span className="badge badge-pill badge-gradient-info pull-right">1</span>
                      </DropdownItem>
                      <DropdownItem>
                        <span>Inbox</span>
                        <span className="badge badge-pill badge-gradient-primary pull-right">2</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </div>
                </Col>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default Dropdowns;