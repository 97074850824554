import React from "react";
import "./navbar.css";

import {
  Row, Col, Card, CardBody, CardTitle, Collapse, Navbar, NavbarToggler,  NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarText
} from 'reactstrap';

class Navbars extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleNavbar = this.toggleNavbar.bind(this);

    this.state = {
      isOpen: false,
      collapsed: true
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Navbar</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1"></i>Home</a>
                <a className="breadcrumb-item" href="#">Components</a>
                <span className="breadcrumb-item active">Navbar</span>
              </nav>
            </div>
          </div>
          
          <Row>
            <Col md={12}>
              <Card className="borderless-shadow mb-5">
                <CardBody>
                  <CardTitle className="mb-3 h5">Simple Example</CardTitle>
                  <Navbar color="light" light expand="md" className="custom-navbar">
                    <NavbarBrand href="#" className="adjust-navbar-brand">Navbar React</NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                      <Nav className="ml-auto" navbar>
                        <NavItem>
                          <NavLink href="#">Components</NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink href="#">Link</NavLink>
                        </NavItem>
                        <UncontrolledDropdown nav inNavbar>
                          <DropdownToggle nav caret>
                            Options
                          </DropdownToggle>
                          <DropdownMenu right className="dropdown-shadow">
                            <DropdownItem>
                              Option 1
                            </DropdownItem>
                            <DropdownItem>
                              Option 2
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem>
                              Reset
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Nav>
                    </Collapse>
                  </Navbar>
                </CardBody>
              </Card>
            </Col>

            <Col md={12}>
              <Card className="borderless-shadow">
                <CardBody>
                  <CardTitle className="mb-3 h5">Navbar Pills</CardTitle>
                  <div className="tab-primary">
                    <Navbar color="faded" light className="custom-navbar">
                      <NavbarBrand href="#" className="mr-auto adjust-navbar-brand">Navbar React</NavbarBrand>
                      <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                      <Collapse isOpen={!this.state.collapsed} navbar>
                        <Nav navbar style={{ flexDirection: 'column' }}>
                          <NavItem>
                            <NavLink href="">Components</NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="">Link</NavLink>
                          </NavItem>
                        </Nav>
                      </Collapse>
                    </Navbar>
                  </div>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </div>
      </div>
    );
  }
}

export default Navbars;