import React from "react";
import "./typography.css";
import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";

class Typography extends React.Component {
  constructor(props) {
    super(props);

  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Typography</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">UI Elements</a>
                <span className="breadcrumb-item active">Typography</span>
              </nav>
            </div>
          </div>

          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle className="font-size-18 font-weight-semibold">Default Heading</CardTitle>
            </CardHeader>
            <CardBody>
              <h1>h1 Heading</h1>
              <h2>h2 Heading</h2>
              <h3>h3 Heading</h3>
              <h4>h4 Heading</h4>
              <h5>h5 Heading</h5>
              <h6>h6 Heading</h6>
            </CardBody>
          </Card>

          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle className="font-size-18 font-weight-semibold">Inverse Heading</CardTitle>
            </CardHeader>
            <CardBody>
              <h1 class="heading-inverse">h1 Heading</h1>
              <h2 class="heading-secondary">h2 Heading</h2>
              <h3 class="heading-inverse">h3 Heading</h3>
              <h4 class="heading-inverse">h4 Heading</h4>
              <h5 class="heading-inverse">h5 Heading</h5>
              <h6 class="heading-inverse">h6 Heading</h6>
            </CardBody>
          </Card>

          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle className="font-size-18 font-weight-semibold">Heading with color</CardTitle>
            </CardHeader>
            <CardBody>
              <h1 class="text-primary">h1 Heading</h1>
              <h2 class="text-secondary">h2 Heading</h2>
              <h3 class="text-success">h3Heading</h3>
              <h4 class="text-info">h4 Heading</h4>
              <h5 class="text-warning">h5 Heading</h5>
              <h6 class="text-danger">h6 Heading</h6>
            </CardBody>
          </Card>

          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle className="font-size-18 font-weight-semibold">Heading with background</CardTitle>
            </CardHeader>
            <CardBody>
              <h1 class="heading-bg-primary">h1 Heading</h1>
              <h2 class="heading-bg-secondary">h2 Heading</h2>
              <h3 class="heading-bg-success">h3 Heading</h3>
              <h4 class="heading-bg-info">h. Heading</h4>
              <h5 class="heading-bg-warning">h5 Heading</h5>
              <h6 class="heading-bg-danger">h6 Heading</h6>
            </CardBody>
          </Card>

          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle className="font-size-18 font-weight-semibold">Font Size</CardTitle>
            </CardHeader>
            <CardBody>
              <p>You can easily customize the font size - just by adding the appropriate classname.</p>
              <table className="table-typography-class-n-value mb-3">
                <tbody>
                  <tr>
                    <td className="table-heading"><b>classname</b></td>
                    <td><code>font-size-[value]</code></td>
                  </tr>
                  <tr>
                    <td className="table-heading"><b>values</b></td>
                    <td>8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 18 | 20 | 22 | 24 | ... | 200</td>
                  </tr>
                </tbody>
              </table>
            </CardBody>
          </Card>

          <Card className="borderless-shadow mb-0">
            <CardHeader>
              <CardTitle className="font-size-18 font-weight-semibold">Font Weight</CardTitle>
            </CardHeader>
            <CardBody>
              <p>You can easily customize the font weight - just by adding the appropriate classname.</p>
              <table className="table-typography-class-n-value mb-3">
                <tbody>
                  <tr>
                    <td className="table-heading"><b>classname</b></td>
                    <td><code>font-weight-light</code></td>
                  </tr>
                  <tr>
                    <td className="table-heading"><b>value is set to</b></td>
                    <td>300</td>
                  </tr>
                </tbody>
              </table>

              <table className="table-typography-class-n-value mb-3">
                <tbody>
                  <tr>
                    <td className="table-heading"><b>classname</b></td>
                    <td><code>font-weight-normal</code></td>
                  </tr>
                  <tr>
                    <td className="table-heading"><b>value is set to</b></td>
                    <td>400</td>
                  </tr>
                </tbody>
              </table>

              <table className="table-typography-class-n-value mb-3">
                <tbody>
                  <tr>
                    <td className="table-heading"><b>classname</b></td>
                    <td><code>font-weight-semibold</code></td>
                  </tr>
                  <tr>
                    <td className="table-heading"><b>value is set to</b></td>
                    <td>600</td>
                  </tr>
                </tbody>
              </table>

              <table className="table-typography-class-n-value mb-3">
                <tbody>
                  <tr>
                    <td className="table-heading"><b>classname</b></td>
                    <td><code>font-weight-bold</code></td>
                  </tr>
                  <tr>
                    <td className="table-heading"><b>value is set to</b></td>
                    <td>700</td>
                  </tr>
                </tbody>
              </table>

              <table className="table-typography-class-n-value mb-3">
                <tbody>
                  <tr>
                    <td className="table-heading"><b>classname</b></td>
                    <td><code>font-weight-black</code></td>
                  </tr>
                  <tr>
                    <td className="table-heading"><b>value is set to</b></td>
                    <td>900</td>
                  </tr>
                </tbody>
              </table>

            </CardBody>
          </Card>
          
        </div>
      </div>
    );
  }
}

export default Typography;