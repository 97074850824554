import React, { Component } from 'react';

import { Input, Label, Collapse } from 'reactstrap';
import { Getcategory, Getprice, Getcolor, Getsize, Getrate } from '../../../actions/saidfilter.js';
import { CategoryList, GetMinMaxPrice, GetColorsList, GetsizeList, GetratesList } from '../../../services/index';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

class Filter extends Component {
    constructor(props) {
        super(props);

        this.toggleCategory = this.toggleCategory.bind(this);
        this.toggleColor = this.toggleColor.bind(this);
        this.toggleSize = this.toggleSize.bind(this);
        this.toggleRating = this.toggleRating.bind(this);

        this.state = {
            categoryCollapse: true,
            brandCollapse: true,
            sizeCollapse: true,
            ratingCollapse: true,
        };
    }


    toggleCategory() {
        this.setState((prevState) => this.state.categoryCollapse = !prevState.categoryCollapse);
    }
    CategoryFilter(event, categorys) {

        var index = categorys.indexOf(event.target.value);
        if (event.target.checked) {
            categorys.push(event.target.value);
        }
        else {
            categorys.splice(index, 1);
        }
        this.props.Getcategory(categorys);
    }

    toggleColor() {
        this.setState(prevState => this.state.brandCollapse = !prevState.brandCollapse);
    }

    ColorFilter = (event, colors) => {
        
        var index = colors.indexOf(event.target.value);
        if (event.target.checked) {
            colors.push(event.target.value);
        }
        else {
            colors.splice(index, 1);
        }
       
        this.props.Getcolor(colors)
    }

    toggleSize() {
        this.setState(prevState => this.state.sizeCollapse = !prevState.sizeCollapse);
    }

    onClickSizeFilter(event, sizes) {
        var index = sizes.indexOf(event.target.value);
        if (event.target.checked) {
            sizes.push(event.target.value);
        }
        else {
            sizes.splice(index, 1);
        }
        this.props.Getsize(sizes);
    }

    toggleRating() {
        this.setState(prevState => this.state.ratingCollapse = !prevState.ratingCollapse);
    }

    onClickRateFilter(event, rates) {
        var index = rates.indexOf(event.target.value);
        if (event.target.checked) {
            rates.push(event.target.value);
        }
        else {
            rates.splice(index, 1);
        }
        this.props.Getrate(rates);
    }



    render() {
        const categoryFilterValues = this.props.filters.category;
        const sizeFilterValues = this.props.filters.size;
        const rateFilterValues = this.props.filters.rate;
        const colorsFilterValues = this.props.filters.color;

        return (
            <div className="product-option-category">
                <div
                    className="category-btn-collapse font-weight-semibold"
                    onClick={this.toggleCategory}
                >
                    Category
                    <div className="plus-icon-before" />
                    <div
                        className="plus-icon-after"
                        style={{
                            transform: `${this.state.categoryCollapse ? "translate(-200%, -50%) rotate(-90deg)" : "translate(-200%, -50%)"}`
                        }}
                    />
                </div>
                <Collapse isOpen={this.state.categoryCollapse}>
                    <Scrollbars style={{ height: 200 }}>
                        <ul className="pt-1 pb-1" style={{ listStyle: "none", paddingLeft: 0, marginLeft: "1rem", marginBottom: 0 }}>
                            {this.props.categorys.map((cate, index) => (
                                <li key={index}>
                                    <div className="checkbox font-size-13 pt-1 pb-1">
                                        <Input type="checkbox" onClick={(e) => this.CategoryFilter(e, categoryFilterValues)} value={cate} defaultChecked={categoryFilterValues.includes(cate) ? true : false} className="form-check-input" id={cate} />
                                        <Label className="ecommerce-checkbox" htmlFor={cate}>{cate}</Label>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </Scrollbars>
                </Collapse>


                <div
                    className="category-btn-collapse font-weight-semibold"
                    onClick={this.toggleColor}
                >
                    Color
                    <div className="plus-icon-before" />
                    <div
                        className="plus-icon-after"
                        style={{
                            transform: `${this.state.brandCollapse ? "translate(-200%, -50%) rotate(-90deg)" : "translate(-200%, -50%)"}`
                        }}
                    />
                </div>
                <Collapse isOpen={this.state.brandCollapse}>
                    <Scrollbars style={{ height: 200 }}>
                        <ul className="pt-1 pb-1" style={{ listStyle: "none", paddingLeft: 0, marginLeft: "1rem", marginBottom: 0 }}>
                            {this.props.colors.map((col, index) => {
                                return (
                                    <li key={index}>
                                        <div className="checkbox font-size-14 pt-1 pb-1">
                                            <Input type="checkbox" onClick={(e) => this.ColorFilter(e, colorsFilterValues)} value={col} defaultChecked={colorsFilterValues.includes(col) ? true : false} className="form-check-input" id={col} />
                                            <Label className="ecommerce-checkbox" htmlFor={col}>{col}</Label>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </Scrollbars>
                </Collapse>

                <div
                    className="category-btn-collapse font-weight-semibold"
                    onClick={this.toggleSize}
                >
                    Size
                <div className="plus-icon-before" />
                    <div
                        className="plus-icon-after"
                        style={{
                            transform: `${this.state.sizeCollapse ? "translate(-200%, -50%) rotate(-90deg)" : "translate(-200%, -50%)"}`
                        }}
                    />
                </div>
                <Collapse isOpen={this.state.sizeCollapse}>
                    <ul className="pt-1 pb-1" style={{ listStyle: "none", paddingLeft: 0, marginLeft: "1rem", marginBottom: 0 }}>
                        {this.props.sizes.map((size, index) => {
                            return (
                                <li>
                                    <div className="checkbox font-size-14 pt-1 pb-1">
                                        <Input type="checkbox" onClick={(e) => this.onClickSizeFilter(e, sizeFilterValues)} defaultChecked={sizeFilterValues.includes(size) ? true : false} value={size} id={size} />
                                        <Label className="ecommerce-checkbox" htmlFor={size}>{size}</Label>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </Collapse>

                <div
                    className="category-btn-collapse font-weight-semibold"
                    onClick={this.toggleRating}
                >
                    Ratings
              <div className="plus-icon-before" />
                    <div
                        className="plus-icon-after"
                        style={{
                            transform: `${this.state.ratingCollapse ? "translate(-200%, -50%) rotate(-90deg)" : "translate(-200%, -50%)"}`
                        }}
                    />
                </div>
                <Collapse isOpen={this.state.ratingCollapse}>
                    <ul className="pt-1 pb-1" style={{ listStyle: "none", paddingLeft: 0, marginLeft: "1rem", marginBottom: 0 }}>
                        <li>
                            <div className="checkbox font-size-14 pt-1 pb-1">
                                <Input type="checkbox" onClick={(e) => this.onClickRateFilter(e, rateFilterValues)} defaultChecked={rateFilterValues.includes("5") ? true : false} value={"5"} id={"5"} />
                                <Label className="ecommerce-checkbox" htmlFor="5">
                                    <i className="fas fa-star mr-1"></i>
                                    <i className="fas fa-star mr-1"></i>
                                    <i className="fas fa-star mr-1"></i>
                                    <i className="fas fa-star mr-1"></i>
                                    <i className="fas fa-star mr-1"></i>
                                </Label>
                            </div>
                        </li>
                        <li>
                            <div className="checkbox font-size-14 pt-1 pb-1">
                                <Input type="checkbox" onClick={(e) => this.onClickRateFilter(e, rateFilterValues)} defaultChecked={rateFilterValues.includes("4") ? true : false} value={"4"} id={"4"} />
                                <Label className="ecommerce-checkbox" htmlFor="4">
                                    <i className="fas fa-star mr-1"></i>
                                    <i className="fas fa-star mr-1"></i>
                                    <i className="fas fa-star mr-1"></i>
                                    <i className="fas fa-star mr-1"></i>
                                    <i className="far fa-star mr-1"></i>
                                </Label>
                            </div>
                        </li>
                        <li>
                            <div className="checkbox font-size-14 pt-1 pb-1">
                                <Input type="checkbox" onClick={(e) => this.onClickRateFilter(e, rateFilterValues)} defaultChecked={rateFilterValues.includes("3") ? true : false} value={"3"} id={"3"} />
                                <Label className="ecommerce-checkbox" htmlFor="3">
                                    <i className="fas fa-star mr-1"></i>
                                    <i className="fas fa-star mr-1"></i>
                                    <i className="fas fa-star mr-1"></i>
                                    <i className="far fa-star mr-1"></i>
                                    <i className="far fa-star mr-1"></i>
                                </Label>
                            </div>
                        </li>
                        <li>
                            <div className="checkbox font-size-14 pt-1 pb-1">
                                <Input type="checkbox" onClick={(e) => this.onClickRateFilter(e, rateFilterValues)} defaultChecked={rateFilterValues.includes("2") ? true : false} value={"2"} id={"2"} />
                                <Label className="ecommerce-checkbox" htmlFor="2">
                                    <i className="fas fa-star mr-1"></i>
                                    <i className="fas fa-star mr-1"></i>
                                    <i className="far fa-star mr-1"></i>
                                    <i className="far fa-star mr-1"></i>
                                    <i className="far fa-star mr-1"></i>
                                </Label>
                            </div>
                        </li>
                        <li>
                            <div className="checkbox font-size-14 pt-1 pb-1">
                                <Input type="checkbox" onClick={(e) => this.onClickRateFilter(e, rateFilterValues)} defaultChecked={rateFilterValues.includes("1") ? true : false} value={"1"} id={"1"} />
                                <Label className="ecommerce-checkbox" htmlFor="1">
                                    <i className="fas fa-star mr-1"></i>
                                    <i className="far fa-star mr-1"></i>
                                    <i className="far fa-star mr-1"></i>
                                    <i className="far fa-star mr-1"></i>
                                    <i className="far fa-star mr-1"></i>
                                </Label>
                            </div>
                        </li>
                    </ul>
                </Collapse>

            </div>

        );
    }
}
const mapDispatchToProps = state => ({
    categorys: CategoryList(state.data.products),
    prices: GetMinMaxPrice(state.data.products),
    colors: GetColorsList(state.data.products),
    sizes: GetsizeList(state.data.products),
    rates: GetratesList(state.data.products),
    filters: state.filters
})
export default connect(
    mapDispatchToProps,
    { Getcategory, Getprice, Getcolor, Getsize, Getrate }
)(Filter);