import React from "react";
import "./tooltip.css";

import { Tooltip, Nav, CardHeader, Card, CardBody, CardTitle, PopoverHeader, PopoverBody, Button, Popover, Row, Col } from 'reactstrap';

class Tooltips extends React.Component {
  constructor(props) {
    super(props);
    
    this.toggle = this.toggle.bind(this);
    this.tooltipLeft = this.tooltipLeft.bind(this);
    this.tooltipBottom = this.tooltipBottom.bind(this);
    this.tooltipRight = this.tooltipRight.bind(this);
    this.tooltipTop = this.tooltipTop.bind(this);
    this.tooltipMsgLeft = this.tooltipMsgLeft.bind(this);
    this.tooltipMsgTop = this.tooltipMsgTop.bind(this);
    this.tooltipMsgBottom = this.tooltipMsgBottom.bind(this);
    this.tooltipMsgRight = this.tooltipMsgRight.bind(this);

    this.state = {
      tooltipOpen: false,
      tooltipLeft: false,
      tooltipBottom: false,
      tooltipRight: false,
      tooltipTop: false,
      tooltipMsgLeft: false,
      tooltipMsgTop: false,
      tooltipMsgBottom: false,
      tooltipMsgRight: false,
    };
  }

  // Tooltip
  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  tooltipLeft() {
    this.setState({
      tooltipLeft: !this.state.tooltipLeft
    });
  }
  tooltipRight() {
    this.setState({
      tooltipRight: !this.state.tooltipRight
    });
  }
  tooltipTop() {
    this.setState({
      tooltipTop: !this.state.tooltipTop
    });
  }
  tooltipBottom() {
    this.setState({
      tooltipBottom: !this.state.tooltipBottom
    });
  }

  tooltipMsgLeft() {
    this.setState({
      tooltipMsgLeft: !this.state.tooltipMsgLeft
    });
  }
  tooltipMsgTop() {
    this.setState({
      tooltipMsgTop: !this.state.tooltipMsgTop
    });
  }
  tooltipMsgBottom() {
    this.setState({
      tooltipMsgBottom: !this.state.tooltipMsgBottom
    });
  }
  tooltipMsgRight() {
    this.setState({
      tooltipMsgRight: !this.state.tooltipMsgRight
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Tooltip</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">Components</a>
                <span className="breadcrumb-item active">Tooltips</span>
              </nav>
            </div>
          </div>
          
          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle className="font-weight-semibold font-size-18">Basic</CardTitle>
            </CardHeader>
            <CardBody>
              <p className="mb-2">Basic tooltip example.</p>
              <div className="mb-3">
                <p>Somewhere in here is a <span style={{ textDecoration: "underline", color: "blue" }} href="#" id="TooltipExample">tooltip</span>.</p>
                <Tooltip
                  placement="right"
                  isOpen={this.state.tooltipOpen}
                  target="TooltipExample"
                  toggle={this.toggle}
                >
                  Tooltip popped!
                </Tooltip>
              </div>
            </CardBody>
          </Card>

          <Card className="borderless-shadow">
            <CardHeader>
              <CardTitle className="font-weight-semibold font-size-18">Tooltips at certain Directions</CardTitle>
            </CardHeader>
            <CardBody>
              <p>Four options are available: top, right, bottom, and left aligned.</p>
              <div>
                <Button className="custom-btn btn-gradient-card-orange" id="TooltipLeft">
                  Tooltip on Left
                </Button>
                <Tooltip
                  placement="left"
                  isOpen={this.state.tooltipLeft}
                  target="TooltipLeft"
                  toggle={this.tooltipLeft}
                >
                  Tooltip Content!
                </Tooltip>

                <Button className="custom-btn btn-gradient-card-blue" id="TooltipTop">
                  Tooltip on Top
                </Button>
                <Tooltip
                  placement="top"
                  isOpen={this.state.tooltipTop}
                  target="TooltipTop"
                  toggle={this.tooltipTop}
                >
                  Tooltip Content!
                </Tooltip>

                <Button className="custom-btn btn-gradient-card-teal" id="TooltipBottom">
                  Tooltip on Bottom
                </Button>
                <Tooltip
                  placement="bottom"
                  isOpen={this.state.tooltipBottom}
                  target="TooltipBottom"
                  toggle={this.tooltipBottom}
                >
                  Tooltip Content!
                </Tooltip>

                <Button className="custom-btn btn-gradient-card-green" id="TooltipRight">
                  Tooltip on Right
                </Button>
                <Tooltip
                  placement="right"
                  isOpen={this.state.tooltipRight}
                  target="TooltipRight"
                  toggle={this.tooltipRight}
                >
                  Tooltip Content!
                </Tooltip>

              </div>
            </CardBody>
          </Card>
          
          <Card className="borderless-shadow mb-0">
            <CardHeader>
              <CardTitle className="font-weight-semibold font-size-18">Tooltips with info messages</CardTitle>
            </CardHeader>
            <CardBody>
              <p>Four options are available: top-end, right-start, bottom-end, and left-start aligned.</p>
              <div>
                <Button className="custom-btn btn-gradient-card-orange" id="TooltipLeft1">
                  Tooltip with message on Left
                </Button>
                <Tooltip
                  placement="left-start"
                  isOpen={this.state.tooltipMsgLeft}
                  target="TooltipLeft1"
                  toggle={this.tooltipMsgLeft}
                >
                  <Card className="custom-tooltip-card">
                    <CardHeader className="p-1">
                      <CardTitle>Title</CardTitle>
                    </CardHeader>
                    <CardBody className="p-2">
                      Message to be shown
                    </CardBody>
                  </Card>
                </Tooltip>

                <Button className="custom-btn btn-gradient-card-blue" id="TooltipTop1">
                  Tooltip with message on Top
                </Button>
                <Tooltip
                  placement="top-end"
                  isOpen={this.state.tooltipMsgTop}
                  target="TooltipTop1"
                  toggle={this.tooltipMsgTop}
                >
                  <Card className="custom-tooltip-card">
                    <CardHeader className="p-1">
                      <CardTitle>Title</CardTitle>
                    </CardHeader>
                    <CardBody className="p-2">
                      Message to be shown
                    </CardBody>
                  </Card>
                </Tooltip>

                <Button className="custom-btn btn-gradient-card-teal" id="TooltipBottom1">
                  Tooltip with message on Bottom
                </Button>
                <Tooltip
                  placement="bottom-end"
                  isOpen={this.state.tooltipMsgBottom}
                  target="TooltipBottom1"
                  toggle={this.tooltipMsgBottom}
                >
                  <Card className="custom-tooltip-card">
                    <CardHeader className="p-1">
                      <CardTitle>Title</CardTitle>
                    </CardHeader>
                    <CardBody className="p-2">
                      Message to be shown
                    </CardBody>
                  </Card>
                </Tooltip>

                <Button className="custom-btn btn-gradient-card-green" id="TooltipRight1">
                  Tooltip with message on Right
                </Button>
                <Tooltip
                  placement="right-start"
                  isOpen={this.state.tooltipMsgRight}
                  target="TooltipRight1"
                  toggle={this.tooltipMsgRight}
                >
                  <Card className="custom-tooltip-card">
                    <CardHeader className="p-1">
                      <CardTitle>Title</CardTitle>
                    </CardHeader>
                    <CardBody className="p-2">
                      Message to be shown
                    </CardBody>
                  </Card>
                </Tooltip>
              </div>

            </CardBody>
          </Card>

        </div>
      </div>
    );
  }
}

export default Tooltips;