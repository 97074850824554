import React, { useState} from "react";
import "./form-elements.css";

import { Input, InputGroup, InputGroupAddon, InputGroupText, Button, ButtonDropdown, FormGroup, Label, DropdownMenu, DropdownToggle, DropdownItem, CardBody, CardHeader, Card, CardTitle, Row, Col, Alert, UncontrolledAlert, Breadcrumb, BreadcrumbItem } from 'reactstrap';

class FormElements extends React.Component {
  constructor(props) {
    super(props);

    this.dropdownAddonToggleTwo = this.dropdownAddonToggleTwo.bind(this);
    this.dropdownAddonToggleOne = this.dropdownAddonToggleOne.bind(this);

    this.state = {
      dropdownaddonOne: false,
      dropdownaddonTwo: false,
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  dropdownAddonToggleOne() {
    this.setState(prevState => ({
      dropdownaddonOne: !prevState.dropdownaddonOne
    }));
  }

  dropdownAddonToggleTwo() {
    this.setState(prevState => ({
      dropdownaddonTwo: !prevState.dropdownaddonTwo
    }));
  }

  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Form Elements</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">Forms</a>
                <span className="breadcrumb-item active">Form Elements</span>
              </nav>
            </div>
          </div>

          <Row>
            <Col md={6} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardBody>
                  <FormGroup>
                    <Label className="control-Label mb-2">Basic Input</Label>
                    <Input type="text" name="basiccontroller" id="basicInput" className="form-control" />
                  </FormGroup>

                  <FormGroup>
                    <Label className="control-Label mb-2">Placeholder</Label>
                    <Input type="text" placeholder="Placeholder" className="form-control" />
                  </FormGroup>

                  <FormGroup>
                    <Label className="control-Label mb-2">Diasbled</Label>
                    <Input type="text" placeholder="Placeholder" className="form-control" disabled />
                  </FormGroup>

                  <FormGroup>
                    <Label className="control-Label mb-2">Read Only</Label>
                    <Input type="text" placeholder="Placeholder" className="form-control" readOnly />
                  </FormGroup>

                  <FormGroup>
                    <Label className="control-Label mb-0">Static</Label>
                    <p className="form-control-plaintext">email@example.com</p>
                  </FormGroup>

                  <FormGroup>
                    <Label className="control-Label mb-2">Help text</Label>
                    <Input type="text" placeholder="Enter email" className="form-control" readOnly />
                    <small className="form-text">We'll never share your email with anyone else.</small>
                  </FormGroup>

                  <FormGroup>
                    <Label className="control-Label mb-2">Textarea</Label>
                    <Input type="textarea" className="form-control" id="exampleFormControlTextarea1" rows={4} defaultValue={""} />
                  </FormGroup>

                </CardBody>
              </Card>
            </Col>

            <Col md={6} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardBody>
                  <FormGroup>
                    <Label className="control-Label mb-2">Sizing</Label>
                    <Input type="text" placeholder="Enter email" className="form-control " bsSize="sm" />
                  </FormGroup>
                  <FormGroup>
                    <Input type="text" className="form-control" placeholder="Default" />
                  </FormGroup>
                  <FormGroup>
                    <Input type="text" placeholder="Enter email" className="form-control " bsSize="lg" />
                  </FormGroup>

                  <FormGroup>
                    <Label className="control-Label mb-2">Select</Label>
                    <Input type="select" className="form-control">
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label className="control-Label mb-2">Multiple Select</Label>
                    <Input type="select" multiple className="form-control">
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    <Label className="control-Label mb-2" htmlFor="icon-Input">Icon Input</Label>
                    <div className="icon-Input">
                      <i className="mdi mdi-email-outline" />
                      <Input type="text" className="form-control" placeholder="Placeholder" id="icon-Input" />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label className="control-Label mb-2">Textarea</Label>
                    <Input type="textarea" className="form-control" id="exampleFormControlTextarea1" rows={3} defaultValue={""} />
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={6} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardBody>

                  <FormGroup>
                    <Label className="control-Label mb-2">Button Addon</Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend"><Button>Go!</Button></InputGroupAddon>
                      <Input placeholder="Search for..." />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup>
                      <Input placeholder="Search for..." />
                      <InputGroupAddon addonType="append"><Button><i className="mdi mdi-magnify" /></Button></InputGroupAddon>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <Label className="control-Label mb-2 mt-5">Dropdown Addon</Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <ButtonDropdown
                          isOpen={this.state.dropdownaddonOne}
                          toggle={this.dropdownAddonToggleOne}
                        >
                          <DropdownToggle caret style={{
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                            borderTopRightRadius: "0",
                            borderBottomRightRadius: "0",
                          }}>
                            Action
                              </DropdownToggle>
                          <DropdownMenu style={{ boxShadow: "0 0 4px rgba(0,0,0,0.3)" }}>
                            <DropdownItem >Action</DropdownItem>
                            <DropdownItem >Another action</DropdownItem>
                            <DropdownItem >Something else here</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem >Separated link</DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>
                      </InputGroupAddon>
                      <Input type="text" className="form-control" />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup>
                      <Input type="text" className="form-control" />
                      <InputGroupAddon
                        addonType="prepend"
                        style={{
                          borderTopRightRadius: "4px",
                          borderBottomRightRadius: "4px",
                        }}
                      >
                        <ButtonDropdown
                          isOpen={this.state.dropdownaddonTwo}
                          toggle={this.dropdownAddonToggleTwo}
                        >
                          <DropdownToggle caret style={{
                            borderTopLeftRadius: "0",
                            borderBottomLeftRadius: "0",
                            borderTopRightRadius: "4px",
                            borderBottomRightRadius: "4px",
                          }}>
                            Action
                          </DropdownToggle>
                          <DropdownMenu style={{ boxShadow: "0 0 4px rgba(0,0,0,0.3)" }}>
                            <DropdownItem >Action</DropdownItem>
                            <DropdownItem >Another action</DropdownItem>
                            <DropdownItem >Something else here</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem >Separated link</DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>

                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <Label className="control-Label mb-2 mt-5">Sizing</Label>
                    <InputGroup size="lg">
                      <InputGroupAddon addonType="prepend">
                        <span className="Input-group-text input-group-icon-left-lg">@</span>
                      </InputGroupAddon>
                      <Input type="text" className="form-control" placeholder="Input Group Large" />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <span className="Input-group-text input-group-icon-left">@</span>
                      </InputGroupAddon>
                      <Input type="text" className="form-control" placeholder="Input Group Default" />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup size="sm">
                      <InputGroupAddon addonType="prepend">
                        <span className="Input-group-text input-group-icon-left-sm">@</span>
                      </InputGroupAddon>
                      <Input type="text" className="form-control" placeholder="Input Group Small" />
                    </InputGroup>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>

            <Col md={6} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardBody>

                  <FormGroup>
                    <Label className="control-Label mb-2">Text Addon</Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">@</InputGroupAddon>
                      <Input placeholder="Username" />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup>
                      <Input placeholder="User Email" />
                      <InputGroupAddon addonType="append">@example.com</InputGroupAddon>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                      <Input />
                      <InputGroupAddon addonType="append">.00</InputGroupAddon>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <Label className="control-Label mb-2 mt-4">Icon Addon</Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <span className="Input-group-text input-group-icon-left"><i className="far fa-user"></i></span>
                      </InputGroupAddon>
                      <Input />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup>
                      <Input placeholder="Email address" />
                      <InputGroupAddon addonType="append">
                        <span className="Input-group-text input-group-icon-right"><i className="far fa-envelope"></i></span>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <span className="Input-group-text input-group-icon-left"><i className="far fa-envelope"></i></span>
                      </InputGroupAddon>
                      <Input placeholder="Email address" />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <Label className="control-Label mb-2 mt-4">Checkboxes and Radio addons</Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <Input addon type="checkbox" aria-label="Checkbox for following text Input" defaultChecked />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Text Input with checkbox" />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <Input addon type="radio" id="rd-1" aria-label="Radio for following text Input" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Text Input with radio" />
                    </InputGroup>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>


          <Row>
            <Col md={6} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardBody>
                  <Row>
                    <Col md={6} className="mb-3">
                      <h5 className="font-size-20 font-weight-semibold mb-3">Checkbox</h5>
                      <div>
                        <div className="checkbox pt-0 pb-0 mb-3">
                          <Input id="checkbox1" type="checkbox" defaultChecked />
                          <Label htmlFor="checkbox1">Checked</Label>
                        </div>
                        <div className="checkbox pt-0 pb-0 mb-3">
                          <Input id="checkbox2" type="checkbox" />
                          <Label htmlFor="checkbox2">Uncheck</Label>
                        </div>
                        <div className="checkbox pt-0 pb-0 mb-3">
                          <Input id="checkbox3" type="checkbox" disabled />
                          <Label htmlFor="checkbox3">Disabled Unchecked</Label>
                        </div>
                        <div className="checkbox pt-0 pb-0 mb-3">
                          <Input id="checkbox4" type="checkbox" defaultChecked disabled />
                          <Label htmlFor="checkbox4">Disabled Checked</Label>
                        </div>
                      </div>
                    </Col>
                    <Col md={6} className="mb-3">
                      <h5 className="font-size-20 font-weight-semibold mb-3">Radio</h5>
                      <div>
                        <div className="radio pt-0 pb-0 mb-3">
                          <Input id="radio1" name="radioDemo" type="radio" defaultChecked />
                          <Label htmlFor="radio1">Checked</Label>
                        </div>
                        <div className="radio pt-0 pb-0 mb-3">
                          <Input id="radio2" name="radioDemo" type="radio" />
                          <Label htmlFor="radio2">Uncheck</Label>
                        </div>
                        <div className="radio pt-0 pb-0 mb-3">
                          <Input id="radio3" name="radioDemo1" type="radio" disabled />
                          <Label htmlFor="radio3">Disabled Unchecked</Label>
                        </div>
                        <div className="radio pt-0 pb-0 mb-3">
                          <Input id="radio4" name="radioDemo1" type="radio" defaultChecked disabled />
                          <Label htmlFor="radio4">Disabled Checked</Label>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <h5 className="font-size-20 font-weight-semibold mb-3">Color Options</h5>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div >
                      <div className="checkbox d-inline">
                        <Input id="checkbox5" type="checkbox" defaultChecked />
                        <Label htmlFor="checkbox5" />
                      </div>
                      <div className="checkbox d-inline checkbox-primary">
                        <Input id="checkbox6" type="checkbox" defaultChecked />
                        <Label htmlFor="checkbox6" />
                      </div>
                      <div className="checkbox d-inline checkbox-info">
                        <Input id="checkbox7" type="checkbox" defaultChecked />
                        <Label htmlFor="checkbox7" />
                      </div>
                      <div className="checkbox d-inline checkbox-warning">
                        <Input id="checkbox8" type="checkbox" defaultChecked />
                        <Label htmlFor="checkbox8" />
                      </div>
                      <div className="checkbox d-inline checkbox-danger">
                        <Input id="checkbox9" type="checkbox" defaultChecked />
                        <Label htmlFor="checkbox9" />
                      </div>
                    </div>
                    
                    <div className="ml-3">
                      <div className="radio d-inline">
                        <Input id="radio5" name="radioDemo2" type="radio" defaultChecked />
                        <Label htmlFor="radio5" />
                      </div>
                      <div className="radio d-inline radio-primary">
                        <Input id="radio6" name="radioDemo3" type="radio" defaultChecked />
                        <Label htmlFor="radio6" />
                      </div>
                      <div className="radio d-inline radio-info">
                        <Input id="radio7" name="radioDemo4" type="radio" defaultChecked />
                        <Label htmlFor="radio7" />
                      </div>
                      <div className="radio d-inline radio-warning">
                        <Input id="radio8" name="radioDemo5" type="radio" defaultChecked />
                        <Label htmlFor="radio8" />
                      </div>
                      <div className="radio d-inline radio-danger">
                        <Input id="radio9" name="radioDemo6" type="radio" defaultChecked />
                        <Label htmlFor="radio9" />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md={6} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardBody>
                  <h5 className="font-size-20 font-weight-semibold mb-3">Switch</h5>
                  <div>
                    <FormGroup>
                      <div className="switch d-inline mr-3">
                        <Input type="checkbox" id="switch-1" defaultChecked />
                        <Label htmlFor="switch-1" />
                      </div>
                      <Label>Checked</Label>
                    </FormGroup>
                    <FormGroup>
                      <div className="switch d-inline mr-3">
                        <Input type="checkbox" id="switch-2" />
                        <Label htmlFor="switch-2" />
                      </div>
                      <Label>Uncheck</Label>
                    </FormGroup>
                    <FormGroup>
                      <div className="switch d-inline mr-3">
                        <Input type="checkbox" id="switch-3" disabled />
                        <Label htmlFor="switch-3" />
                      </div>
                      <Label>Disabled</Label>
                    </FormGroup>
                    <FormGroup>
                      <div className="switch d-inline mr-3">
                        <Input type="checkbox" id="switch-4" disabled defaultChecked />
                        <Label htmlFor="switch-4" />
                      </div>
                      <Label>Disabled Checked</Label>
                    </FormGroup>
                  </div>

                  <h5 className="mt-4 font-size-20 font-weight-semibold mb-3">Color Options</h5>
                  <div>
                    <div className="switch d-inline mr-3">
                      <Input type="checkbox" id="switch-5" defaultChecked />
                      <Label htmlFor="switch-5" />
                    </div>
                    <div className="switch switch-primary d-inline mr-3">
                      <Input type="checkbox" id="switch-6" defaultChecked />
                      <Label htmlFor="switch-6" />
                    </div>
                    <div className="switch switch-info d-inline mr-3">
                      <Input type="checkbox" id="switch-7" defaultChecked />
                      <Label htmlFor="switch-7" />
                    </div>
                    <div className="switch switch-warning d-inline mr-3">
                      <Input type="checkbox" id="switch-8" defaultChecked />
                      <Label htmlFor="switch-8" />
                    </div>
                    <div className="switch switch-danger d-inline mr-3">
                      <Input type="checkbox" id="switch-9" defaultChecked />
                      <Label htmlFor="switch-9" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={6} className="d-flex">
              <Card className="borderless-shadow w-100 form-misc-1">
                <CardBody>
                  <div className="mb-4">
                    <h5 className="font-size-18 font-weight-semibold mb-3">Single Item Select</h5>
                    <select id="selectize-dropdown" className="custom-select">
                      <option defaultValue="select a person" disabled>Select a person...</option>
                      <option value={4}>Thomas Edison</option>
                      <option value={1}>Nikola</option>
                      <option value={3}>Nikola Tesla</option>
                      <option value={5}>Arnold Schwarzenegger</option>
                    </select>
                  </div>
                  
                  <h5 className="font-size-18 font-weight-semibold mb-3">Multiple Select</h5>
                  <div className="mb-4">
                    <select id="selectize-tags-1" name="person" multiple className="custom-select">
                      <option value="select a person" disabled>Select a person...</option>
                      <option value={1}>Adam</option>
                      <option defaultValue={2}>Amalie</option>
                      <option value={3}>Estefanía</option>
                      <option value={4}>Adrian</option>
                      <option value={5}>Wladimir</option>
                      <option value={6}>Samantha</option>
                      <option value={7}>Nicole</option>
                      <option defaultValue={8}>Michael</option>
                    </select>
                  </div>

                  <h5 className="font-size-18 font-weight-semibold mb-3">Option Groups</h5>
                  <div className="mb-2">
                    <select id="selectize-group" className="custom-select">
                      <option value>Select gear...</option>
                      <optgroup label="Climbing">
                        <option value="pitons">Pitons</option>
                        <option value="cams">Cams</option>
                        <option value="nuts">Nuts</option>
                        <option value="bolts">Bolts</option>
                        <option value="stoppers">Stoppers</option>
                        <option value="sling">Sling</option>
                      </optgroup>
                      <optgroup label="Skiing">
                        <option value="skis">Skeis</option>
                        <option value="skins">Skins</option>
                        <option value="poles">Poles</option>
                      </optgroup>
                    </select>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md={6} className="d-flex">
              <Card className="borderless-shadow w-100 form-misc-2">
                <CardBody>
                  <FormGroup>
                    <Label for="exampleDatetime" className="mb-2">Datetime</Label>
                    <Input
                      type="datetime"
                      name="datetime"
                      id="exampleDatetime"
                      placeholder="datetime placeholder"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleDate" className="mb-2">Date</Label>
                    <Input
                      type="date"
                      name="date"
                      id="exampleDate"
                      placeholder="date placeholder"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleTime" className="mb-2">Time</Label>
                    <Input
                      type="time"
                      name="time"
                      id="exampleTime"
                      placeholder="time placeholder"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleColor" className="mb-2">Color</Label>
                    <Input
                      className="input-color-custom"
                      type="color"
                      name="color"
                      id="exampleColor"
                      placeholder="color placeholder"
                    />
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        
        </div>
      </div>
    );
  }
}

export default FormElements;