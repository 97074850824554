import React from "react";
import { Link } from 'react-router-dom';
import "./products.css";

import { Row, Col, Tooltip, Card, CardBody } from 'reactstrap';
import { Pagination } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import { FilterProduct } from '../../services';
import Filter from "./shop/filter";
import Items from "./shop/items";
import { connect } from 'react-redux';

const ProductParPage = 6;

class ProductsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      minValue: 0,
      maxValue: 6,
      status: true
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  handleChange = value => {
    this.setState({
      minValue: (value - 1) * ProductParPage,
      maxValue: value * ProductParPage
    });
  };

  itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <Link className="page-link" >Previous</Link>;
    }
    if (type === 'next') {
      return <Link className="page-link" >Next</Link>;
    }
    return originalElement;
  }

  render() {
    let { products } = this.props;
    let { status } = this.state;
    return (
      <div className="main-content">
        <div className="container-fluid">
          <div className="page-header">
            <h2 className="header-title">Products</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">E-Commerce</a>
                <span className="breadcrumb-item active">Products</span>
              </nav>
            </div>
          </div>
        </div>

        <Row>
          <Col md={4} lg={3}>
            <Card className="borderless-shadow" style={{ overflow: "hidden" }}>
              <CardBody style={{ padding: '0' }}>
                <Filter />
              </CardBody>
            </Card>
          </Col>
          {status ?
            <Col md={8} lg={9}>
              {(products.length > 0) ?
                <Row>
                  {products.slice(this.state.minValue, this.state.maxValue).map((product, index) => (
                    <Items productdata={product} key={index} />
                  ))}
                  <div className="text-center col-12">
                    <Pagination
                      defaultCurrent={1}
                      defaultPageSize={ProductParPage}
                      onChange={this.handleChange}
                      total={products.length}
                      itemRender={this.itemRender}
                    />
                  </div>
                </Row>
                :
                <div className="col-lg-9 col-md-12 order-lg-12">
                  <div className="row text-center">
                    <h3>Sorry! No products were found matching your selection!    </h3>
                    <p>Please try to other words.</p>
                  </div>
                </div>
              }
            </Col>
            : <div>Loading</div>
          }
        </Row>

      </div>

    );
  }
}

const ProductDispatchToProps = (state) => ({
  products: FilterProduct(state.data, state.filters)

})
export default connect(
  ProductDispatchToProps, {}
)(ProductsPage);