import React from "react";
import "./email.css";

import { Row, Col, NavItem, Nav, Dropdown, Breadcrumb, BreadcrumbItem, DropdownItem, DropdownMenu, DropdownToggle, TabContent, TabPane } from 'reactstrap';

import { Scrollbars } from 'react-custom-scrollbars';

class EmailsApp extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      name: 'Zina Blubber',
      chatdropdown: false,
      dropdownbarOpen: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  toggle(tab, name) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        name: name
      });
    }
  }

  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Email</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">App</a>
                <span className="breadcrumb-item active">Email</span>
              </nav>
            </div>
          </div>
        </div>

        <div className="email-wrapper row borderless-shadow" style={{ borderRadius: '4px' }}>
          <div className="email-list">
            <div className="email-list-tools" style={{display: 'flex', justifyContent: 'space-between'}}>
              <ul className="tools pull-left mb-0">
                <li>
                  <a href="#">
                    <span className="font-size-14">Mark as Read</span>
                  </a>
                </li>
              </ul>
              <ul className="tools text-right mb-0">
                <li>
                  <a href="#">
                    <i className="ti-star" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="ti-timer" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="ti-trash" />
                  </a>
                </li>
              </ul>
            </div>
            <Scrollbars
              autoHeight
              autoHeightMin={300}
              autoHeightMax={450}
              className="email-list-wrapper"
            >
              <ul className="email-list-item">
                <li className="email-item" onClick={() => { this.toggle('1', 'Zina Blubber'); }}  >
                  <div className="email-avatar">
                    <img className="thumb-img img-circle" src={require(`../../../assets/images/profiles/profile-pic-7.jpg`)} />
                  </div>
                  <div className="open-mail">
                    <div className="email-detail">
                      <p className="from">Zina Blubber</p>
                      <p className="subject">
                        Bring me Solo and th...
                      </p>
                      <p>
                        Hi, Luke Somebody's coming. Oh!...
                      </p>
                      <span className="datetime">8 min ago</span>
                    </div>
                  </div>
                </li>
                <li className="email-item" onClick={() => { this.toggle('2', 'Lady Valarian'); }}>
                  <div className="email-avatar">
                    <img className="thumb-img img-circle" src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                  </div>
                  <div className="open-mail">
                    <div className="email-detail">
                      <p className="from">Lady Valarian</p>
                      <p className="subject">
                        I think you'll fit i ...
                                </p>
                      <p>
                        Hello Luke If I don't make it b...
                                </p>
                      <span className="datetime">17 min ago</span>
                    </div>
                  </div>
                </li>
                <li className="email-item" onClick={() => { this.toggle('3', 'Lady Valarian'); }}>
                  <div className="email-avatar">
                    <img className="thumb-img img-circle" src={require(`../../../assets/images/profiles/profile-pic-1.jpg`)} />
                  </div>
                  <div className="open-mail">
                    <div className="email-detail">
                      <p className="from">Garindan</p>
                      <p className="subject">
                        That face you make...
                                </p>
                      <p>
                        Hi Luke Good luck. You're gonna...
                                </p>
                      <span className="datetime">48 min ago</span>
                    </div>
                  </div>
                </li>
                <li className="email-item" onClick={() => { this.toggle('4', 'Lady Valarian'); }}>
                  <div className="email-avatar">
                    <img className="thumb-img img-circle" src={require(`../../../assets/images/profiles/profile-pic-9.jpg`)} />
                  </div>
                  <div className="open-mail">
                    <div className="email-detail">
                      <p className="from">Nomi Sunrider</p>
                      <p className="subject">
                        Rouge Group
                                </p>
                      <p>
                        Dear LukeOh! Nice to see a fam...
                                </p>
                      <span className="datetime">1 Hours ago</span>
                    </div>
                  </div>
                </li>
                <li className="email-item" onClick={() => { this.toggle('5', 'Lady Valarian'); }}>
                  <div className="email-avatar">
                    <img className="thumb-img img-circle" src={require(`../../../assets/images/profiles/profile-pic-3.jpeg`)} />
                  </div>
                  <div className="open-mail">
                    <div className="email-detail">
                      <p className="from">Grand Admiral Thrawn</p>
                      <p className="subject">
                        Empire Strikes Back
                                </p>
                      <p>
                        Run! Yes. A Jedi's strength fl...
                      </p>
                      <span className="datetime">Fri 8:40 AM</span>
                    </div>
                  </div>
                </li>
                <li className="email-item" onClick={() => { this.toggle('6', 'Lady Valarian'); }}>
                  <div className="email-avatar">
                    <img className="thumb-img img-circle" src={require(`../../../assets/images/profiles/profile-pic-8.jpg`)} />
                  </div>
                  <div className="open-mail">
                    <div className="email-detail">
                      <p className="from">Sy Snootles</p>
                      <p className="subject">
                        Farewell
                                </p>
                      <p>
                        You have learned much, young o...
                                </p>
                      <span className="datetime">Fri 7:51 AM</span>
                    </div>
                  </div>
                </li>
                <li className="email-item" onClick={() => { this.toggle('7', 'Lady Valarian'); }}>
                  <div className="email-avatar">
                    <img className="thumb-img img-circle" src={require(`../../../assets/images/profiles/profile-pic-5.jpg`)} />
                  </div>
                  <div className="open-mail">
                    <div className="email-detail">
                      <p className="from">Moruth Doole</p>
                      <p className="subject">
                        Feeling all right, s...
                                </p>
                      <p>
                        Hi, Luke Somebody's coming. Oh!...
                                </p>
                      <span className="datetime">Wed 11:27 PM</span>
                    </div>
                  </div>
                </li>
                <li className="email-item" onClick={() => { this.toggle('8', 'Lady Valarian'); }} >
                  <div className="email-avatar">
                    <img className="thumb-img img-circle" src={require(`../../../assets/images/profiles/profile-pic-2.png`)} />
                  </div>
                  <div className="open-mail">
                    <div className="email-detail">
                      <p className="from">Salla Zend</p>
                      <p className="subject">
                        Leave that to me
                                </p>
                      <p>
                        Secure this area until the ale...
                                </p>
                      <span className="datetime">Wed 9:19 PM</span>
                    </div>
                  </div>
                </li>
                <li className="email-item" onClick={() => { this.toggle('9', 'Lady Valarian'); }}>
                  <div className="email-avatar">
                    <img className="thumb-img img-circle" src={require(`../../../assets/images/profiles/profile-pic-5.jpg`)} />
                  </div>
                  <div className="open-mail">
                    <div className="email-detail">
                      <p className="from">Talon Karrde</p>
                      <p className="subject">
                        What are you doing?
                                </p>
                      <p>
                        Run! Yes. A Jedi's strength fl...
                                </p>
                      <span className="datetime">Wed 5:53 PM</span>
                    </div>
                  </div>
                </li>
                <li className="email-item" onClick={() => { this.toggle('10', 'Lady Valarian'); }} >
                  <div className="email-avatar">
                    <img className="thumb-img img-circle" src={require(`../../../assets/images/profiles/profile-pic-4.jpg`)} />
                  </div>
                  <div className="open-mail">
                    <div className="email-detail">
                      <p className="from">Teneniel Djo</p>
                      <p className="subject">
                        Obi-Wan is here
                                </p>
                      <p>
                        Hi Luke Good luck. You're gonna...
                                </p>
                      <span className="datetime">Tue 12:10 AM</span>
                    </div>
                  </div>
                </li>
                <li className="email-item" onClick={() => { this.toggle('11', 'Lady Valarian'); }} >
                  <div className="email-avatar">
                    <img className="thumb-img img-circle" src={require(`../../../assets/images/profiles/profile-pic-3.jpeg`)} />
                  </div>
                  <div className="open-mail">
                    <div className="email-detail">
                      <p className="from">Cindel Towani</p>
                      <p className="subject">
                        All flight trooper
                                </p>
                      <p>
                        You have learned much, young o...
                                </p>
                      <span className="datetime">Tue 6:48 PM</span>
                    </div>
                  </div>
                </li>
              </ul>
            
            </Scrollbars>
          </div>
          
          <div className="email-content" style={{ overflow: 'hidden' }}>
            <Scrollbars
              autoHeight
              autoHeightMin={300}
              autoHeightMax={490}
            >
              <div className="email-content-tools">
                <ul>
                  <li>
                    <a className="back-to-mailbox" href="#">
                      <i className="ti-arrow-circle-left" />
                    </a>
                  </li>
                </ul>
                <ul className="pull-right">
                  <li>
                    <a href="#">
                      <i className="fa fa-reply" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ti-star" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ti-flag" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ti-more-" />
                    </a>
                  </li>
                </ul>
              </div>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <div className="email-content-detail">
                    <div className="detail-head" style={{ display: "flex", justifyContent: "space-between" }}>
                      <div className="p-v-10 p-h-20" style={{display: "flex"}}>
                          <img className="thumb-img img-circle pull-left" src={require(`../../../assets/images/profiles/profile-pic-7.jpg`)} />
                        <div className="info pl-3">
                          <span className="d-block font-size-16 text-dark">Zina Blubber</span>
                          <span className="font-size-12">To: zinablubber@gmail.com</span>
                        </div>
                      </div>
                      <ul className="tools mt-0 mb-0">
                        <li className="font-size-13">Fri 8:40 AM</li>
                        <li className="d-none d-md-inline-block">
                          <a href="#">
                            <i className="fa fa-reply" />
                          </a>
                        </li>
                        <li className="d-none d-md-inline-block dropdown dropdown-animated scale-left">
                          <a href="#" data-toggle="dropdown">
                            <i className="ti-more-" />
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a href="#" className="dropdown-item">Action</a>
                            </li>
                            <li>
                              <a href="#" className="dropdown-item">Another Action</a>
                            </li>
                            <li>
                              <a href="#" className="dropdown-item">Something Else Here</a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="detail-body">
                      <h5 className="mb-2">A Note for all UI/UX devlopers</h5>
                      <div className="mrg-top-15">
                        <p>
                          I hope this message finds you well. I’m reaching out today because I’m managing an application redesign project here at ABC Company and seeking a skilled UX research contractor to help analyze several sets of usability testing data.
                        </p>
                        <p>
                          This is a three-month project beginning February 1st, and we estimate it will take roughly 15 hours per week. All work can be completed remotely, but you’re welcome to use our workspace.
                        </p>
                        <p>
                          Please let me know if you’re interested in this project and we can set up some time to discuss the details further. I look forward to hearing from you.
                        </p>
                      </div>
                    </div>
                    <div className="detail-foot">
                      <ul className="attachments">
                        <li>
                          <a href="#" style={{display: "flex", alignItems: "center"}}>
                            <div className="ml-3" style={{fontSize: "35px"}}>
                              <i className="fa fa-file-pdf" />
                            </div>
                            <div className="file-info">
                              <span className="file-name ">Battle_Report.pdf</span>
                              <span className="file-size "> 18Mb</span>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#" style={{ display: "flex", alignItems: "center" }}>
                            <div className="ml-3" style={{ fontSize: "35px" }}>
                              <i className="fa fa-images" />
                            </div>
                            <div className="file-info">
                              <span className="file-name ">Image_1.jpg</span>
                              <span className="file-size "> 172Kb</span>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="email-content-detail">
                    <div className="detail-head" style={{ display: "flex", justifyContent: "space-between" }}>
                      <div className="p-v-10 p-h-20" style={{ display: "flex" }}>
                          <img className="thumb-img img-circle pull-left" src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} />
                        <div className="info pl-3">
                          <span className="d-block font-size-16 text-dark">Lady Valarian</span>
                          <span className="font-size-12">To: lukeskywalker@gmail.com</span>
                        </div>
                      </div>
                      <ul className="tools mt-0 mb-0">
                        <li className="font-size-13">Fri 8:40 AM</li>
                        <li className="d-none d-md-inline-block">
                          <a href="#">
                            <i className="fa fa-reply" />
                          </a>
                        </li>
                        <li className="d-none d-md-inline-block dropdown dropdown-animated scale-left">
                          <a href="#" data-toggle="dropdown">
                            <i className="ti-more-" />
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a href="#" className="dropdown-item">Action</a>
                            </li>
                            <li>
                              <a href="#" className="dropdown-item">Another Action</a>
                            </li>
                            <li>
                              <a href="#" className="dropdown-item">Something Else Here</a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="detail-body">
                      <h5 className="mb-2">Empire Strikes Back</h5>
                      <div className="mrg-top-15">
                        <p>
                          Luke! Luke, don't - it's a trap! It's a trap! The Force is with you, young Skywalker. But you are not a Jedi yet.
                              </p>
                        <p>
                          Alert all commands. Ready for the tractor beam. Artoo, come back at once! You haven't finished with me yet! You don't know how to fix the hyperdrive. Chewbacca can do it. I'm standing here in pieces, and you're having delusions of grandeur! Oh, you did it!
                              </p>
                        <p>
                          What...what's going on? Turn around, Chewbacca, I can't see. Oh...they've encased him in carbonite. He should be quite well-protected - if he survives the freezing process, that is. Well, Calrissian, did he survive? Yes, he's alive. And in perfect hibernation. He's all yours bounty hunter. Reset the chamber for Skywalker. Skywalker has just landed, my lord. Good. See to it that he finds his way here. Calrissian, take the princess and the Wookiee to my ship. You said they'd be left in the city under my supervision. I am ering the deal. Pray I don't er it any further.
                              </p>
                      </div>
                    </div>
                    <div className="detail-foot">
                      <ul className="attachments">
                        <li>
                          <a href="#" style={{ display: "flex", alignItems: "center" }}>
                            <div className="ml-3" style={{ fontSize: "35px" }}>
                              <i className="fa fa-file-pdf" />
                            </div>
                            <div className="file-info">
                              <span className="file-name ">Battle_Report.pdf</span>
                              <span className="file-size "> 18Mb</span>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#" style={{ display: "flex", alignItems: "center" }}>
                            <div className="ml-3" style={{ fontSize: "35px" }}>
                              <i className="fa fa-images" />
                            </div>
                            <div className="file-info">
                              <span className="file-name ">Image_1.jpg</span>
                              <span className="file-size "> 172Kb</span>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="email-content-detail">
                    <div className="detail-head" style={{ display: "flex", justifyContent: "space-between" }}>
                      <div className="p-v-10 p-h-20" style={{ display: "flex" }}>
                          <img className="thumb-img img-circle pull-left" src={require(`../../../assets/images/profiles/profile-pic-1.jpg`)} />
                        <div className="info pl-3">
                          <span className="d-block font-size-16 text-dark">Garindan</span>
                          <span className="font-size-12">To: lukeskywalker@gmail.com</span>
                        </div>
                      </div>
                      <ul className="tools mt-0 mb-0">
                        <li className="font-size-13">Fri 8:40 AM</li>
                        <li className="d-none d-md-inline-block">
                          <a href="#">
                            <i className="fa fa-reply" />
                          </a>
                        </li>
                        <li className="d-none d-md-inline-block dropdown dropdown-animated scale-left">
                          <a href="#" data-toggle="dropdown">
                            <i className="ti-more-" />
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a href="#" className="dropdown-item">Action</a>
                            </li>
                            <li>
                              <a href="#" className="dropdown-item">Another Action</a>
                            </li>
                            <li>
                              <a href="#" className="dropdown-item">Something Else Here</a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="detail-body">
                      <h5 className="-b-2">Empire Strikes Back</h5>
                      <div className="mrg-top-15">
                        <p>
                          Run! Yes. A Jedi's strength flows from the Force. But beware of the dark side. Anger...fear...aggression. The dark side of the Force are they. Easily they flow, quick to join you in a fight. If once you start down the dark path, forever will it dominate your destiny, consume you it will, as it did Obi-Wan's apprentice. Vader. Is the dark side stronger? No...no...no. Quicker, easier, more seductive. But how am I to know the good side from the bad? You will know. When you are calm, at peace. Passive. A Jedi uses the Force for knowledge and defense, never for attack. But tell me why I can't... No, no, there is no why. Nothing more will I teach you today. Clear your mind of questions. Mmm. Mmmmmm.
                              </p>
                        <p>
                          Luke! Luke, don't - it's a trap! It's a trap! The Force is with you, young Skywalker. But you are not a Jedi yet.
                              </p>
                        <p>
                          Alert all commands. Ready for the tractor beam. Artoo, come back at once! You haven't finished with me yet! You don't know how to fix the hyperdrive. Chewbacca can do it. I'm standing here in pieces, and you're having delusions of grandeur! Oh, you did it!
                              </p>
                        <p>
                          What...what's going on? Turn around, Chewbacca, I can't see. Oh...they've encased him in carbonite. He should be quite well-protected - if he survives the freezing process, that is. Well, Calrissian, did he survive? Yes, he's alive. And in perfect hibernation. He's all yours bounty hunter. Reset the chamber for Skywalker. Skywalker has just landed, my lord. Good. See to it that he finds his way here. Calrissian, take the princess and the Wookiee to my ship. You said they'd be left in the city under my supervision. I am ering the deal. Pray I don't er it any further.
                              </p>
                      </div>
                    </div>
                    <div className="detail-foot">
                      <ul className="attachments">
                        <li>
                          <a href="#" style={{ display: "flex", alignItems: "center" }}>
                            <div className="ml-3" style={{ fontSize: "35px" }}>
                              <i className="fa fa-file-pdf" />
                            </div>
                            <div className="file-info">
                              <span className="file-name ">Battle_Report.pdf</span>
                              <span className="file-size "> 18Mb</span>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#" style={{ display: "flex", alignItems: "center" }}>
                            <div className="ml-3" style={{ fontSize: "35px" }}>
                              <i className="fa fa-images" />
                            </div>
                            <div className="file-info">
                              <span className="file-name ">Image_1.jpg</span>
                              <span className="file-size "> 172Kb</span>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="4">
                  <div className="email-content-detail">
                    <div className="detail-head" style={{ display: "flex", justifyContent: "space-between" }}>
                      <div className="p-v-10 p-h-20" style={{ display: "flex" }}>
                          <img className="thumb-img img-circle pull-left" src={require(`../../../assets/images/profiles/profile-pic-9.jpg`)} />
                        <div className="info pl-3">
                          <span className="d-block font-size-16 text-dark">Nomi Sunrider</span>
                          <span className="font-size-12">To: lukeskywalker@gmail.com</span>
                        </div>
                      </div>
                      <ul className="tools mt-0 mb-0">
                        <li className="font-size-13">Fri 8:40 AM</li>
                        <li className="d-none d-md-inline-block">
                          <a href="#">
                            <i className="fa fa-reply" />
                          </a>
                        </li>
                        <li className="d-none d-md-inline-block dropdown dropdown-animated scale-left">
                          <a href="#" data-toggle="dropdown">
                            <i className="ti-more-" />
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a href="#" className="dropdown-item">Action</a>
                            </li>
                            <li>
                              <a href="#" className="dropdown-item">Another Action</a>
                            </li>
                            <li>
                              <a href="#" className="dropdown-item">Something Else Here</a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="detail-body">
                      <h5 className="mb-2">Empire Strikes Back</h5>
                      <div className="mrg-top-15">
                        <p>
                          What...what's going on? Turn around, Chewbacca, I can't see. Oh...they've encased him in carbonite. He should be quite well-protected - if he survives the freezing process, that is. Well, Calrissian, did he survive? Yes, he's alive. And in perfect hibernation. He's all yours bounty hunter. Reset the chamber for Skywalker. Skywalker has just landed, my lord. Good. See to it that he finds his way here. Calrissian, take the princess and the Wookiee to my ship. You said they'd be left in the city under my supervision. I am ering the deal. Pray I don't er it any further.
                        </p>
                        <p>
                          Run! Yes. A Jedi's strength flows from the Force. But beware of the dark side. Anger...fear...aggression. The dark side of the Force are they. Easily they flow, quick to join you in a fight. If once you start down the dark path, forever will it dominate your destiny, consume you it will, as it did Obi-Wan's apprentice. Vader. Is the dark side stronger? No...no...no. Quicker, easier, more seductive. But how am I to know the good side from the bad? You will know. When you are calm, at peace. Passive. A Jedi uses the Force for knowledge and defense, never for attack. But tell me why I can't... No, no, there is no why. Nothing more will I teach you today. Clear your mind of questions. Mmm. Mmmmmm.
                        </p>
                        <p>
                          Luke! Luke, don't - it's a trap! It's a trap! The Force is with you, young Skywalker. But you are not a Jedi yet.
                        </p>
                        <p>
                          Alert all commands. Ready for the tractor beam. Artoo, come back at once! You haven't finished with me yet! You don't know how to fix the hyperdrive. Chewbacca can do it. I'm standing here in pieces, and you're having delusions of grandeur! Oh, you did it!
                        </p>
                        
                      </div>
                    </div>
                    <div className="detail-foot">
                      <ul className="attachments">
                        <li>
                          <a href="#" style={{ display: "flex", alignItems: "center" }}>
                            <div className="ml-3" style={{ fontSize: "35px" }}>
                              <i className="fa fa-file-pdf" />
                            </div>
                            <div className="file-info">
                              <span className="file-name ">Battle_Report.pdf</span>
                              <span className="file-size "> 18Mb</span>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#" style={{ display: "flex", alignItems: "center" }}>
                            <div className="ml-3" style={{ fontSize: "35px" }}>
                              <i className="fa fa-images" />
                            </div>
                            <div className="file-info">
                              <span className="file-name ">Image_1.jpg</span>
                              <span className="file-size "> 172Kb</span>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="5">
                  <div className="email-content-detail">
                    <div className="detail-head" style={{ display: "flex", justifyContent: "space-between" }}>
                      <div className="p-v-10 p-h-20" style={{ display: "flex" }}>
                          <img className="thumb-img img-circle pull-left" src={require(`../../../assets/images/profiles/profile-pic-3.jpeg`)} />
                        <div className="info pl-3">
                          <span className="d-block font-size-16 text-dark">Grand Admiral Thrawn</span>
                          <span className="font-size-12">To: lukeskywalker@gmail.com</span>
                        </div>
                      </div>
                      <ul className="tools mt-0 mb-0">
                        <li className="font-size-13">Fri 8:40 AM</li>
                        <li className="d-none d-md-inline-block">
                          <a href="#">
                            <i className="fa fa-reply" />
                          </a>
                        </li>
                        <li className="d-none d-md-inline-block dropdown dropdown-animated scale-left">
                          <a href="#" data-toggle="dropdown">
                            <i className="ti-more-" />
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a href="#" className="dropdown-item">Action</a>
                            </li>
                            <li>
                              <a href="#" className="dropdown-item">Another Action</a>
                            </li>
                            <li>
                              <a href="#" className="dropdown-item">Something Else Here</a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="detail-body">
                      <h5 className="mb-2">Empire Strikes Back</h5>
                      <div className="mrg-top-15">
                        <p>
                          Run! Yes. A Jedi's strength flows from the Force. But beware of the dark side. Anger...fear...aggression. The dark side of the Force are they. Easily they flow, quick to join you in a fight. If once you start down the dark path, forever will it dominate your destiny, consume you it will, as it did Obi-Wan's apprentice. Vader. Is the dark side stronger? No...no...no. Quicker, easier, more seductive. But how am I to know the good side from the bad? You will know. When you are calm, at peace. Passive. A Jedi uses the Force for knowledge and defense, never for attack. But tell me why I can't... No, no, there is no why. Nothing more will I teach you today. Clear your mind of questions. Mmm. Mmmmmm.
                              </p>
                        <p>
                          Luke! Luke, don't - it's a trap! It's a trap! The Force is with you, young Skywalker. But you are not a Jedi yet.
                              </p>
                        <p>
                          Alert all commands. Ready for the tractor beam. Artoo, come back at once! You haven't finished with me yet! You don't know how to fix the hyperdrive. Chewbacca can do it. I'm standing here in pieces, and you're having delusions of grandeur! Oh, you did it!
                              </p>
                        <p>
                          What...what's going on? Turn around, Chewbacca, I can't see. Oh...they've encased him in carbonite. He should be quite well-protected - if he survives the freezing process, that is. Well, Calrissian, did he survive? Yes, he's alive. And in perfect hibernation. He's all yours bounty hunter. Reset the chamber for Skywalker. Skywalker has just landed, my lord. Good. See to it that he finds his way here. Calrissian, take the princess and the Wookiee to my ship. You said they'd be left in the city under my supervision. I am ering the deal. Pray I don't er it any further.
                              </p>
                      </div>
                    </div>
                    <div className="detail-foot">
                      <ul className="attachments">
                        <li>
                          <a href="#" style={{ display: "flex", alignItems: "center" }}>
                            <div className="ml-3" style={{ fontSize: "35px" }}>
                              <i className="fa fa-file-pdf" />
                            </div>
                            <div className="file-info">
                              <span className="file-name ">Battle_Report.pdf</span>
                              <span className="file-size "> 18Mb</span>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#" style={{ display: "flex", alignItems: "center" }}>
                            <div className="ml-3" style={{ fontSize: "35px" }}>
                              <i className="fa fa-images" />
                            </div>
                            <div className="file-info">
                              <span className="file-name ">Image_1.jpg</span>
                              <span className="file-size "> 172Kb</span>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="6">
                  <div className="email-content-detail">
                    <div className="detail-head" style={{ display: "flex", justifyContent: "space-between" }}>
                      <div className="p-v-10 p-h-20" style={{ display: "flex" }}>
                          <img className="thumb-img img-circle pull-left" src={require(`../../../assets/images/profiles/profile-pic-8.jpg`)} />
                        <div className="info pl-3">
                          <span className="d-block font-size-16 text-dark">Sy Snootles</span>
                          <span className="font-size-12">To: lukeskywalker@gmail.com</span>
                        </div>
                      </div>
                      <ul className="tools mt-0 mb-0">
                        <li className="font-size-13">Fri 8:40 AM</li>
                        <li className="d-none d-md-inline-block">
                          <a href="#">
                            <i className="fa fa-reply" />
                          </a>
                        </li>
                        <li className="d-none d-md-inline-block dropdown dropdown-animated scale-left">
                          <a href="#" data-toggle="dropdown">
                            <i className="ti-more-" />
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a href="#" className="dropdown-item">Action</a>
                            </li>
                            <li>
                              <a href="#" className="dropdown-item">Another Action</a>
                            </li>
                            <li>
                              <a href="#" className="dropdown-item">Something Else Here</a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="detail-body">
                      <h5 className="mb-2">Empire Strikes Back</h5>
                      <div className="mrg-top-15">
                        <p>
                          Run! Yes. A Jedi's strength flows from the Force. But beware of the dark side. Anger...fear...aggression. The dark side of the Force are they. Easily they flow, quick to join you in a fight. If once you start down the dark path, forever will it dominate your destiny, consume you it will, as it did Obi-Wan's apprentice. Vader. Is the dark side stronger? No...no...no. Quicker, easier, more seductive. But how am I to know the good side from the bad? You will know. When you are calm, at peace. Passive. A Jedi uses the Force for knowledge and defense, never for attack. But tell me why I can't... No, no, there is no why. Nothing more will I teach you today. Clear your mind of questions. Mmm. Mmmmmm.
                              </p>
                        <p>
                          Luke! Luke, don't - it's a trap! It's a trap! The Force is with you, young Skywalker. But you are not a Jedi yet.
                              </p>
                        <p>
                          Alert all commands. Ready for the tractor beam. Artoo, come back at once! You haven't finished with me yet! You don't know how to fix the hyperdrive. Chewbacca can do it. I'm standing here in pieces, and you're having delusions of grandeur! Oh, you did it!
                              </p>
                        <p>
                          What...what's going on? Turn around, Chewbacca, I can't see. Oh...they've encased him in carbonite. He should be quite well-protected - if he survives the freezing process, that is. Well, Calrissian, did he survive? Yes, he's alive. And in perfect hibernation. He's all yours bounty hunter. Reset the chamber for Skywalker. Skywalker has just landed, my lord. Good. See to it that he finds his way here. Calrissian, take the princess and the Wookiee to my ship. You said they'd be left in the city under my supervision. I am ering the deal. Pray I don't er it any further.
                              </p>
                      </div>
                    </div>
                    <div className="detail-foot">
                      <ul className="attachments">
                        <li>
                          <a href="#" style={{ display: "flex", alignItems: "center" }}>
                            <div className="ml-3" style={{ fontSize: "35px" }}>
                              <i className="fa fa-file-pdf" />
                            </div>
                            <div className="file-info">
                              <span className="file-name ">Battle_Report.pdf</span>
                              <span className="file-size "> 18Mb</span>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#" style={{ display: "flex", alignItems: "center" }}>
                            <div className="ml-3" style={{ fontSize: "35px" }}>
                              <i className="fa fa-images" />
                            </div>
                            <div className="file-info">
                              <span className="file-name ">Image_1.jpg</span>
                              <span className="file-size "> 172Kb</span>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="7">
                  <div className="email-content-detail">
                    <div className="detail-head" style={{ display: "flex", justifyContent: "space-between" }}>
                      <div className="p-v-10 p-h-20" style={{ display: "flex" }}>
                          <img className="thumb-img img-circle pull-left" src={require(`../../../assets/images/profiles/profile-pic-5.jpg`)} />
                        <div className="info pl-3">
                          <span className="d-block font-size-16 text-dark">Moruth Doole</span>
                          <span className="font-size-12">To: lukeskywalker@gmail.com</span>
                        </div>
                      </div>
                      <ul className="tools mt-0 mb-0">
                        <li className="font-size-13">Fri 8:40 AM</li>
                        <li className="d-none d-md-inline-block">
                          <a href="#">
                            <i className="fa fa-reply" />
                          </a>
                        </li>
                        <li className="d-none d-md-inline-block dropdown dropdown-animated scale-left">
                          <a href="#" data-toggle="dropdown">
                            <i className="ti-more-" />
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a href="#" className="dropdown-item">Action</a>
                            </li>
                            <li>
                              <a href="#" className="dropdown-item">Another Action</a>
                            </li>
                            <li>
                              <a href="#" className="dropdown-item">Something Else Here</a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="detail-body">
                      <h5 className="mb-2">Empire Strikes Back</h5>
                      <div className="mrg-top-15">
                        <p>
                          Run! Yes. A Jedi's strength flows from the Force. But beware of the dark side. Anger...fear...aggression. The dark side of the Force are they. Easily they flow, quick to join you in a fight. If once you start down the dark path, forever will it dominate your destiny, consume you it will, as it did Obi-Wan's apprentice. Vader. Is the dark side stronger? No...no...no. Quicker, easier, more seductive. But how am I to know the good side from the bad? You will know. When you are calm, at peace. Passive. A Jedi uses the Force for knowledge and defense, never for attack. But tell me why I can't... No, no, there is no why. Nothing more will I teach you today. Clear your mind of questions. Mmm. Mmmmmm.
                              </p>
                        <p>
                          Luke! Luke, don't - it's a trap! It's a trap! The Force is with you, young Skywalker. But you are not a Jedi yet.
                              </p>
                        <p>
                          Alert all commands. Ready for the tractor beam. Artoo, come back at once! You haven't finished with me yet! You don't know how to fix the hyperdrive. Chewbacca can do it. I'm standing here in pieces, and you're having delusions of grandeur! Oh, you did it!
                              </p>
                        <p>
                          What...what's going on? Turn around, Chewbacca, I can't see. Oh...they've encased him in carbonite. He should be quite well-protected - if he survives the freezing process, that is. Well, Calrissian, did he survive? Yes, he's alive. And in perfect hibernation. He's all yours bounty hunter. Reset the chamber for Skywalker. Skywalker has just landed, my lord. Good. See to it that he finds his way here. Calrissian, take the princess and the Wookiee to my ship. You said they'd be left in the city under my supervision. I am ering the deal. Pray I don't er it any further.
                              </p>
                      </div>
                    </div>
                    <div className="detail-foot">
                      <ul className="attachments">
                        <li>
                          <a href="#" style={{ display: "flex", alignItems: "center" }}>
                            <div className="ml-3" style={{ fontSize: "35px" }}>
                              <i className="fa fa-file-pdf" />
                            </div>
                            <div className="file-info">
                              <span className="file-name ">Battle_Report.pdf</span>
                              <span className="file-size "> 18Mb</span>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#" style={{ display: "flex", alignItems: "center" }}>
                            <div className="ml-3" style={{ fontSize: "35px" }}>
                              <i className="fa fa-images" />
                            </div>
                            <div className="file-info">
                              <span className="file-name ">Image_1.jpg</span>
                              <span className="file-size "> 172Kb</span>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </Scrollbars>
          </div>
        </div>

      </div>
    );
  }
}

export default EmailsApp;