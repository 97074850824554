import React from "react";
import "./modals.css";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';

class Modals extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      modalright: false,
      modalneasted: false,
      modalicon: false
    };

    this.toggle = this.toggle.bind(this);
    this.toggleright = this.toggleright.bind(this);
    this.toggleneasted = this.toggleneasted.bind(this);
    this.toggleNesteddemo = this.toggleNesteddemo.bind(this);
    this.toggleAllneasted = this.toggleAllneasted.bind(this);
    this.toggleicon = this.toggleicon.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  toggleright() {
    this.setState({
      modalright: !this.state.modalright
    });
    // console.log(this.modalright);
  }

  toggleneasted() {
    this.setState({
      modalneasted: !this.state.modalneasted
    });
  }

  toggleNesteddemo() {
    this.setState({
      nestedModal: !this.state.nestedModal,
      closeAll: false
    });
  }

  toggleAllneasted() {
    this.setState({
      nestedModal: !this.state.nestedModal,
      closeAll: true
    });
  }

  toggleicon() {
    this.setState({
      modalicon: !this.state.modalicon
    });
  }

  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Modals</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">Components</a>
                <span className="breadcrumb-item active">Modals</span>
              </nav>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card borderless-shadow">
                <div className="card-header">
                  <h4 className="card-title">Basic Modal</h4>
                </div>

                <div className="card-body">
                  <div className="d-flex align-items-center h-100">
                    <div className="d-block">
                      <p>Modals are streamlined, but flexible, dialog prompts with the minimum required functionality and smart defaults. You can use a modal for dialog boxes, confirmation messages, or other content that can be called up.</p>
                      <div className="m-t-25">
                        <Button color="info" onClick={this.toggle} className="btn-gradient-card-blue">Generate</Button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-md-6 d-flex">
              <div className="card borderless-shadow">
                <div className="card-header">
                  <h4 className="card-title">Sign in Modal</h4>
                </div>

                <div className="card-body">
                  <div className="d-flex align-items-center h-100">
                    <div className="d-block">
                      <p>Modals are streamlined, but flexible, dialog prompts with the minimum required functionality and smart defaults.</p>
                      <p>This is Basic sign up modal.</p>
                      <div className="m-t-25">
                        <Button color="info" onClick={this.toggleright} className="btn-gradient-card-blue">Generate</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card borderless-shadow nested-modal">
                <div className="card-header">
                  <h4 className="card-title">Nested Modals</h4>
                </div>

                <div className="card-body">
                  <div className="d-flex align-items-center h-100">
                    <div className="d-block">
                      <p>Modals are streamlined, but flexible, dialog prompts with the minimum required functionality and smart defaults.</p>
                      <p>You can nest modals inside of modal.</p>
                      <div className="m-t-25">
                        <Button onClick={this.toggleneasted} className="btn-gradient-card-blue">Generate</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 d-flex">
              <div className="card borderless-shadow modal-with-custom-close">
                <div className="card-header">
                  <h4 className="card-title">Modals with custom close icon & size</h4>
                </div>

                <div className="card-body">
                  <div className="d-flex align-items-center h-100">
                    <div className="d-block">
                      <p>Modals have two optional sizes, available <code>size='lg'</code> &amp; <code>size='sm'</code> to be placed as prop. These sizes kick in at certain breakpoints to avoid horizontal scrollbars on narrower viewports.</p>
                      <div className="m-t-25">
                        <Button color="info" onClick={this.toggleicon} className="btn-gradient-card-blue">Generate</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          {/* BASIC MODAL */}
          <Modal isOpen={this.state.modal} toggle={this.toggle} >
            <ModalHeader toggle={this.toggle}>Basic Modal</ModalHeader>
            <ModalBody>
              <p>Epic cheeseburgers come in all kinds of manifestations, but we want them in and around our mouth no matter what. Slide those smashed patties with the gently caramelized meat fat between a toasted brioche bun and pass it over. You fall in love with the cheeseburger itself but the journey ain’t half bad either.</p>
              <p>They’re the childhood friend that knows your highest highs and lowest lows. They’ve been with you through thick and thin and they’re the best at keeping secrets. Whether it’s dressed up or informal, cheeseburgers have your back.</p>
            </ModalBody>
            <ModalFooter>
              <Button className="btn-gradient-card-blue" onClick={this.toggle}>Cancel</Button>{' '}
              <Button color="secondary" onClick={this.toggle}>ok</Button>
            </ModalFooter>
          </Modal>

          {/* Modal SIGN UP*/}
          <Modal isOpen={this.state.modalright} toggle={this.toggleright} className="modal-right" >
            <div className="side-modal-wrapper">
              <div className="vertical-align">
                <div className="table-cell">
                  <ModalHeader toggle={this.toggleright}>Sign Up</ModalHeader>
                  <ModalBody>
                    <div className="p-h-15">
                      <p className="m-b-15 font-size-13">Please enter your email and password to create account</p>
                      <form>
                        <FormGroup>
                          <input type="email" className="form-control" placeholder="Email Adress" />
                        </FormGroup>
                        <FormGroup>
                          <input type="password" className="form-control" placeholder="Password" />
                        </FormGroup>
                        <div className="checkbox font-size-13 m-b-10">
                          <input id="agreement" name="agreement" type="checkbox"/>
                          <label className="pl-2"  htmlFor="agreement">I agree with the <a href="#">Privacy &amp; Policy</a></label>
                        </div>
                        <Button className="btn-gradient-card-green" onClick={this.toggleright}>Sign Up</Button>
                      </form>
                    </div>
                  </ModalBody>
                </div>
              </div>
              <ModalFooter>
                <span>Already have an account? <a href="#">Login Now</a></span>
              </ModalFooter>
            </div>
          </Modal>

          {/* Nested Modal */}
          <Modal isOpen={this.state.modalneasted} toggle={this.toggleneasted} className={this.props.className}>
            <ModalHeader toggle={this.toggleneasted}>Neasted Modal</ModalHeader>
            <ModalBody>
              <p>Epic cheeseburgers come in all kinds of manifestations, but we want them in and around our mouth no matter what. Slide those smashed patties with the gently caramelized meat fat between a toasted brioche bun and pass it over. You fall in love with the cheeseburger itself but the journey ain’t half bad either.</p>

              <Button className="btn-gradient-card-orange" onClick={this.toggleNesteddemo}>Show Nested Modal</Button>
              <Modal isOpen={this.state.nestedModal} toggle={this.toggleNesteddemo} onClosed={this.state.closeAll ? this.toggle : undefined}>
                <ModalHeader>Nested Modal Demo</ModalHeader>
                <ModalBody>Modal Suffe</ModalBody>
                <ModalFooter>
                  <Button className="btn-gradient-card-blue" onClick={this.toggleNesteddemo}>Done</Button>{' '}
                  {/* <Button className="btn-gradient-card-orange" onClick={this.toggleAllneasted}>All Done</Button> */}
                  <Button className="btn-gradient-card-orange" onClick={this.toggleNesteddemo}>All Done</Button>
                </ModalFooter>
              </Modal>
            </ModalBody>
            <ModalFooter>
              <Button className="btn-gradient-card-blue" onClick={this.toggleneasted}>Do Something</Button>{' '}
              <Button color="secondary" onClick={this.toggleneasted}>Cancel</Button>
            </ModalFooter>
          </Modal>

          {/* Modals with custom close icon */}
          <Modal isOpen={this.state.modalicon} toggle={this.toggleicon} size="lg" >
            <ModalHeader toggle={this.toggleicon} charCode="Y">Modal Custom Icon</ModalHeader>
            <ModalBody>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </ModalBody>
            <ModalFooter>
              <Button className="btn-gradient-card-blue" onClick={this.toggleicon}>Cancel</Button>{' '}
              <Button color="secondary" onClick={this.toggleicon}>ok</Button>
            </ModalFooter>
          </Modal>
          
        </div>
      </div>
    );
  }
}

export default Modals;
