import React from "react";
import "./colors.css";

import { SketchPicker, PhotoshopPicker, ChromePicker, AlphaPicker, BlockPicker, TwitterPicker, SwatchesPicker, CirclePicker, SliderPicker, CompactPicker, GithubPicker, HuePicker } from 'react-color';
import reactCSS from 'reactcss'

class Colors extends React.Component {
  constructor(props) {
    super(props);

    this.handleChangeComplete = this.handleChangeComplete.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickbutton = this.handleClickbutton.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClosebutton = this.handleClosebutton.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      background: '#fff',
      displayColorPicker: false,
      displayColorPickerbutton: false,
      color: {
        r: '241',
        g: '112',
        b: '19',
        a: '1',
      },
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  handleClick() {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }
  handleClickbutton() {
    this.setState({ displayColorPickerbutton: !this.state.displayColorPickerbutton })
  }
  handleChange(color) {
    this.setState({ color: color.rgb })
  }

  handleClose() {
    this.setState({ displayColorPicker: false })
  }
  handleClosebutton() {
    this.setState({ displayColorPickerbutton: false })
  }
  handleChangeComplete(color) {
    this.setState({ background: color.hex });
  }
  photohandleChangeComplete(color, event) {
    this.setState({ background: color.hex });
  }

  render() {
    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });
    const popover = {
      position: 'absolute',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Colors</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1"></i>Home</a>
                <a className="breadcrumb-item" href="#">UI Elements</a>
                <span className="breadcrumb-item active">Colors</span>
              </nav>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 mb-30">
              <div className="card card-statistics mb-30 borderless-shadow">
                <div className="card-body">
                  <h5 className="card-title mb-3">SketchPicker </h5>
                  <div className="input-group colorpicker-component" title="Using input value">
                    <div style={styles.swatch} onClick={this.handleClick}>
                      <div style={styles.color} />
                    </div>
                    {this.state.displayColorPicker ? <div style={styles.popover}>
                      <div style={styles.cover} onClick={this.handleClose} />
                      <SketchPicker color={this.state.color} onChange={this.handleChange} />
                    </div> : null
                    }
                  </div>
                </div>
              </div>
              <div className="card card-statistics mb-30 borderless-shadow">
                <div className="card-body">
                  <h5 className="card-title mb-3">ChromePicker </h5>
                  <button onClick={this.handleClickbutton}
                    className="btn btn-gradient-card-blue"
                  >
                    Pick Color
                  </button>
                  {this.state.displayColorPickerbutton ? <div style={popover}>
                    <div style={cover} onClick={this.handleClosebutton} />
                    <ChromePicker />
                  </div> : null}
                </div>
              </div>
              <div className="card card-statistics mb-30 borderless-shadow">
                <div className="card-body">
                  <h5 className="card-title mb-3">SliderPicker </h5>
                  <SliderPicker />
                </div>
              </div>
              <div className="card card-statistics mb-30 borderless-shadow">
                <div className="card-body">
                  <h5 className="card-title mb-3">AlphaPicker</h5>
                  <div id="cp5" className="input-group colorpicker-component colorpicker-element" title="Using format option" data-colorpicker-id={6}>
                    <AlphaPicker width="100%" />
                  </div>
                </div>
              </div>
              <div className="card card-statistics mb-30 borderless-shadow">
                <div className="card-body">
                  <h5 className="card-title mb-3">SwatchesPicker</h5>
                  <SwatchesPicker width='100%'/>
                </div>
              </div>
              <div className="card card-statistics mb-30 borderless-shadow">
                <div className="card-body">
                  <h5 className="card-title mb-3">CirclePicker</h5>
                  <div id="cp5" className="input-group colorpicker-component colorpicker-element" title="Using format option" data-colorpicker-id={6}>
                    <CirclePicker width="100%" />
                  </div>
                </div>
              </div>
              <div className="card card-statistics mb-30 borderless-shadow">
                <div className="card-body">
                  <h5 className="card-title mb-3">SketchPicker</h5>
                  <div id="cp5" className="input-group colorpicker-component colorpicker-element" title="Using format option" data-colorpicker-id={6}>
                    <SketchPicker width="100%" />
                  </div>
                </div>
              </div>
              <div className="card card-statistics mb-30 borderless-shadow color-chrome-picker">
                <div className="card-body">
                  <h5 className="card-title mb-3">ChromePicker</h5>
                  <div id="cp5" className="input-group colorpicker-component colorpicker-element" title="Using format option" data-colorpicker-id={6}>
                    <ChromePicker />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 mb-30" style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
              <div className="card card-statistics mb-30 borderless-shadow">
                <div className="card-body">
                  <h5 className="card-title mb-3">CompactPicker</h5>
                  <div id="cp9" className="input-group colorpicker-component colorpicker-element" data-colorpicker-id={11}>
                    <CompactPicker />
                  </div>
                </div>
              </div>
              <div className="card card-statistics mb-30 borderless-shadow">
                <div className="card-body">
                  <h5 className="card-title mb-3">HuePicker</h5>
                  <div id="cp9" className="input-group colorpicker-component colorpicker-element" data-colorpicker-id={11}>
                    <HuePicker width="100%" />
                  </div>
                </div>
              </div>
              <div className="card card-statistics mb-30 borderless-shadow">
                <div className="card-body">
                  <h5 className="card-title mb-3">GithubPicker</h5>
                  <div id="cp9" className="input-group colorpicker-component colorpicker-element" data-colorpicker-id={11}>
                    <GithubPicker width="100%" />
                  </div>
                </div>
              </div>
              <div className="card card-statistics mb-30 borderless-shadow">
                <div className="card-body">
                  <h5 className="card-title mb-3">SketchPicker </h5>
                  <SketchPicker color={this.state.background} onChangeComplete={this.handleChangeComplete} width="90%" />
                </div>
              </div>
              <div className="card card-statistics mb-30 borderless-shadow">
                <div className="card-body">
                  <h5 className="card-title mb-3">PhotoshopPicker</h5>
                  <div id="cp9" className="input-group colorpicker-component colorpicker-element"
                    data-colorpicker-id={11}>
                    <PhotoshopPicker onChangeComplete={this.photohandleChangeComplete} />
                  </div>
                </div>
              </div>
              <div className="card card-statistics mb-30 borderless-shadow">
                <div className="card-body">
                  <h5 className="card-title mb-3">BlockPicker</h5>
                  <div id="cp9" className="input-group colorpicker-component colorpicker-element" data-colorpicker-id={11}>
                    <BlockPicker width="100%" />
                  </div>
                </div>
              </div>
              <div className="card card-statistics mb-30 borderless-shadow color-twitter-picker">
                <div className="card-body">
                  <h5 className="card-title mb-3">TwitterPicker</h5>
                  <div id="cp9" className="input-group colorpicker-component colorpicker-element" data-colorpicker-id={11}>
                    <TwitterPicker width="100%" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Colors;