import React from "react";
import "./navs.css";

import { Row, Col, Nav, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, NavItem, NavLink } from 'reactstrap';

class Navs extends React.Component {
  constructor(props) {
    super(props);

    this.tabdropdown = this.tabdropdown.bind(this);
    this.pillsdropdown = this.pillsdropdown.bind(this);

    this.state = {
      tabdropdownOpen: false,
      pillsdropdownOpen: false
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  tabdropdown() {
    this.setState({
      tabdropdownOpen: !this.state.tabdropdownOpen
    });
  }
  pillsdropdown() {
    this.setState({
      pillsdropdownOpen: !this.state.pillsdropdownOpen
    });
  }

  render() {
    return (

      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Navs</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">UI Elements</a>
                <span className="breadcrumb-item active">Navs</span>
              </nav>
            </div>
          </div>

          <Row>
            <Col md={6} mb={30}>
              <div className="card card-statistics mb-30 borderless-shadow">
                <div className="card-body">
                  <h5 className="card-title mb-3">Base nav</h5>
                  <Nav>
                    <NavItem>
                      <NavLink>Nav 1 content</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink>Nav 2 content</NavLink>
                    </NavItem>
                    <NavItem disabled>
                      <NavLink>Disable</NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
              <div className="card card-statistics mb-30 borderless-shadow">
                <div className="card-body">
                  <h5 className="card-title mb-3">Base nav a tag</h5>
                  <Nav tabs >
                    <NavItem>
                      <NavLink>Nav 1 content</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink>Nav 2 content</NavLink>
                    </NavItem>
                    <NavItem disabled>
                      <NavLink>Disable</NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
              <div className="card card-statistics borderless-shadow">
                <div className="card-body">
                  <h5 className="card-title mb-3">Pills with dropdowns</h5>
                  <Nav pills>
                    <NavItem>
                      <NavLink active>Nav 1 </NavLink>
                    </NavItem>
                    <NavItem >
                      <NavLink>Nav 2 </NavLink>
                    </NavItem>
                    <NavItem disabled>
                      <NavLink>Disable</NavLink>
                    </NavItem>
                    <Dropdown nav isOpen={this.state.pillsdropdownOpen} toggle={this.pillsdropdown}>
                      <DropdownToggle nav caret className="dropdownLink">
                        Dropdown
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-shadow">
                        <DropdownItem header>Action</DropdownItem>
                        <DropdownItem disabled>Another Action</DropdownItem>
                        <DropdownItem>Something else here</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>Separated link</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>

                  </Nav>
                </div>
              </div>
              <div className="card card-statistics mb-3 borderless-shadow vertical-nav-tabs">
                <div className="card-body">
                  <h5 className="card-title mb-3">Vertical nav Tabs</h5>
                  <Nav tabs vertical>
                    <NavItem  >
                      <NavLink href="#">Nav 1 content</NavLink>
                    </NavItem>
                    <NavItem >
                      <NavLink href="#">Nav 2 content</NavLink>
                    </NavItem>
                    <NavItem >
                      <NavLink href="#">Nav 3 content</NavLink>
                    </NavItem>
                    <NavItem disabled>
                      <NavLink href="#">Nav 4 content</NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
            </Col>

            <Col md={6} mb={30}>
              <div className="card card-statistics mb-30 borderless-shadow">
                <div className="card-body">
                  <h5 className="card-title mb-3">Fill and justify</h5>
                  <Nav pills>
                    <NavItem >
                      <NavLink>Nav 1 content</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink>Nav 2 content</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink>Nav 3 content</NavLink>
                    </NavItem>
                    <NavItem disabled>
                      <NavLink>Nav 4 content</NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
              <div className="card card-statistics mb-30 borderless-shadow">
                <div className="card-body">
                  <h5 className="card-title mb-3">Tabs with dropdowns</h5>
                  <Nav tabs>
                    <NavItem >
                      <NavLink>Nav 1 </NavLink>
                    </NavItem>
                    <NavItem >
                      <NavLink>Nav 2 </NavLink>
                    </NavItem>
                    <NavItem disabled>
                      <NavLink>Disable</NavLink>
                    </NavItem>
                    <Dropdown nav isOpen={this.state.tabdropdownOpen} toggle={this.tabdropdown}>
                      <DropdownToggle nav caret>
                        Dropdown
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-shadow">
                        <DropdownItem header>Action</DropdownItem>
                        <DropdownItem disabled>Another Action</DropdownItem>
                        <DropdownItem>Something else here</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>Separated link</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>

                  </Nav>
                </div>
              </div>
              <div className="card card-statistics mb-30 borderless-shadow vertical-navs">
                <div className="card-body">
                  <h5 className="card-title mb-3">Vertical Navs</h5>
                  <Nav vertical>
                    <NavItem>
                      <NavLink href="#">Nav 1 content</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="#">Nav 2 content</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="#">Nav 3 content</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink disabled href="#">Disabled Link</NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>

            </Col>
          </Row>

        </div>
      </div>

        
    );
  }
}

export default Navs;