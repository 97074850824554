import React from "react";
import "./data-table.css";

import { CardBody, CardHeader, Card, CardTitle, Row, Col, Alert, UncontrolledAlert } from 'reactstrap';

import BootstrapTableNext from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const employees = [
  {
    "id": 1,
    "name": "Ethel Price",
    "gender": "female",
    "company": "Johnson LLC CMP DDC",
    "age": 22,
  },
  {
    "id": 2,
    "name": "Claudine Neal",
    "gender": "female",
    "company": "Sealoud",
    "age": 55
  },
  {
    "id": 3,
    "name": "Beryl Rice",
    "gender": "female",
    "company": "Velity",
    "age": 67
  },
  {
    "id": 4,
    "name": "Wilder Gonzales",
    "gender": "male",
    "company": "Geekko",
    "age": 28
  },
  {
    "id": 5,
    "name": "Georgina Schultz",
    "gender": "female",
    "company": "Suretech",
    "age": 65
  },
  {
    "id": 6,
    "name": "Carroll Buchanan",
    "gender": "male",
    "company": "Ecosys",
    "age": 46
  },
  {
    "id": 7,
    "name": "Valarie Atkinson",
    "gender": "female",
    "company": "Hopeli",
    "age": 22
  },
  {
    "id": 8,
    "name": "Schroeder Mathews",
    "gender": "male",
    "company": "Polarium",
    "age": 37
  },
  {
    "id": 9,
    "name": "Lynda Mendoza",
    "gender": "female",
    "company": "Dogspa",
    "age": 33
  },
  {
    "id": 10,
    "name": "Sarah Massey",
    "gender": "female",
    "company": "Bisba",
    "age": 44
  },
  {
    "id": 11,
    "name": "Robles Boyle",
    "gender": "male",
    "company": "Comtract",
    "age": 46
  },
  {
    "id": 12,
    "name": "Evans Hickman",
    "gender": "male",
    "company": "Parleynet",
    "age": 28
  },
  {
    "id": 13,
    "name": "Dawson Barber",
    "gender": "male",
    "company": "Dymi",
    "age": 36
  },
  {
    "id": 14,
    "name": "Bruce Strong",
    "gender": "male",
    "company": "Xyqag",
    "age": 55
  },
  {
    "id": 15,
    "name": "Nellie Whitfield",
    "gender": "female",
    "company": "Exospace",
    "age": 60
  },
  {
    "id": 16,
    "name": "Jackson Macias",
    "gender": "male",
    "company": "Aquamate",
    "age": 51
  },
  {
    "id": 17,
    "name": "Pena Pena",
    "gender": "male",
    "company": "Quarx",
    "age": 50
  },
  {
    "id": 18,
    "name": "Lelia Gates",
    "gender": "female",
    "company": "Proxsoft",
    "age": 31
  },
  {
    "id": 19,
    "name": "Letitia Vasquez",
    "gender": "female",
    "company": "Slumberia",
    "age": 40
  },
  {
    "id": 20,
    "name": "Trevino Moreno",
    "gender": "male",
    "company": "Conjurica",
    "age": 47
  },
  {
    "id": 21,
    "name": "Barr Page",
    "gender": "male",
    "company": "Apex",
    "age": 48
  },
  {
    "id": 22,
    "name": "Kirkland Merrill",
    "gender": "male",
    "company": "Utara",
    "age": 29
  },
  {
    "id": 24,
    "name": "Blanche Conley",
    "gender": "female",
    "company": "Imkan",
    "age": 53
  },
  {
    "id": 25,
    "name": "Claire Menz",
    "gender": "female",
    "company": "AEA",
    "age": 25
  },
  {
    "id": 26,
    "name": "Frank Martin",
    "gender": "male",
    "company": "AEA",
    "age": 26
  },
  {
    "id": 27,
    "name": "Blanca Cloe",
    "gender": "female",
    "company": "AEA",
    "age": 24
  }
]
const employeesColumns = [
  {
    dataField: 'id',
    text: 'ID',
    sort: true,
    headerStyle: { width: '10%' }
  },
  {
    dataField: 'name',
    text: 'Customer Name',
    sort: true,
    headerStyle: { width: '30%' },
    formatter: imageFormatter,
    // formatExtraData: {
    //   profileImage: '../../../../src/assets/images/profiles/profile-pic-6.jpg',
    // }
  },
  {
    dataField: 'gender',
    text: 'Gender'
  },
  {
    dataField: 'company',
    text: 'Company'
  },
  {
    dataField: 'age',
    text: 'Age',
    sort: true
  }
];

function imageFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{ height: '35px', width: '35px', borderRadius: '4px', overflow: 'hidden', marginRight: '1rem' }}
      >
        <img src={require(`../../../assets/images/profiles/profile-pic-${row.id}.jpg`)} width="100%" />
      </div>
      {cell}
    </div>
  );
}

const rowClasses = (row, rowIndex) => {
  let classes = null;

  if ((rowIndex + 1) % 2) {
    classes = 'custom-data-table-row-striped-color';
  }

  return classes;
};
const customPaginationTotal = (from, to, size) => {
  return (
    <span className="react-bootstrap-table-pagination-total">
      Showing { from} to { to} of { size} Results
    </span>
  );
};

const customPaginationOptions = {
  paginationSize: 4,
  pageStartIndex: 0,
  // alwaysShowAllBtns: true, // Always show next and previous button
  // withFirstAndLast: false, // Hide the going to First and Last page button
  // hideSizePerPage: true, // Hide the sizePerPage dropdown always
  // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
  firstPageText: 'First',
  prePageText: 'Back',
  nextPageText: 'Next',
  lastPageText: 'Last',
  nextPageTitle: 'First page',
  prePageTitle: 'Pre page',
  firstPageTitle: 'Next page',
  lastPageTitle: 'Last page',
  showTotal: true,
  paginationTotalRenderer: customPaginationTotal,
  disablePageTitle: true,
  sizePerPageList: [
    { text: '10', value: 10 },
    { text: '5', value: 5 },
    { text: '20', value: 20 },
    { text: 'All', value: employees.length }
  ] // A numeric array is also available. the purpose of above example is custom the text
};
const { SearchBar } = Search;

class DataTables extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {

    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Data Table</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">Tables</a>
                <span className="breadcrumb-item active">Data Table</span>
              </nav>
            </div>
          </div>

          <Card className="borderless-shadow mb-0">
            <CardBody>
              <div className="table-overflow-x">
                <ToolkitProvider
                  bootstrap4
                  keyField='id'
                  data={employees}
                  columns={employeesColumns}
                  hover
                  search
                  className="table-overflow-x"
                >
                  {
                    props => (
                      <div>
                        <div
                          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                          className="mb-3"
                        >
                          <h3 className="mb-2 font-size-22 font-weight-semibold">Customer Data Table</h3>
                          <SearchBar
                            {...props.searchProps}
                            className="dash2-data-table-serach-input"
                            delay={500}
                            placeholder="Search the Data Table"
                          />
                        </div>
                        <BootstrapTableNext
                          pagination={paginationFactory(customPaginationOptions)}
                          rowClasses={rowClasses}
                          {...props.baseProps}
                        />
                      </div>
                    )
                  }
                </ToolkitProvider>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default DataTables;