import React from 'react';
import "./dashboard1.css";
import { Row, Col, Card, CardBody } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';

class Dashboard1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      barSeries: [
        { name: 'Net Profit', data: [44, 55, 57, 56, 61, 58, 63, 60, 66] },
        { name: 'Revenue', data: [76, 85, 101, 98, 87, 105, 91, 114, 94] },
        { name: 'Free Cash Flow', data: [35, 41, 36, 26, 45, 48, 52, 53, 41] }
      ],
      barOptions: {
        grid: { borderColor: '#eee' },
        dataLabels: { enabled: false },
        stroke: { show: true, width: 4, colors: ['transparent'] },
        fill: { opacity: 1, colors: ['#28b366', '#005bea', '#f18f1e'] },
        chart: { type: 'bar', height: 350, toolbar: { show: false } },
        xaxis: { categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'], },
        // yaxis: { title: { text: '$ (thousands)' } },
        tooltip: { y: { formatter: function (val) { return "$ " + val + " thousands" } } },
        plotOptions: { bar: { horizontal: false, columnWidth: '70%', endingShape: 'rounded' } },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'right',
          itemMargin: { horizontal: 10, vertical: 0 },
          markers: { fillColors: ['#28b366', '#005bea', '#f18f1e'] }
        }
      },

      radialSeries: [44, 55, 67, 83],
      radialOptions: {
        chart: { height: 350, type: 'radialBar', },
        labels: ['Apples', 'Oranges', 'Bananas', 'Berries'],
        fill: { opacity: 1, colors: ['#28b366', '#005bea', '#f18f1e', '#f93a5a'] },
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: { fontSize: '22px' },
              value: { fontSize: '16px' },
              style: { colors: ['#28b366', '#005bea', '#f18f1e', '#f93a5a'] },
              total: { show: true, label: 'Total', formatter: function (w) {
                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                return 3156
              }}
            }
          }
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'center',
          itemMargin: { horizontal: 10 },
          markers: { fillColors: ['#28b366', '#005bea', '#f18f1e', '#f93a5a'] }
        }
      },

      radialGradientSeries: [75],
      radialGradientOptions: {
        chart: { type: 'radialBar', toolbar: { show: false } },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: '80%',
              background: '#fff',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24
              }
            },
            track: {
              background: '#fff',
              strokeWidth: '97%',
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35
              }
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: '#888',
                fontSize: '17px'
              },
              value: {
                formatter: function (val) {
                  return parseInt(val);
                },
                color: '#111',
                fontSize: '36px',
                show: true,
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#005bea'],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: 'round'
        },
        labels: ['Percent'],
      },
    };

  };
  componentDidMount() {
      window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pt-2 pr-0 pl-0">

          <Row className="mt-4">
            <Col md={6} xl={3} className="d-flex">
              <Card className="borderless-shadow top-card-main-container top-card-main-container-blue w-100">
                <CardBody>
                  <div className="top-card-inc-dec">
                    <i className="far fa-arrow-alt-circle-up mr-1"></i>
                    <span>12%</span>
                  </div>
                  <h6 className="font-size-16 font-weight-semibold mb-3 text-white">Sales Online</h6>
                  <div className="top-card-detail-container">
                    <div>
                      <p className="font-size-17 font-weight-bold mb-0 text-white">3,156</p>
                      <p className="font-size-12 mb-0 text-white" style={{ opacity: "0.7" }}>
                        As per previous month
                      </p>
                    </div>
                    <div className="dashboard1-topcard-icon-container-blue">
                      <i className="fas fa-store font-size-25"></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} xl={3} className="d-flex">
              <Card className="borderless-shadow top-card-main-container top-card-main-container-orange w-100">
                <CardBody>
                  <div className="top-card-inc-dec">
                    <i className="far fa-arrow-alt-circle-down mr-1"></i>
                    <span>21%</span>
                  </div>
                  <h6 className="font-size-16 font-weight-semibold mb-3 text-white">Sales Offline</h6>
                  <div className="top-card-detail-container">
                    <div>
                      <p className="font-size-17 font-weight-bold mb-0 text-white">1,740</p>
                      <p className="font-size-12 mb-0 text-white" style={{ opacity: "0.7" }}>
                        As per previous month
                      </p>
                    </div>
                    <div className="dashboard1-topcard-icon-container-orange">
                      <i className="fas fa-shopping-bag font-size-25"></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} xl={3} className="d-flex">
              <Card className="borderless-shadow top-card-main-container top-card-main-container-green w-100">
                <CardBody>
                  <div className="top-card-inc-dec">
                    <i className="far fa-arrow-alt-circle-up mr-1"></i>
                    <span>16%</span>
                  </div>
                  <h6 className="font-size-16 font-weight-semibold mb-3 text-white">Earnings</h6>
                  <div className="top-card-detail-container">
                    <div>
                      <p className="font-size-17 font-weight-bold mb-0 text-white">$3,199</p>
                      <p className="font-size-12 mb-0 text-white" style={{ opacity: "0.7" }} >
                        As per previous month
                      </p>
                    </div>
                    <div className="dashboard1-topcard-icon-container-green">
                      <i className="fas fa-dollar-sign font-size-25"></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} xl={3} className="d-flex">
              <Card className="borderless-shadow top-card-main-container top-card-main-container-red w-100">
                <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className="top-card-inc-dec">
                    <i className="far fa-check-circle mr-1"></i>
                    <span>No Dues</span>
                  </div>
                  <h6 className="font-size-16 font-weight-semibold mb-3 text-white">Balance</h6>
                  <div className="top-card-detail-container" style={{ flex: 1 }}>
                    <div>
                      <p className="font-size-17 font-weight-bold mb-0 text-white">$78,190</p>
                      <p className="font-size-12 mb-0 text-white" style={{ opacity: "0.7" }}>
                        As of Current Date
                      </p>
                    </div>
                    <div className="dashboard1-topcard-icon-container-red">
                      <i className="fas fa-wallet font-size-25"></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={7} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardBody className="pt-3 pl-0 pr-0 pb-0">
                  <ReactApexChart
                    options={this.state.barOptions}
                    series={this.state.barSeries}
                    type="bar"
                    height={370}
                  />
                </CardBody>
              </Card>
            </Col>

            <Col md={5} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardBody className="pt-4 pl-0 pr-0 pb-0">
                  <ReactApexChart
                    options={this.state.radialOptions}
                    series={this.state.radialSeries}
                    type="radialBar" height={370}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={4} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardBody>
                  <h6 className="font-size-17 font-weight-semibold">Recent Sale Order</h6>
                  <p className="font-size-12 text-muted">Duis in euismod orci. Proin ac viverra eros. Nullam scelerisque vitae lorem in bibendum. Mauris pharetra nibh eu sapien tempus.</p>

                  <div className="radial-gradient-chart-container mt-4">
                    <ReactApexChart options={this.state.radialGradientOptions} series={this.state.radialGradientSeries} type="radialBar" width="100%" />
                  </div>

                  <div className="radial-sale">
                    <div>
                      <p className="font-size-18 font-weight-semibold mb-2">Online Sale</p>
                      <span className="font-weight-bold">3156</span>
                    </div>
                    <div>
                      <p className="font-size-18 font-weight-semibold mb-2">Offline Sale</p>
                      <span className="font-weight-bold">1740</span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={8} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardBody>
                  <h6 className="font-size-17 font-weight-semibold">Your recent Earnings</h6>
                  <p className="font-size-14 text-muted">Showing recent company earnings on every sales</p>
                  <div className="table-overflow-x">
                    <table className="earnings-table">
                      <thead>
                        <tr>
                          <th>DATE</th>
                          <th>SALES</th>
                          <th>EARNINGS</th>
                          <th style={{ width: '20%' }}>TAX WITHELD</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>2 July 2020</td>
                          <td>44</td>
                          <td>$459</td>
                          <td className="tax-in-red">-$49.20</td>
                        </tr>
                        <tr>
                          <td>4 July 2020</td>
                          <td>26</td>
                          <td>$205</td>
                          <td className="tax-in-red">-$24.00</td>
                        </tr>
                        <tr>
                          <td>5 July 2020</td>
                          <td>98</td>
                          <td>$1542</td>
                          <td className="tax-in-red">-$140.50</td>
                        </tr>
                        <tr>
                          <td>5 July 2020</td>
                          <td>23</td>
                          <td>$194</td>
                          <td className="tax-in-red">-$11.20</td>
                        </tr>
                        <tr>
                          <td>7 July 2020</td>
                          <td>54</td>
                          <td>$1103</td>
                          <td className="tax-in-red">-$98.50</td>
                        </tr>
                        <tr>
                          <td>9 July 2020</td>
                          <td>67</td>
                          <td>$1652</td>
                          <td className="tax-in-red">-$158.50</td>
                        </tr>
                        <tr>
                          <td>9 July 2020</td>
                          <td>34</td>
                          <td>$582</td>
                          <td className="tax-in-red">-$79.50</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={12} xl={4} className="d-flex">
              <Card className="borderless-shadow w-100 sales-activity-card">
                <CardBody className="sale-activity-body">
                  <h5 className="font-size-17 font-weight-semibold">Sales activity</h5>
                  <p className="font-size-14 text-muted">Showing activities of past 7 days.</p>
                  
                  <div className="sale-activity-bar-wrapper">
                    <div className="sale-activity-container mb-3">
                      <div className="sale-activity-icon-container sa-icon-bg-blue">
                        <i className="fas fa-archive sale-activity-icon"></i>
                      </div>
                      <div style={{display: "flex", justifyContent: "space-between", flexGrow: 1, alignItems: "center"}}>
                        <div className="sale-activity-detail ml-2">
                          <p className="mb-0 font-size-15 font-weight-semibold">Online Sale</p>
                          <p className="mb-0 font-size-12 text-muted">2k new sales</p>
                        </div>
                        <span className="sale-activity-day font-size-12 text-muted">1 day ago</span>
                      </div>
                    </div>

                    <div className="sale-activity-container mb-3">
                      <div className="sale-activity-icon-container sa-icon-bg-orange">
                        <i className="fas fa-shopping-bag sale-activity-icon"></i>
                      </div>
                      <div style={{display: "flex", justifyContent: "space-between", flexGrow: 1, alignItems: "center"}}>
                        <div className="sale-activity-detail ml-2">
                          <p className="mb-0 font-size-15 font-weight-semibold">Offline Sale</p>
                          <p className="mb-0 font-size-12 text-muted">1.4k new sales</p>
                        </div>
                        <span className="sale-activity-day font-size-12 text-muted">3 day ago</span>
                      </div>
                    </div>

                    <div className="sale-activity-container mb-3">
                      <div className="sale-activity-icon-container sa-icon-bg-green">
                        <i className="fas fa-wallet sale-activity-icon"></i>
                      </div>
                      <div style={{display: "flex", justifyContent: "space-between", flexGrow: 1, alignItems: "center"}}>
                        <div className="sale-activity-detail ml-2">
                          <p className="mb-0 font-size-15 font-weight-semibold">Balance</p>
                          <p className="mb-0 font-size-12 text-muted">$78,190k new sales</p>
                        </div>
                        <span className="sale-activity-day font-size-12 text-muted">7 day ago</span>
                      </div>
                    </div>

                    <div className="sale-activity-container mb-3">
                      <div className="sale-activity-icon-container sa-icon-bg-red">
                        <i className="fas fa-dollar-sign sale-activity-icon"></i>
                      </div>
                      <div style={{display: "flex", justifyContent: "space-between", flexGrow: 1, alignItems: "center"}}>
                        <div className="sale-activity-detail ml-2">
                          <p className="mb-0 font-size-15 font-weight-semibold">New Production Cost</p>
                          <p className="mb-0 font-size-12 text-muted">$55,445</p>
                        </div>
                        <span className="sale-activity-day font-size-12 text-muted">7 day ago</span>
                      </div>
                    </div>

                    <div className="sale-activity-container mb-3">
                      <div className="sale-activity-icon-container sa-icon-bg-purple">
                        <i className="fas fa-warehouse sale-activity-icon"></i>
                      </div>
                      <div style={{display: "flex", justifyContent: "space-between", flexGrow: 1, alignItems: "center"}}>
                        <div className="sale-activity-detail ml-2">
                          <p className="mb-0 font-size-15 font-weight-semibold">New Production</p>
                          <p className="mb-0 font-size-12 text-muted">5.5k new products</p>
                        </div>
                        <span className="sale-activity-day font-size-12 text-muted">7 day ago</span>
                      </div>
                    </div>

                    <div className="sale-activity-container">
                      <div className="sale-activity-icon-container sa-icon-bg-teal">
                        <i className="fas fa-hand-holding-usd sale-activity-icon"></i>
                      </div>
                      <div style={{display: "flex", justifyContent: "space-between", flexGrow: 1, alignItems: "center"}}>
                        <div className="sale-activity-detail ml-2">
                          <p className="mb-0 font-size-15 font-weight-semibold">Total Profit</p>
                          <p className="mb-0 font-size-12 text-muted">$104,635 profit</p>
                        </div>
                        <span className="sale-activity-day font-size-12 text-muted">9 day ago</span>
                      </div>
                    </div>

                  </div>

                </CardBody>
              </Card>
            </Col>
            
            <Col md={12} xl={4} className="d-flex">
              <Card className="borderless-shadow w-100 recent-customers-card">
                <CardBody>
                  <h6 className="font-size-17 font-weight-semibold">Recent Customers</h6>
                  <p className="font-size-12 text-muted">A customer is an individual or business</p>

                  <div className='recent-customer-wrapper'>

                    <div className="recent-customer-container mb-0">
                      <div className="recent-customer-img-container">
                        <img
                          src={require(`../../assets/images/profiles/profile-pic-2.png`)}
                          width="100%"
                        />
                      </div>

                      <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="ml-4">
                          <h6 className="font-size-14 font-weight-semibold mb-0">Benzette Pvt. Ltd.</h6>
                          <span className="font-size-12 text-muted mr-2">ID: 2avr2</span>
                        </div>

                        <div className="mr-2 text-center">
                          <span className="font-size-14 font-weight-semibold text-success">Paid</span>
                          <p className="font-size-11 text-muted mb-0">Status</p>
                        </div>
                      </div>
                    </div>

                    <div className="divide-bar mt-2 mb-2" />

                    <div className="recent-customer-container mb-0">
                      <div className="recent-customer-img-container">
                        <img
                          src={require(`../../assets/images/profiles/profile-pic-6.jpg`)}
                          alt="comapny logo"
                          width="100%"
                        />
                      </div>

                      <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="ml-4">
                          <h6 className="font-size-14 font-weight-semibold mb-0">Flamingose Pvt. Ltd.</h6>
                          <span className="font-size-12 text-muted mr-2">ID: 1343FA</span>
                        </div>

                        <div className="mr-2 text-center">
                          <span className="font-size-14 font-weight-semibold text-success">Paid</span>
                          <p className="font-size-11 text-muted mb-0">Status</p>
                        </div>
                      </div>

                    </div>

                    <div className="divide-bar mt-2 mb-2" />

                    <div className="recent-customer-container mb-0">
                      <div className="recent-customer-img-container">
                        <img
                          src={require(`../../assets/images/profiles/profile-pic-7.jpg`)}
                          alt="comapny logo"
                          width="100%"
                        />
                      </div>

                      <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="ml-4">
                          <h6 className="font-size-14 font-weight-semibold mb-0">TIBCO Pvt. Ltd.</h6>
                          <span className="font-size-12 text-muted mr-2">ID: ees422</span>
                        </div>

                        <div className="mr-2 text-center">
                          <span className="font-size-14 font-weight-semibold text-danger">Pending</span>
                          <p className="font-size-11 text-muted mb-0">Status</p>
                        </div>
                      </div>

                    </div>

                    <div className="divide-bar mt-2 mb-2" />

                    <div className="recent-customer-container mb-0">
                      <div className="recent-customer-img-container">
                        <img
                          src={require(`../../assets/images/profiles/profile-pic-1.jpg`)}
                          alt="comapny logo"
                          width="100%"
                        />
                      </div>

                      <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="ml-4">
                          <h6 className="font-size-14 font-weight-semibold mb-0">Code Exceptionals</h6>
                          <span className="font-size-12 text-muted mr-2">ID: CODEEx90</span>
                        </div>

                        <div className="mr-2 text-center">
                          <span className="font-size-14 font-weight-semibold text-danger">Pending</span>
                          <p className="font-size-11 text-muted mb-0">Status</p>
                        </div>
                      </div>
                    </div>

                    <div className="divide-bar mt-2 mb-2" />

                    <div className="recent-customer-container mb-0">
                      <div className="recent-customer-img-container">
                        <img
                          src={require(`../../assets/images/profiles/profile-pic-3.jpeg`)}
                          alt="comapny logo"
                          width="100%"
                        />
                      </div>

                      <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="ml-4">
                          <h6 className="font-size-14 font-weight-semibold mb-0">Gasin Air</h6>
                          <span className="font-size-12 text-muted mr-2">ID: AIRg24</span>
                        </div>

                        <div className="mr-2 text-center">
                          <span className="font-size-14 font-weight-semibold text-danger">Pending</span>
                          <p className="font-size-11 text-muted mb-0">Status</p>
                        </div>
                      </div>
                    </div>

                    <div className="divide-bar mt-2 mb-2" />

                    <div className="recent-customer-container mb-0">
                      <div className="recent-customer-img-container">
                        <img
                          src={require(`../../assets/images/profiles/profile-pic-4.jpg`)}
                          alt="comapny logo"
                          width="100%"
                        />
                      </div>

                      <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="ml-4">
                          <h6 className="font-size-14 font-weight-semibold mb-0">Enoy Internationals</h6>
                          <span className="font-size-12 text-muted mr-2">ID: Evy34o</span>
                        </div>

                        <div className="mr-2 text-center">
                          <span className="font-size-14 font-weight-semibold text-success">Paid</span>
                          <p className="font-size-11 text-muted mb-0">Status</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md={12} xl={4} className="d-flex">
              <Card className="borderless-shadow w-100 country-page-view-card">
                <CardBody className="pb-2">
                  <h6 className="font-size-17 font-weight-semibold">Country wise page views</h6>
                  <p className="font-size-14 text-muted mb-2">This week page views</p>
                  <div className='country-page-view-wrapper'>
                    <div className="country-page-view-container mb-1">
                      <div className="country-flag-container">
                        <img src={require(`../../assets/images/country-flags/025-russia.png`)} alt="Country flag" width="100%" />
                      </div>
                      <div className="ml-3 font-size-15" style={{ flexGrow: 1, display: 'flex', justifyContent: "space-between" }}
                      >
                        <span>Russia</span>
                        <span>2851</span>
                      </div>
                    </div>
                    <div className="country-page-view-container mb-1">
                      <div className="country-flag-container">
                        <img src={require(`../../assets/images/country-flags/014-australia.png`)} alt="Country flag" width="100%" />
                      </div>
                      <div className="ml-3 font-size-15" style={{flexGrow: 1, display: 'flex', justifyContent: "space-between"}}>
                        <span>Australia</span>
                        <span>3854</span>
                      </div>
                    </div>
                    <div className="country-page-view-container mb-1">
                      <div className="country-flag-container">
                        <img src={require(`../../assets/images/country-flags/039-india.png`)} alt="Country flag" width="100%" />
                      </div>
                      <div className="ml-3 font-size-15" style={{flexGrow: 1, display: 'flex', justifyContent: "space-between"}}>
                        <span>India</span>
                        <span>7984</span>
                      </div>
                    </div>
                    <div className="country-page-view-container mb-1">
                      <div className="country-flag-container">
                        <img src={require(`../../assets/images/country-flags/012-united states.png`)} alt="Country flag" width="100%" />
                      </div>
                      <div className="ml-3 font-size-15" style={{flexGrow: 1, display: 'flex', justifyContent: "space-between"}}>
                        <span>United States</span>
                        <span>3784</span>
                      </div>
                    </div>
                    <div className="country-page-view-container mb-1">
                      <div className="country-flag-container">
                        <img src={require(`../../assets/images/country-flags/031-south africa.png`)} alt="Country flag" width="100%" />
                      </div>
                      <div className="ml-3 font-size-15" style={{flexGrow: 1, display: 'flex', justifyContent: "space-between"}}>
                        <span>South Africa</span>
                        <span>2998</span>
                      </div>
                    </div>
                    <div className="country-page-view-container mb-1">
                      <div className="country-flag-container">
                        <img src={require(`../../assets/images/country-flags/029-united kingdom.png`)} alt="Country flag" width="100%" />
                      </div>
                      <div className="ml-3 font-size-15" style={{flexGrow: 1, display: 'flex', justifyContent: "space-between"}}>
                        <span>United Kingdom</span>
                        <span>2597</span>
                      </div>
                    </div>
                    <div className="country-page-view-container mb-1">
                      <div className="country-flag-container">
                        <img src={require(`../../assets/images/country-flags/017-ukraine.png`)} alt="Country flag" width="100%" />
                      </div>
                      <div className="ml-3 font-size-15" style={{flexGrow: 1, display: 'flex', justifyContent: "space-between"}}>
                        <span>Ukraine</span>
                        <span>1452</span>
                      </div>
                    </div>
                    <div className="country-page-view-container mb-1">
                      <div className="country-flag-container">
                        <img src={require(`../../assets/images/country-flags/047-germany.png`)} alt="Country flag" width="100%" />
                      </div>
                      <div className="ml-3 font-size-15" style={{flexGrow: 1, display: 'flex', justifyContent: "space-between"}}>
                        <span>Germany</span>
                        <span>1895</span>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </div>
    );
  }
}

export default Dashboard1;  