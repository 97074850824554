import React from "react";
import "./contact.css";

import { Row, Col, NavItem, Nav, Dropdown, Breadcrumb, BreadcrumbItem, DropdownItem, DropdownMenu, DropdownToggle, TabContent, TabPane, Card, CardHeader, CardTitle, CardBody, Input, Button, Tooltip } from 'reactstrap';

import { Scrollbars } from 'react-custom-scrollbars';

class Contacts extends React.Component {
  constructor(props) {
    super(props);

    this.tooltipCall = this.tooltipCall.bind(this);
    this.tooltipVideoCall = this.tooltipVideoCall.bind(this);
    this.tooltipMessage = this.tooltipMessage.bind(this);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      name: 'Zina Blubber',
      chatdropdown: false,
      dropdownbarOpen: false,
      tooltipCall: false,
      tooltipVideoCall: false,
      tooltipMessage: false,
    };

  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  toggle(tab, name) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        name: name
      });
    }
  }
  tooltipCall() {
    this.setState({
      tooltipCall: !this.state.tooltipCall
    });
  }
  tooltipVideoCall() {
    this.setState({
      tooltipVideoCall: !this.state.tooltipVideoCall
    });
  }
  tooltipMessage() {
    this.setState({
      tooltipMessage: !this.state.tooltipMessage
    });
  }

  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Contact</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">App</a>
                <span className="breadcrumb-item active">Contact</span>
              </nav>
            </div>
          </div>
        </div>

        <Row>
          <Col xl={4}>
            <Card className="borderless-shadow contact-contact-list">
              <CardHeader className="pt-2 pb-2">
                <CardTitle>
                  <div className="contact-title">
                    <p className="mb-0">All Contacts</p>
                    <p className="mb-0">Favorites</p>
                  </div>
                </CardTitle>
              </CardHeader>
              <CardBody style={{padding: '0'}}>
                <div className="contact-search">
                  <i className="fas fa-search text-muted"></i>
                  <input type="text"  />
                </div>

                <div className="divide-bar" />

                <div className="contact-list">
                  <Scrollbars
                    autoHide
                    autoHeight
                    autoHeightMin={400}
                  >

                    <div className="contact-alphabet">A</div>
                    <div className="contact-list-item">
                      <div className="contact-image mr-2">
                        <img src={require(`../../../assets/images/profiles/profile-pic-4.jpg`)} width="100%" />
                      </div>
                      <div className="contact-detail">
                        <div>
                          <p className="contact-name font-size-15 font-weight-semibold mb-0">Abigale Maquenzi</p>
                          <p className="contact-number font-size-12 text-muted mb-0">+1-234-567-890</p>
                        </div>
                        <div>
                          <ul className="contact-item-option mb-0 font-size-12">
                            <li><a href="#"><i className="far fa-star text-danger"></i></a></li>
                            <li><a href="#"><i className="fas fa-pen text-muted"></i></a></li>
                            <li><a href="#"><i className="fas fa-ellipsis-v text-muted"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="contact-list-item">
                      <div className="contact-image mr-2">
                        <img src={require(`../../../assets/images/profiles/profile-pic-6.jpg`)} width="100%" />
                      </div>
                      <div className="contact-detail">
                        <div>
                          <p className="contact-name font-size-15 font-weight-semibold mb-0">Amy Smith</p>
                          <p className="contact-number font-size-12 text-muted mb-0">+1-234-567-890</p>
                        </div>
                        <div>
                          <ul className="contact-item-option mb-0 font-size-12">
                            <li><a href="#"><i className="far fa-star text-danger"></i></a></li>
                            <li><a href="#"><i className="fas fa-pen text-muted"></i></a></li>
                            <li><a href="#"><i className="fas fa-ellipsis-v text-muted"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="contact-list-item">
                      <div className="contact-image mr-2">
                        <img src={require(`../../../assets/images/profiles/profile-pic-2.png`)} width="100%" />
                      </div>
                      <div className="contact-detail">
                        <div>
                          <p className="contact-name font-size-15 font-weight-semibold mb-0">Araon Braganza</p>
                          <p className="contact-number font-size-12 text-muted mb-0">+1-234-567-890</p>
                        </div>
                        <div>
                          <ul className="contact-item-option mb-0 font-size-12">
                            <li><a href="#"><i className="far fa-star text-danger"></i></a></li>
                            <li><a href="#"><i className="fas fa-pen text-muted"></i></a></li>
                            <li><a href="#"><i className="fas fa-ellipsis-v text-muted"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="contact-list-item"> 
                      <div className="contact-image mr-2">
                        <img src={require(`../../../assets/images/profiles/profile-pic-1.jpg`)} width="100%" />
                      </div>
                      <div className="contact-detail">
                        <div>
                          <p className="contact-name font-size-15 font-weight-semibold mb-0">Aston Primus</p>
                          <p className="contact-number font-size-12 text-muted mb-0">+1-234-567-890</p>
                        </div>
                        <div>
                          <ul className="contact-item-option mb-0 font-size-12">
                            <li><a href="#"><i className="far fa-star text-danger"></i></a></li>
                            <li><a href="#"><i className="fas fa-pen text-muted"></i></a></li>
                            <li><a href="#"><i className="fas fa-ellipsis-v text-muted"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="contact-alphabet">B</div>
                    <div className="contact-list-item">
                      <div className="contact-image mr-2">
                        <img src={require(`../../../assets/images/profiles/profile-pic-3.jpeg`)} width="100%" />
                      </div>
                      <div className="contact-detail">
                        <div>
                          <p className="contact-name font-size-15 font-weight-semibold mb-0">Brei Martinz</p>
                          <p className="contact-number font-size-12 text-muted mb-0">+1-234-567-890</p>
                        </div>
                        <div>
                          <ul className="contact-item-option mb-0 font-size-12">
                            <li><a href="#"><i className="far fa-star text-danger"></i></a></li>
                            <li><a href="#"><i className="fas fa-pen text-muted"></i></a></li>
                            <li><a href="#"><i className="fas fa-ellipsis-v text-muted"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="contact-list-item">
                      <div className="contact-image mr-2">
                        <img src={require(`../../../assets/images/profiles/profile-pic-7.jpg`)} width="100%" />
                      </div>
                      <div className="contact-detail">
                        <div>
                          <p className="contact-name font-size-15 font-weight-semibold mb-0">Betheny Ralphs</p>
                          <p className="contact-number font-size-12 text-muted mb-0">+1-234-567-890</p>
                        </div>
                        <div>
                          <ul className="contact-item-option mb-0 font-size-12">
                            <li><a href="#"><i className="far fa-star text-danger"></i></a></li>
                            <li><a href="#"><i className="fas fa-pen text-muted"></i></a></li>
                            <li><a href="#"><i className="fas fa-ellipsis-v text-muted"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  
                    <div className="contact-alphabet">D</div>
                    <div className="contact-list-item">
                      <div className="contact-image mr-2">
                        <img src={require(`../../../assets/images/profiles/profile-pic-10.jpg`)} width="100%" />
                      </div>
                      <div className="contact-detail">
                        <div>
                          <p className="contact-name font-size-15 font-weight-semibold mb-0">Daniun Duff</p>
                          <p className="contact-number font-size-12 text-muted mb-0">+1-234-567-890</p>
                        </div>
                        <div>
                          <ul className="contact-item-option mb-0 font-size-12">
                            <li><a href="#"><i className="far fa-star text-danger"></i></a></li>
                            <li><a href="#"><i className="fas fa-pen text-muted"></i></a></li>
                            <li><a href="#"><i className="fas fa-ellipsis-v text-muted"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="contact-list-item">
                      <div className="contact-image mr-2">
                        <img src={require(`../../../assets/images/profiles/profile-pic-5.jpg`)} width="100%" />
                      </div>
                      <div className="contact-detail">
                        <div>
                          <p className="contact-name font-size-15 font-weight-semibold mb-0">Danchin Toya</p>
                          <p className="contact-number font-size-12 text-muted mb-0">+1-234-567-890</p>
                        </div>
                        <div>
                          <ul className="contact-item-option mb-0 font-size-12">
                            <li><a href="#"><i className="far fa-star text-danger"></i></a></li>
                            <li><a href="#"><i className="fas fa-pen text-muted"></i></a></li>
                            <li><a href="#"><i className="fas fa-ellipsis-v text-muted"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="contact-list-item">
                      <div className="contact-image mr-2">
                        <img src={require(`../../../assets/images/profiles/profile-pic-12.jpg`)} width="100%" />
                      </div>
                      <div className="contact-detail">
                        <div>
                          <p className="contact-name font-size-15 font-weight-semibold mb-0">Darcy Lin</p>
                          <p className="contact-number font-size-12 text-muted mb-0">+1-234-567-890</p>
                        </div>
                        <div>
                          <ul className="contact-item-option mb-0 font-size-12">
                            <li><a href="#"><i className="far fa-star text-danger"></i></a></li>
                            <li><a href="#"><i className="fas fa-pen text-muted"></i></a></li>
                            <li><a href="#"><i className="fas fa-ellipsis-v text-muted"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="contact-list-item">
                      <div className="contact-image mr-2">
                        <img src={require(`../../../assets/images/profiles/profile-pic-11.jpg`)} width="100%" />
                      </div>
                      <div className="contact-detail">
                        <div>
                          <p className="contact-name font-size-15 font-weight-semibold mb-0">Deby Maclaine</p>
                          <p className="contact-number font-size-12 text-muted mb-0">+1-234-567-890</p>
                        </div>
                        <div>
                          <ul className="contact-item-option mb-0 font-size-12">
                            <li><a href="#"><i className="far fa-star text-danger"></i></a></li>
                            <li><a href="#"><i className="fas fa-pen text-muted"></i></a></li>
                            <li><a href="#"><i className="fas fa-ellipsis-v text-muted"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Scrollbars>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col xl={8}>
            <Card className="borderless-shadow contact-contact-detail">
              <CardBody>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1" className="pt-0 pb-0">
                    <div  style={{ display: "flex", justifyContent: "space-between" }}>
                      <div  style={{ display: "flex", alignItems: "center" }}>
                        <img className="contact-big-image" src={require(`../../../assets/images/profiles/profile-pic-4.jpg`)} />
                        <div className="pl-3">
                          <h5 className="text-dark">Abigale Maquenzi</h5>
                          <p className="mb-0">Marketing team Lead</p>
                        </div>
                      </div>


                    </div>

                    <div className="mt-3 mb-3">
                      <Button className="contact-btn mr-3" id="TooltipCall">
                        <i className="fas fa-phone"></i>
                      </Button>
                      <Tooltip
                        placement="bottom"
                        isOpen={this.state.tooltipCall}
                        target="TooltipCall"
                        toggle={this.tooltipCall}
                      >
                        Call
                      </Tooltip>
                      <Button className="contact-btn mr-3" id="TooltipVideoCall">
                        <i className="fas fa-video"></i>
                      </Button>
                      <Tooltip
                        placement="bottom"
                        isOpen={this.state.tooltipVideoCall}
                        target="TooltipVideoCall"
                        toggle={this.tooltipVideoCall}
                      >
                        Video Call
                      </Tooltip>
                      <Button className="contact-btn mr-3" id="TooltipMessage">
                        <i className="fas fa-envelope"></i>
                      </Button>
                      <Tooltip
                        placement="bottom"
                        isOpen={this.state.tooltipMessage}
                        target="TooltipMessage"
                        toggle={this.tooltipMessage}
                      >
                        Message
                      </Tooltip>
                    </div>

                    <div className="divide-bar-big" />

                    <div className="detail-body">
                      <div className="font-size-14">
                        <p>
                          Supervised up to fifteen customer service representatives engaged in providing outstanding customer service for high call volume program.
                          Developed marketing materials and enhanced customer service techniques of each representative to drive revenue growth and staff development.
                        </p>
                        <p>
                          Identified issues compromising customer service; designed implemented new processes to increase customer satisfaction. Feel free to contact upon any issues, clearing them out together makes good enviornment.
                        </p>
                      </div>

                      <div className="divide-bar-big" />

                      <div className="contact-phone-email" style={{marginTop: '1.4rem'}}>
                        <div className="mr-5">
                          <h6 className="mb-1">Email</h6>
                          <p>maquenzi.abigale@gmail.com</p>
                        </div>
                        <div className="ml-5">
                          <h6 className="mb-1">Phone</h6>
                          <p>+1-234-567-890</p>
                        </div>
                      </div>

                    </div>

                  </TabPane>
                </TabContent>
              </CardBody>
              </Card>
          </Col>
        </Row>

      </div>
    );
  }
}

export default Contacts;