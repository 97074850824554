import React from 'react';
import "./header.css"
import { Scrollbars } from "react-custom-scrollbars";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {  };
  }
  render() {
    return (
      <div>
        <div className="header-background" />
        <nav className="navbar navbar-expand-lg main-navbar">
          <form className="form-inline mr-auto">
            <ul className="navbar-nav mr-3 header-nav-adjuster">
              <li>
                <a href="#" data-toggle="sidebar" className="nav-link nav-link-lg">
                  <i className="fas fa-stream header-bars"></i>
                </a>
              </li>
              <li>
                <a href="#" data-toggle="search" className="nav-link nav-link-lg d-sm-none"><i className="fas fa-search" /></a>
              </li>
            </ul>
            <div className="search-element">
              <input
                className="form-control header-search-input"
                type="search"
                placeholder="Search"
                aria-label="Search"
                data-width={250}
              />
              <button className="btn header-search-button" type="submit"><i className="fas fa-search" /></button>
              <div className="search-backdrop" />
            </div>
          </form>
          <ul className="navbar-nav navbar-right" style={{ display: "flex", alignItems: "center" }}>
            <li className="dropdown dropdown-list-toggle">
              <a href="#" data-toggle="dropdown" className="nav-link nav-link-lg message-toggle beep">
                <i className="far fa-envelope text-dark" />
              </a>
              <div className="dropdown-menu message-dropdown-list dropdown-menu-right"
                style={{ border: 'none', boxShadow: '0 2px 20px rgba(0, 0, 0, 0.25)'}}
              >
                <div className="dropdown-header">Messages
                  <div className="float-right">
                    <a href="#" style={{ color: '#005bea' }}>Mark All As Read</a>
                  </div>
                </div>
                <Scrollbars
                  autoHeight
                  autoHeightMin={200}
                  autoHeightMax={350}
                >
                  <div className="dropdown-list-content dropdown-list-message">
                    <a href="#" className="dropdown-item dropdown-item-unread">
                      <div className="dropdown-item-avatar">
                        <img src={require(`../../assets/images/profiles/profile-pic-1.jpg`)} className="rounded-circle" />
                        <div className="is-online" />
                      </div>
                      <div className="dropdown-item-desc">
                        <b>Assenci Clerie</b>
                        <p>Our team is focussing on the new project.</p>
                        <div className="time">2 Hours Ago</div>
                      </div>
                    </a>
                    <a href="#" className="dropdown-item dropdown-item-unread">
                      <div className="dropdown-item-avatar">
                        <img src={require(`../../assets/images/profiles/profile-pic-2.jpg`)} className="rounded-circle" />
                      </div>
                      <div className="dropdown-item-desc">
                        <b>Derik Hopes</b>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit</p>
                        <div className="time">12 Hours Ago</div>
                      </div>
                    </a>
                    <a href="#" className="dropdown-item dropdown-item-unread">
                      <div className="dropdown-item-avatar">
                        <img src={require(`../../assets/images/profiles/profile-pic-3.jpg`)} className="rounded-circle" />
                        <div className="is-online" />
                      </div>
                      <div className="dropdown-item-desc">
                        <b>Kresik Adesania</b>
                        <p>Sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        <div className="time">12 Hours Ago</div>
                      </div>
                    </a>
                    <a href="#" className="dropdown-item">
                      <div className="dropdown-item-avatar">
                        <img src={require(`../../assets/images/profiles/profile-pic-4.jpg`)} className="rounded-circle" />
                      </div>
                      <div className="dropdown-item-desc">
                        <b>Ardian Bahren</b>
                        <p>Duis aute irure dolor in reprehenderit in voluptate velit ess</p>
                        <div className="time">16 Hours Ago</div>
                      </div>
                    </a>
                    <a href="#" className="dropdown-item">
                      <div className="dropdown-item-avatar">
                        <img src={require(`../../assets/images/profiles/profile-pic-5.jpg`)} className="rounded-circle" />
                      </div>
                      <div className="dropdown-item-desc">
                        <b>Zulkarin Brazka</b>
                        <p>Exercitation ullamco laboris nisi ut aliquip ex ea commodo</p>
                        <div className="time">Yesterday</div>
                      </div>
                    </a>
                  </div>
                </Scrollbars>
                <div className="dropdown-footer text-center">
                  <a href="#">View All <i className="fas fa-chevron-right" /></a>
                </div>
              </div>
            </li>

            <li className="dropdown dropdown-list-toggle">
              <a href="#" data-toggle="dropdown" className="nav-link notification-toggle nav-link-lg beep">
                <i className="far fa-bell text-dark" />
              </a>
              <div className="dropdown-menu notification-dropdown-list dropdown-menu-right"
                style={{ border: 'none', boxShadow: '0 2px 20px rgba(0, 0, 0, 0.25)' }}
              >
                <div className="dropdown-header">Notifications
                  <div className="float-right">
                    <a href="#" style={{color: '#005bea'}}>Mark All As Read</a>
                  </div>
                </div>
                <Scrollbars
                  autoHeight
                  autoHeightMin={200}
                  autoHeightMax={350}
                >
                  <div className="dropdown-list-content dropdown-list-icons">
                    <a href="#" className="dropdown-item dropdown-item-unread">
                      <div className="dropdown-item-icon bg-success text-white">
                        <i className="fab fa-buffer"></i>
                      </div>
                      <div className="dropdown-item-desc">
                        New App version released!
                        <div className="time text-primary">9 Min Ago</div>
                      </div>
                    </a>
                    <a href="#" className="dropdown-item">
                      <div className="dropdown-item-icon bg-primary text-white">
                        <i className="far fa-grin-alt"></i>
                      </div>
                      <div className="dropdown-item-desc">
                        <b>You</b> and <b>Derik Hopes</b> are now friends
                        <div className="time">10 Hours Ago</div>
                      </div>
                    </a>
                    <a href="#" className="dropdown-item">
                      <div className="dropdown-item-icon bg-info text-white">
                        <i className="fas fa-users"></i>
                      </div>
                      <div className="dropdown-item-desc">
                        <b>Assenci</b> completed the team formation
                        <div className="time">12 Hours Ago</div>
                      </div>
                    </a>
                    <a href="#" className="dropdown-item">
                      <div className="dropdown-item-icon bg-success text-white">
                        <i className="fas fa-check"></i>
                      </div>
                      <div className="dropdown-item-desc">
                        Minor bug fixed!
                        <div className="time">17 Hours Ago</div>
                      </div>
                    </a>
                    <a href="#" className="dropdown-item">
                      <div className="dropdown-item-icon bg-danger text-white">
                        <i className="fas fa-bug"></i>
                      </div>
                      <div className="dropdown-item-desc">
                        Client issued an opening bug.
                        <div className="time">Yesterday</div>
                      </div>
                    </a>
                  </div>
                </Scrollbars>
                <div className="dropdown-footer text-center">
                  <a href="#">View All <i className="fas fa-chevron-right" /></a>
                </div>
              </div>
            </li>
           
            <li className="dropdown">
              <a href="#"
                data-toggle="dropdown"
                className="nav-link dropdown-toggle nav-link-lg nav-link-user text-dark"
                style={{display: 'flex', alignItems: 'center'}}
              >
                <img
                  alt="image"
                  src={require(`../../assets/images/profiles/profile-pic-3.jpeg`)}
                  className="rounded-circle mr-1"
                  style={{width: "40px"}}
                />
                <div className="d-sm-none d-lg-inline-block mt-0">Hi, David houston</div>
              </a>

              <div className="dropdown-menu dropdown-menu-right user-navigation-dropdown"
                style={{ border: 'none', boxShadow: '0 2px 20px rgba(0, 0, 0, 0.25)', width: '200px' }}
              >
                <a href="#" className="dropdown-item has-icon">
                  <i className="fas fa-user"></i> Profile
                </a>
                <a href="#" className="dropdown-item has-icon">
                  <i className="fas fa-clipboard-list"></i> Activities
                </a>
                <a href="#" className="dropdown-item has-icon">
                  <i className="fas fa-cog" /> Settings
                </a>
                <div className="dropdown-divider" />
                <a href="#" className="dropdown-item has-icon">
                  <i className="fas fa-sign-out-alt text-danger" /> Logout
                </a>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default Header;