import React from "react";
import "./alert.css";

import { CardBody, CardHeader, Card, CardTitle, Row, Col, Alert, UncontrolledAlert } from 'reactstrap';

class Alerts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
    }

    this.onDismiss = this.onDismiss.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  onDismiss() {
    this.setState({ visible: false });
  }

  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Alert</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">UI Elements</a>
                <span className="breadcrumb-item active">Alert</span>
              </nav>
            </div>
          </div>
          
          <Row>
            <Col md={12}>
              <Card className="borderless-shadow">
                <CardHeader>
                  <CardTitle className="font-size-16 font-weight-semibold">Basic Alert</CardTitle>
                  <p className="mb-0">Alerts are available for any length of text, as well as an optional dismiss button. For proper styling, use one of the five <b className="text-dark">required</b> contextual classes (e.g., <code>.alert-success</code>).</p>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <Alert color="primary">
                        This is a primary alert — check it out!
                                </Alert>
                      <Alert color="secondary">
                        This is a secondary alert — check it out!
                                </Alert>
                      <Alert color="success">
                        This is a success alert — check it out!
                                </Alert>
                      <Alert color="danger">
                        This is a danger alert — check it out!
                                </Alert>
                      <Alert color="warning">
                        This is a warning alert — check it out!
                                </Alert>
                      <Alert color="info">
                        This is a info alert — check it out!
                                </Alert>
                      <Alert color="light">
                        This is a light alert — check it out!
                                </Alert>
                      <Alert color="dark" className="mb-0">
                        This is a dark alert — check it out!
                                </Alert>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="borderless-shadow">
                <CardHeader>
                  <CardTitle className="font-size-16 font-weight-semibold">Dismissible Alerts</CardTitle>
                  <p className="mb-0">Add a dismiss button and the <code>.alert-dismissible</code> class, which adds extra padding to the right of the alert and positions the <code>.close</code> button.</p>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <Alert color="info" isOpen={this.state.visible} toggle={this.onDismiss} className="mb-0">
                        I am an alert and I can be dismissed!
                              </Alert>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="borderless-shadow">
                <CardHeader>
                  <CardTitle className="font-size-16 font-weight-semibold">UnControlled Dismissible Alerts</CardTitle>
                  <p className="mb-0">Add a dismiss button and the <code>.alert-dismissible</code> class, which adds extra padding to the right of the alert and positions the <code>.close</code> button.</p>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <UncontrolledAlert color="info" className="mb-0">
                        I am an alert and I can be dismissed!
                              </UncontrolledAlert>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="borderless-shadow">
                <CardHeader>
                  <CardTitle className="font-size-16 font-weight-semibold">Additional content</CardTitle>
                  <p className="mb-0">Alerts can also contain additional HTML elements like headings, paragraphs and dividers.</p>
                </CardHeader>
                <CardBody>
                  <Alert color="success" className="mb-0">
                    <h4 className="alert-heading">Well done!</h4>
                    <p>
                      Aww yeah, you successfully read this important alert message. This example text is going
                      to run a bit longer so that you can see how spacing within an alert works with this kind
                      of content.
                        </p>
                    <hr />
                    <p className="mb-0">
                      Whenever you need to, be sure to use margin utilities to keep things nice and tidy.
                        </p>
                  </Alert>
                </CardBody>
              </Card>
              <Card className="borderless-shadow mb-0">
                <Row>
                  <Col md={12}>
                    <CardHeader>
                      <CardTitle className="font-size-16 font-weight-semibold">Gradient Alert</CardTitle>
                      <p className="mb-0">Use <code>.alert-*-gradient</code>(e.g <code>.alert-success-gradient</code>) instead contextual class to create gradient alert.</p>
                    </CardHeader>
                    <CardBody>
                      <Alert className="alert-primary-gradient" color="primary">
                        <span><strong>Brand!</strong> You successfully read this important alert message.</span>
                      </Alert>
                      <Alert color="secondary" className="alert-secondary-gradient">
                        <span><strong>Well done!</strong> You successfully read this important alert message.</span>
                      </Alert>
                      <Alert color="success" className="alert-success-gradient">
                        <span><strong>Success!</strong> This alert needs your attention, but it's not super important.</span>
                      </Alert>
                      <Alert color="danger" className="alert-danger-gradient">
                        <span><strong>Oh snap!</strong> Change a few things up and try submitting again..</span>
                      </Alert>
                      <Alert color="warning" className="alert-warning-gradient">
                        <span><strong>Warning!</strong> Change a few things up and try submitting again..</span>
                      </Alert>
                      <Alert color="info" className="alert-info-gradient">
                        <span><strong>Info!</strong> Change a few things up and try submitting again..</span>
                      </Alert>
                      <Alert color="light" className="alert-light-gradient">
                        <span><strong>Sweet Light!</strong> Change a few things up and try submitting again..</span>
                      </Alert>
                      <Alert color="dark" className="mb-0 alert-dark-gradient">
                        <span><strong>Oh Dark!</strong> Change a few things up and try submitting again..</span>
                      </Alert>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Alerts;
