import React, { Component } from 'react';
// import "../products.css";

import { Col, Tooltip, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

class Items extends Component {
  constructor(props) {
    super(props);

    this.toggleQuickViewTooltip = this.toggleQuickViewTooltip.bind(this);
    this.toggleCartTooltip = this.toggleCartTooltip.bind(this);
    this.toggleWishlistTooltip = this.toggleWishlistTooltip.bind(this);
    this.state = {
      quickViewTooltip: false,
      cartTooltip: false,
      wishlistTooltip: false
    };
  }
  toggleQuickViewTooltip() {
    this.setState(prevState => this.state.quickViewTooltip = !prevState.quickViewTooltip);
  }
  toggleCartTooltip() {
    this.setState(prevState => this.state.cartTooltip = !prevState.cartTooltip);
  }
  toggleWishlistTooltip() {
    this.setState(prevState => this.state.wishlistTooltip = !prevState.wishlistTooltip);
  }
  Productaddcart(ProductID, ProductName, ProductImage, Qty, Rate, StockStatus) {
    var Cart = JSON.parse(localStorage.getItem("CartProduct"));
    if (Cart == null)
      Cart = new Array();
    let Productadd = Cart.find(product => product.ProductID === ProductID);
    if (Productadd == null) {
      Cart.push({ ProductID: ProductID, ProductName: ProductName, ProductImage: ProductImage, Qty: Qty, Rate: Rate, StockStatus: StockStatus });
      localStorage.removeItem("CartProduct");
      localStorage.setItem("CartProduct", JSON.stringify(Cart));
      var flag = 0;
      if (flag == 0) {
        toast.success("Item Added to Cart");
        flag = 1;
      }
    }
    else {
      toast.warning("Item is already in Cart");
    }
  }
  Productaddwishlist(ProductID, ProductName, ProductImage, Qty, Rate, StockStatus) {
    var Cart = JSON.parse(localStorage.getItem("WishlistProduct"));
    if (Cart == null)
      Cart = new Array();

    let Productadd = Cart.find(product => product.ProductID === ProductID);
    if (Productadd == null) {

      Cart.push({ ProductID: ProductID, ProductName: ProductName, ProductImage: ProductImage, Qty: Qty, Rate: Rate, StockStatus: StockStatus });
      localStorage.removeItem("WishlistProduct");
      localStorage.setItem("WishlistProduct", JSON.stringify(Cart));

      toast.success("Item Added to WishList");
    }
    else {
      toast.warning("Item is already in WishList");
    }


  }
  CartItems(ID) {
    let checkcart = false;
    var Cart = JSON.parse(localStorage.getItem("CartProduct"));
    if (Cart && Cart.length > 0) {
      for (const cartItem of Cart) {
        if (cartItem.ProductID === ID) {
          checkcart = true
        }
      }
    }
    return checkcart;
  }
  WishlistItems(ID) {
    let wishlist = false;
    var Wish = JSON.parse(localStorage.getItem("WishlistProduct"));

    if (Wish && Wish.length > 0) {
      for (const wishItem of Wish) {
        if (wishItem.ProductID === ID) {
          wishlist = true
        }
      }
    }
    return wishlist;
  }

  render() {
    const { productdata } = this.props;
    let rat = [];
    if (productdata !== null) {
      let rates = productdata.rates;
      let i = 1;
      while (i <= 5) {
        if (i <= rates) {
          rat.push(<i className="fas fa-star mr-1"></i>)
        }
        else {
          rat.push(<i className="far fa-star mr-1"></i>)
        }
        i += 1;
      };
    }

    return (
      <Col md={6} xl={4} classame="d-flex">
        <ToastContainer />
        <Card className="borderless-shadow product-card w-100">
          <div className="wishlist-heart">
            <span className="heart-empty-container">
              <i className="far fa-heart heart-empty"></i>
            </span>
            <span className="heart-empty-container">
            </span>
          </div>
          <Link to="/product-detail">
            <div className="product-image-container">
              <img src={require(`../../../assets/images/${productdata.pictures[0]}`)} width="100%" />
            </div>
          </Link>

          <div className="product-detail text-center">
            <div className="ratings">
              {rat}
            </div>
            <h6 className="font-size-15 mt-2">{productdata.name}</h6>
            <div className="product-price">
              <span className="product-price-old mt-3">${productdata.price}</span>
              <span className="product-price-new">${productdata.salePrice}</span>
            </div>

            <div className="product-actions">
              {/* <button id={`quick-view-${productdata.id}`} className="quick-view-btn"><i className="far fa-eye"></i></button>
              <Tooltip
                placement="bottom"
                isOpen={this.state.quickViewTooltip}
                target={`quick-view-${productdata.id}`}
                toggle={this.toggleQuickViewTooltip}
              >
                Quick View
              </Tooltip> */}

              {!this.CartItems(productdata.id) ?
                <>
                  <Link id={`add-to-cart-${productdata.id}`} className="cart-btn" onClick={() => this.Productaddcart(productdata.id, productdata.name, productdata.pictures[0], 1, productdata.salePrice, "In Stock")}>
                    <i className="fas fa-shopping-cart mr-2"></i>
                    <span className="font-size-14">Add to Cart</span>
                  </Link>
                  {/* <Tooltip
                    placement="bottom"
                    isOpen={this.state.cartTooltip}
                    // target={`add-to-cart-${productdata.id}`}
                    toggle={this.toggleCartTooltip}
                  >
                    Add to Cart
                  </Tooltip> */}
                </>
                :
                <Link to="/cart" id={`add-to-cart-${productdata.id}`} rel="nofollow" className="cart-btn-link cart-btn font-size-14">Go to Cart</Link>
              }
              {!this.WishlistItems(productdata.id) ?
                <>
                  <Link id={`compare-${productdata.id}`} className="wishlist-btn" onClick={() => this.Productaddwishlist(productdata.id, productdata.name, productdata.pictures[0], 1, productdata.salePrice, "In Stock")}><i className="far fa-heart"></i></Link>
                  <Tooltip
                    placement="bottom"
                    isOpen={this.state.wishlistTooltip}
                    target={`compare-${productdata.id}`}
                    toggle={this.toggleWishlistTooltip}
                  >
                    Add to Wishlist
                  </Tooltip>
                </>
                :
                <>
                  <Link to="#" id={`compare-${productdata.id}`} className="wishlist-btn-link wishlist-btn font-size-14">
                    <i className="fas fa-heart"></i>
                  </Link>
                  <Tooltip
                    placement="bottom"
                    isOpen={this.state.wishlistTooltip}
                    target={`compare-${productdata.id}`}
                    toggle={this.toggleWishlistTooltip}
                  >
                    View Wishlist
                  </Tooltip>
                </>
              }
            </div>
          </div>

        </Card>
      </Col>

    );
  }
}

export default Items;