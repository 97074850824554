import React from "react";
import "./product-detail.css";
import { Card, Row, Col, Input, Label, CardBody } from "reactstrap";
import ReactImageMagnify from 'react-image-magnify';

class ProductDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeImage: 'shoes1a.jpg'
    }
  }

  changeImage() {
    if (document.getElementById("imgClickAndChange").src == "http://www.userinterfaceicons.com/80x80/minimize.png") {
      document.getElementById("imgClickAndChange").src = "http://www.userinterfaceicons.com/80x80/maximize.png";
    }
    else {
      document.getElementById("imgClickAndChange").src = "http://www.userinterfaceicons.com/80x80/minimize.png";
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pl-0 pr-0">
          <div className="page-header">
            <h2 className="header-title mb-0">Product Detail</h2>
            <div className="header-sub-title">
              <nav className="breadcrumb breadcrumb-dash">
                <a href="#" className="breadcrumb-item"><i className="ti-home pr-2 mt-1" />Home</a>
                <a className="breadcrumb-item" href="#">E-Commerce</a>
                <span className="breadcrumb-item active">Product Detail</span>
              </nav>
            </div>
          </div>

          <Card className="borderless-shadow mb-0" style={{ overflow: "hidden" }}>
            <CardBody>
              <Row>
                <Col md={5}>
                  <div className="fluid" style={{ cursor: 'default' }}>
                    <div className="fluid__image-container">
                      <ReactImageMagnify {...{
                        smallImage: {
                          alt: 'Wristwatch by Ted Baker London',
                          isFluidWidth: true,
                          src: (require(`../../assets/images/products/${this.state.activeImage}`)),
                        },
                        largeImage: {
                          src: (require(`../../assets/images/products/${this.state.activeImage}`)),
                          width: 1200,
                          height: 1800
                        }
                      }} />
                    </div>
                    <div className="product-images-slider">
                      <div className="prooduct-small-image">
                        <img src={require(`../../assets/images/products/shoes1b.jpg`)} alt="Product other image 1" width="100%"
                          onClick={() => this.setState({ activeImage: 'shoes1b.jpg'}) }
                        />
                      </div>
                      <div className="prooduct-small-image">
                        <img src={require(`../../assets/images/products/shoes8a.jpg`)} alt="Product other image 1" width="100%"
                          onClick={() => this.setState({ activeImage: 'shoes8a.jpg' }) }
                        />
                      </div>
                      <div className="prooduct-small-image">
                        <img src={require(`../../assets/images/products/shoes4a.jpg`)} alt="Product other image 1" width="100%"
                          onClick={() => this.setState({ activeImage: 'shoes4a.jpg' }) }
                        />
                      </div>
                    </div>
                  </div>


                </Col>
                
                <Col md={7} >
                <div className="fluid__instructions">
                  <h5>Nike Black Sneakers</h5>
                  
                  <div className="product-detail-price mt-3">
                    <span className="current-price">$34.99</span>
                    <span className="old-price">$49.00</span>
                    <span className="discount-percent">28% off</span>
                  </div>

                  <div className="product-detail-rating mt-3">
                    <span className="actual-rating">4.3</span>
                    <div className="product-detail-stars">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star-half-alt"></i>
                    </div>
                  </div>
                  
                  <div className="product-detail-rating-numbers">
                    <span className="rating-number">3,212</span>
                    <span>ratings</span>
                  </div>

                  <div className="product-detail-desc mt-4 mb-4">
                    <p>Morbi tempus commodo ullamcorper. Etiam id tellus quam. Donec sed erat molestie, feugiat mi nec, elementum urna. Morbi dictum ante sit amet turpis egestas efficitur.</p>
                  </div>

                  <div className="product-size">
                    <span className="size-heading">size</span>
                    <div className="mt-2">
                      <span className="square-radio mb-2 d-inline-block">
                        <Input
                          id="size6"
                          name="sizeRadio"
                          type="radio"
                        />
                        <Label htmlFor="size6">6</Label>
                      </span>
                      <span className="square-radio mb-2 d-inline-block">
                        <Input
                          id="size7"
                          name="sizeRadio"
                          type="radio"
                        />
                        <Label htmlFor="size7">7</Label>
                      </span>
                      <span className="square-radio mb-2 d-inline-block">
                        <Input
                          id="size8"
                          name="sizeRadio"
                          type="radio"
                        />
                        <Label htmlFor="size8">8</Label>
                      </span>
                      <span className="square-radio mb-2 d-inline-block">
                        <Input
                          id="size9"
                          name="sizeRadio"
                          type="radio"
                        />
                        <Label htmlFor="size9">9</Label>
                      </span>
                      <span className="square-radio mb-2 d-inline-block">
                        <Input
                          id="size10"
                          name="sizeRadio"
                          type="radio"
                        />
                        <Label htmlFor="size10">10</Label>
                      </span>
                      <span className="square-radio mb-2 d-inline-block">
                        <Input
                          id="size11"
                          name="sizeRadio"
                          type="radio"
                        />
                        <Label htmlFor="size11">11</Label>
                      </span>
                      <span className="square-radio mb-2 d-inline-block">
                        <Input
                          id="size12"
                          name="sizeRadio"
                          type="radio"
                        />
                        <Label htmlFor="size12">12</Label>
                      </span>
                    </div>
                  </div>

                  <div className="mt-3" style={{ display: "flex", alignItems: "center" }} >
                    <div className="filter-color mr-4">
                      <span className="color-heading mb-2">Color</span>
                      <div>
                        <span className="circle-radio">
                          <Input
                            id="color-blue"
                            name="sizeRadio"
                            type="radio"
                          />
                          <Label className="color-blue" htmlFor="color-blue" />
                        </span>
                        <span className="circle-radio">
                          <Input
                            id="color-green"
                            name="sizeRadio"
                            type="radio"
                          />
                          <Label className="color-green" htmlFor="color-green" />
                        </span>
                        <span className="circle-radio">
                          <Input
                            id="color-orange"
                            name="sizeRadio"
                            type="radio"
                          />
                          <Label className="color-orange" htmlFor="color-orange" />
                        </span>
                        <span className="circle-radio">
                          <Input
                            id="color-red"
                            name="sizeRadio"
                            type="radio"
                          />
                          <Label className="color-red" htmlFor="color-red" />
                        </span>
                        <span className="circle-radio">
                          <Input
                            id="color-grey"
                            name="sizeRadio"
                            type="radio"
                          />
                          <Label className="color-grey" htmlFor="color-grey" />
                        </span>
                      </div>
                    </div>

                    <div className="product-detail-quantity">
                      <Label htmlFor="qty1">Quantity</Label>
                      <Input id="qty1" className="product-detail-qty-input" bsSize="sm" type="number" min="0" defaultValue="1" />
                    </div>
                  </div>

                  <div className="product-detail-actions mt-4">
                    <button className="add-to-cart-btn add-to-btn mb-2">
                      <i className="fas fa-shopping-cart mr-2"></i>
                      Add to Cart
                    </button>

                    <button className="add-to-wishlist add-to-btn">
                      <i className="fas fa-heart mr-2"></i>
                      Add to Wishlist
                    </button>
                  </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Row>
          </Row>

        </div>
      </div>
    );
  }
}

export default ProductDetail;