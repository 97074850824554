import React from "react";
import "./dashboard2.css";
import { Row, Col, Card, CardBody, CardHeader, CardTitle, Media } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const products = [
  {
    "id": 1,
    "name": "Ethel Price",
    "gender": "female",
    "company": "Johnson LLC CMP DDC",
    "age": 22,
  },
  {
    "id": 2,
    "name": "Claudine Neal",
    "gender": "female",
    "company": "Sealoud",
    "age": 55
  },
  {
    "id": 3,
    "name": "Beryl Rice",
    "gender": "female",
    "company": "Velity",
    "age": 67
  },
  {
    "id": 4,
    "name": "Wilder Gonzales",
    "gender": "male",
    "company": "Geekko",
    "age": 28
  },
  {
    "id": 5,
    "name": "Georgina Schultz",
    "gender": "female",
    "company": "Suretech",
    "age": 65
  },
  {
    "id": 6,
    "name": "Carroll Buchanan",
    "gender": "male",
    "company": "Ecosys",
    "age": 46
  },
  {
    "id": 7,
    "name": "Valarie Atkinson",
    "gender": "female",
    "company": "Hopeli",
    "age": 22
  },
  {
    "id": 8,
    "name": "Schroeder Mathews",
    "gender": "male",
    "company": "Polarium",
    "age": 37
  },
  {
    "id": 9,
    "name": "Lynda Mendoza",
    "gender": "female",
    "company": "Dogspa",
    "age": 33
  },
  {
    "id": 10,
    "name": "Sarah Massey",
    "gender": "female",
    "company": "Bisba",
    "age": 44
  },
  {
    "id": 11,
    "name": "Robles Boyle",
    "gender": "male",
    "company": "Comtract",
    "age": 46
  },
  {
    "id": 12,
    "name": "Evans Hickman",
    "gender": "male",
    "company": "Parleynet",
    "age": 28
  },
  {
    "id": 13,
    "name": "Dawson Barber",
    "gender": "male",
    "company": "Dymi",
    "age": 36
  },
  {
    "id": 14,
    "name": "Bruce Strong",
    "gender": "male",
    "company": "Xyqag",
    "age": 55
  },
  {
    "id": 15,
    "name": "Nellie Whitfield",
    "gender": "female",
    "company": "Exospace",
    "age": 60
  },
  {
    "id": 16,
    "name": "Jackson Macias",
    "gender": "male",
    "company": "Aquamate",
    "age": 51
  },
  {
    "id": 17,
    "name": "Pena Pena",
    "gender": "male",
    "company": "Quarx",
    "age": 50
  },
  {
    "id": 18,
    "name": "Lelia Gates",
    "gender": "female",
    "company": "Proxsoft",
    "age": 31
  },
  {
    "id": 19,
    "name": "Letitia Vasquez",
    "gender": "female",
    "company": "Slumberia",
    "age": 40
  },
  {
    "id": 20,
    "name": "Trevino Moreno",
    "gender": "male",
    "company": "Conjurica",
    "age": 47
  },
  {
    "id": 21,
    "name": "Barr Page",
    "gender": "male",
    "company": "Apex",
    "age": 48
  },
  {
    "id": 22,
    "name": "Kirkland Merrill",
    "gender": "male",
    "company": "Utara",
    "age": 29
  },
  {
    "id": 24,
    "name": "Blanche Conley",
    "gender": "female",
    "company": "Imkan",
    "age": 53
  },
  {
    "id": 25,
    "name": "Claire Menz",
    "gender": "female",
    "company": "AEA",
    "age": 25
  },
  {
    "id": 26,
    "name": "Frank Martin",
    "gender": "male",
    "company": "AEA",
    "age": 26
  },
  {
    "id": 27,
    "name": "Blanca Cloe",
    "gender": "female",
    "company": "AEA",
    "age": 24
  }
]
const columns = [
  {
    dataField: 'id',
    text: 'ID',
    sort: true,
    headerStyle: { width: '10%' }
  },
  {
    dataField: 'name',
    text: 'Customer Name',
    sort: true,
    headerStyle: { width: '30%' },
    formatter: imageFormatter,
    // formatExtraData: {
    //   profileImage: '../../../src/assets/images/profiles/profile-pic-6.jpg',
    // }
  },
  {
    dataField: 'gender',
    text: 'Gender'
  },
  {
    dataField: 'company',
    text: 'Company'
  },
  {
    dataField: 'age',
    text: 'Age',
    sort: true
  }
];

function imageFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{ height: '35px', width: '35px', borderRadius: '4px', overflow: 'hidden', marginRight: '1rem' }}
      >
        <img src={require(`../../assets/images/profiles/profile-pic-${row.id}.jpg`)} width="100%" />
      </div>
      {cell}
    </div>
  );
}

const rowClasses = (row, rowIndex) => {
  let classes = null;

  if ((rowIndex + 1) % 2) {
    classes = 'custom-data-table-row-striped-color';
  }

  return classes;
};
const customPaginationTotal = (from, to, size) => {
  return (
    <span className="react-bootstrap-table-pagination-total">
      Showing { from} to { to} of { size} Results
    </span>
  );
};

const customPaginationOptions = {
  paginationSize: 4,
  pageStartIndex: 0,
  // alwaysShowAllBtns: true, // Always show next and previous button
  // withFirstAndLast: false, // Hide the going to First and Last page button
  // hideSizePerPage: true, // Hide the sizePerPage dropdown always
  // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
  firstPageText: 'First',
  prePageText: 'Back',
  nextPageText: 'Next',
  lastPageText: 'Last',
  nextPageTitle: 'First page',
  prePageTitle: 'Pre page',
  firstPageTitle: 'Next page',
  lastPageTitle: 'Last page',
  showTotal: true,
  paginationTotalRenderer: customPaginationTotal,
  disablePageTitle: true,
  sizePerPageList: [
    { text: '10', value: 10 },
    { text: '5', value: 5 },
    { text: '20', value: 20 },
    { text: 'All', value: products.length }
  ] // A numeric array is also available. the purpose of above example is custom the text
};
const { SearchBar } = Search;


class Dashboard2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      barSeriesDash2: [
        { name: 'Offline Sale', data: [44, 55, 57, 56, 61, 58, 63, 60, 46] },
        { name: 'Online Sale', data: [76, 85, 101, 98, 87, 105, 91, 114, 94] }
      ],
      barOptionsDash2: {
        grid: { borderColor: '#eee' },
        dataLabels: { enabled: false },
        stroke: { show: true, width: 4, colors: ['transparent'] },
        fill: { opacity: 1, colors: ['#f18f1e', '#005bea'] },
        chart: { type: 'bar', height: '100%', toolbar: { show: false } },
        xaxis: { categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'], },
        // yaxis: { title: { text: '$ (thousands)' } },
        tooltip: { y: { formatter: function (val) { return " " + val } }, marker: { colors: ['#f18f1e', '#005bea'] } },
        plotOptions: { bar: { horizontal: false, columnWidth: '70%', endingShape: 'rounded' } },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          itemMargin: { horizontal: 10, vertical: 0 },
          markers: { fillColors: ['#f18f1e', '#005bea'] }
          // '#3b3ee9'
        }
      },

      stockCircleSeries: [82],
      stockCircleOptions: {
        chart: { type: 'radialBar' },
        fill: { opacity: 1, colors: ['#029666'] },
        plotOptions: {
          radialBar: {
            hollow: { size: '80%' },
            track: {
              show: true,
              background: '#eee',
              strokeWidth: '20%',
              opacity: 1,
              margin: 0,
            },
            dataLabels: {
              show: true,
              name: { show: false },
              value: {
                show: true,
                fontSize: '30px',
                fontFamily: undefined,
                fontWeight: 200,
                color: undefined,
                offsetY: 12,
                formatter: function (val) { return val + "%" }
              },
            }
          },
        },
      },

      onlineSaleCircleSeries: [89],
      onlineSaleCircleOptions: {
        chart: { type: 'radialBar' },
        fill: { opacity: 1, colors: ['#005bea'] },
        plotOptions: {
          radialBar: {
            hollow: { size: '80%' },
            track: {
              show: true,
              background: '#eee',
              strokeWidth: '20%',
              opacity: 1,
              margin: 0,
            },
            dataLabels: {
              show: true,
              name: { show: false },
              value: {
                show: true,
                fontSize: '20px',
                fontFamily: undefined,
                fontWeight: 200,
                color: undefined,
                offsetY: 8,
                formatter: function (val) { return val + "%" }
              },
            }
          },
        },
      },

      offlineSaleCircleSeries: [56],
      offlineSaleCircleOptions: {
        chart: { type: 'radialBar' },
        fill: { opacity: 1, colors: ['#f18f1e'] },
        plotOptions: {
          radialBar: {
            hollow: { size: '80%' },
            track: {
              show: true,
              background: '#eee',
              strokeWidth: '20%',
              opacity: 1,
              margin: 0,
            },
            dataLabels: {
              show: true,
              name: { show: false },
              value: {
                show: true,
                fontSize: '20px',
                fontFamily: undefined,
                fontWeight: 200,
                color: undefined,
                offsetY: 8,
                formatter: function (val) { return val + "%" }
              },
            }
          },
        },
      },

      salesAreaSeries: [{
        name: 'Online Sales',
        data: [89, 70, 28, 51, 42, 109, 100]
      }, {
        name: 'Offline Sales',
        data: [79, 52, 26, 32, 34, 49, 41]
      }],
      salesAreaOptions: {
        chart: { height: 350, type: 'area', toolbar: { show: false } },
        dataLabels: { enabled: false },
        stroke: { curve: 'smooth', colors: ['#005bea', '#f18f1e'] },
        fill: { opacity: 1, colors: ['#005bea', '#f18f1e'] },
        xaxis: {
          type: 'month',
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July"]
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          itemMargin: { horizontal: 25 },
          markers: { fillColors: ['#005bea', '#f18f1e'] }
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
      },

      radialSeries: [89, 56],
      radialOptions: {
        chart: { type: 'radialBar', },
        labels: ['Online Sales', 'Offline Sales'],
        fill: { opacity: 1, colors: ['#005bea', '#f18f1e'] },
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: { fontSize: '22px' },
              value: { fontSize: '16px' },
              style: { colors: ['#005bea', '#f18f1e'] },
              total: {
                show: true, label: 'Total', formatter: function (w) {
                  // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                  return 4896
                }
              }
            }
          }
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'center',
          itemMargin: { horizontal: 10 },
          markers: { fillColors: ['#005bea', '#f18f1e'] }
        }
      },
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pt-2 pl-0 pr-0">

          <Row className="mt-4">
            <Col xl={3} md={6} className="d-flex">
              <Card className="borderless-shadow dash2-top-card-main-container" style={{ width: '100%' }}>
                <CardBody>
                  <div className="dash2-top-card-inc-dec ml-2">
                    <i className="far fa-arrow-alt-circle-up mr-1"></i>
                    <span>12%</span>
                  </div>

                  <div className="dash2-top-card-detail-container">
                    <div className="dash2-topcard-icon-container-blue">
                      <i className="fas fa-store font-size-25"></i>
                    </div>
                    <div className="mr-2">
                      <p className="font-size-22 font-weight-semibold mb-0">3,156</p>
                      <p className="font-size-14 font-weight-semibold text-muted mb-0">Sales Online</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6} className="d-flex">
              <Card className="borderless-shadow dash2-top-card-main-container" style={{ width: '100%' }}>
                <CardBody>
                  <div className="dash2-top-card-inc-dec ml-2">
                    <i className="far fa-arrow-alt-circle-down mr-1"></i>
                    <span>21%</span>
                  </div>

                  <div className="dash2-top-card-detail-container">
                    <div className="dash2-topcard-icon-container-orange">
                      <i className="fas fa-shopping-bag font-size-25"></i>
                    </div>
                    <div className="mr-2">
                      <p className="font-size-22 font-weight-semibold mb-0">1,740</p>
                      <p className="font-size-14 font-weight-semibold text-muted mb-0">Sales Offline</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6} className="d-flex">
              <Card className="borderless-shadow top-card-main-container" style={{ width: '100%' }}>
                <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className="dash2-top-card-inc-dec ml-2">
                    <i className="far fa-arrow-alt-circle-up mr-1"></i>
                    <span>16%</span>
                  </div>

                  <div className="dash2-top-card-detail-container" style={{ flex: 1 }}>
                    <div className="dash2-topcard-icon-container-green">
                      <i className="fas fa-dollar-sign font-size-25"></i>
                    </div>
                    <div className="mr-2">
                      <p className="font-size-22 font-weight-semibold mb-0">$3,199</p>
                      <p className="font-size-14 font-weight-semibold text-muted mb-0">Earnings</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6} className="d-flex">
              <Card className="borderless-shadow top-card-main-container" style={{ width: '100%' }}>
                <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className="dash2-top-card-inc-dec ml-2">
                    <i className="far fa-check-circle mr-1"></i>
                    <span>No Dues</span>
                  </div>

                  <div className="dash2-top-card-detail-container" style={{ flex: 1 }}>
                    <div className="dash2-topcard-icon-container-red">
                      <i className="fas fa-wallet font-size-25"></i>
                    </div>
                    <div className="mr-2">
                      <p className="font-size-22 font-weight-semibold mb-0">$78,190</p>
                      <p className="font-size-14 font-weight-semibold text-muted mb-0">Balance</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={8} className="d-flex">
              <Card className="borderless-shadow w-100">
                <CardBody className="pt-3 pl-0 pr-0 pb-0">
                  <Row style={{ display: 'felx', alignItems: 'center', height: '100%' }}>
                    <Col md={8} className="pdr-breakpoint pr-0" style={{ maxHeight: '525px' }}>
                      <ReactApexChart
                        options={this.state.barOptionsDash2}
                        series={this.state.barSeriesDash2}
                        type="bar"
                        height={350}
                      />
                    </Col>
                    <Col md={4} className="pdl-breakpoint pl-0">
                      <div>
                        <ReactApexChart options={this.state.stockCircleOptions} series={this.state.stockCircleSeries} type="radialBar" />
                      </div>
                      <div>
                        <p className="font-size-20 font-weight-semibold text-center">Product in Stock</p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4}>
              <Row style={{ height: '100%' }}>
                <Col xl={12} md={6} className="d-flex">
                  <Card className="borderless-shadow" style={{ flexGrow: 1 }}>
                    <CardBody className="pt-2 pl-0 pb-2" style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className='radial-sales-container'>
                          <ReactApexChart options={this.state.onlineSaleCircleOptions} series={this.state.onlineSaleCircleSeries} type="radialBar" />
                        </div>
                        <div>
                          <h6 className="font-size-20 font-weight-semibold">Online Sales</h6>
                          <span className="font-size-12 text-muted">Keeping track of online sales</span>
                        </div>

                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl={12} md={6} className="d-flex">
                  <Card className="borderless-shadow" style={{ flexGrow: 1 }}>
                    <CardBody className="pt-2 pl-0 pb-2" style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className='radial-sales-container'>
                          <ReactApexChart options={this.state.offlineSaleCircleOptions} series={this.state.offlineSaleCircleSeries} type="radialBar" />
                        </div>
                        <div>
                          <h6 className="font-size-20 font-weight-semibold">Offline Sales</h6>
                          <span className="font-size-13 text-muted">Keeping track of offline sales.</span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

            </Col>
          </Row>

          <Row>
            <Col xl={6} className="d-flex">
              <Card className="borderless-shadow dash2-top-card-main-container w-100">
                <CardBody className="pl-0 pb-0 pr-0">
                  <ReactApexChart options={this.state.salesAreaOptions} series={this.state.salesAreaSeries} type="area" height={320} />
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Row style={{ height: "100%" }}>
                <Col lg={12} className="d-flex">
                  <Card className="borderless-shadow dash2-top-card-main-container w-100">
                    <CardBody className="pt-3 pb-3">
                      <h6 className="font-size-18 mb-2">Online Sales</h6>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <i className="fas fa-money-bill-alt dash2-online-money-icon"></i>
                        <p className="dash2-online-money ml-3 mb-0">$12,789</p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg={12} className="d-flex">
                  <Card className="borderless-shadow dash2-top-card-main-container w-100">
                    <CardBody className="pt-3 pb-3">
                      <h6 className="font-size-18 mb-2">Offline Sales</h6>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <i className="fas fa-money-bill-alt dash2-offline-money-icon"></i>
                        <p className="dash2-offline-money ml-3 mb-0">$5,150</p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg={12} className="d-flex">
                  <Card className="borderless-shadow dash2-top-card-main-container w-100">
                    <CardBody className="pt-3 pb-3">
                      <h6 className="font-size-18 mb-2">Earnings</h6>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <i className="fas fa-money-bill-alt dash2-earning-money-icon"></i>
                        <p className="dash2-earning-money ml-3 mb-0">$3,199</p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xl={3} md={6} className="d-flex">
              <Card className="borderless-shadow dash2-top-card-main-container w-100">
                <CardBody className="pt-3 pr-0 pl-0 pb-0">
                  <h6 className="font-size-18 text-center">Total product sales</h6>
                  <p className="font-size-14 text-muted text-center mb-4">Online vs. Offline Sales</p>
                  <ReactApexChart
                    options={this.state.radialOptions}
                    series={this.state.radialSeries}
                    type="radialBar" height={280}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className="borderless-shadow mb-0">
                <CardBody>
                  <div className="table-overflow-x">
                    <ToolkitProvider
                      bootstrap4
                      keyField='id'
                      data={products}
                      columns={columns}
                      hover
                      search
                    >
                      {
                        props => (
                          <div>
                            <div
                              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}
                              className="mb-3"
                            >
                              <h3 className="mb-2 font-size-22 font-weight-semibold">Customer Data Table</h3>
                              <SearchBar
                                {...props.searchProps}
                                className="dash2-data-table-serach-input"
                                delay={500}
                                placeholder="Search the Data Table"
                              />
                            </div>
                            <BootstrapTable
                              pagination={paginationFactory(customPaginationOptions)}
                              rowClasses={rowClasses}
                              {...props.baseProps}
                            />
                          </div>
                        )
                      }
                    </ToolkitProvider>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </div>
    );
  }
}

export default Dashboard2;